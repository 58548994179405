<template>
    <section>
        <h3 v-if="!dismissable && localizedAlert">
            {{ $t('alert.currentAlert') }}
        </h3>
        <b-message
            v-if="localizedAlert && (!dismissed || !dismissable)"
            :type="`is-${type}`"
            :closable="dismissable"
            class="alert shadow"
            :class="{ dismissable: dismissable }"
            @close="dismiss"
        >
            <template #header>
                <div class="alert-header">
                    <editor
                        :content="localizedAlert"
                        :editable="false"
                    />
                </div>
            </template>
        </b-message>
    </section>
</template>

<script>
import { lowerCase, } from 'lodash'

import Editor from '@/components/common/editor/Editor.vue'

export default {
    name: 'AlertMessage',
    components: { Editor, },
    props: {
        alert: {
            type: Object,
            default: null,
        },
        dismissable: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        const multiLangMessage = JSON.parse(this.alert.message)
        return {
            type: lowerCase(this.alert?.types) || null,
            localizedAlert: multiLangMessage[this.$root.currentLocale] || null,
            lSAlert: JSON.parse(window.localStorage.getItem('alerts')) || { dismissed: [], },
        }
    },
    computed: {
        dismissed () {
            return this.lSAlert.dismissed.indexOf(this.alert.id) >= 0
        },
    },
    methods: {
        dismiss () {
            this.lSAlert.dismissed.push(this.alert.id)
            window.localStorage.setItem('alerts', JSON.stringify(this.lSAlert))
            this.$buefy.snackbar.open({
                message: this.$i18n.t('alert.messageClosed'),
                type: 'is-info',
                position: 'is-bottom',
                duration: 5000, // 3 sec
                pauseOnHover: true,
                actionText: null,
            })
        },
    },
}
</script>

<style lang="scss">
.alert .message-header {
    border-radius: 6px;
}

.alert .message-header .delete {
    background-color: white!important;
}

.alert-header {
    display: flex;
    flex-flow: row;
    align-items: center;

    .editor {
        border: none;
    }
}

.alert-header {
    display: flex;
    flex-flow: row;
    align-items: center;

    .editor {
        border: none;
    }
}
</style>
