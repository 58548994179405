export default {
    "de": {
        "agreements": {
            "create": {
                "agreementsList": "Vereinbarungen",
                "breadcrumb": "Hinzufügen",
                "feedback": "Vereinbarung erfolgreich hinzugefügt",
                "title": "Eine neue Vereinbarung hinzufügen"
            },
            "edit": {
                "agreementsList": "Vereinbarungen",
                "breadcrumb": "Bearbeiten",
                "feedback": "Vereinbarung erfolgreich aktualisiert",
                "title": "Vereinbarung bearbeiten"
            },
            "form": {
                "alreadyExists": "Es besteht bereits eine Vereinbarung zwischen {cpo} - {emsp}",
                "cpo": "Netzwerk",
                "emsp": "Anbieter",
                "emspPlaceholder": "Anbieter suchen",
                "includeSubCpos": "Sub-Netzwerke einbeziehen",
                "includeSubEmsps": "Sub-Anbieter einbeziehen",
                "isActive": "Aktiv",
                "ocpiModules": "OCPI-Module",
                "searchPlaceholder": "Hier eingeben",
                "submit": "Speichern",
                "tariffPlaceholder": "Kein Tarif definiert",
                "viaRoaming": "Über die Interoperabilitätsplattform"
            },
            "list": {
                "addAgreement": "Vereinbarung hinzufügen",
                "autoliquidation": "Umkehrung der Steuerschuldnerschaft",
                "breadcrumb": "Vereinbarungen (Liste)",
                "cpoId": "Netzwerk-ID",
                "directFilter": "Einschließlich der Netzwerke der Eltern",
                "emspId": "Anbieter-ID",
                "includeSubCpos": "Sub-Netzwerke einbeziehen",
                "includeSubEmsps": "Sub-Anbieter einbeziehen",
                "no": "Nein",
                "noOcpiModules": "Keine OCPI-Module",
                "ocpiModules": "OCPI-Module",
                "optionsSelected": "ausgewählte Optionen",
                "tariff": "Spezifischer Tarif",
                "title": "Vereinbarungen",
                "updated_at": "Letzte Aktualisierung",
                "yes": "Ja"
            },
            "show": {
                "agreements": "Vereinbarungen",
                "bridges": "Brücken",
                "cpo": "Netzwerk",
                "edit": "Bearbeiten",
                "emsp": "Anbieter",
                "false": "Falsch",
                "includeSubCpos": "Sub-Netzwerke einbeziehen",
                "includeSubEmsps": "Sub-Anbieter einbeziehen",
                "lastUpdate": "Letzte Aktualisierung",
                "noBridges": "Direkt",
                "true": "Wahr"
            }
        },
        "alert": {
            "alerts": "Warnungen",
            "colorTitle": "Farbe der Nachricht",
            "confirmPublish": "Sind Sie sicher, dass Sie diese Warnung veröffentlichen möchten ?",
            "confirmUnPublish": "Sind Sie sicher, dass Sie diese Warnung entfernen möchten ?",
            "createTitle": "Eine neue Warnung erstellen",
            "currentAlert": "Aktuelle Warnung",
            "delete": "Aktuelle Ausschreibung löschen",
            "disableFailed": "Die Ausschreibung konnte nicht gelöscht werden",
            "disabled": "Die Warnung wurde entfernt",
            "editStatuses": "Status der Warnungen bearbeiten",
            "editTitle": "Aktuelle Warnung bearbeiten",
            "messageClosed": "Sie finden diese Warnungen auf der Registerkarte \"Einstellungen\"",
            "newAlert": "Neue Warnung",
            "publishFailed": "Warnung konnte nicht veröffentlicht werden",
            "publishSuccessful": "Die Warnung wurde tatsächlich veröffentlicht",
            "showOnlyActive": "Nur aktive Warnungen anzeigen",
            "title": "Verwalten von Warnmeldungen",
            "type": {
                "danger": "Vorfall",
                "info": "Informationen",
                "success": "Auflösung",
                "warning": "Wartung"
            },
            "unPublishFailed": "Die Ausschreibung konnte nicht gelöscht werden",
            "unPublishSuccessful": "Die Warnung wurde entfernt.",
            "updateFailed": "Löschen der Warnung fehlgeschlagen",
            "updated": "Die Warnung wurde geändert"
        },
        "audit": {
            "ACTION": "Aktion durchgeführt",
            "CREATE": "Hinzugefügt",
            "DELETE": "Entfernt",
            "READ": "Konsultiert",
            "RESTORE": "Restauriert",
            "UPDATE": "Aktualisiert",
            "UPDATE_TRANSLATION": "Aktualisierte Übersetzungen",
            "breadcrumb": "Aktivität",
            "noActivity": "Keine Aktivität",
            "title": "Aktivität von {name}",
            "via": {
                "BACKOFFICE": "Back-Office",
                "CLI": "CLI",
                "JOBS": "Automatische Aufgabe",
                "NOVA": "Back-Office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "SUPERVISION": "Überwachung"
            }
        },
        "auth": {
            "confirmPassword": "Passwort bestätigen",
            "emailSent": "E-Mail gesendet",
            "failed": "Falsches Passwort oder E-Mail",
            "login": "Anmeldung",
            "passResetSuccess": "Kennwort erfolgreich zurückgesetzt",
            "permanent_ban": "Aufgrund zu vieler Anmeldeversuche wurde Ihr Konto deaktiviert. Bitte wenden Sie sich an Ihren Administrator.",
            "register": "Anmelden",
            "reset": "Passwort zurücksetzen",
            "sendLink": "Link zum Zurücksetzen des Passworts senden",
            "successLogout": "Erfolgreich abgemeldet",
            "temporary_ban": "Sie haben zu viele Anmeldeversuche unternommen: Bitte versuchen Sie es später erneut.",
            "throttle": "Zu viele Versuche. Bitte versuchen Sie es noch einmal in {seconds} Sekunden"
        },
        "breadcrumbs": {
            "operators": {
                "create": "Create",
                "list ": "Operators (list)",
                "operators": "Operators"
            }
        },
        "businessLogs": {
            "breadcrumb": "Logs",
            "byEvse": "Filter by EVSE",
            "context": "Context",
            "contextValues": {
                "CLI": "Server",
                "JOBS": "Task",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "OCPPJ": "OCPP-J",
                "SUPERVISION": "Supervision",
                "WEBSOCKET": "Websocket"
            },
            "diagnosticStatus": {
                "UploadFailed": "Failed to send diagnostic",
                "Uploaded": "Diagnostic successfully sent"
            },
            "event": "Event",
            "eventValues": {
                "ALREADY_COMPLETED_SESSION_ENDED": "End of already completed session",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Price asked by already completed session",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Energy updated by already completed session",
                "ALREADY_COMPLETED_SESSION_STARTED": "Start of already completed session",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "Rearming device",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "Start outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Change availability",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Clear cache",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Get diagnostics",
                "ATTEMPTING_REMOTE_RESET": "Reset",
                "ATTEMPTING_REMOTE_START": "Remote start",
                "ATTEMPTING_REMOTE_STOP": "Remote stop",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Unlock",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Update firmware",
                "CONNECTOR_STATUS_UPDATED": "Connector status",
                "DETECTION_SENSOR_BLOCKED": "Detection sensor blocked",
                "DETECTION_SENSOR_IGNORED": "Detection sensor ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Detection sensor unblocked",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Detection sensor unknown connector",
                "DIAGNOSTIC_STATUS": "Diagnostic status",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "Device rearmed successfully",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "Failure to rearm device",
                "ERCOGENER_START_OUTLET_FAILURE": "Outlet started successfully",
                "ERCOGENER_START_OUTLET_SUCCESS": "Failure to start outlet",
                "EVSE_STATUS_UPDATED": "EVSE status",
                "FIRMWARE_STATUS": "Firmware status",
                "LAN_DEVICE_BOOTED": "Charger boot",
                "LAN_DEVICE_DOWN": "Charger offline",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Firmware updated",
                "LAN_DEVICE_MODEL_CHANGE": "Model updated",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Protocol updated",
                "LAN_DEVICE_UP": "Charger online",
                "LAN_DEVICE_VENDOR_CHANGE": "Vendor updated",
                "LAN_PUBLIC_IP_CHANGE": "IP updated",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "Payment terminal offline failure report",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "Payment terminal offline success report",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment terminal online failure report",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment terminal online success report",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Change availability failure",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Change availability success",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Failed to get diagnostics",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Diagnostics successfully got",
                "REMOTE_RESET_FAILURE": "Failed to reset",
                "REMOTE_RESET_SUCCESS": "Successfully reset",
                "REMOTE_START_FAILURE": "Remote start failure",
                "REMOTE_START_SUCCESS": "Remote start success",
                "REMOTE_STOP_FAILURE": "Remote stop failure",
                "REMOTE_STOP_SUCCESS": "Remote stop success",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Unlock failure",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Unlock success",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Update firmware failure",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Update firmware success",
                "SESSION_AUTHORIZED": "Session authorized",
                "SESSION_DENIED": "Session denied",
                "SESSION_ENDED": "Session ended",
                "SESSION_ENERGY_UPDATED": "Energy updated",
                "SESSION_FORCED": "Session forced",
                "SESSION_STARTED": "Session started",
                "SESSION_START_BLOCKED": "Session start blocked",
                "SESSION_TIMED_OUT": "Session timed out",
                "SESSION_TOTAL_COST_UPDATED": "Price",
                "TOKEN_AUTHORIZE_ACCEPTED": "Token authorized",
                "TOKEN_AUTHORIZE_DENIED": "Token not authorized",
                "TOKEN_AUTHORIZE_UNKNOWN": "Token not recognized",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector status",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session ended",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Energy updated by unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session started"
            },
            "evseStatusUpdated": {
                "administrative": "Administrative status set from {previous_status} to {current_status}",
                "charging": "Charging status set from {previous_status} to {current_status}",
                "message": "EVSE {name} set from {previous_status} to {current_status}. Reason: {status_reason}",
                "operational": "Operational status set from {previous_status} to {current_status}"
            },
            "filterPlaceholder": "Search by context",
            "firmwareStatus": {
                "DownloadFailed": "Download firmware failure",
                "Downloaded": "Download firmware success",
                "InstallationFailed": "Install firmware failure",
                "Installed": "Install firmware success"
            },
            "lanDeviceStatus": {
                "down": "is no longer connected to the server",
                "up": "is now connected to the server"
            },
            "level": {
                "ALERT": "Alert log",
                "CRITICAL": "Critical log",
                "DEBUG": "Debug log",
                "EMERGENCY": "Emergency log",
                "ERROR": "Error log",
                "INFO": "Info log",
                "NOTICE": "Notice log",
                "WARNING": "Warning log"
            },
            "message": "Message",
            "messages": {
                "ALREADY_COMPLETED_SESSION_ENDED": "Session {sessionLink}, already ended, has been ended by charger {chargeboxid}",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Charger {chargeboxid} is asking price for session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Charger {chargeboxid} updated consumption of session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_STARTED": "Session {sessionLink} already ended has been authorized by charger {chargeboxid}",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "(Ercogener) Attempt to rearm device {chargeboxid}",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "(Ercogener) Attempt to start outlet",
                "ATTEMPTING_ERCOGENER_STOP_OUTLET": "(Ercogener) Attempt to stop outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Attempt to set station {chargeboxid} to state {availability}",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Attempt to clear cache of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Attempt to get diagonistcs of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_RESET": "Attempting to reset charger {chargeboxid}",
                "ATTEMPTING_REMOTE_START": "Attempting to start a session on charger {chargeboxid} and on connector {connector_id}",
                "ATTEMPTING_REMOTE_STOP": "Attempting to stop session {session} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Attempting to unlock connector {connector_id} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Attempting to update firmware of charger {chargeboxid} (filename: {filename})",
                "CONNECTOR_STATUS_UPDATED": "is set from {previous_status} to {current_status}",
                "DETECTION_SENSOR_BLOCKED": "An obstacle is detected on EVSE {evse}",
                "DETECTION_SENSOR_IGNORED": "Some information of charger {chargeboxid} about an obstacle was ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Obstacle on EVSE {evse} removed",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Charger {chargeboxid} detected an obstacle on unknown connector {connector_id}",
                "DIAGNOSTIC_STATUS": "Charger {chargeboxid} {status}",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "(Ercogener) Failed to rearm device {chargeboxid}. Reason: {reason}",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "(Ercogener) Device {chargeboxid} rearmed",
                "ERCOGENER_START_OUTLET_FAILURE": "(Ercogener) Failed to start outlet. Reason: reason",
                "ERCOGENER_START_OUTLET_SUCCESS": "(Ercogener) Outlet started",
                "ERCOGENER_STOP_OUTLET_FAILURE": "(Ercogener) Failed to stop outlet. Reason: reason",
                "ERCOGENER_STOP_OUTLET_SUCCESS": "(Ercogener) Outlet stopped",
                "FIRMWARE_STATUS": "Charger {chargeboxid} {status}",
                "LAN_DEVICE": "Charger {chargeboxid} {lan_device_status}",
                "LAN_DEVICE_BOOTED": "Charger {chargeboxid} booted",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Charger firmware {chargeboxid} is now {current_firmware} (beforehand {previous_firmware})",
                "LAN_DEVICE_MODEL_CHANGE": "Charger model {chargeboxid} is now {current_model} (beforehand {previous_model})",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Charger {chargeboxid} now uses {current_protocol} (beforehand {previous_protocol})",
                "LAN_DEVICE_VENDOR_CHANGE": "Charger vendor {chargeboxid} is now {current_vendor} (beforehand {previous_vendor})",
                "LAN_PUBLIC_IP_CHANGE": "LAN {lan} now has public IP {current_ip} (beforehand {previous_ip})",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId})",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId})",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Failed to set charger {chargeboxid} state to {availability}. Reason: {reason}",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Charger {chargeboxid} successfully set to state {availability}",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache of charger {chargeboxid}. Reason: {reason}",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache of charger {chargeboxid} successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Charger {chargeboxid} refused get diagnostics command. Reason: {reason}",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Charger {chargeboxid} accepted order, diagnostic will be generated soon",
                "REMOTE_RESET_FAILURE": "Failed to reset charger {chargeboxid}. Reason: {reason}",
                "REMOTE_RESET_SUCCESS": "Charger {chargeboxid} successfully reset",
                "REMOTE_START_FAILURE": "Failed to start a session on connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_START_SUCCESS": "Session start successfully allowed on connector {connector_id} on charger {chargeboxid}",
                "REMOTE_STOP_FAILURE": "Failed to stop session {session} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_STOP_SUCCESS": "Session {session} successfully stopped on charger {chargeboxid}",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Failed to unlock connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Connector {connector_id} successfully unlocked on charger {chargeboxid}",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Charger {chargeboxid} refused order to update firmware (filename: {filename}). Reason: {reason}",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Charger {chargeboxid} accepted order to update firmware (filename: {filename})",
                "SESSION_AUTHORIZED": "Session {session} authorized for contract {auth_id} (UID: {token_uid})",
                "SESSION_DENIED": "Session {sessionLink} with UID {token_uid} not authorized and will not be completed. Reason:",
                "SESSION_ENDED": "Session {session} is over. {invalid_msg} Consumption: {consumption}. Duration:",
                "SESSION_ENERGY_UPDATED": "Session {session} consumption {consumption} (total: {current_wh})",
                "SESSION_FORCED": "Session {sessionLink} with UID {token_uid} started without authorization",
                "SESSION_STARTED": "Session {session} has started. Contract: {auth_id}. UID: {token_uid}",
                "SESSION_START_BLOCKED": "Unknown session blocked by tag {idTag} on charger {chargeboxid}",
                "SESSION_TIMED_OUT": "Session {sessionLink} (authorized at {authorizeDate}) expired and will not be completed",
                "SESSION_TOTAL_COST_UPDATED": "Session {session} cost {current_total_cost} (beforehand {previous_total_cost})",
                "TOKEN_AUTHORIZE_ACCEPTED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-success\">authorized<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<strong>",
                "TOKEN_AUTHORIZE_DENIED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-danger\">did not authorize<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<\/strong> for the following reason: ({allowed}) <strong>{reason}<\/strong>",
                "TOKEN_AUTHORIZE_UNKNOWN": "<strong class=\"has-text-warning\">No provider<\/strong> recognized token <strong>{tokenUid}<\/strong>",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector {connector_id} set to {status}",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session stopped by charger {chargeboxid}",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Consumption updated by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session authorized by charger {chargeboxid}",
                "default": "Sorry, no interpretation possible",
                "moreDetails": "See details"
            },
            "noContextualData": "No contextual data available",
            "orderSentBy": "Order sent by {email}",
            "paymentTerminal": {
                "errorMessage": "Error message:",
                "noErrorMessage": "No error message",
                "noReceipt": "No receipt for this session",
                "number": "Payment terminal number: {number}",
                "receiptData": "Receipt data:"
            },
            "remoteFailedReason": {
                "NOT_SUPPORTED": "Order not supported by charger",
                "REJECTED": "Order rejected by charger",
                "TIMEOUT": "No response from charger",
                "default": "Unknown reason",
                "null": "Unknown reason"
            },
            "sessionStatus": {
                "invalid": "Session failed"
            },
            "statusReason": {
                "ADMINISTRATIVE_DECLARATION": "Administrative status updated by a supervisor",
                "ALL_CONNECTORS_FAULTED": "All connectors are faulted",
                "ALL_CONNECTORS_UNAVAILABLE": "All connectors are unavailable",
                "BLOCKED_EVSE": "Access to EVSE is blocked by an obstacle",
                "NO_CONNECTOR": "EVSE has no connector",
                "NO_LAN_DEVICE": "EVSE is attached to no charger",
                "OFFLINE_LAN_DEVICE": "Charger is offline",
                "ONGOING_RESERVATION": "A reservation is ongoing",
                "ONGOING_SESSION": "A session is ongoing",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Ordered by the electricity supplier",
                "default": "Everything is fine",
                "null": "Unknown reason"
            },
            "title": "Business logs {name}"
        },
        "cdrs": {
            "csv": {
                "confirmTitle": "CSV von {from} bis {to}",
                "default": "Bestätigen Sie den Export?",
                "tooHeavy": "Die Datei wird gerade generiert und an {email} zugeschickt"
            },
            "list": {
                "authId": "Vertrag",
                "breadcrumb": "CDRs",
                "connector": "Stecker",
                "cpo": "Netzwerk",
                "created": "Erstellt bei",
                "emsp": "Anbieter",
                "evse": "LP",
                "externalRef": "Externe referenz",
                "lastSent": "letzte Lieferung",
                "location": "Standort",
                "ref": "Reference",
                "scopeTitle": "CDRs von {name}",
                "startDateTime": "Start",
                "stopDateTime": "Ende",
                "title": "CDRs",
                "totalCost": "Total price",
                "totalEnergy": "kWh",
                "vat": "MwSt.",
                "withAdvenir": "With CDRs for Advenir",
                "withChildrenOperators": "Vorgänge aus Sub-Netzwerken"
            },
            "show": {
                "auth": "Authorisation ID",
                "badge": "Tag ID",
                "breadcrumb": "CDR",
                "cdrOfSessionRef": "CDR of #{ref}",
                "cost": "Price",
                "energy": "Energy",
                "exportToPDF": "Export to PDF",
                "isWhiteListed": "is white-listed",
                "preTaxPrice": "Price excl. VAT",
                "ref": "Reference",
                "relations": "Relations",
                "remark": "Comments",
                "title": "Charge details record #{ref}",
                "totalCost": "Total price",
                "totalParkingTime": "Parking time",
                "totalTime": "Total time"
            }
        },
        "common": {
            "all": "All",
            "cpo": "CPO",
            "edit": "Edit",
            "emsp": "EMSP",
            "false": "False",
            "globalError": "There was an error",
            "isActive": "Active",
            "no": "Nein",
            "notDefined": "Not defined",
            "ocpiModules": "OCPI Modules",
            "submit": "Submit",
            "true": "True",
            "type": "Type",
            "updated_at": "Updated at",
            "yes": "Ja"
        },
        "commons": {
            "From": "Von",
            "Now": "Jetzt",
            "To": "An",
            "active": "Aktiv",
            "add": "Neu",
            "agreement": "Vereinbarung",
            "all": "Alle",
            "asyncSearch": "Hier eingeben",
            "authId": "Vertrag",
            "autoRefreshDisabled": "Automatische Aktualisierung jetzt nicht verfügbar",
            "autoRefreshOff": "Auto-Aktualisierung aus",
            "autoRefreshOn": "Auto-Aktualisierung ein",
            "back": "Zurück",
            "by": "von {user}",
            "cancel": "Abbrechen",
            "clear": "Leeren",
            "common": "Allgemein",
            "confirm": "Bestätigen",
            "cpo": "Netzwerk",
            "created_at": "Created date",
            "creationPlaceholder": "Erstellung",
            "curve": "Kurve",
            "date": "Datum",
            "dateRange": "Nach Datum filtern",
            "delete": "Delete",
            "durationUnits": {
                "days": "Tag(e)",
                "hours": "Stunde(n)",
                "minutes": "m",
                "months": "monat(e)",
                "seconds": "s"
            },
            "durationWithDays": "{nb_days} Tag(e) und {standard_duration}",
            "edit": "Bearbeiten",
            "edition": "{name}",
            "emi3": "Privates eMI3-ID",
            "emsp": "Anbieter",
            "end": "Ende",
            "error403": "Nicht autorisiert",
            "error404": "Seite nicht gefunden",
            "evse": "LP",
            "exclVat": "exkl. MwSt.",
            "export": "Exportieren",
            "exportToCSV": "Als CSV exportieren",
            "exportToPDF": "Als PDF exportieren",
            "false": "Falsch",
            "filterOn": "Filter",
            "from": "von",
            "fullPrice": "exkl. MwSt.: {exclVat} \/ MwSt.: {vat} \/ {vatRate} %",
            "globalError": "Ein Fehler ist aufgetreten",
            "histogram": "Histogramm",
            "hotline": "Hotline",
            "import": "Importieren",
            "inactive": "Inaktiv",
            "inclVat": "inkl. MwSt.",
            "isActive": "Aktiv",
            "kW": "kW",
            "kWh": "kWh",
            "last_update": "Letzte Aktualisierung",
            "location": "Standort",
            "locationsList": "Standorte",
            "month": "Monat",
            "no": "Nein",
            "noResult": "Kein Ergebnis",
            "noResultAsync": "Kein Ergebnis, hier eingeben",
            "noSmartphone": "Nicht verfügbar für Smartphone",
            "notDefined": "Undefiniert",
            "ocpiModules": "OCPI-Module",
            "of": "von {data}",
            "onlyCommaAllowed": "Nur \",\" ist als Trennzeichen erlaubt",
            "optional": "optional",
            "optionsSelected": "{number} ausgewählte Option(en)",
            "placeholderSelect": "Option auswählen",
            "preTaxCost": "{amount} exkl. MwSt., {VAT} MwSt.",
            "process": "Prozess",
            "quarter": "Quartal",
            "rangeHint": "Data from {from} to {to}",
            "reference": "Referenz",
            "refunds": "Erstattungen",
            "schedule": "Zeitplan",
            "search": "Suchen",
            "session": "Vorgang",
            "start": "Anfang",
            "status": "Status",
            "submit": "Bestätigen",
            "tagId": "Zeichen",
            "tariff": "Tarif",
            "to": "an",
            "today": "Today",
            "true": "Wahr",
            "type": "Typ",
            "updated_at": "Aktualisiert",
            "vat": "MwSt.",
            "vatRate": "Mehrwertsteuersätze",
            "year": "Jahr",
            "years": "Jahre",
            "yes": "Ja"
        },
        "connectors": {
            "actions": {
                "start": "Vorgang starten",
                "stop": "Vorgang stoppen",
                "unlock": "Freischalten"
            },
            "form": {
                "addConnectorTitle": "Stecker hinzufügen",
                "amps": "Amperage (A)",
                "cable": "Kabel angeschlossen",
                "create": "Stecker hinzufügen",
                "customPower": "Custom power",
                "delete": "Delete",
                "edit": "Bearbeiten",
                "editConnectorTitle": "Stecker bearbeiten",
                "invalid": "Stecker sind ungültig",
                "maxPower": "Max power: {maxPower} kW",
                "ocppNumber": "OCPP-Stecker-ID",
                "ocppNumberIsUnavailable": "ist nicht verfügbar",
                "ocppNumberUnavailable": "Dieser Stecker-ID ist nicht verfügbar",
                "power": "Leistung",
                "powerType": "Power type",
                "tariff": "Tarif",
                "type": "Typ",
                "voltage": "Voltage (V)"
            },
            "list": {
                "errorCode": "Fehlercode",
                "evseName": "LP Name",
                "evseRef": "LP Ref",
                "lastStatusChangeAvailable": "Gelöst seit",
                "lastStatusChangeIssue": "Fehler seit",
                "maintenanceAvailableTitle": "Stecker: Fehler gelöst",
                "maintenanceIssuesSubtitle": "Liste der Konnektoren, die mindestens 1 Stunde lang einen Fehlercode gemeldet haben",
                "maintenanceIssuesTitle": "Fehlercodes",
                "standard": "Typ \/ Leistung"
            },
            "powerTypes": {
                "AC_1_PHASE": "1 phase",
                "AC_3_PHASE": "3 phase",
                "DC": "Direct current"
            },
            "show": {
                "cable": "Kabel angeschlossen",
                "cannotRemove": "Mindestens ein Stecker ist erforderlich",
                "confirmDeletion": "Bitte bestätigen Sie die Löschung",
                "connectorCreated": "Stecker erstellt",
                "connectorEdited": "Stecker bearbeitet",
                "connectorRemoved": "Stecker entfernt",
                "defaultTariff": "Standardtarif",
                "localisation": "Standort",
                "locked": "Gesperrt",
                "noRestrictions": "Keine Einschränkung",
                "noTariff": "Kein Tarif definiert",
                "ocppNumber": "OCPP-Stecker-ID",
                "power": "Leistung",
                "socketFalse": "Kabel angeschlossen",
                "socketTrue": "Kein Kabel angeschlossen",
                "tariff": "Tarif",
                "tariffApply": "Angewandter Tarif",
                "title": "Stecker #",
                "type": "Typ",
                "unlocked": "Freigeschaltet"
            },
            "standard": {
                "chademo": "Chademo",
                "domestic_a": "Standard\/Domestic type A",
                "domestic_b": "Standard\/Domestic type B",
                "domestic_c": "Standard\/Domestic type C",
                "domestic_d": "Standard\/Domestic type D",
                "domestic_e": "Standard\/Domestic type E",
                "domestic_f": "Standard\/Domestic type F",
                "domestic_g": "Standard\/Domestic type G",
                "domestic_h": "Standard\/Domestic type H",
                "domestic_i": "Standard\/Domestic type I",
                "domestic_j": "Standard\/Domestic type J",
                "domestic_k": "Standard\/Domestic type K",
                "domestic_l": "Standard\/Domestic type L",
                "iec_60309_2_single_16": "IEC 60309-Industrial single phase 16",
                "iec_60309_2_three_16": "IEC 60309-Industrial three phase 16",
                "iec_60309_2_three_32": "IEC 60309-Industrial three phase 32",
                "iec_60309_2_three_64": "IEC 60309-Industrial three phase 64",
                "iec_62196_t1": "IEC 62196 Type 1",
                "iec_62196_t1_combo": "Combo Type 1",
                "iec_62196_t2": "IEC 62196 Type 2",
                "iec_62196_t2_combo": "Combo Type 2",
                "iec_62196_t3a": "IEC 62196 Type 3A",
                "iec_62196_t3c": "IEC 62196 Type 3C",
                "other": "Andere Stecker",
                "tesla_r": "Tesla Connector Roadster-type",
                "tesla_s": "Tesla Connector Model-S-type"
            },
            "status": {
                "available": "Verfügbar",
                "charging": "Lädt gerade",
                "faulted": "Fehlerhaft",
                "finishing": "Fertigstellung",
                "issue": "Problem",
                "occupied": "Besetzt",
                "preparing": "Vorbereitung",
                "reserved": "Reserviert",
                "suspendedev": "Ausgesetztes EV",
                "suspendedevse": "Ausgesetztes LP",
                "unavailable": "Nicht verfügbar"
            }
        },
        "contact-groups": {
            "actions": {
                "assignGroup": "Hinzufügen einer bestehenden Gruppe",
                "delete": "Löschen",
                "remove": "Entfernen",
                "replaceGroup": "Gruppe ersetzen"
            },
            "create": {
                "feedback": "Gruppe erfolgreich erstellt",
                "title": "Eine Kontaktgruppe erstellen"
            },
            "edit": {
                "addExistingGroup": "Eine bestehende Gruppe hinzufügen",
                "edit": "Bearbeiten",
                "feedback": "Gruppe erfolgreich bearbeitet",
                "searchContactGroups": "Kontaktgruppen suchen"
            },
            "form": {
                "affiliations": "Zugehörigkeiten",
                "assign": "zuweisen",
                "contacts": "Kontakte",
                "description": "Beschreibung",
                "info": "Informationen zur Gruppe",
                "language": "Gesprochene Sprache",
                "location": "Standort",
                "name": "Name der Gruppe",
                "nameExists": "Diese Gruppe existiert bereits",
                "network": "Netzwerk",
                "or": "oder",
                "planning": "Planung",
                "searchContacts": "Kontakte suchen",
                "searchContactsEmpty": "Add at least one contact to the group"
            },
            "list": {
                "confirmDelete": "Sind Sie sicher, dass Sie diese Kontaktgruppe löschen möchten?",
                "delete": "Löschen",
                "duplicate": "Duplizieren",
                "incompleteSchedule": "Warnung: Unvollständiger Zeitplan"
            },
            "planning": {
                "add": "Hinzufügen",
                "addContact": "Kontakt hinzufügen",
                "addContactTitle": "Kontakt für {Tag} hinzufügen",
                "addSchedule": "Einen Zeitplan hinzufügen",
                "begin": "Beginnen",
                "empty": "Kein Kontakt definiert",
                "end": "Ende",
                "incomplete": "Planung unvollständig",
                "schedule": "Planung",
                "selectContact": "Wählen Sie einen Kontakt"
            },
            "show": {
                "breadcrumb": "Kontaktgruppe {Name}",
                "contacts": "Kontakte",
                "description": "Beschreibung",
                "groupInfo": "Informationen zur Gruppe",
                "inheritedFrom": "geerbt von",
                "language": "Sprache",
                "name": "Name der Gruppe",
                "planningContactInfo": "Alle Kontakte in dieser Gruppe werden per E-Mail über einen Notfall informiert. Kontakte, die an den Tagen und zu den Zeiten des Notfalls im Terminplan eingetragen sind, werden über die ausgefüllte(n) Kontaktmöglichkeit(en) informiert: SMS und\/oder Anruf.",
                "scopeDeleteGroup": "Sind Sie sicher, dass Sie die Gruppe {group} löschen möchten? Mit dieser Aktion wird die Gruppe aus allen Netzen und Stationen, denen sie angehört, entfernt.",
                "scopeRemoveGroup": "Sind Sie sicher, dass Sie die Gruppe {group} aus dem Netz entfernen wollen?",
                "supportGroup": "Gruppe warten"
            },
            "toast": {
                "deleted": "Die Kontaktgruppe wurde gelöscht"
            }
        },
        "contacts": {
            "affiliations": {
                "actions": "Aktionen",
                "affiliationsOf": "Zuteilungen von",
                "deletePopupTextLocation": "Sind Sie sicher, dass Sie diese Zuteilung löschen möchten?",
                "deletePopupTextNetwork": "Sind Sie sicher, dass Sie diese Zuteilung löschen möchten? Das wird auch alle Zuteilungen zwischen dem Kontakt und den Standorten des Netzwerks löschen.",
                "deletePopupTitleLocation": "Zuteilung löschen",
                "deletePopupTitleNetwork": "Zuteilung löschen",
                "locations": "Standorte",
                "network": "Netzwerk",
                "role": "Rolle",
                "title": "Kontaktzuteilungen",
                "type": "Typ"
            },
            "breadcrumb": {
                "affiliations": "Zuteilungen",
                "contact": "Kontakte",
                "edit": "bearbeiten",
                "groups": "Kontaktgruppen"
            },
            "contactMethods": {
                "email": "E-Mail",
                "phone": "Anruf",
                "sms": "SMS"
            },
            "create": {
                "title": "Kontakt erstellen"
            },
            "edit": {
                "close": "Schließen",
                "edit": "bearbeiten",
                "editContactsTitle": "Kontakte bearbeiten"
            },
            "error": {
                "contactEmailAlreadyTakenAdmin": "E-Mail ist bereits vergeben",
                "contactEmailAlreadyTakenNotAdmin": "Der Kontakt existiert bereits. Um ihn Ihrem Netzwerk zuzuordnen, wenden Sie sich bitte an exploitation@freshmile.com"
            },
            "form": {
                "addContactTitle": "Kontakt erstellen",
                "alwaysOneOwnerContact": "Das Netzwerk muss mindestens einen Eigentümerkontakt haben.",
                "buttonAddContact": "Neuen Kontakt erstellen",
                "buttonAddType": "Typ hinzufügen",
                "buttonLink": "Verlinken",
                "call": "anrufen",
                "company": "Unternehmen",
                "contact_methods": "Kontaktmethode(n)",
                "contactsOfNetwork": "Netzwerkkontakte",
                "countrySelectorLabel": "Landesvorwahl",
                "editContactTitle": "Kontakt bearbeiten",
                "email": "E-Mail",
                "errorDeleteType": "Der Kontakt muss mindestens einen Typ haben",
                "errorUpdate": "Ein Fehler ist aufgetreten",
                "linkContactExisting": "Bestehenden Kontakt verlinken",
                "linkContactNotExisting": "Neuen Kontakt verlinken",
                "name": "Vollständiger Name",
                "nameExists": "Bereits verwendeter Name",
                "notesInput": "Notizen",
                "optional": "Optional",
                "phone_number": "Anruf",
                "placeholderUser": "Suchen nach Namen, E-Mail-Adresse",
                "searchContact": "Nach einem bestehenden Kontakt suchen",
                "sms_number": "SMS",
                "textSearch": "Suchen Sie nach einem bestehenden Kontakt und wählen Sie einen Typ, dann klicken Sie auf die Verknüpfungsschaltfläche.",
                "textSearchGroup": "Suchen Sie nach einem bestehenden Kontakt und klicken Sie auf die Verknüpfungsschaltfläche.",
                "titleContactsOfNetwork": "Kontakt(e)",
                "type": "Art des Kontakts",
                "userLink": "Verknüpfter Benutzer"
            },
            "groups": {
                "actions": "Aktionen",
                "addGroupContact": "Kontaktgruppe erstellen",
                "affiliationOfGroup": "Direkte Mitgliedschaft",
                "confirmDelete": "Möchten Sie den Kontakt wirklich aus dieser Kontaktgruppe entfernen?",
                "contact_methods": "Kontaktmethode(n)",
                "contacts": "Kontakte",
                "groupOfContact": "Kontaktgruppe",
                "groupOfContactPlaceholder": "Suchen Sie nach einer Kontaktgruppe",
                "modalTitle": "Einer Kontaktgruppe zum Kontakt hinzufügen",
                "nameOfGroup": "Name der Gruppe",
                "or": "ODER",
                "title": "Kontaktgruppen",
                "updated": "Aktualisiert"
            },
            "list": {
                "actions": "Aktionen",
                "affiliated": "Direktes Mitglied",
                "allTypes": "Alle Arten von Kontakten",
                "breadcrumb": "Kontakte",
                "confirmDelete": "Sind Sie sicher, dass Sie diesen Kontakt löschen möchten?",
                "contact_methods": "Kontakt-Methoden",
                "contacts": "Kontakte",
                "contactsGroups": "Kontakt-Gruppen",
                "email": "E-Mail",
                "filterType": "Nach Typ filtern",
                "groupName": "Name der Gruppe",
                "name": "Vollständiger Name",
                "phone_number": "Anruf",
                "sms_number": "SMS",
                "title": "Kontakte und Gruppen",
                "type": "Typ",
                "types": "Typs"
            },
            "show": {
                "accessContactPage": "Zur Kontaktseite gelangen",
                "company": "Unternehmen",
                "contactMethods": "Kontaktmethode(n):",
                "email": "E-Mail:",
                "groups": "Gruppen",
                "nameOfContact": "Name des Kontakts",
                "networkAndLocations": "Netzwerke und Standorte",
                "notes": "Supervisionsnotizen",
                "otherContacts": "Andere Kontakte",
                "phone_number": "Anrufen:",
                "sms_number": "SMS:",
                "title": "Kontakt",
                "type": "Kontakttyp:",
                "userLink": "Verknüpfter Benutzer"
            },
            "toast": {
                "deleted": "Kontakt gelöscht"
            },
            "types": {
                "ADMINISTRATIVE": "Manager",
                "EMERGENCY": "Notfall",
                "INDIVIDUAL": "Privatperson",
                "INSTALLER": "Installateur",
                "MAINTENANCE": "Wartung",
                "OWNER": "Eigentümer",
                "undefined": "Nicht definiert"
            }
        },
        "countries": {
            "ABW": "Aruba",
            "AFG": "Afghanistan",
            "AGO": "Angola",
            "AIA": "Anguilla",
            "ALB": "Åland Islands",
            "AND": "Andorra",
            "ARE": "United Arab Emirates",
            "ARG": "Argentina",
            "ARM": "Armenia",
            "ASM": "American Samoa",
            "ATA": "Antarctica",
            "ATF": "French Southern Territories",
            "ATG": "Antigua and Barbuda",
            "AUS": "Australia",
            "AUT": "Austria",
            "AZE": "Azerbaijan",
            "BDI": "Burundi",
            "BEL": "Belgium",
            "BEN": "Benin",
            "BES": "Bonaire, Sint Eustatius and Saba",
            "BFA": "Burkina Faso",
            "BGD": "Bangladesh",
            "BGR": "Bulgaria",
            "BHR": "Bahrain",
            "BHS": "Bahamas",
            "BIH": "Bosnia and Herzegovina",
            "BLM": "Saint Barthélemy",
            "BLR": "Belarus",
            "BLZ": "Belize",
            "BMU": "Bermuda",
            "BOL": "Bolivia (Plurinational State of)",
            "BRA": "Brazil",
            "BRB": "Barbados",
            "BRN": "Brunei Darussalam",
            "BTN": "Bhutan",
            "BVT": "Bouvet Island",
            "BWA": "Botswana",
            "CAF": "Central African Republic",
            "CAN": "Canada",
            "CCK": "Cocos (Keeling) Islands",
            "CHE": "Switzerland",
            "CHL": "Chile",
            "CHN": "China",
            "CIV": "Côte d'Ivoire",
            "CMR": "Cameroon",
            "COD": "Congo (Democratic Republic of the)",
            "COG": "Congo",
            "COK": "Cook Islands",
            "COL": "Colombia",
            "COM": "Comoros",
            "CPV": "Cabo Verde",
            "CRI": "Costa Rica",
            "CUB": "Cuba",
            "CUW": "Curaçao",
            "CXR": "Christmas Island",
            "CYM": "Cayman Islands",
            "CYP": "Cyprus",
            "CZE": "Czechia",
            "DEU": "Germany",
            "DJI": "Djibouti",
            "DMA": "Dominica",
            "DNK": "Denmark",
            "DOM": "Dominican Republic",
            "DZA": "Algeria",
            "ECU": "Ecuador",
            "EGY": "Egypt",
            "ERI": "Eritrea",
            "ESH": "Western Sahara",
            "ESP": "Spain",
            "EST": "Estonia",
            "ETH": "Ethiopia",
            "FIN": "Finland",
            "FJI": "Fiji",
            "FLK": "Falkland Islands (Malvinas)",
            "FRA": "France",
            "FRO": "Faroe Islands",
            "FSM": "Micronesia (Federated States of)",
            "GAB": "Gabon",
            "GBR": "United Kingdom of Great Britain and Northern Ireland",
            "GEO": "Georgia",
            "GGY": "Guernsey",
            "GHA": "Ghana",
            "GIB": "Gibraltar",
            "GIN": "Guinea",
            "GLP": "Guadeloupe",
            "GMB": "Gambia",
            "GNB": "Guinea-Bissau",
            "GNQ": "Equatorial Guinea",
            "GRC": "Greece",
            "GRD": "Grenada",
            "GRL": "Greenland",
            "GTM": "Guatemala",
            "GUF": "French Guiana",
            "GUM": "Guam",
            "GUY": "Guyana",
            "HKG": "Hong Kong",
            "HMD": "Heard Island and McDonald Islands",
            "HND": "Honduras",
            "HRV": "Croatia",
            "HTI": "Haiti",
            "HUN": "Hungary",
            "IDN": "Indonesia",
            "IMN": "Isle of Man",
            "IND": "India",
            "IOT": "British Indian Ocean Territory",
            "IRL": "Ireland",
            "IRN": "Iran (Islamic Republic of)",
            "IRQ": "Iraq",
            "ISL": "Iceland",
            "ISR": "Israel",
            "ITA": "Italy",
            "JAM": "Jamaica",
            "JEY": "Jersey",
            "JOR": "Jordan",
            "JPN": "Japan",
            "KAZ": "Kazakhstan",
            "KEN": "Kenya",
            "KGZ": "Kyrgyzstan",
            "KHM": "Cambodia",
            "KIR": "Kiribati",
            "KNA": "Saint Kitts and Nevis",
            "KOR": "Korea (Republic of)",
            "KWT": "Kuwait",
            "LAO": "Lao People's Democratic Republic",
            "LBN": "Lebanon",
            "LBR": "Liberia",
            "LBY": "Libya",
            "LCA": "Saint Lucia",
            "LIE": "Liechtenstein",
            "LKA": "Sri Lanka",
            "LSO": "Lesotho",
            "LTU": "Lithuania",
            "LUX": "Luxembourg",
            "LVA": "Latvia",
            "MAC": "Macao",
            "MAF": "Saint Martin (French part)",
            "MAR": "Morocco",
            "MCO": "Monaco",
            "MDA": "Moldova (Republic of)",
            "MDG": "Madagascar",
            "MDV": "Maldives",
            "MEX": "Mexico",
            "MHL": "Marshall Islands",
            "MKD": "Macedonia (the former Yugoslav Republic of)",
            "MLI": "Mali",
            "MLT": "Malta",
            "MMR": "Myanmar",
            "MNE": "Montenegro",
            "MNG": "Mongolia",
            "MNP": "Northern Mariana Islands",
            "MOZ": "Mozambique",
            "MRT": "Mauritania",
            "MSR": "Montserrat",
            "MTQ": "Martinique",
            "MUS": "Mauritius",
            "MWI": "Malawi",
            "MYS": "Malaysia",
            "MYT": "Mayotte",
            "NAM": "Namibia",
            "NCL": "New Caledonia",
            "NER": "Niger",
            "NFK": "Norfolk Island",
            "NGA": "Nigeria",
            "NIC": "Nicaragua",
            "NIU": "Niue",
            "NLD": "Netherlands",
            "NOR": "Norway",
            "NPL": "Nepal",
            "NRU": "Nauru",
            "NZL": "New Zealand",
            "OMN": "Oman",
            "PAK": "Pakistan",
            "PAN": "Panama",
            "PCN": "Pitcairn",
            "PER": "Peru",
            "PHL": "Philippines",
            "PLW": "Palau",
            "PNG": "Papua New Guinea",
            "POL": "Poland",
            "PRI": "Puerto Rico",
            "PRK": "Korea (Democratic People's Republic of)",
            "PRT": "Portugal",
            "PRY": "Paraguay",
            "PSE": "Palestine, State of",
            "PYF": "French Polynesia",
            "QAT": "Qatar",
            "REU": "Réunion",
            "ROU": "Romania",
            "RUS": "Russian Federation",
            "RWA": "Rwanda",
            "SAU": "Saudi Arabia",
            "SDN": "Sudan",
            "SEN": "Senegal",
            "SGP": "Singapore",
            "SGS": "South Georgia and the South Sandwich Islands",
            "SHN": "Saint Helena, Ascension and Tristan da Cunha",
            "SJM": "Svalbard and Jan Mayen",
            "SLB": "Solomon Islands",
            "SLE": "Sierra Leone",
            "SLV": "El Salvador",
            "SMR": "San Marino",
            "SOM": "Somalia",
            "SPM": "Saint Pierre and Miquelon",
            "SRB": "Serbia",
            "SSD": "South Sudan",
            "STP": "Sao Tome and Principe",
            "SUR": "Suriname",
            "SVK": "Slovakia",
            "SVN": "Slovenia",
            "SWE": "Sweden",
            "SWZ": "Eswatini",
            "SXM": "Sint Maarten (Dutch part)",
            "SYC": "Seychelles",
            "SYR": "Syrian Arab Republic",
            "TCA": "Turks and Caicos Islands",
            "TCD": "Chad",
            "TGO": "Togo",
            "THA": "Thailand",
            "TJK": "Tajikistan",
            "TKL": "Tokelau",
            "TKM": "Turkmenistan",
            "TLS": "Timor-Leste",
            "TON": "Tonga",
            "TTO": "Trinidad and Tobago",
            "TUN": "Tunisia",
            "TUR": "Turkey",
            "TUV": "Tuvalu",
            "TWN": "Taiwan, Province of China",
            "TZA": "Tanzania, United Republic of",
            "UGA": "Uganda",
            "UKR": "Ukraine",
            "UMI": "United States Minor Outlying Islands",
            "URY": "Uruguay",
            "USA": "United States of America",
            "UZB": "Uzbekistan",
            "VAT": "Holy See",
            "VCT": "Saint Vincent and the Grenadines",
            "VEN": "Venezuela (Bolivarian Republic of)",
            "VGB": "Virgin Islands (British)",
            "VIR": "Virgin Islands (U.S.)",
            "VNM": "Viet Nam",
            "VUT": "Vanuatu",
            "WLF": "Wallis and Futuna",
            "WSM": "Samoa",
            "YEM": "Yemen",
            "ZAF": "South Africa",
            "ZMB": "Zambia",
            "ZWE": "Zimbabwe"
        },
        "dashboard": {
            "activity": {
                "consumptionTitle": "Energie (MWh)",
                "sessionsTitle": "Ladevorgänge (Einheiten)",
                "turnoverTitle": "Umsatz ({currency})"
            },
            "availableEvses": "Verfügbare Ladepunkte",
            "availableEvsesPercentage": "{1} {current} nicht verfügbare LP von {total} (Verfügbarkeit: {percentage}%)| {current} nicht verfügbare LP von {total} (Verfügbarkeit: {percentage}%)",
            "currentSessions": "Aktuelle Vorgänge",
            "currentSessionsPercentage": "{percentage}% von {total} LP laden derzeit",
            "exportTranslations": "Übersetzungen exportieren",
            "importExportTranslations": "Übersetzungen importieren\/exportieren",
            "importTranslations": "Übersetzungen importieren",
            "loadMap": "Netzkarte laden",
            "networkStatus": "Netzwerkstatus in Echtzeit",
            "openTickets": "Offene Tickets",
            "title": "Dashboard"
        },
        "days": {
            "dayNamesCalendar": [
                "So",
                "Mo",
                "Di",
                "Mi",
                "Do",
                "Fr",
                "Sa"
            ],
            "friday": "Freitag",
            "monday": "Montag",
            "monthNamesCalendar": [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember"
            ],
            "saturday": "Samstag",
            "sunday": "Sonntag",
            "thursday": "Donnerstag",
            "tuesday": "Dienstag",
            "wednesday": "Mittwoch"
        },
        "devices": {
            "actions": {
                "breadcrumb": "Entfernte Aktionen",
                "change": "Ändern",
                "changeAvailability": "Verfügbarkeit ändern",
                "changeConfig": "Konfiguration ändern",
                "changeConfigPlaceholder": "Name der Konfiguration",
                "clearCache": "Cache leeren",
                "cnr": {
                    "rearm": "Gerät scharf machen",
                    "rearmFailed": "Scharfmachung des Geräts fehlgeschlagen",
                    "rearmSuccess": "Gerät erfolgreich wieder scharf gemacht",
                    "stop": "Halt",
                    "stopSuccess": "Standardstecker erfolgreich gestoppt",
                    "supply": "Energieabgabe über Standardstecker (1 Stunde)",
                    "supplySince": "Laufende Energielieferung seit: {date}",
                    "supplySuccess": "Standardstecker erfolgreich für 1 Stunde gestartet",
                    "title": "CNR-Automat"
                },
                "configReadonly": "(Nicht editierbar)",
                "configValue": "Konfigurationswert",
                "configurationPlaceholder": "Hier auswählen oder eingeben",
                "customConfig": "Benutzerdefinierte Konfiguration",
                "get": "Holen",
                "getConfiguration": "Konfiguration abrufen",
                "getDiagnostics": "Diagnostik erhalten",
                "getDiagnosticsManual": "Send diagnostic via URL",
                "ping": "Ping",
                "protocol": "Protokoll: {protocol}",
                "reset": "Zurücksetzen",
                "selectFile": "Datei auswählen",
                "title": "Entfernte Aktionen für {chargeboxid}",
                "unknownConfig": "Unbekannte Konfiguration",
                "updateFirmware": "Firmware aktualisieren",
                "updateFirmwareManual": "Update firmware via URL",
                "upload": "Hochladen",
                "urlPlaceholder": "Enter URL",
                "value": "Wert:"
            },
            "create": {
                "breadcrumb": "Neues Ladegerät",
                "cpoTitle": "Neues Ladegerät in {name}",
                "feedback": "Ladegerät erfolgreich hinzugefügt",
                "lanTitle": "Neues Ladegerät verbunden mit {name}",
                "locationTitle": "Neues Ladegerät in {name}",
                "title": "Neues Ladegerät"
            },
            "edit": {
                "breadcrumb": "Bearbeiten",
                "feedback": "Ladegerät erfolgreich bearbeitet",
                "title": "Bearbeiten"
            },
            "form": {
                "addLan": "LAN hinzufügen",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID ist verfügbar",
                "chargeboxidUnavailable": "Chargebox ID ist nicht verfügbar",
                "definition": "Definition des Ladegeräts",
                "deviceProtocol": "Protokoll",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "LAN bearbeiten",
                "isDhcp": "Ist DHCP",
                "lan": "LAN",
                "lanDescription": "LAN Beschreibung",
                "lanName": "Name",
                "localIp": "Lokale IP-Adresse",
                "localIpIsInvalid": "Lokale IP-Adresse ist ungültig",
                "localPort": "Lokaler Port",
                "model": "Modell",
                "modelIntro": "Ein vorhandenes Modell verwenden",
                "newLanName": "LAN #",
                "offlineEvses": "Noch nicht unter Vorsitz von LP",
                "publicIp": "Öffentliche IP-Adresse",
                "publicPort": "Öffentlicher Port",
                "selectLocation": "Standort",
                "submit": "Hinzufügen",
                "technicalInfos": "Technische Information",
                "unknownModel": "Unbekanntes Modell",
                "url": "URL"
            },
            "list": {
                "addDevice": "Ladegerät hinzufügen",
                "all": "Alle",
                "breadcrumb": "Ladegeräte",
                "chargeboxid": "Chargebox ID",
                "cpoTitle": "CBID von {name}",
                "firmware": "Firmware",
                "isOnline": "Online",
                "lan": "LAN",
                "lastRequestOffline": "Offline seit",
                "lastRequestOnline": "Online seit",
                "localIp": "Lokale IP-Adresse",
                "locationTitle": "CBID von {name}",
                "maintenanceOfflineSubtitle": "Liste der CBIDs, die die Kommunikation für mindestens 12 Stunden verloren haben",
                "maintenanceOfflineTitle": "Kommunikationsverluste",
                "maintenanceOnlineTitle": "CBID: Fehler gelöst",
                "model": "Modell",
                "nbEvses": "LP",
                "offline": "Offline",
                "online": "Online",
                "protocol": "Protokoll",
                "status": "Stand",
                "title": "CBID",
                "updatedAt": "Aktualisiert",
                "vendor": "Anbieter"
            },
            "ocpp": {
                "breadcrumb": "OCPP-Logs",
                "changeAvailabilityAvailable": "Ladegerät ist jetzt verfügbar",
                "changeAvailabilityUnavailable": "Ladegerät ist jetzt nicht verfügbar",
                "changeConfigSuccess": "Neuer Wert erfolgreich auf {configuration} angewendet",
                "clearSuccess": "Cache erfolgreich geleert",
                "commonSuccess": "Order sent",
                "diagnosticsOngoing": "Get Diagnostics zugeschickt - Warten auf Ladegerät",
                "diagnosticsReady": "Diagnostik bereit",
                "failed": "Befehl fehlgeschlagen",
                "getDiagnosticsFail": "Diagnose fehlgeschlagen erhalten",
                "notSupported": "Befehl nicht unterstützt",
                "pingError": "Ping fehlgeschlagen",
                "pingSuccess": "Ping erfolgreich",
                "rejected": "Befehl abgelehnt",
                "remoteFail": "Befehl fehlgeschlagen",
                "resetSuccess": "Reset Befehl zugeschickt - Neustart des Ladegeräts",
                "startSuccess": "Vorgang erfolgreich gestartet",
                "stopSuccess": "Vorgang erfolgreich beendet",
                "timeout": "Befehl mit Zeitüberschreitung",
                "unlockFailed": "Entsperren fehlgeschlagen",
                "unlockSuccess": "Freischaltung erfolgreich",
                "uploadFirmwareFailed": "Upload fehlgeschlagen",
                "uploadFirmwareSuccess": "Upload erfolgreich"
            },
            "protocol": {
                "OCPP15J": "OCPP 1.5 J",
                "OCPP15S": "OCPP 1.5 S",
                "OCPP16J": "OCPP 1.6 J",
                "OCPP16S": "OCPP 1.6 S",
                "OCPP20J": "OCPP 2.0 J",
                "OCPP20S": "OCPP 2.0 S",
                "OTHER": "Andere"
            },
            "show": {
                "DHCP": "DHCP",
                "NAT": "NAT",
                "deviceInfo": "Information über die Ladestation",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "firmware": "Firmware",
                "isOffline": "Offline",
                "isOnline": "Online",
                "lan": "LAN",
                "lastRequest": "Letzte Anfrage",
                "localIp": "Lokale IP-Adresse",
                "localPort": "Lokaler Anschluss",
                "model": "Modell",
                "network": "Netzwerk",
                "noOcppMessage": "Keine OCPP Nachricht gesendet",
                "noPairsConnected": "Kein Paar",
                "ocppInfos": "OCPP",
                "pairs": "Paar",
                "protocol": "Protokoll",
                "publicPort": "Öffentlicher Port",
                "url": "URL",
                "vendor": "Hersteller"
            }
        },
        "emails": {
            "ExportReady": {
                "body-attached": "Im Anhang finden Sie die gewünschte CSV-Datei",
                "body-linked": "Bitte klicken Sie auf den untenstehenden Link, um die gewünschte CSV-Datei herunterzuladen",
                "download-link-text": "Herunterladen der Datei",
                "subject": "Ihre CSV-Datei ist fertig"
            },
            "Issues": {
                "closed": {
                    "introduction": "Das Ticket Fix: #{id} {title} - {location} wurde von {closer} in {closed_at} geschlossen.",
                    "subject": "Schließen eines Wartungstickets] - Fix: #{id} - {locationName}"
                },
                "created": {
                    "introduction": "Das folgende Fix-Ticket wurde soeben erstellt:",
                    "link": "Ticket-Link :",
                    "name": "Name des Tickets :",
                    "number": "Nummer des Tickets :",
                    "subject": "Wartungsticket eröffnen - Fix: #{id} - {locationName}",
                    "type": "Art der Wartung:"
                },
                "link": "Konsultieren Sie das Ticket:",
                "messageCreated": {
                    "introduction": "{updater} hat gerade eine Nachricht in das Ticket Fix geschrieben: #{id} {title} - {location} le {created_at}.",
                    "subject": "Eine Nachricht wurde zu einem Fix Maintenance Ticket hinzugefügt - Fix: #{id} - {locationName}"
                },
                "reminder": "Vergessen Sie nicht, wie wichtig es ist, die Tickets zu kommentieren, um die Entwicklung zu verfolgen und somit eine bessere Nachverfolgung zu ermöglichen.",
                "reopened": {
                    "introduction": "Das Ticket Fix: #{id} {title} - {location} wurde von {opener} am {updated_at} neu eröffnet.",
                    "subject": "Wiedereröffnung eines Wartungstickets - Fix: #{id} - {locationName}"
                },
                "updated": {
                    "introduction": "Das Ticket Fix: #{id} {title} - {location} wurde von {updater} am {updated_at} geändert.",
                    "subject": "Änderung eines Wartungstickets - Fix: #{id} - {locationName}"
                },
                "userQuoted": {
                    "introduction": "{dispatcher} hat Sie gerade im Ticket Fix zitiert: #{id} {title} - {location} le {quoted_at}.",
                    "subject": "Zitat in einem Wartungsticket - Fix: #{id} - {locationName}"
                }
            },
            "ResetPassword": {
                "lines": {
                    "call_to_action": "Klicken Sie auf den Link, um das Passwort zu ändern",
                    "expiration": "Dieser Link läuft aus in {expirationTime} Minuten ab",
                    "explanation": "Sie erhalten diese Nachricht, weil wir eine Anfrage zur Änderung Ihres Passworts erhalten haben",
                    "no_action_required": "Wenn Sie diese Anfrage nicht initiert haben, ignorieren Sie bitte diese Meldung"
                },
                "link": "Passwort ändern",
                "subject": "Ändern des Passworts"
            },
            "SendRetrocessionEmployerPdf": {
                "cordially": "Mit freundlichen Grüßen",
                "explanation": "Beiliegend finden Sie die namentliche Aufstellung über die Rückerstattung von Ladevorgängen, die an der Ladestation in Ihrem Wohnort beruflich durchgeführt wurden.",
                "greetings": "Hallo,",
                "payment": "Die Überweisung wird in den nächsten Tagen erfolgen.",
                "subject": "Rückerstattung der bei Ihnen zu Hause vorgenommenen professionellen Aufladungen"
            },
            "SendRetrocessionPdf": {
                "cordially": "Mit freundlichen Grüßen",
                "explanation": "Anbei finden Sie das Rückerstattungsdokument des letzten Quartals.",
                "greetings": "Hallo,",
                "payment": "Die Überweisung wird in den nächsten Tagen erfolgen.",
                "subject": "Rückerstattung letztes Quartal - Freshmile"
            },
            "StationCheckApiTokenConfirmMail": {
                "explanation": "Pour vérifier votre addresse e-mail et accéder à l'interface de test des bornes, veuillez cliquer sur le bouton de vérification ci-dessous",
                "link": "Vérification de l'addresse email",
                "linkComment": "Si ce bouton ne fonctionne pas, vous pouvez copier-coller le lien suivant dans votre navigateur :",
                "subject": "Vérification de l'addresse email"
            },
            "common": {
                "apologize": "Entschuldigung für etwaige Unannehmlichkeiten",
                "contact": "Bei Fragen können Sie uns unter der folgenden Adresse kontaktieren:",
                "greetings": "Hallo {firstname} {lastname}",
                "login": "Anmelden",
                "thanks": "Vielen Dank für die Verwendung von {appname}!"
            },
            "fromName": "Freshmile"
        },
        "evses": {
            "administrative_status": {
                "available": "Im Dienst",
                "inoperative": "Wartung",
                "outoforder": "Außer Betrieb gesetzt",
                "planned": "Geplant",
                "removed": "Entfernt",
                "unknown": "Nicht-kommunikativ"
            },
            "charging_status": {
                "available": "Verfügbar",
                "charging": "Lädt gerade",
                "reserved": "Reserviert",
                "unknown": "Unbekannter Vorgang"
            },
            "create": {
                "breadcrumb": "Ladepunkte",
                "cpoTitle": "Neuer Ladepunkt mit {name}",
                "evseList": "Ladepunkte (Liste)",
                "feedback": "Ladepunkt erfolgreich hinzugefügt",
                "locationTitle": "Neuer Ladepunkt an {name}",
                "title": "Neuer Ladepunkt"
            },
            "edit": {
                "BOEditRef": "To change the reference of an evse after it has been created, please use the action \"Changer la ref d'un point de charge\" in the backoffice.",
                "feedback": "Ladepunkt erfolgreich aktualisiert",
                "title": "Bearbeiten"
            },
            "export-data-gouv": "data.gouv",
            "form": {
                "addConnector": "Stecker hinzufügen",
                "addDevice": "Ladegerät hinzufügen",
                "addLan": "LAN hinzufügen",
                "administrative": "Verwaltungsstatus",
                "applyCoordinates": "GPS-Koordinaten anwenden",
                "asyncSearch": "Search by EVSE (reference, name)",
                "connectors": "Stecker",
                "customRefAvailable": "Referenz ist verfügbar",
                "customRefUnavailable": "Referenz ist nicht verfügbar",
                "device": "Chargebox ID",
                "directions": "Wegbeschreibung",
                "duplicate": "Erstellen Sie ein n+1-Duplikat dieses Ladepunkts.",
                "emi3": "eMI3-ID",
                "evseInfos": "Charging point information",
                "floorLevel": "Stockwerk",
                "hideMap": "Karte ausblenden",
                "lan": "LAN an diesem Standort",
                "lanDeviceCreationError": "Fehler",
                "lanDeviceCreationSuccess": "Ladegerät erfolgreich hinzugefügt",
                "latitude": "Breitengrad",
                "location": "Standort",
                "locationInfos": "Informationen zum Standort",
                "locationPlaceholder": "Standort",
                "locations": "Standorte",
                "longitude": "Längengrad",
                "name": "Benutzerdefinierter Name",
                "newDeviceTitle": "Neues Ladegerät in {name}",
                "optional": "(fakultativ)",
                "parkingType": "Art des Parkens",
                "physicalRef": "Physikalische Referenz",
                "ref": "REF",
                "showMap": "Karte anzeigen",
                "submit": "Speichern"
            },
            "list": {
                "addEvse": "Ladepunkt hinzufügen",
                "all": "Alle",
                "breadcrumb": "Ladepunkte",
                "byCpo": "Nach Netzwerk-ID filtern",
                "commissionedAt": "Inbetriebnahme",
                "connectors": "Stecker",
                "cpoTitle": "Ladepunkte von {name}",
                "decommissionedAt": "Stilllegung",
                "device": "Ladegerät",
                "emi3": "eMI3-ID",
                "free": "Kostenlos",
                "location": "Standort",
                "locationTitle": "Ladepunkte von {name}",
                "name": "Name",
                "paying": "Nicht kostenlos",
                "reference": "Referenz",
                "status": "Stand",
                "tariffs": "Tarife",
                "title": "Ladepunkte",
                "updatedAt": "Letzte Aktualisierung"
            },
            "operational_status": {
                "available": "Funktionsfähig",
                "blocked": "Blockiert",
                "inoperative": "Nicht funktionsfähig",
                "outoforder": "Außer Betrieb",
                "unknown": "Unbekannt"
            },
            "show": {
                "administrative": "Administrativ",
                "administrativeDetails": "Verwaltungsstatus",
                "chargeStarted": "Ladevorgang gestartet",
                "chargeStopped": "Ladevorgang gestoppt",
                "charging": "Lädt gerade",
                "chargingDetails": "Ladestatus",
                "connectors": "Stecker",
                "device": "Chargebox ID",
                "deviceIsConnected": "Verbunden",
                "directions": "Wegbeschreibung",
                "emi3": "eMI3-ID",
                "floorLevel": "Stockwerk",
                "misc": "Sonstiges",
                "name": "Name",
                "noDescription": "Keine Beschreibung",
                "noDeviceConnected": "Kein Ladegerät angeschlossen, LP nicht erreichbar",
                "onGoingSessionStarted": "Vorgang angefangen",
                "operational": "Operativ",
                "operationalDetails": "Betriebsstatus",
                "pairs": "Peers",
                "parkingRestrictions": "Parkbeschränkungen",
                "physicalReference": "Physikalische Referenz",
                "ref": "Referenz",
                "stopCharge": "Ladevorgang stoppen"
            },
            "status": {
                "alive": "Funktionsfähig",
                "available": "Verfügbar",
                "blocked": "Blockiert",
                "charging": "Lädt gerade",
                "inoperative": "Nicht funktionsfähig",
                "obstructed": "Blockiert",
                "outoforder": "Außer Betrieb",
                "planned": "Geplant",
                "removed": "Entfernt",
                "reserved": "Reserviert",
                "test": "Test",
                "unavailable": "Nicht verfügbar",
                "unknown": "Nicht-kommunikativ",
                "unobstructed": "Uneingeschränkt"
            }
        },
        "fix": {
            "bot": {
                "automatic_action_close": {
                    "LanDevice_reconnected": "All charging point CBIDs have been reconnected. The fix ticket was closed automatically.",
                    "charge_completed": "A valid charge has been made on one of the charging points. The fix ticket was closed automatically.",
                    "connector_available": "No error is reported on the charging point connectors. The fix ticket was closed automatically.",
                    "inactive_emergency_issue": "Ohne Nachricht des Nutzers oder des Notfallkontakts geht Freshmile davon aus, dass das Ticket Notfall-Fix gelöst wurde. Es wurde automatisch geschlossen."
                },
                "automatic_action_close_default": "The fix ticket was closed automatically.",
                "automatic_action_create_default": "The fix ticket was opened automatically.",
                "automatic_action_update_default": "The ticket fix has been changed automatically.",
                "chargeCompleted": "Charge completed",
                "chargeCompletedTitle": "\"COMPLETED + > 2min + > 500Wh\" on at least one Evse linked to a ticket has taken place",
                "connectorAvailable": "Connecteur operationnal",
                "connectorAvailableTitle": "When all Evse connectors linked to a ticket return a StatusNotificationRequest: NoError",
                "disabled": "Disabled",
                "lanDeviceConnected": "CBID connected",
                "lanDeviceConnectedTitle": "When all CBIDs of Evse linked to a ticket are (re)connected"
            },
            "create": {
                "addTask": "Hinzufügen",
                "allOptions": "Alle Optionen",
                "assignTo": "Ticket zuweisen",
                "autoManagement": "Automatic closing",
                "breadcrumb": "Ticket erstellen",
                "clickUpload": "Klicken Sie, um eine Datei auszuwählen",
                "dropUpload": "Dateien ziehen oder anklicken. Maximale Größe: {max} Kb",
                "fewerOptions": "Weniger Optionen",
                "issueTitle": "Ticket-Titel",
                "linkTo": "Ticket verknüpfen mit",
                "optionnalEntity": "Leer lassen, um das Ticket dem Netz zuzuweisen {operator}",
                "specificEvse": "EVSEs angeben (oder standardmäßig den gesamten Standort)",
                "subscribeTo": "Benutzer abonnieren",
                "title": "Fix - Neues Ticket",
                "titleScope": "Ticket erstellen für {name}"
            },
            "dashboard": {
                "automaticActionsApplied": "Automatically closed",
                "createdAt": "Erstellt am {Datum} von {email}",
                "edit": "Bearbeiten",
                "entityFilter": "Suchen",
                "exportAll": "Alle exportieren",
                "filterSelection": "{Anzahl} Entitäten ausgewählt",
                "new": "Neues Ticket",
                "show": "anzeigen",
                "showMoreBtn": "Mehr anzeigen",
                "statusFilter": "Nach Status filtern",
                "title": "Korrektur - Dashboard",
                "updatedAt": "Aktualisiertes {Datum} durch {E}-Mail",
                "withMsg": "Inklusive Nachrichten und Beschreibungen"
            },
            "duplicate": {
                "duplicate": "Doppeltes",
                "issueTitle": "Ticket-Titel",
                "linkMessages": "Doppelte Nachrichten",
                "linkModels": "Doppelte Entitäten",
                "linkTags": "Doppelte Tags",
                "linkTasks": "Doppelte Aufgaben",
                "success": "Ticket dupliziert",
                "title": "Ein Ticket duplizieren"
            },
            "emergency-issue-title": {
                "LOCKED_CABLE": "Emergency: Locked cable ({evseRef})",
                "SECURITY": "Emergency: Security issue ({evseRef})"
            },
            "entity": {
                "connectors": "Verbinder",
                "devices": "CBID",
                "evses": "EVSE",
                "lans": "LAN",
                "locations": "Standort",
                "networks": "Netzwerk",
                "status": "Status",
                "supervisors": "Zugewiesen",
                "tags": "Markierungen"
            },
            "history": {
                "title": "Beheben - Geschichte"
            },
            "list": {
                "action": "Aktionen",
                "assigned": "Zugewiesen",
                "author": "Autor",
                "automaticActionsApplied": "Automatic closing",
                "begin": "Datum des Beginns",
                "breadcrumb0": "Korrigierende Wartung",
                "breadcrumb1": "Vorbeugende Instandhaltung",
                "breadcrumb3": "Informationen",
                "closeSelection": "Auswahl schließen",
                "closedAt": "Abgeschlossen am",
                "closedBy": "Abgeschlossen am",
                "completedAt": "Erledigt am",
                "confirmClose": "Ticket schließen",
                "correctiveTitle": "Tickets zur korrektiven Wartung",
                "correctiveTitleScope": "Tickets zur Korrektur von Wartungsarbeiten von {name}",
                "created": "Erstellt am",
                "description": "Beschreibung",
                "end": "Fälligkeitsdatum",
                "entities": "Zugehörige Entität",
                "historyTitleScope": "Ticketverlauf von {name}",
                "incidentDate": "Vorfall am",
                "informationTitle": "Informationen",
                "informationTitleScope": "Informationen von {name}",
                "interventionAt": "Intervention am",
                "name": "Name",
                "preventiveTitle": "Tickets für vorbeugende Wartung",
                "preventiveTitleScope": "Vorbeugende Wartungstickets von {name}",
                "startedAt": "Begonnen am",
                "status": "Status",
                "tags": "Stichworte",
                "tasks": "Aufgaben",
                "title": "Titel",
                "type": "Art der Wartung",
                "updateSelection": "Auswahl aktualisieren",
                "updatedAt": "Aktualisiert am",
                "updatedBy": "Aktualisiert von"
            },
            "scope": {
                "createIssue": "Ein Ticket erstellen",
                "ongoingIssues": "Offene Anfragen : {count}"
            },
            "show": {
                "about": "Freshmile Fix, ticket #{id}",
                "activity": "Aktivität",
                "add": "Hinzufügen",
                "addedBy": "Hinzugefügt von {email}",
                "attachments": "Anhänge",
                "close": "Ticket schließen",
                "closedAt": "Geschlossen am {Datum}",
                "closedAtBy": "Geschlossen am {Datum} durch {email}",
                "comments": "Kommentare",
                "completeClose": "Sie können fehlende Daten ergänzen, bevor Sie das Ticket schließen",
                "completedDate": "Datum der Auflösung",
                "confirmDelete": "Möchten Sie den Wert löschen?",
                "confirmDeleteFile": "Möchten Sie die Datei löschen?",
                "confirmDeleteTask": "Möchten Sie die Aufgabe löschen?",
                "deleteMessageAnswers": "Antworten löschen?",
                "deleteMessageTitle": "Möchten Sie die Nachricht löschen?",
                "description": "Beschreibung",
                "download": "Herunterladen",
                "interventionDate": "Datum der Intervention",
                "lastUpdate": "Aktualisiert am: {Datum}",
                "lastUpdateBy": "Aktualisiert am: {date} von {email}",
                "mailBody": "Hallo,%0D%0A%0D%0AWir laden Sie ein, das Ticket \"{name}\" : {url}%0D%0A%0D%0AThanks zu konsultieren,",
                "messageComment": "Kommentar",
                "messageEdit": "Bearbeiten",
                "messageReply": "Antwort",
                "messages": "Nachrichten",
                "noMessage": "Noch keine Nachrichten",
                "noTask": "Noch keine Aufgaben",
                "noUpload": "Noch keine Dateien",
                "original": "Ursprüngliches Ticket:",
                "reopen": "Ticket wieder öffnen",
                "replyTo": "Antwort an {email}",
                "resolvedBy": "Gelöst von {email} am {Datum}",
                "subscribe": "Abonnieren",
                "subscribed": "Abonnenten",
                "taskDescription": "Beschreibung der Aufgabe",
                "title": "Behebung #{id}",
                "todoList": "Liste der zu erledigenden Aufgaben",
                "unsubscribe": "Abbestellen",
                "update": "aktualisieren",
                "urlPrompt": "Geben Sie eine URL ein:"
            },
            "stats": {
                "emergency": {
                    "average_between_opening_intervention_emergencies": "Durchschnittliche Zeit bis zum Notfalleinsatz",
                    "emergency-by-evse-table": {
                        "title": "Klassifizierung der Stationen mit der höchsten Anzahl an Notfallausfällen"
                    },
                    "title": "Wartungsstatistiken Fix Notfall",
                    "total_ticket_emergencies_fix": "Gesamtanzahl erstellte Notfall-Fix-Tickets"
                },
                "maintenance": {
                    "issue-count-table": {
                        "title": "Klassifizierung der Stationen mit der höchsten Anzahl an Pannen"
                    },
                    "issue-type-table": {
                        "title": "Klassifizierung der Arten von Netzwerkausfällen"
                    },
                    "title": "Wartungsstatistiken Fix",
                    "total_tickets_fix_created_by_network": "Gesamtanzahl der erstellten Fix-Tickets",
                    "total_tickets_fix_resolve_by_network": "Gesamtzahl der gelösten Fix-Tickets"
                },
                "network": {
                    "average_between_opening_intervention": "Durchschnittliche Zeit bis zur Intervention",
                    "average_failures_occurrence_resolution": "Durchschnittliche Lösungsfrist",
                    "evse-unavailability-table": {
                        "title": "Klassifizierung der Ladestellen mit der höchsten Ausfallrate und der höchsten Stundenzahl"
                    },
                    "title": "Allgemeine Statistiken zur Netzwerkwartung"
                },
                "noIssue": "Keine Ausgabe",
                "pdf": {
                    "fileName": "Fix – Statistiken",
                    "issue_type": "Art des Ausfalls",
                    "rate": "Rate (Anzahl)"
                },
                "title": "Fix – Statistiken"
            },
            "status": {
                "ABANDONED": "Abgebrochen",
                "AWAITING": "Wartet",
                "AWAITING_REPORT": "Warten auf Bericht",
                "BEING_PROCESSED": "In Bearbeitung",
                "COMPLETED": "Abgeschlossen",
                "NEW": "Neu",
                "ONGOING": "Laufend",
                "PLANNED": "Geplant",
                "dashboard": {
                    "CORRECTIVE": {
                        "AWAITING": "Wartet",
                        "BEING_PROCESSED": "In Bearbeitung",
                        "NEW": "Neu"
                    },
                    "PREVENTIVE": {
                        "AWAITING": "Geplant",
                        "AWAITING_REPORT": "Warten auf Bericht",
                        "ONGOING": "Unterstützt",
                        "PLANNED": "Planen"
                    }
                }
            },
            "supervisor": {
                "closed": "Abgeschlossen",
                "open": "Offen",
                "title": "Meine Tickets"
            },
            "tags": {
                "addTitle": "Ein Tag hinzufügen",
                "color": "Farbe",
                "confirmDelete": "Möchten Sie die Markierung entfernen?",
                "editTitle": "Tag bearbeiten",
                "title": "Tag-Verwaltung"
            },
            "title": "korrigieren",
            "toast": {
                "issue": {
                    "clipboard": "Link zum Ticket kopiert",
                    "updated": "Ticket aktualisiert"
                },
                "message": {
                    "delete": "Nachricht gelöscht",
                    "updated": "Nachricht aktualisiert"
                },
                "quote": {
                    "failed": "Benutzer kann nicht zitiert werden"
                },
                "subscription": {
                    "add": {
                        "failed": "Benutzer konnte nicht für diese Ausgabe angemeldet werden",
                        "success": "Benutzer erfolgreich angemeldet"
                    },
                    "remove": {
                        "failed": "Abmeldung des Benutzers von dieser Ausgabe fehlgeschlagen",
                        "success": "Benutzer erfolgreich angemeldet"
                    }
                },
                "tag": {
                    "added": "Tag erfolgreich erstellt",
                    "deleted": "Markierung gelöscht",
                    "updated": "Tag aktualisiert"
                },
                "task": {
                    "added": "Aufgabe hinzugefügt",
                    "deleted": "Aufgabe gelöscht",
                    "updated": "Aufgabe aktualisiert"
                },
                "upload": {
                    "added": "Datei(en) hinzugefügt",
                    "deleted": "Datei(en) gelöscht",
                    "ignored": "Die folgenden Dateien wurden ignoriert, weil sie zu groß sind ({max} kB): {files}"
                }
            },
            "type": {
                "corrective": "Korrektive Wartung",
                "information": "Informationen",
                "preventive": "Vorbeugende Instandhaltung",
                "soonLate": "Abschlussverzögerungen und Fristen"
            }
        },
        "general": {
            "edition": "Edition of {name}",
            "of": "of {data}"
        },
        "header": {
            "alerts": "Verwalten von Warnmeldungen",
            "cdr": "CDRs",
            "contact": "Kontakte",
            "dashboard": "Dashboard",
            "device": "CBID",
            "evse": "LP",
            "fix": {
                "dashboard": "Fix - Dashboard",
                "history": "Fix - History",
                "stats": "Fix - Statistiken",
                "tags": "Tags",
                "title": "Fix"
            },
            "generateQRcodes": "QR-Codes erzeugen",
            "generateRetro": "Generator of retrocession .pdf",
            "location": "Standorte",
            "locations_mass_import": "Massenimport von Standorten",
            "logout": "Abmeldung",
            "network": "Netzwerke",
            "others": "Andere",
            "reports": "Aktivierungen",
            "reservation": "Reservierungen",
            "retrocessionGroups": "Retrocession groups",
            "retrocessionLogs": "Retrocession logs",
            "retrocessions": "Retrocessions",
            "role": "Rolle",
            "session": "Vorgänge",
            "settings": "Einstellungen",
            "stats": "Statistik",
            "statsInSubmenu": "Allgemein",
            "tariff": "Tarife",
            "ticket": "Tickets",
            "translations": "Übersetzungen"
        },
        "languages": {
            "de": "Deutsch",
            "en": "Englisch",
            "es": "Spanisch",
            "fr": "Französisch",
            "gb": "Englisch",
            "gn": "Übersetzung deaktivieren",
            "nl": "Niederländisch",
            "undefined": "Undefiniert"
        },
        "lans": {
            "create": {
                "breadcrumb": "Neues LAN",
                "feedback": "LAN erfolgreich hinzugefügt",
                "title": "Neues LAN in {name}"
            },
            "edit": {
                "breadcrumb": "Bearbeiten",
                "feedback": "LAN erfolgreich aktualisiert",
                "title": "Bearbeiten"
            },
            "form": {
                "addLan": "LAN hinzufügen",
                "availableEvses": "Verfügbare LP",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID ist verfügbar",
                "chargeboxidUnavailable": "Chargebox ID ist nicht verfügbar",
                "description": "Beschreibung",
                "deviceDefinition": "Optional=> Definition des Ladegeräts",
                "deviceProtocol": "Protokoll",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "LAN bearbeiten",
                "gateway": "Gateway",
                "gatewayIsInvalid": "Ungültiger Gateway",
                "isDhcp": "Ist DHCP",
                "lan": "LAN",
                "lanCreation": "Neues LAN",
                "lanCreationError": "Fehler",
                "lanCreationSuccess": "LAN erfolgreich hinzugefügt",
                "lanDescription": "LAN Beschreibung",
                "lanDeviceCreation": "Neues LAN",
                "lanName": "Name",
                "localIp": "Lokale IP-Adresse",
                "localPort": "Lokaler Anschluss",
                "model": "Modell",
                "modelPlaceholder": "Modell",
                "name": "Name",
                "newLanName": "LAN #",
                "offlineEvses": "Offline LP",
                "optional": "(fakultativ)",
                "proxy": "Proxy",
                "publicIp": "Öffentliche IP-Adresse",
                "publicIpIsInvalid": "Ungültige öffentliche IP-Adresse",
                "publicPort": "Öffentlicher Port",
                "selectLocation": "Standort",
                "submit": "Neues Ladegerät hinzufügen",
                "subnetMask": "Subnet Maske",
                "subnetMaskIsInvalid": "Ungültige Subnetzmaske",
                "technicalInfos": "Technische Informationen",
                "unknownModel": "Unbekanntes Modell",
                "url": "URL",
                "wirelessLogic": "Wireless Logic"
            },
            "list": {
                "breadcrumb": "LAN",
                "create": "Hinzufügen",
                "gateway": "Gateway",
                "lanDevices": "Ladegeräte",
                "name": "Name",
                "proxy": "Proxy",
                "publicIp": "Öffentliche IP-Adresse",
                "subnetMask": "Subnet Maske",
                "title": "LAN von {name}",
                "updatedAt": "Letzte Aktualisierung"
            },
            "proxies": {
                "MAIN": "Main",
                "NONE": "None",
                "WIRELESSLOGIC": "Wireless Logic"
            },
            "show": {
                "addDevice": "CBID hinzufügen",
                "connectedDevices": "Angeschlossene CBID",
                "description": "Beschreibung",
                "deviceIsConnected": "Verbunden",
                "edit": "bearbeiten",
                "gateway": "Tor",
                "lastUpdate": "Letzte Aktualisierung",
                "name": "Name",
                "nat": "NAT",
                "protocol": "Protokoll",
                "proxy": "Proxy",
                "publicIp": "Öffentliche IP-Adresse",
                "subnetMask": "Subnet Maske",
                "url": "URL"
            }
        },
        "locations": {
            "create": {
                "breadcrumb": "Neuer Standort",
                "cpoTitle": "Neuer Standort in {name}",
                "feedback": "Standort erfolgreich hinzugefügt",
                "locationsList": "Standorte",
                "title": "Neuer Standort"
            },
            "edit": {
                "edit": "Bearbeiten",
                "feedback": "Standort erfolgreich aktualisiert"
            },
            "form": {
                "addOpeningHourFor": "Fügen Sie eine Öffnungszeit für {day} hinzu",
                "address": "Adresse",
                "asyncSearch": "Suche nach Name, Adresse, Ort, Postleitzahl",
                "chargingWhenClosed": "Laden auch wenn geschlossen",
                "city": "Stadt",
                "country": "Land",
                "countryPlaceholder": "Land",
                "cpoPlaceholder": "Netzwerk",
                "directions": "Zugangsinformationen",
                "displayMap": "Karte anzeigen",
                "emi3": "eMI3-ID",
                "facilities": "Einrichtungen",
                "facilitiesPlaceholder": "Einrichtungen",
                "hideMap": "Karte ausblenden",
                "informations": "Information",
                "invalidHourMessage": "Ungültig",
                "latitude": "Breitengrad",
                "latitudePlaceholder": "Breitengrad",
                "localisation": "Standort",
                "longitude": "Längengrad",
                "longitudePlaceholder": "Längengrad",
                "name": "Name",
                "network": "Netzwerk",
                "openingHoursInfos": "Öffnungszeiten",
                "optional": "(fakultativ)",
                "postalcode": "Postleitzahl",
                "resetPosition": "Position zurücksetzen",
                "submit": "Speichern",
                "time_zone": "Timezone",
                "twentyfourseven": "24\/7 geöffnet",
                "type": "Typ",
                "typePlaceholder": "Typ"
            },
            "list": {
                "addLocation": "Standort hinzufügen",
                "breadcrumb": "Standorte",
                "chargePoints": "Ladepunkte",
                "city": "Stadt",
                "country": "Land",
                "cpoId": "Netzwerk-ID",
                "cpoTitle": "Standorte von {name}",
                "emi3": "eMI3-ID",
                "name": "Name",
                "timeZone": "Zeitzone",
                "title": "Standorte",
                "type": "Typ",
                "updatedAt": "Letzte Aktualisierung"
            },
            "show": {
                "address": "Adresse",
                "chargingWhenClosed": "Laden auch wenn geschlossen",
                "city": "Stadt",
                "country": "Land",
                "cpo": "Netzwerk",
                "directions": "Zugangsinformationen",
                "edit": "Bearbeiten",
                "emi3": "eMI3-ID",
                "facilities": "Dienstleistungen",
                "lastUpdate": "Letzte Aktualisierung",
                "localisation": "Standort",
                "location": "Standort",
                "locationInfos": "Informationen zum Standort",
                "locations": "Standorte",
                "no": "Nein",
                "noRegularHours": "No opening hours configured",
                "openingHoursInfos": "Öffnungszeiten",
                "twentyfourseven": "24\/7 geöffnet",
                "type": "Typ",
                "yes": "Ja"
            }
        },
        "login": {
            "email": "E-Mail",
            "intro": "Verwalten Sie Ihr Netzwerk",
            "loginSubmit": "Verbindung",
            "password": "Kennwort",
            "passwordReset": "Passwort zurücksetzen"
        },
        "maintenance-alert": {
            "commons": {
                "list": {
                    "last-valid-session-date": "Letztes gültiges Sitzungsdatum"
                }
            },
            "downtime": {
                "list": {
                    "subtitle": "Liste der CBID, die seit mindestens 12 Stunden die Kommunikation verloren haben",
                    "title": "Kommunikationsausfälle"
                }
            },
            "error-code": {
                "list": {
                    "subtitle": "Liste der Stecker, die seit mindestens 1 Stunde einen Fehlercode gemeldet haben",
                    "title": "Fehlercodes"
                }
            },
            "no-energy-rate": {
                "list": {
                    "no-energy-count": "Anzahl ohne Verbrauch",
                    "no-energy-rate": "% ohne Verbrauch",
                    "subtitle": "Liste der CBID mit mindestens 20% gültiger Sitzungen, bei denen in den letzten 30 Tagen 0 kWh Energie gemeldet wurde.",
                    "title": "Energieausfälle",
                    "with-energy-count": "Anzahl mit Verbrauch"
                }
            },
            "session-failure-rate": {
                "list": {
                    "failure-rate": "% ungültig (<30 Tage)",
                    "invalid-count": "Ungültige Anzahl",
                    "subtitle": "Liste der CBID mit mindestens 60% ungültiger Sitzungen in den letzten 30 Tagen.",
                    "title": "Ladeverhältnisse",
                    "valid-count": "Gültige Anzahl"
                }
            },
            "usage-rate": {
                "list": {
                    "session-count-last30d": "Sitzungsanzahl (<30 Tage)",
                    "session-count-prior-month": "Sitzungsanzahl (30;60 Tage)",
                    "subtitle": "Liste der CBID mit mindestens 50% Nutzungsrückgang gegenüber den letzten 30 Tagen.",
                    "title": "Nutzungsrückgänge",
                    "usage-rate": "% Rückgang"
                }
            }
        },
        "networks": {
            "list": {
                "breadcrumb": "Netzwerke"
            }
        },
        "notifications": {
            "DOWNTIME": "Communication Losses",
            "ERROR_CODE": "Error Codes",
            "NO_ENERGY_RATE": "Energy Absences",
            "SESSION_FAILURE_RATE": "Load Ratios",
            "USAGE_RATE": "Utilization Drops",
            "confirmSelection": "Auswahl bestätigen",
            "selectAll": "Alle auswählen",
            "tabs": {
                "handled": "Verarbeitet",
                "unhandled": "Ausstehend"
            },
            "text": "Maintenance"
        },
        "ocpi": {
            "locationFacilities": {
                "AIRPORT": "Flughafen",
                "BUS_STOP": "Bushaltestelle",
                "CAFE": "Café",
                "CARPOOL_PARKING": "Fahrgemeinschaft",
                "FUEL_STATION": "Tankstelle",
                "HOTEL": "Hotel",
                "MALL": "Einkaufszentrum",
                "MUSEUM": "Museum",
                "NATURE": "Natur",
                "RECREATION_AREA": "Erholungsgebiet",
                "RESTAURANT": "Restaurant",
                "SPORT": "Sport",
                "SUPERMARKET": "Supermarkt",
                "TAXI_STAND": "Taxistand",
                "TRAIN_STATION": "Bahnhof",
                "WIFI": "Wifi"
            },
            "locationTypes": {
                "ON_STREET": "Parken auf der Straße",
                "OTHER": "Andere",
                "PARKING_GARAGE": "Parkhaus",
                "PARKING_LOT": "Parkplatz",
                "UNDERGROUND_GARAGE": "Tiefgarage",
                "UNKNOWN": "Unbekannt"
            },
            "logs": {
                "CLIENT": "Client",
                "REQUEST": "Anfrage",
                "RESPONSE": "Antwort",
                "SERVER": "Server",
                "breadcrumb": "OCPI",
                "context": "Kontext",
                "date": "Datum",
                "distant_operator": "Operator",
                "filterPlaceholder": "Suche nach Nachricht",
                "message": "Nachricht",
                "method": "Methode",
                "model": "Objekt",
                "module": "Module",
                "ocpi": "OCPI Logs",
                "request_type": "Typ",
                "title": "OCPI Logs von {name}",
                "way": "Richtung"
            },
            "parkingRestrictions": {
                "CUSTOMERS": "Reserviert für Gäste",
                "DISABLED": "Reserviert für behinderte Menschen",
                "EV_ONLY": "Reserviert für EV",
                "MOTORCYCLES": "Reserviert für Motorräder oder Roller",
                "PLUGGED": "Reserviert für EV beim Aufladen"
            },
            "priceComponentTypes": {
                "DURATION": "Duration",
                "ENERGY": "Energie",
                "FLAT": "Pauschal",
                "PARKING_TIME": "Parkzeit",
                "STOP": "Stop",
                "TIME": "Zeit"
            },
            "reservationStatus": {
                "ACTIVE": "Aktiv",
                "CANCELLED": "Abgebrochen",
                "ERROR": "Fehler",
                "FINISHED": "Beendet",
                "NOSHOW": "Keine Anzeige"
            }
        },
        "ocpp": {
            "logs": {
                "REQUEST": "Anfrage",
                "RESPONSE": "Antwort",
                "breadcrumb": "OCPP Logs",
                "filterPlaceholder": "Suche nach Nachricht",
                "message": "Nachricht",
                "protocol": "Protokoll",
                "requestType": "Typ",
                "sentBy": "Herausgeber",
                "title": "OCPP Logs von {name}"
            }
        },
        "operators": {
            "capabilities": {
                "CHARGING_PROFILE_CAPABLE": "Ladeprofil",
                "CREDIT_CARD_PAYABLE": "Zahlungsgerät",
                "REMOTE_START_STOP_CAPABLE": "Ladung mit Smartphone",
                "RESERVABLE": "Reservierung",
                "RESET_CAPABLE": "Zurücksetzen",
                "RFID_READER": "RFID",
                "UNLOCK_CAPABLE": "Fernentriegelung",
                "info": {
                    "CHARGING_PROFILE_CAPABLE": "Demnächst",
                    "CREDIT_CARD_PAYABLE": "Benutzer können mit einer Kredit-\/Debitkarte vor Ort bezahlen",
                    "REMOTE_START_STOP_CAPABLE": "Benutzer können per Smartphone aus der Ferne starten und stoppen",
                    "RESERVABLE": "Benutzer können einen Ladepunkt buchen",
                    "RESET_CAPABLE": "Hotline kann Ladegerät aus der Entfernung zurücksetzen",
                    "RFID_READER": "Benutzer können mit einer RFID-Karte starten und stoppen",
                    "UNLOCK_CAPABLE": "Hotline kann Kabel aus der Ferne freischalten"
                }
            },
            "configuration": {
                "breadcrumb": "Konfigurationen",
                "capability_set_by_parent": "Kann nicht geändert werden, da von Mutter festgelegt {emi3}",
                "manyEvsesWarning": "Alle Ladepunkte werden aktualisiert... bitte warten",
                "needSubmit": "Bitte speichern Sie zur Bestätigung der Änderungen",
                "technics": "Technische Konfiguration",
                "title": "Konfiguration von {name}"
            },
            "create": {
                "feedback": "Netzwerk erfolgreich hinzugefügt",
                "subCPOTitle": "Neues Sub-Netzwerk zu {name}",
                "title": "Neues Netzwerk"
            },
            "edit": {
                "breadcrumb": "Bearbeiten",
                "feedback": "Netzwerk erfolgreich aktualisiert",
                "title": "Bearbeiten"
            },
            "form": {
                "allowIssueClose": "Allow automatic closing of a ticket",
                "allowIssueCloseDesc": "Die Option wird angeboten, wenn das Ticket erstellt wird. Eine Änderung wirkt sich nicht auf bestehende Tickets aus.",
                "businessInfos": "Geschäftliche Informationen",
                "businessName": "Name",
                "businessURL": "Webseite",
                "comInterval": "Kommunikations-Intervall",
                "commission": "Provisionen und Bankgebühren",
                "consoInterval": "Intervall für Zählerwerte",
                "crc": "CRC",
                "defaultTariff": "Standardtarif",
                "defaultTariffPlaceholder": "Standardtarif",
                "defaultVAT": "Standard MwSt.",
                "detectionLoop": "Erkennungsschleife",
                "emi3IsUndefined": "Undefinierte eMI3-ID",
                "emi3SuccessMessage": "ist verfügbar",
                "emi3UnavailableMessage": "Dieses eMI3-ID ist nicht verfügbar. Bitte versuchen Sie ein anderes",
                "generateEmi3": "Generieren",
                "heartbeat_interval": "Herzschlag-Intervall",
                "inheritance": "Auf Subnetze ausdehnen",
                "initialState": "Anfangszustand",
                "interopConfig": "Konfiguration des Zugriffs",
                "invalidEmi3Message": "ist kein gültiges eMI3-ID. Bitte versuchen Sie es erneut",
                "maintenance": "Wartung",
                "metervalues_sample_interval": "Intervall für Zählerwerte",
                "name": "Name",
                "optional": "(fakultativ)",
                "parent": "Mutter",
                "parentPlaceholder": "Wählen Sie eine Mutter",
                "presence": "Erkennungsvorrichtung",
                "privateEmi3": "Privates eMI3-ID",
                "publicEmi3": "Öffentliches eMI3-ID",
                "publicEmi3Placeholder": "Wählen Sie ein eMI3-ID",
                "refPlaceholder": "Kann mit dem Namen gefüllt werden",
                "refUnavailableMessage": "Diese Referenz ist nicht verfügbar. Bitte versuchen Sie eine andere",
                "retrocession": "Rückgabe",
                "retrocessionGroup": "Retrozessionsgruppe",
                "sameAsParent": "Dasselbe wie Mutter",
                "sameAsPrivate": "Dieser Operator",
                "segment": "Abschnitt",
                "submit": "Speichern",
                "support": "Support",
                "timezone": "Zeitzone",
                "timezonePlaceholder": "Zeitzone"
            },
            "hotline": {
                "phoneNumber": "Telefonnummer",
                "schedule": "Zeitplan",
                "schedules": {
                    "Mon-Fri 7\/22": "Montag bis Freitag, 7.00 bis 22.00 Uhr",
                    "Mon-Fri 9\/17": "Montag bis Freitag, 9.00 bis 17.00 Uhr",
                    "Mon-Fri H24": "Montag bis Freitag, rund um die Uhr",
                    "Mon-Sun 7\/22": "Täglich von 7 bis 22 Uhr",
                    "Mon-Sun 9\/17": "Täglich von 9 bis 17 Uhr",
                    "Mon-Sun H24": "Täglich, rund um die Uhr"
                }
            },
            "list": {
                "VAT": "MwSt.",
                "breadcrumb": "Netzwerke",
                "children": "Sub-Netzwerke",
                "create": "Ein Netzwerk hinzufügen",
                "default_tariff": "Tarif",
                "emi3": "eMI3-ID",
                "evses": "Ladepunkte",
                "exportWithchildrens": "Kinder einbeziehen",
                "linkedCPOTitle": "Linked networks of {name}",
                "locations": "Standorte",
                "name": "Name",
                "network": "Netzwerk",
                "ongoingIssues": "Open tickets",
                "operator": "Netzwerk",
                "operatorsList": "Netzwerke",
                "parent": "Mutter",
                "parent_id": "Mutter ID",
                "parent_ref": "Mutter ID",
                "public_emi3": "Öffentliches eMI3-ID",
                "segment": "Segment",
                "subCPOTitle": "Sub-Netzwerke von {name}",
                "title": "Netzwerke",
                "updated_at": "Letzte Aktualisierung"
            },
            "map": {
                "breadcrumb": "Karte",
                "noLocation": "Es gibt keinen Standort in diesem Netzwerk",
                "options": {
                    "all": "Alle",
                    "charging": "Laufender Ladevorgang",
                    "issue": "Ausser Betrieb"
                },
                "title": "Karte von {name}"
            },
            "segments": {
                "COMPANIES": "Firmen",
                "RESIDENTIAL": "Wohnen",
                "ROADS": "Straßen",
                "SHOPS": "Geschäfte",
                "UNINFORMED": "Nicht spezifiziert"
            },
            "show": {
                "VAT": "MwSt.",
                "business": "Sonstiges",
                "businessName": "Name",
                "businessURL": "Webseite",
                "crc": "Hotline",
                "maintenance": "Wartung",
                "parent": "Mutter",
                "privateEmi3": "Privates eMI3-ID",
                "publicEmi3": "Öffentliches eMI3-ID",
                "segment": "Abschnitt",
                "support": "Hotline",
                "tariff": "Tarif",
                "timezone": "Zeitzone",
                "undefined": "Nicht definiert"
            }
        },
        "pagination": {
            "next": "Nächste Seite &raquo;",
            "previous": "&laquo; Vorherige Seite"
        },
        "passwords": {
            "password": "Mindestens 6 Buchstaben oder Ziffern. Beide Passwörter müssen übereinstimmen",
            "reset": "Passwort zurücksetzen",
            "sent": "E-Mail zugeschickt",
            "token": "Fehler",
            "user": "Falsches Passwort oder E-Mail"
        },
        "payment": {
            "iban": "Lastschrift",
            "methods": {
                "expires:": "Verfällt am:",
                "invalid": "Ungültig",
                "valid": "Valid"
            },
            "refunds": {
                "addFormTitle": "Erstattung vornehmen",
                "alreadyProcessed": "Rückerstattung bereits bearbeitet",
                "amountExceedRefund": "Betrag übersteigt Erstattungspreis",
                "amountIncVat": "Betrag (inkl. MwSt.)",
                "confirmDelete": "Möchten Sie diese Erstattung wirklich löschen?",
                "confirmDeleteTitle": "Erstattung löschen",
                "created": "Erstattung erstellt",
                "deleted": "Erstattung gelöscht",
                "editFormTitle": "Erstattung bearbeiten",
                "edited": "Rückerstattung bearbeitet",
                "export": "Erstattung exportieren",
                "highAmountWarn": "Erstattung über 100 {W}ährung",
                "inRetrocession": "In die Retrozession integriert",
                "includeRetro": "In Retrozessionen zu integrieren",
                "method": "Verfahren",
                "moveConnection": "Sie müssen in Move eingeloggt sein, um Erstattungen bearbeiten zu können.",
                "processedAt": "Verarbeitet bei",
                "reason": "Begründung",
                "refund": "Rückerstattung",
                "refundIssue": "Möglicherweise liegt ein Fehler bei der Rückerstattung des Stripe- oder Prepaid-Kontos vor.",
                "refunded": "Erstattet",
                "session": "Rückerstattung für Sitzung von {date} am {evse_ref}",
                "stripeAutoRefund": "Automatische Rückerstattung über Stripe ist nicht möglich, bitte erstatten Sie dem Benutzer manuell.",
                "subscription_with_order": "Rückerstattung für das Abonnement des Plans \"{plan_name}\" (Bestellung ab {order_date}).",
                "subscription_without_order": "Rückerstattung für das Abonnement des Plans \"{plan_name}\".",
                "token_with_order": "Rückerstattung für Token n°{token_number} (Bestellung ab {order_date}).",
                "token_without_order": "Rückerstattung für Token n°{token_number}.",
                "way": {
                    "manual": "Manuell",
                    "prepaid": "Vorausbezahlt",
                    "prepaid_account": "Vorausbezahlt",
                    "stripe": "Stripe"
                }
            },
            "status": {
                "AUTHORIZED": "Autorisiert",
                "CANCELLED": "Abgebrochen",
                "CAPTURED": "Abgerufen",
                "DONE": "Validiert",
                "FAILED": "Fehlgeschlagen",
                "PENDING_USER": "Warten",
                "REFUNDED": "Erstattet"
            },
            "transaction_labels": {
                "credit": "Guthaben auf Prepaid-Konto {CURRENY}{amount}",
                "credit_invoice_refund": "Rückerstattung für Rechnung {invoice_ref}",
                "debit": "Abbuchung vom Prepaid-Konto {CURRENCY}{amount}",
                "invoice": "Rechnung {ref}",
                "order": "Bestellung {ref}",
                "payment": "Rechnung {invoice_ref} (Bezahlung {payment_ref})",
                "session": "Ladevorgang {ref}",
                "subscription": "Abonnement {ref} (bis {end_at})"
            }
        },
        "reservations": {
            "add": "Hinzufügen",
            "authId": "Vertrag",
            "authIdPlaceholder": "Should match a Move eMAID",
            "breadcrumb": "Reservierungen",
            "calendar": "Kalender",
            "cancel": "Abbrechen",
            "cancelationIsSuccessful": "Reservierung storniert",
            "create": {
                "authId": "Vertrag",
                "end": "Ende",
                "locationId": "Standort",
                "start": "Start",
                "submit": "Speichern",
                "title": "Eine Reservierung hinzufügen"
            },
            "creationIsSuccessful": "Reservierung hinzugefügt",
            "day": "Tag",
            "list": {
                "breadcrumb": "Reservierung"
            },
            "location": "Standort",
            "locationTitle": "Reservierungen an {name}",
            "month": "Monat",
            "noReservationsThatDay": "Keine Reservierung für diesen Tag",
            "noReservationsThatMonth": "Diesen Monat keine Reservierung",
            "noReservationsThatWeek": "Diese Woche keine Reservierung",
            "reservation": "Reservierung",
            "status": {
                "ACTIVE": "Aktiv",
                "CANCELLED": "Abgebrochen",
                "ERROR": "Fehler",
                "FINISHED": "Beendet",
                "NOSHOW": "Keine Vorstellung"
            },
            "time": "Zeit",
            "title": "Reservierungen",
            "week": "Woche"
        },
        "retrocession-group-logs": {
            "list": {
                "breadcrumb": "Retrocession group logs",
                "created_at": "Date",
                "message": "Message",
                "retrocessionGroupName": "Retrocession group name",
                "scopeTitle": "Retrocession group logs from {name}",
                "title": "Retrocession group logs",
                "type": "Event",
                "user": "Supervisor"
            },
            "types": {
                "ChangeCommission": "Change in commission",
                "ChangeName": "Change of name",
                "ChangeNetwork": "Change the network",
                "ChangeRib": "Change of bank details"
            }
        },
        "retrocession-groups": {
            "create": {
                "breadcrumb": "Créer un groupe de retrocessions",
                "title": "Créer un groupe de retrocessions"
            },
            "edit": {
                "title": "Modifier un groupe de retrocessions"
            },
            "form": {
                "addExisting": "Add an existing retrocession group",
                "applyPastSessions": "Apply changes to past sessions",
                "applyPastSessionsMessage": "Be careful, this is a heavy operation that can take a few seconds or even a few minutes.",
                "assign": "Assign an existing retrocession group",
                "asyncSearch": "Select an existing group",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact (optional)",
                "create": "Create a new retrocession group",
                "general": "General",
                "group": "Retrocession group",
                "name": "Name"
            },
            "iban": {
                "bankAccountName": "Account Name",
                "bic": "BIC",
                "errorBic": "The BIC must be in a valid format of the SEPA zone",
                "errorIban": "The IBAN must be in a valid format of the SEPA zone",
                "iban": "IBAN",
                "modalDeleteConfirm": "The bank account details have been successfully deleted",
                "modalDeleteContent": "If you delete bank account details of the retrocession group, we will not be able to pay you your retrocession.",
                "modalDeleteError": "An error occurred while deleting the bank account details",
                "modalDeleteTitle": "Delete a bank account detail",
                "modalDeleteWarning": "Be careful, you are about to delete bank account details.",
                "modalUpdateTitle": "Edit the RIB of the retrocession group"
            },
            "list": {
                "breadcrumb": "Retrocession groups",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "created_at": "Creation date",
                "has_bank_info": "RIB completed",
                "name": "Retrocession group name",
                "nb_networks": "Number of networks",
                "title": "Retrocession groups"
            },
            "network": {
                "add": "Add",
                "continue": "Continue",
                "create": "Create a new group",
                "removeMessage": "You are about to remove the retrocession group from {name}. Removing the handover group from {name} also removes it from all of its subnets.",
                "removeTitle": "Remove the retrocession group",
                "replace": "Replace retrocession group"
            },
            "show": {
                "bankInfo": "Banking information",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "contactDetail": "Contact",
                "created_at": "Creation date",
                "general": "General informations",
                "name": "Retrocession group name",
                "rib": "RIB"
            },
            "toast": {
                "added": "The retrocession group has been successfully assigned",
                "removed": "The retrocession group has been successfully removed",
                "session-updated": "The sessions has been successfully updated"
            }
        },
        "retrocessions": {
            "exportRefundsOfRetrocession": "Export der Rückerstattungen von Retrozessionen",
            "modal": {
                "amountExceedInitialAmount": "Der Rückübertragungsbetrag darf den ursprünglichen Preis nicht überschreiten",
                "amountWithVat": "Betrag inkl. MwSt.",
                "createSuccess": "Die Rückübertragung wurde erfolgreich erstellt",
                "deleteSuccess": "Die Rückübertragung wurde erfolgreich gelöscht",
                "editSuccess": "Die Rückübertragung wurde erfolgreich bearbeitet",
                "reason": "Begründung",
                "retrocede": "Rückübertragen",
                "sessionRetrocession": "Rückübertragung der Sitzung"
            },
            "refundOfRetrocession": "Rückerstattung der Retrozession",
            "retrocession": "Retrozession"
        },
        "sessions": {
            "authorizationReasons": {
                "AUTHORIZED_BY_CPO": "CPO",
                "AUTHORIZED_BY_EMSP": "EMSP",
                "NOT_AUTHORIZED_BY_ANY_EMSP": "Kein EMSP",
                "NO_AUTHORIZATION_CHECK": "Keine Überprüfung",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Stromversorger",
                "PAYMENT_TERMINAL": "Zahlungsgerät",
                "UNKNOWN": "Unbekannt",
                "description": {
                    "AUTHORIZED_BY_CPO": "Von CPO autorisiert",
                    "AUTHORIZED_BY_EMSP": "Autorisiert durch EMSP",
                    "NOT_AUTHORIZED_BY_ANY_EMSP": "Von keinem EMSP autorisiert",
                    "NO_AUTHORIZATION_CHECK": "Vom Ladegerät nicht angeforderte Autorisierung",
                    "ORDERED_BY_ELECTRICITY_SUPPLIER": "Auftrag vom Stromversorger erhalten",
                    "PAYMENT_TERMINAL": "Verkaufsstelle (Zahlungsterminal vor Ort)"
                }
            },
            "create": {
                "agreementsList": "Vereinbarungen",
                "breadcrumb": "Hinzufügen",
                "title": "Vereinbarung hinzufügen"
            },
            "csv": {
                "confirmTitle": "CSV von {from} bis {to}",
                "default": "Bestätigen Sie den Export?",
                "retrocessionExporterTitle": "Export for retrocessions",
                "tooHeavy": "Die Datei wird gerade generiert und an {email} zugeschickt"
            },
            "curve": {
                "breadcrumb": "Kurven",
                "title": "Kurven für #{ref}"
            },
            "edit": {
                "agreementsList": "Vereinbarungen",
                "breadcrumb": "Bearbeiten",
                "title": "Vereinbarung bearbeiten"
            },
            "failureReasons": {
                "AUTHORIZATION_TIMEOUT": "Auszeit",
                "EMI3_MINIMUM_DURATION": "< 2 min",
                "INACTIVE": "Inaktiv",
                "LOCATION_FULLY_BOOKED": "Reservierter Platz",
                "NO_AUTHORIZATION": "Nicht autorisiert",
                "UNKNOWN": "Unbekannt",
                "description": {
                    "AUTHORIZATION_TIMEOUT": "Zeitüberschreitung bei der Autorisierung",
                    "EMI3_MINIMUM_DURATION": "Anwendung des von eMI3 empfohlenen Schwellenwertes",
                    "NO_AUTHORIZATION": "Von keinem EMSP autorisiert"
                }
            },
            "form": {
                "bridges": "Über",
                "cpo": "Netzwerk",
                "emsp": "Anbieter",
                "emspPlaceholder": "Einen Anbieter suchen",
                "includeSubCpos": "Sub-Netzwerke einbeziehen",
                "includeSubEmsps": "Sub-Anbieter einbeziehen",
                "isActive": "Aktiv",
                "ocpiModules": "OCPI-Module",
                "searchPlaceholder": "Hier eingeben",
                "submit": "Speichern"
            },
            "list": {
                "active": "Aktiv",
                "addAgreement": "Vereinbarung hinzufügen",
                "all": "Alle",
                "authorizationReason": "Grund der Autorisierung",
                "breadcrumb": "Vorgänge",
                "completed": "Abgeschlossen",
                "connector": "Stecker",
                "contrat": "Vertrag",
                "cpoTitle": "Vorgänge von {name}",
                "end": "Ende",
                "evse": "Ladepunkt",
                "evseTitle": "Vorgänge von {name}",
                "failureReason": "Grund des Versagens",
                "free": "Kostenlos",
                "invalid": "Ungültig",
                "kwh": "kWh",
                "locationTitle": "Vorgänge von {name}",
                "no": "Nein",
                "ocpiModules": "OCPI-Module",
                "ongoing": "Laufend",
                "optionsSelected": "Ausgewählte Optionen",
                "pending": "Ausstehend",
                "price": "Preis",
                "source": "Herkunft",
                "start": "Start",
                "station": "Standort",
                "status": "Stand",
                "stopReceivedAt": "Billing",
                "tag": "Zeichen",
                "tariff": "Tarif",
                "tariffPos": "Tariff POS",
                "title": "Vorgänge",
                "withChildrenOperators": "Vorgänge aus Sub-Netzwerken",
                "yes": "Ja"
            },
            "show": {
                "agreements": "Vereinbarungen",
                "auth": "Autorisierung",
                "badge": "Zeichen",
                "bridges": "Brücken",
                "cpo": "Netzwerk",
                "curveBreadcrumb": "Kurve",
                "curveTitle": "Kurve für",
                "edit": "bearbeiten",
                "emsp": "Anbieter",
                "energy": "Energie",
                "energyLabel": "Energie (kWh)",
                "false": "Falsch",
                "finalConsumption": "Endenergie",
                "fullPrice": "{amount} {currency}",
                "includeSubCpos": "Sub-Netzwerke einbeziehen",
                "includeSubEmsps": "Sub-Anbieter einbeziehen",
                "isActive": "Aktiv",
                "isWhiteListed": "Weisse Liste",
                "lastPeriod": "Letzte Periode",
                "lastUpdate": "Letzte Aktualisierung=>",
                "noBridges": "Direkt",
                "notEnoughChargingData": "Nicht genug Ladedaten für die Erstellung eines Diagramms",
                "ongoing": "Laufend",
                "periodDate": "Datum",
                "power": "Leistung",
                "powerLabel": "Leistung (kW)",
                "preTaxPrice": "{amount} exkl. MwSt., {Mwst}% MwSt.",
                "price": "Preis",
                "relations": "Beziehungen",
                "statusIsInvalid": "Vorgang gescheitert. Keine Gebühr",
                "statusIsPending": "Vorgang hat noch nicht begonnen",
                "stopReceivedAt": "Billing date",
                "stopSession": "Vorgang beenden",
                "stopSuccess": "Vorgang erfolgreich beendet",
                "title": "Ladevorgang #{ref}",
                "true": "Wahr"
            },
            "source": {
                "CPO_REMOTE_ORDER": "CPO",
                "EMSP_REMOTE_ORDER": "EMSP",
                "STATION": "Ladegerät"
            },
            "status": {
                "active": "Laufend",
                "completed": "Beendet",
                "invalid": "Ungültig",
                "pending": "Ausstehend",
                "valid": "Gültig"
            }
        },
        "settings": {
            "accessibleOperators": "Erreichbare Betreiber (Netze, zu denen Sie Zugang haben)",
            "changeLang": "Sprache wechseln",
            "currentPermission": "Ihre derzeitige Rolle:",
            "darkTheme": "Dunkles Design",
            "display": "Display",
            "fix": {
                "disabled": {
                    "detail": "Sie werden keine Benachrichtigungen erhalten",
                    "title": "Deaktiviert"
                },
                "fromDisabled": {
                    "toMonitoring": "Sie werden alle Tickets in Ihrem Netzwerk abonnieren",
                    "toParticipating": "Sie werden für alle Tickets, denen Sie zugewiesen sind, abonniert und von allen anderen abgemeldet.",
                    "toQuoting": "Sie werden von allen Tickets abgemeldet. Sie erhalten Benachrichtigungen, wenn Sie in einem Ticket erwähnt werden."
                },
                "fromMonitoring": {
                    "toDisabled": "Sie werden von allen Tickets abgemeldet",
                    "toParticipating": "Sie werden für alle Tickets, denen Sie zugewiesen sind, abonniert und von allen anderen abgemeldet.",
                    "toQuoting": "Sie werden von allen Tickets abgemeldet. Sie erhalten Benachrichtigungen, wenn Sie in einem Ticket erwähnt werden."
                },
                "fromParticipating": {
                    "toDisabled": "Sie werden von allen Tickets abgemeldet",
                    "toMonitoring": "Sie werden alle Tickets in Ihrem Netzwerk abonnieren",
                    "toQuoting": "Sie werden von allen Tickets abgemeldet. Sie erhalten Benachrichtigungen, wenn Sie in einem Ticket erwähnt werden."
                },
                "fromQuoting": {
                    "toDisabled": "Sie werden von allen Tickets abgemeldet",
                    "toMonitoring": "Sie werden alle Tickets in Ihrem Netzwerk abonnieren",
                    "toParticipating": "Sie werden für alle Tickets, denen Sie zugewiesen sind, abonniert und von allen anderen abgemeldet."
                },
                "isSubscribable": "Ich möchte nicht von anderen Supervisoren auf Tickets abonniert werden.",
                "monitoring": {
                    "detail": "Standardmäßig werden Sie für alle Tickets in Ihrem Netzwerk angemeldet. Sie erhalten also Benachrichtigungen zu jeder Aktivität.",
                    "title": "Überwachung"
                },
                "participating": {
                    "detail": "Sie werden für alle Tickets abonniert, denen Sie zugewiesen, erwähnt oder abonniert sind.",
                    "title": "Teilnahme"
                },
                "quoting": {
                    "detail": "Sie erhalten nur Benachrichtigungen, wenn Sie in einem Ticket oder einem Kommentar erwähnt werden. Sie werden nicht für diese Tickets abonniert.",
                    "title": "Bei Erwähnung"
                },
                "title": "Einstellungen für Mailbenachrichtigungen für Fix"
            },
            "fixedHeight": "Fixed height (Scroll in the table)",
            "hasAtLeastOneEmspInfo": "* In Park you cannot select a provider",
            "others": "Others",
            "preview": "Preview",
            "reduceMargin": "Reduce margins",
            "success": "Präferenzen gespeichert",
            "switchPermission": "Rolle wechseln",
            "tables": "Tables",
            "title": "Einstellungen",
            "truncateHeader": "Reduce column names"
        },
        "sidebar": {
            "items": {
                "actions": "Aktionen",
                "affiliations": "Zuordnungen",
                "agreements": "Vereinbarungen",
                "audit": "Aktivität",
                "business": "Business logs",
                "cdrs": "CDRs",
                "configurations": "Konfigurationen",
                "corrective": "Corrective maintenance",
                "costDetails": "Kostendetails",
                "curve": "Kurven",
                "details": "Details",
                "devices": "Chargebox ID",
                "duplicate": "Duplizieren",
                "edit": "Bearbeiten",
                "evses": "Ladepunkte",
                "fix": "Fix",
                "groups": "Gruppen",
                "history": "History",
                "information": "Informationen",
                "lans": "LAN",
                "linked_networks": "Linked networks",
                "locations": "Standorte",
                "logs": "Logs",
                "map": "Karte",
                "ocpi": "OCPI-Logs",
                "ocpp": "OCPP-Logs",
                "preventive": "Preventive maintenance",
                "reservations": "Reservierungen",
                "sessions": "Vorgänge",
                "subcpos": "Sub-Netzwerke",
                "tariffs": "Tarife"
            }
        },
        "station-check": {
            "breadcrumb": {
                "logs": "Protokolle",
                "report": "Inbetriebnahme"
            },
            "csv": {
                "confirmTitle": "Confirm your export"
            },
            "list": {
                "device_ok": "PdC Info correct",
                "location_ok": "Station Info correct",
                "nb_cbids": "Number of CBID",
                "nb_fail": "Fail",
                "nb_success": "Tested & successful",
                "nb_tested": "Tested",
                "title": "Commissioning of Terminals"
            },
            "logs": {
                "emptyEmailReplace": "Installer",
                "keys": {
                    "lan_device_commissioned": "CBID commissioned",
                    "lan_device_feedback_comment": "CBID Info Comment added",
                    "lan_device_feedback_comment_withdrawn": "CBID Info Comment withdrawn",
                    "lan_device_feedback_ok": "CBID Info correct",
                    "lan_device_feedback_resolved": "CBID feedback resolved",
                    "lan_device_feedbacks_all_done": "All CBID info processed",
                    "lan_device_test_is_online_ko": "Connection Test KO",
                    "lan_device_test_is_online_ok": "Connection Test OK",
                    "lan_device_test_remote_orders_ko": "Remote Commands Test KO",
                    "lan_device_test_remote_orders_ok": "Remote Commands Test OK",
                    "lan_device_validated": "CBID validated",
                    "report_archived_automatically": "Report archived automatically",
                    "report_archived_manually": "Report archived manually",
                    "report_location_feedback_ok": "Station Info correct",
                    "report_location_feedback_updated": "Station Info updated",
                    "report_status_incomplete": "Report Status incomplete",
                    "report_status_to_ko": "Report Status KO",
                    "report_status_to_ok": "Report Status OK"
                },
                "tableContact": "Related Contact",
                "tableMessage": "Message",
                "tableType": "Type",
                "title": "Activation logs of",
                "type": {
                    "LanDeviceFeedback": "Feedback",
                    "LanDeviceTest": "Tests",
                    "Location": "Station",
                    "Supervision": "Supervision"
                }
            },
            "show": {
                "address": "Address",
                "allPaid": "All paid",
                "archiveReport": "Archive this report",
                "archived": "Archived",
                "buttonValidateWithCommissioning": "Validate selection with commissioning",
                "buttonValidateWithoutCommissioning": "Validate selection without commissioning",
                "cbidUntested": "Untested",
                "comment": "Report",
                "commentHistory": "Global comments history",
                "commissioned": "In service",
                "company": "Company",
                "dateGeneration": "Status generation date",
                "dateUpdate": "Update date",
                "email": "Email address",
                "firstname": "First name",
                "fitter": "Fitter",
                "fitterSay": "Installer says",
                "informationsStation": "Station Information",
                "installerHistory": "Installer history",
                "is_online": "Connection test",
                "listCbidTested": "List of CBID OK",
                "listUntestedAndFailedCbid": "List of CBID NOK",
                "modalArchivedConfirmation": "The report has been successfully archived.",
                "modalArchivedConfirmationFailed": "The report could not be archived.",
                "modalArchivedContent": "You are about to archive this report.",
                "modalArchivedQuestion": "Do you want to continue?",
                "modalArchivedTitle": "Archive a Report",
                "modalCommentConfirmation": "The comment has been successfully marked as resolved.",
                "modalCommentConfirmationFailed": "Comment resolution failed.",
                "modalCommentContent": "You are about to mark this comment as resolved. It will disappear as a result.",
                "modalCommentQuestion": "Do you want to continue?",
                "modalCommentTitle": "Mark as resolved",
                "modalFailedCommissioningContent": "It seems the connection to the following terminals has been lost since the last test.",
                "modalFailedCommissioningTitle": "Terminal Commissioning Failed",
                "name": "Name",
                "noCbidToDisplay": "No CBID to display here",
                "noComments": "No comments",
                "numberOfCbid": "Number of CBID",
                "ocpp-numbers-missmatch": "Warning, the connector IDs do not seem to match, please check the logs.",
                "oneIsFree": "At least one is free",
                "phone": "Phone",
                "pointOfCharge": "Charge Points",
                "pricing": "Pricing",
                "remote_orders_ok": "Remote Orders Test",
                "report": "Report",
                "selectAllCbid": "Select all CBID",
                "showOcppLogs": "Show logs",
                "status": "Status",
                "statusOf": "Status of",
                "terminalComments": "Comments",
                "testerMostRecent": "Information of the most recent installer",
                "testsStatus": "Test status",
                "timestamp": "Timestamp",
                "toValidated": "To be validated",
                "validated": "validated",
                "waitingCommissioning": "Waiting for commissioning"
            },
            "status": {
                "archived": "Archived",
                "incomplete": "Incomplete",
                "nok": "NOK",
                "ok": "Ready"
            }
        },
        "stats": {
            "CO2AvoidedEmissions": "CO2",
            "CarbonMonoxideAvoidedEmissions": "CO",
            "DistanceAvoidedThermalVehicle": "Vermeideter Distanz in konventionellen Fahrzeugen",
            "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons und NOx",
            "IncomeByNetwork": "Umsatz pro Netzwerk (inkl. MwSt.)",
            "IncomeByProvider": "Umsatz pro Anbieter (inkl. MwSt.)",
            "IncomePerLocation": "Umsatz pro Standort (inkl. MwSt.)",
            "MTBF": "MTBF",
            "MTTR": "MTTR",
            "NoxAvoidedEmissions": "NOx",
            "ParticleAvoidedEmissions": "Partikel",
            "SavedFuel": "Vermeideter Kraftstoffverbrauch",
            "SessionsByConnectorType": "Vorgänge pro Stecker",
            "SessionsByConsumption": "Vorgänge pro Verbrauch",
            "SessionsByDay": "Vorgänge pro Tag",
            "SessionsByDuration": "Vorgänge pro Dauer",
            "SessionsByHours": "Vorgänge pro Uhrzeit",
            "SessionsByMonth": "Vorgänge pro Monat",
            "SessionsByNetwork": "Vorgänge pro Netzwerk",
            "SessionsByPower": "Vorgänge pro Leistung",
            "SessionsByProvider": "Vorgänge pro Anbieter",
            "SessionsPerLocation": "Vorgänge pro Standort",
            "availability": "Verfügbarkeit",
            "availabilityDuration": "Dauer der Verfügbarkeit",
            "average": "Durchschnitt",
            "avoidedEmissions": "Vermeidete Emissionen",
            "descriptions": {
                "AberrationRate": "Aberrationsrate",
                "AverageChargingDuration": "Während das Fahrzeug Energie von der Station bezieht",
                "AverageConnectionDuration": "Während das Fahrzeug geparkt und mit der Station verbunden ist, auch wenn es nicht mehr aufgeladen wird",
                "AverageConsumptionBySession": "Durchschnittlicher Verbrauch pro Vorgang",
                "CO2AvoidedEmissions": "Vermeidung von CO2-Emissionen dank der Verwendung von EVs, die an den Stationen aufgeladen werden",
                "CarbonMonoxideAvoidedEmissions": "Vermeidung von CO-Emissionen dank der Verwendung von EVs, die an den Stationen aufgeladen werden",
                "CleanSessionNumber": "Anzahl der qualifizierten Vorgänge",
                "DistanceAvoidedThermalVehicle": "Schätzung der Entfernung, die durch das Aufladen der EVs an den Stationen zurückgelegt wird",
                "EffectiveChargingOverConnectionRate": "Verhältnis tatsächliche Ladezeit\/Gesamtverbindungszeit",
                "ExcludedSession": "Ausgeschlossene Vorgänge",
                "HydrocarbonAndNoxAvoidedEmissions": "Kohlenwasserstoffe und NOx vermiedene Emissionen",
                "MTBF": "Durchschnittliche Zeit zwischen Ausfällen",
                "MTTR": "Durchschnittliche Zeit bis zur Reparatur",
                "NoxAvoidedEmissions": "Vermeidung von NOx-Emissionen dank der Verwendung von EVs, die an den Stationen aufgeladen werden",
                "ParticleAvoidedEmissions": "Vermeidung von Partikelemissionen dank der Verwendung von EVs, die an den Stationen aufgeladen werden",
                "SavedFuel": "Die Zahlen basieren auf dem aktuellen Mix konventioneller Fahrzeuge in der Europäischen Union",
                "SessionNumber": "Anzahl der Vorgänge",
                "SuccessConsumption": "Verbrauch von qualifizierten Vorgänge",
                "TotalChargingDuration": "Während das Fahrzeug Energie von der Station bezieht",
                "TotalConnectionDuration": "Während das Fahrzeug geparkt und mit der Station verbunden ist, auch wenn es nicht mehr aufgeladen wird",
                "TotalConsumption": "Verbrauch aller Vorgänge",
                "availabilityDuration": "Dauer der Verfügbarkeit",
                "availability_device_count": "Ladestationen",
                "availability_evse_count": "Ladepunkte",
                "averageIncome": "Durchschnittliche Kosten für kostenpflichtige Vorgänge. Kostenlose Vorgänge sind ausgeschlossen",
                "average_charge_time": "Während das Fahrzeug Energie von der Station bezieht",
                "average_plugin_time": "Während das Fahrzeug geparkt und mit der Station verbunden ist, auch wenn es nicht mehr aufgeladen wird",
                "device_offline_duration": "Stationen - Offline Dauer",
                "device_offline_duration_rate": "Stationen - Offline Rate",
                "evse_available_duration_rate": "Ladepunkte - Verfügbarkeitrate",
                "evse_failure_count": "Störungen",
                "evse_unavailable_duration": "Ladepunkte - Nichtverfügbarkeitdauer",
                "evse_unavailable_duration_rate": "Ladepunkte - Nichtverfügbarkeitrate",
                "failed_session_count": "Anzahl der als \"gescheitert\" betrachteten Vorgänge (< 2 min oder < 500 Wh)",
                "failed_session_rate": "Verhältnis \"gescheiterte\" Vorgänge \/ Gesamtzahl der Vorgänge",
                "numberOfIssues": "Anzahl der Störungen",
                "offlineDuration": "Offline-Dauer",
                "sessionIssueRatio": "Verhältnis von Vorgänge mit Problem",
                "successful_session_consumption": "Gesamtverbrauch der qualifizierten Vorgänge",
                "successful_session_count": "Qualifizierte Vorgänge sind Ladevorgänge über 2 Minuten",
                "successful_session_rate": "Verhältnis qualifizierte Vorgänge \/ Gesamtzahl der Vorgänge",
                "totalIncome": "Gesamtumsatz",
                "total_charge_time": "Während das Fahrzeug Energie von der Station bezieht",
                "total_plugin_time": "Während das Fahrzeug geparkt und mit der Station verbunden ist, auch wenn es nicht mehr aufgeladen wird",
                "total_session_consumption": "Gesamtverbrauch",
                "total_session_count": "Statistiken basieren nur auf der Anzahl der qualifizierten Vorgänge",
                "unavailabilityDuration": "Dauer der Nichtverfügbarkeit"
            },
            "g": "g",
            "ie": "d.h.",
            "kg": "Kg",
            "km": "Km",
            "l": "Liter",
            "labels": {
                "LessThan10": "< 10",
                "LessThan120": "< 120",
                "LessThan1440": "< 1440",
                "LessThan15": "< 15",
                "LessThan20": "< 20",
                "LessThan240": "< 240",
                "LessThan30": "< 30",
                "LessThan40": "< 40",
                "LessThan480": "< 480",
                "LessThan5": "< 5",
                "LessThan60": "< 60",
                "LessThan720": "< 720",
                "MoreThan1440": "> 1440",
                "MoreThan60": "> 60",
                "SessionsByConsumption": {
                    "_0_5_kwh": "0 ≤ kWh < 5",
                    "_10_20_kwh": "10 ≤ kWh < 20",
                    "_20_40_kwh": "20 ≤ kWh < 40",
                    "_40_60_kwh": "40 ≤ kWh < 60",
                    "_5_10_kwh": "5 ≤ kWh < 10",
                    "_60_kwh": "≥ 60 kWh"
                },
                "SessionsByDuration": {
                    "_0_15_min": "0 ≤ min < 15",
                    "_120_240_min": "2 ≤ h < 4",
                    "_1440_min": "≥ 24 h",
                    "_15_30_min": "15 ≤ min < 30",
                    "_240_480_min": "4 ≤ h < 8",
                    "_30_60_min": "30 ≤ min < 60",
                    "_480_720_min": "8 ≤ h < 12",
                    "_60_120_min": "1 ≤ h < 2",
                    "_720_1440_min": "12 ≤ h < 24"
                },
                "SessionsByPower": {
                    "_0_4_kw": "0 ≤ kW < 4",
                    "_100_150_kw": "100 ≤ kW < 150",
                    "_12_22_kw": "12 ≤ kW < 22",
                    "_150_kw": "≥ 150 kW",
                    "_22_50_kw": "22 ≤ kW < 50",
                    "_4_8_kw": "4 ≤ kW < 8",
                    "_50_100_kw": "50 ≤ kW < 100",
                    "_8_12_kw": "8 ≤ kW < 12"
                }
            },
            "liters": "Liter",
            "mi": "Meilen",
            "miles": "Meilen",
            "noProvider": "Kein Anbieter (P&C, Zahlungsterminal)",
            "noStats": "Keine Statistiken verfügbar",
            "noStatsMessage": "Keine Daten für diesen Zeitraum",
            "numberOfIssues": "Anzahl der Probleme",
            "numberOfSessions": "{number} Vorgänge",
            "offlineDuration": "Offline-Dauer",
            "pdf": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons und NOx",
                "IncomeByNetwork": "Umsatz pro Netzwerk (inkl. MwSt.)",
                "IncomeByProvider": "Umsatz pro Anbieter (inkl. MwSt.)",
                "IncomePerLocation": "Umsatz pro Standort (inkl. MwSt.)",
                "LessThan10kWh": "≤ 10 kWh",
                "LessThan12": "≤ 12 kW",
                "LessThan12kW": "≤ 12 kW",
                "LessThan20kWh": "≤ 20 kWh",
                "LessThan22": "≤ 22 kW",
                "LessThan22kW": "≤ 22 kW",
                "LessThan4": "≤ 4 kW",
                "LessThan40kWh": "≤ 40 kWh",
                "LessThan4kW": "≤ 4 kW",
                "LessThan5kWh": "≤ 5 kWh",
                "LessThan60kWh": "≤ 60 kWh",
                "LessThan8": "≤ 8 kW",
                "LessThan8kW": "≤ 8 kW",
                "MWh": "MWh",
                "MoreThan22": "Alle Vorgänge",
                "MoreThan22kW": "Alle Vorgänge",
                "MoreThan60kWh": "Alle Vorgänge",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Partikel",
                "SessionsByConnectorType": "Aufschlüsselung pro Stecker",
                "SessionsByConsumption": "Vorgänge pro Verbrauch",
                "SessionsByConsumptionTitle": "Vorgänge pro Verbrauch",
                "SessionsByDay": "Vorgänge pro Tag",
                "SessionsByDayTitle": "Vorgänge pro Tag",
                "SessionsByDuration": "Vorgänge pro Dauer",
                "SessionsByDurationTitle": "Vorgänge pro Dauer",
                "SessionsByHours": "Vorgänge pro Uhrzeit",
                "SessionsByMonth": "Vorgänge pro Monat",
                "SessionsByMonthTitle": "Vorgänge pro Monat",
                "SessionsByNetwork": "Vorgänge pro Netzwerk",
                "SessionsByPower": "Vorgänge pro Leistung",
                "SessionsByPowerTitle": "Vorgänge pro Leistung",
                "SessionsByProvider": "Vorgänge pro Anbieter",
                "SessionsHistogramme": "Belegungsdiagramm",
                "SessionsPerLocation": "Vorgänge pro Standort",
                "aggregatedDuration": "Aggregierte Dauer",
                "amountCurrency": "Betrag in {currency}",
                "availability": "Verfügbarkeit",
                "average": "Durchschnitt",
                "averageBasket": "Durchschnittlich bezahlter Preis",
                "averageChargeTime": "Durchschnittliche Ladezeit",
                "averageCostBySession": "Durchschnittliche Kosten pro Vorgang",
                "averageDuration": "Durchschnittliche Ladedauer",
                "averagePluginTime": "Durchschnittliche Dauer während der Verbindung",
                "chargeTime": "Dauer der Vorgänge",
                "connector": "Stecker",
                "consumption": "Verbrauch",
                "data": "Daten",
                "distanceAvoidedThermalVehicle": "Vermeidete Distanz in konventionellen Fahrzeugen",
                "duration": "Dauer",
                "environmentImpact": "Auswirkungen auf die Umwelt",
                "environmentInfos": "Umweltdaten",
                "fileName": "cpo-stats-",
                "group": "Anbieter",
                "hhmmss": "St.,m,s",
                "income": "Umsatz (inkl. MwSt.)",
                "kWh": "kWh",
                "kg": "Kg",
                "km": "Km",
                "lessThan15Min": "≤ 15 min",
                "lessThan1Hour": "≤ 1 Stunde",
                "lessThan2Hour": "≤ 2 Stunden",
                "lessThan30Min": "≤ 30 min",
                "lessThan4Hour": "≤ 4 Stunden",
                "liters": "Liter",
                "location": "Standort",
                "mainIndicators": "Wichtige Indikatoren",
                "miles": "Meilen",
                "month": "Monate",
                "moreThan4Hour": ">  4 Stunden",
                "network": "Netzwerk",
                "nodata": "Nicht genügend Daten",
                "number": "Anzahl",
                "numberOfSessions": "Anzahl der Vorgänge",
                "occupationRate": "Besetzungsrate",
                "operator": "Anbieter",
                "others": "Andere",
                "percent": "Prozent",
                "period": "Zeitraum",
                "pluginTime": "Ladedauer",
                "savedFuel": "Eingesparter Treibstoff",
                "sessionSuccessRate": "Qualifizierte Vorgangsrate",
                "successfulSessions": "Qualifizierte Vorgänge",
                "title": "Statistiken",
                "total": "Gesamt",
                "weekDay": "Wochentag"
            },
            "percentageOfDuration": "{percentage}% von Gesamtdauer",
            "q1": "1. Quartal",
            "q2": "2. Quartal",
            "q3": "3. Quartal",
            "q4": "4. Quartal",
            "rempConvVehicles": "Umweltdaten",
            "selectNetworkPlaceholder": "Netzwerk auswählen",
            "sessionIssueRatio": "Ausgabeverhältnis der Vorgänge",
            "sessionSimpleStats": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "DistanceAvoidedThermalVehicle": "Vermeidete Distanz in konventionellen Fahrzeugen",
                "EffectiveChargingOverConnectionRate": "Verhältnis tatsächliche Ladezeit\/Gesamtverbindungszeit",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons und NOx",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Partikel",
                "SavedFuel": "Eingesparter Treibstoff",
                "SessionNumber": "Anzahl der Vorgänge",
                "average_charge_time": "Durchschnittliche Ladedauer",
                "average_conso_by_session": "Durchschnittlicher Verbrauch pro Vorgang",
                "average_plugin_time": "Durchschnittliche Dauer während der Verbindung",
                "chargingDuration": "Ladedauer",
                "consumption": "Verbrauch",
                "failed_session_count": "Nicht qualifizierte Vorgänge",
                "failed_session_rate": "Aberrationsrate",
                "income": "Umsatz (inkl. MwSt.)",
                "sessionDuration": "Dauer der Vorgänge",
                "successful_session_consumption": "Verbrauch von qualifizierten Vorgänge",
                "successful_session_count": "Anzahl der qualifizierten Vorgänge",
                "successful_session_rate": "Rate qualifizierter Vorgänge",
                "total_charge_time": "Gesamte Ladedauer",
                "total_plugin_time": "Gesamtdauer während der Verbindung",
                "total_session_consumption": "Gesamtverbrauch",
                "total_session_count": "Gesamtzahl der Vorgänge"
            },
            "successOnly": "Nur qualifizierte Vorgänge",
            "title": "Statistiken",
            "top5Remains": "Andere",
            "total": "Gesamt",
            "totalIncome": "Gesamteinkommen: {income}{currency}",
            "totalIncomeLabel": "Gesamteinkommen",
            "unavailabilityDuration": "Dauer der Nichtverfügbarkeit"
        },
        "stickers": {
            "bigStickers": "Enthält 10x10 Stickers",
            "download": "Herunterladen",
            "evses": "Ladepunkte",
            "exemple": "Beispiel eines kompatiblen Formats:",
            "guide": "Um alle QR-Codes in einem PDF zu erzeugen, kopieren Sie bitte Ihre Liste in den Textbereich und klicken Sie auf Download. Sie können auch eine Vorschau des PDF mit dem Kippschalter anzeigen",
            "noEvsesAvailable": "Keinen LP mit diesem Operator verfügbar",
            "selectOperator": "Wählen Sie einen Operator zum Importieren von LP",
            "showPreview": "PDF-Vorschau anzeigen",
            "title": "Ladepunkt-QR-Code-Generator"
        },
        "supervision": {
            "default_error": "Fehler",
            "downloadRecentBrowser": "Bitte aktualisieren Sie Ihren Browser",
            "following_errors": "Fehler:",
            "not_allowed": "Zugang verweigert"
        },
        "table": {
            "columns": "Kolumnen",
            "count": "Ein Datensatz|Anzeigen von {from} bis {to} von {count} Datensätzen|{count} Datensätze",
            "defaultOption": "Wählen Sie {column}",
            "filter": "Filtern:",
            "filterBy": "Filtern nach {column}",
            "filterPlaceholder": "Suchen",
            "first": "Erste",
            "goToFirstPage": "Erste",
            "goToLastPage": "Letzte",
            "last": "Letzte",
            "limit": "Aufzeichnungen:",
            "loadPaging": "Sehen Sie sich die Anzahl der Ergebnisse an",
            "loading": "Laden...",
            "noFilter": "Kein Filter angewendet",
            "noResults": "Keine übereinstimmenden Daten",
            "page": "Seite:",
            "undefined": "Undefiniert"
        },
        "tariffs": {
            "create": {
                "breadcrumb": "Tarif hinzufügen",
                "cpoTitle": "Neuer Tarif mit {name}",
                "feedback": "Tarif erfolgreich hinzugefügt",
                "tariffList": "Tarife",
                "title": "Einen neuen Tarif hinzufügen"
            },
            "edit": {
                "breadcrumb": "Bearbeiten",
                "feedback": "Tarif erfolgreich aktualisiert",
                "tariffList": "Tarife",
                "title": "Tarif bearbeiten"
            },
            "form": {
                "cgv": "AGB",
                "cpo": "Netzwerk",
                "currency": "Währung",
                "currencyPlaceholder": "Währung",
                "defaultTariffPlaceholder": "Nach einen Tarif suchen",
                "description": "Beschreibung",
                "errorNameAlreadyExists": "A tariff with the same name already exists",
                "is_private": "Privat",
                "maxPrice": "Maximaler Preis",
                "name": "Name",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provision inkl. MwSt.",
                "refPlaceholder": "Kann mit dem Namen gefüllt werden",
                "refUnavailableMessage": "Diese Referenz ist nicht verfügbar. Bitte versuchen Sie eine andere",
                "submit": "Speichern",
                "updatedAt": "Letzte Aktualisierung"
            },
            "list": {
                "addTariff": "Tarif",
                "breadcrumb": "Tarife",
                "cpoTitle": "Tarife von {name}",
                "currency": "Währung",
                "elements": "Preisgruppen",
                "globalRestrictions": "Anwendungsschwelle",
                "is_private": "Privat",
                "name": "Name",
                "network": "Netzwerk",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provision inkl. MwSt.",
                "title": "Tarife",
                "updated_at": "Letzte Aktualisierung",
                "vat": "MwSt."
            },
            "maxPrice": {
                "add": "Hinzufügen",
                "confirmDelete": "Löschung des gemeinsamen Höchstpreises bestätigen?",
                "maxPrice": "Maximaler Preis",
                "maxPriceDeleted": "Gemeinsamer Höchstpreis gelöscht",
                "maxPriceEdited": "Gemeinsamer Höchstpreis aktualisiert",
                "noMaxPrices": "Kein Höchstpreis definiert",
                "searchAddGroup": "Gruppe suchen oder hinzufügen",
                "title": "Gemeinsamer Höchstpreis",
                "tooltipPriceDelete": "Gemeinsamen Preis löschen"
            },
            "operators": {
                "CONSUMPTION": {
                    "<": "Verbrauch kleiner als",
                    "<=": "Verbrauch kleiner oder gleich als",
                    ">": "Verbrauch größer als",
                    ">=": "Verbrauch größer oder gleich als"
                },
                "DURATION": {
                    "<": "Dauer kleiner als",
                    "<=": "Dauer kleiner oder gleich als",
                    ">": "Dauer größer als",
                    ">=": "Dauer größer oder gleich als"
                },
                "and": "UND",
                "or": "ODER"
            },
            "prices": {
                "DURATION_description": "Die Abrechnung hängt von der Länge der Verbindungszeit ab, unabhängig vom Verbrauch des Fahrzeugs",
                "ENERGY_description": "Rechnungsstellung hängt vom Verbrauch ab",
                "FLAT_description": "Die Rechnungsstellung umfasst einen festen Betrag",
                "PARKING_TIME_description": "Rechnungsstellung hängt von der Zeit ab, ohne Energie zu verbrauchen",
                "STOP_description": "Ladevorgang wird ab einem bestimmten Schwellenwert aufhören",
                "TIME_description": "Rechnungsstellung hängt von der Zeit während des Energiebezugs ab",
                "addFormTitle": "Neuer Preis",
                "addTooltip": "Einen neuen Preis hinzufügen",
                "confirmDeletion": "Preislöschung bestätigen?",
                "created": "Preis hinzugefügt",
                "deleteTooltip": "Preis löschen",
                "deleted": "Preis gelöscht",
                "editFormTitle": "Preis bearbeiten",
                "editTooltip": "Preis bearbeiten",
                "edited": "Preis editiert",
                "minutes": "Minuten",
                "stepSize": "Schritt",
                "type": "Typ"
            },
            "restrictions": {
                "allDays": "Täglich",
                "consumption": "Energie (kWh)",
                "created": "Preisgruppe erfolgreich hinzugefügt",
                "date": "Datum",
                "days": "Tage",
                "deleteGroupTooltip": "Gruppe löschen",
                "duration": "Dauer (Minuten)",
                "editTooltip": "Einschränkungen bearbeiten",
                "edited": "Einschränkung erfolgreich bearbeitet",
                "formTitle": "Einschränkungen",
                "info": "Preise, die in dieser Gruppe hinzugefügt werden, gehorchen den Einschränkungen",
                "invalidConsumption": "Ungültiger Energiewert",
                "invalidDays": "Wählen Sie mindestens einen Tag aus",
                "invalidDuration": "Ungültiger Wert für die Dauer",
                "invalidPower": "Ungültiger Leistungswert",
                "max": "Max",
                "min": "Min",
                "power": "Leistung (kW)",
                "price": "Preis",
                "removeDate": "Datum entfernen",
                "time_after_consumption_end": "Zeit nach Beendigung des Verbrauchs (Minuten)",
                "updated": "Schwellenwert aktualisiert"
            },
            "show": {
                "add": "hinzufügen",
                "addCondition": "Schwellenwert hinzufügen",
                "addPriceFormTitle": "Preis hinzufügen",
                "breadcrumb": "Tarif",
                "cgvUrl": "URL zu den AGB",
                "conditions": "Anwendungsschwelle",
                "confirmGroupDeletion": "Gruppenlöschung bestätigen ?",
                "confirmNotificationDeletion": "Bestätigen Sie die Löschung von Meldungen?",
                "confirmTariffDeletion": "Tariflöschung bestätigen ?",
                "consumption": "Energie (Wh)",
                "costPerPowerUpdated": "Kosten pro durchschnittlich aktualisierter Leistung",
                "cpo": "Netzwerk",
                "createGroup": "Eine neue Gruppe hinzufügen",
                "currency": "Währung",
                "date": "Datum",
                "days": "Tage",
                "description": "Beschreibung",
                "duration": "Dauer (Minuten)",
                "edit": "Bearbeiten",
                "editPriceFormTitle": "Preis bearbeiten",
                "elements": "Preise",
                "fixedPrice": "Feste Kosten",
                "from": "Von",
                "groupDeleted": "Gruppe gelöscht",
                "hours": "Zeitplan",
                "invalidConsumption": "Ungültiger Energiewert",
                "invalidDays": "Wählen Sie mindestens einen Tag aus",
                "invalidDuration": "Ungültiger Wert für die Dauer",
                "invalidPower": "Ungültiger Leistungswert",
                "is_private": "Privat",
                "lastUpdate": "Letzte Aktualisierung:",
                "max": "Max",
                "maxPrice": "Maximaler Preis",
                "min": "Min",
                "minutes": "Minuten",
                "mustAddRestriction": "In dieser Gruppe hinzugefügte Preise unterliegen Einschränkungen",
                "name": "Name",
                "noCgv": "Keine AGB",
                "noConditions": "Kein Schwellenwert",
                "noDescription": "Keine Beschreibung",
                "noPrices": "Keine Preise",
                "notificationAdded": "Notifizierung der Tarifentwicklung hinzugefügt",
                "notificationCantBeAdd": "Es liegt keine Anmeldung vor. Es gibt eine Einschränkung in Bezug auf Leistung und\/oder Energie für den Tarif",
                "notificationDeleted": "Mitteilung der Tarifentwicklung gestrichen",
                "notificationEmpty": "Keine konfigurierte Benachrichtigung",
                "notificationTitle": "Notifizierung der Tarifentwicklung",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "popup": {
                    "atTimeEvolve": "zum Zeitpunkt",
                    "beforeAndAtTimeEvolve": "vor und zur Zeit",
                    "beforeEvolve": "vor",
                    "chooseMomentTitle": "Bitte wählen Sie, wann die Benutzer eine Benachrichtigung erhalten sollen",
                    "evolveTariffText": "die Tarifentwicklung",
                    "minBefore": "Minuten vor",
                    "minutes": "Minuten",
                    "sendNotifText": "Eine Benachrichtigung senden",
                    "sendNotifTitle": "Übermittlung der Meldung",
                    "title": "Konfigurieren Sie eine Benachrichtigung zum Tarif"
                },
                "power": "Leistung (kW)",
                "powerMax": "Maximale Leistung",
                "powerMin": "Minimale Leistung",
                "powerTimeTitle": "Durchschnittliche Leistung über die Zeit",
                "price": "Preis",
                "priceGroup": "Gruppe {number}",
                "prices": "Preise",
                "provision": "Provision inkl. MwSt.",
                "restrictionEdited": "Einschränkung erfolgreich bearbeitet",
                "restrictionFormTitle": "Einschränkungen",
                "restrictionIntro": "Definieren, wann der Tarif gilt",
                "restrictions": "Einschränkungen",
                "stepSize": "Schritt",
                "tariffDeleted": "Tarif gelöscht",
                "to": "An",
                "type": "Typ",
                "weekDays": "Wochentage",
                "weekEnd": "Wochenende",
                "wh": "Wh"
            },
            "status": {
                "active": "Aktiven",
                "inactive": "Inaktiv"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "string": "The {attribute} must be greater than or equal {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal {value}.",
                "string": "The {attribute} must be less than or equal {value} characters."
            },
            "max": {
                "array": "The {attribute} may not have more than {max} items.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "numeric": "The {attribute} may not be greater than {max}.",
                "string": "The {attribute} may not be greater than {max} characters."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid."
        }
    },
    "en": {
        "agreements": {
            "create": {
                "agreementsList": "Agreements",
                "breadcrumb": "Add",
                "feedback": "Agreement successfully added",
                "title": "Add a new agreement"
            },
            "edit": {
                "agreementsList": "Agreements",
                "breadcrumb": "Edit",
                "feedback": "Agreement successfully updated",
                "title": "Edit agreement"
            },
            "form": {
                "alreadyExists": "An agreement already exists between {cpo} - {emsp}",
                "cpo": "Network",
                "emsp": "Provider",
                "emspPlaceholder": "Search a provider",
                "includeSubCpos": "Include sub-networks",
                "includeSubEmsps": "Include sub-providers",
                "isActive": "Active",
                "ocpiModules": "OCPI modules",
                "searchPlaceholder": "Type here",
                "submit": "Save",
                "tariffPlaceholder": "No tariff defined",
                "viaRoaming": "Via roaming platform"
            },
            "list": {
                "addAgreement": "Add agreement",
                "autoliquidation": "Reverse charge",
                "breadcrumb": "Agreements (list)",
                "cpoId": "Network ID",
                "directFilter": "Parent networks included",
                "emspId": "Provider ID",
                "includeSubCpos": "Include sub-networks",
                "includeSubEmsps": "Include sub-providers",
                "no": "No",
                "noOcpiModules": "No OCPI modules",
                "ocpiModules": "OCPI modules",
                "optionsSelected": "options selected",
                "tariff": "Specific tariff",
                "title": "Agreements",
                "updated_at": "Updated",
                "yes": "Yes"
            },
            "show": {
                "agreements": "Agreements",
                "bridges": "Bridges",
                "cpo": "Network",
                "edit": "Edit",
                "emsp": "Provider",
                "false": "False",
                "includeSubCpos": "Include sub-networks",
                "includeSubEmsps": "Include sub-providers",
                "lastUpdate": "Last update",
                "noBridges": "Direct",
                "true": "True"
            }
        },
        "alert": {
            "alerts": "Alerts",
            "colorTitle": "Message color",
            "confirmPublish": "Are you sure you want to publish this alert ?",
            "confirmUnPublish": "Are you sure you want to delete this alert ?",
            "createTitle": "Create a new alert",
            "currentAlert": "Alert in progress",
            "delete": "Delete current alert",
            "disableFailed": "Failed to delete the alert",
            "disabled": "The alert has been removed",
            "editStatuses": "Edit alerts status",
            "editTitle": "Modify the current alert",
            "messageClosed": "You can find these alerts in the \"Settings\" tab",
            "newAlert": "New alert",
            "publishFailed": "Failed to publish alert",
            "publishSuccessful": "The alert has been published",
            "showOnlyActive": "Show Only active alerts",
            "title": "Manage alerts",
            "type": {
                "danger": "Incident",
                "info": "Information",
                "success": "Resolution",
                "warning": "Maintenance"
            },
            "unPublishFailed": "Failed to delete the alert",
            "unPublishSuccessful": "The alert has been removed.",
            "updateFailed": "Failed to delete the alert",
            "updated": "The alert has been modified"
        },
        "audit": {
            "ACTION": "Action done",
            "CREATE": "Added",
            "DELETE": "Removed",
            "READ": "Consulted",
            "RESTORE": "Restored",
            "UPDATE": "Updated",
            "UPDATE_TRANSLATION": "Translations updated",
            "breadcrumb": "Activity",
            "noActivity": "No activity",
            "title": "Activity of {name}",
            "via": {
                "BACKOFFICE": "Back-office",
                "CLI": "CLI",
                "JOBS": "Automatic task",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "SUPERVISION": "Supervision"
            }
        },
        "auth": {
            "confirmPassword": "Confirm password",
            "emailSent": "Email sent",
            "failed": "Wrong credentials",
            "login": "Login",
            "passResetSuccess": "Password successfully reset",
            "permanent_ban": "Due to too many login attempts, your account has been deactivated. Please contact your administrator.",
            "register": "Sign up",
            "reset": "Reset password",
            "sendLink": "Send password reset link",
            "successLogout": "Successfully logged out",
            "temporary_ban": "You have made too many login attempts: Please try again later.",
            "throttle": "Too many attempts. Please try again in {seconds} seconds"
        },
        "businessLogs": {
            "breadcrumb": "Logs",
            "byEvse": "Filter by EVSE",
            "context": "Context",
            "contextValues": {
                "CLI": "Server",
                "JOBS": "Task",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "OCPPJ": "OCPP-J",
                "SUPERVISION": "Supervision",
                "WEBSOCKET": "Websocket"
            },
            "diagnosticStatus": {
                "UploadFailed": "Failed to send diagnostic",
                "Uploaded": "Diagnostic successfully sent"
            },
            "event": "Event",
            "eventValues": {
                "ALREADY_COMPLETED_SESSION_ENDED": "End of already completed session",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Price asked by already completed session",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Energy updated by already completed session",
                "ALREADY_COMPLETED_SESSION_STARTED": "Start of already completed session",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "Rearming device",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "Start outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Change availability",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Clear cache",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Get diagnostics",
                "ATTEMPTING_REMOTE_RESET": "Reset",
                "ATTEMPTING_REMOTE_START": "Remote start",
                "ATTEMPTING_REMOTE_STOP": "Remote stop",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Unlock",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Update firmware",
                "CONNECTOR_STATUS_UPDATED": "Connector status",
                "DETECTION_SENSOR_BLOCKED": "Detection sensor blocked",
                "DETECTION_SENSOR_IGNORED": "Detection sensor ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Detection sensor unblocked",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Detection sensor unknown connector",
                "DIAGNOSTIC_STATUS": "Diagnostic status",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "Device rearmed successfully",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "Failure to rearm device",
                "ERCOGENER_START_OUTLET_FAILURE": "Outlet started successfully",
                "ERCOGENER_START_OUTLET_SUCCESS": "Failure to start outlet",
                "EVSE_STATUS_UPDATED": "EVSE status",
                "FIRMWARE_STATUS": "Firmware status",
                "LAN_DEVICE_BOOTED": "Charger boot",
                "LAN_DEVICE_DOWN": "Charger offline",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Firmware updated",
                "LAN_DEVICE_MODEL_CHANGE": "Model updated",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Protocol updated",
                "LAN_DEVICE_UP": "Charger online",
                "LAN_DEVICE_VENDOR_CHANGE": "Vendor updated",
                "LAN_PUBLIC_IP_CHANGE": "IP updated",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "Payment terminal offline failure report",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "Payment terminal offline success report",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment terminal online failure report",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment terminal online success report",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Change availability failure",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Change availability success",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Failed to get diagnostics",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Diagnostics successfully got",
                "REMOTE_RESET_FAILURE": "Failed to reset",
                "REMOTE_RESET_SUCCESS": "Successfully reset",
                "REMOTE_START_FAILURE": "Remote start failure",
                "REMOTE_START_SUCCESS": "Remote start success",
                "REMOTE_STOP_FAILURE": "Remote stop failure",
                "REMOTE_STOP_SUCCESS": "Remote stop success",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Unlock failure",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Unlock success",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Update firmware failure",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Update firmware success",
                "SESSION_AUTHORIZED": "Session authorized",
                "SESSION_DENIED": "Session denied",
                "SESSION_ENDED": "Session ended",
                "SESSION_ENERGY_UPDATED": "Energy updated",
                "SESSION_FORCED": "Session forced",
                "SESSION_STARTED": "Session started",
                "SESSION_START_BLOCKED": "Session start blocked",
                "SESSION_TIMED_OUT": "Session timed out",
                "SESSION_TOTAL_COST_UPDATED": "Price",
                "TOKEN_AUTHORIZE_ACCEPTED": "Token authorized",
                "TOKEN_AUTHORIZE_DENIED": "Token not authorized",
                "TOKEN_AUTHORIZE_UNKNOWN": "Token not recognized",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector status",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session ended",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Energy updated by unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session started"
            },
            "evseStatusUpdated": {
                "administrative": "Administrative status set from {previous_status} to {current_status}",
                "charging": "Charging status set from {previous_status} to {current_status}",
                "message": "EVSE {name} set from {previous_status} to {current_status}. Reason: {status_reason}",
                "operational": "Operational status set from {previous_status} to {current_status}"
            },
            "filterPlaceholder": "Search by context",
            "firmwareStatus": {
                "DownloadFailed": "Download firmware failure",
                "Downloaded": "Download firmware success",
                "InstallationFailed": "Install firmware failure",
                "Installed": "Install firmware success"
            },
            "lanDeviceStatus": {
                "down": "is no longer connected to the server",
                "up": "is now connected to the server"
            },
            "level": {
                "ALERT": "Alert log",
                "CRITICAL": "Critical log",
                "DEBUG": "Debug log",
                "EMERGENCY": "Emergency log",
                "ERROR": "Error log",
                "INFO": "Info log",
                "NOTICE": "Notice log",
                "WARNING": "Warning log"
            },
            "message": "Message",
            "messages": {
                "ALREADY_COMPLETED_SESSION_ENDED": "Session {sessionLink}, already ended, has been ended by charger {chargeboxid}",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Charger {chargeboxid} is asking price for session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Charger {chargeboxid} updated consumption of session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_STARTED": "Session {sessionLink} already ended has been authorized by charger {chargeboxid}",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "(Ercogener) Attempt to rearm device {chargeboxid}",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "(Ercogener) Attempt to start outlet",
                "ATTEMPTING_ERCOGENER_STOP_OUTLET": "(Ercogener) Attempt to stop outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Attempt to set station {chargeboxid} to state {availability}",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Attempt to clear cache of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Attempt to get diagonistcs of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_RESET": "Attempting to reset charger {chargeboxid}",
                "ATTEMPTING_REMOTE_START": "Attempting to start a session on charger {chargeboxid} and on connector {connector_id}",
                "ATTEMPTING_REMOTE_STOP": "Attempting to stop session {session} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Attempting to unlock connector {connector_id} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Attempting to update firmware of charger {chargeboxid} (filename: {filename})",
                "CONNECTOR_STATUS_UPDATED": "is set from {previous_status} to {current_status}",
                "DETECTION_SENSOR_BLOCKED": "An obstacle is detected on EVSE {evse}",
                "DETECTION_SENSOR_IGNORED": "Some information of charger {chargeboxid} about an obstacle was ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Obstacle on EVSE {evse} removed",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Charger {chargeboxid} detected an obstacle on unknown connector {connector_id}",
                "DIAGNOSTIC_STATUS": "Charger {chargeboxid} {status}",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "(Ercogener) Failed to rearm device {chargeboxid}. Reason: {reason}",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "(Ercogener) Device {chargeboxid} rearmed",
                "ERCOGENER_START_OUTLET_FAILURE": "(Ercogener) Failed to start outlet. Reason: reason",
                "ERCOGENER_START_OUTLET_SUCCESS": "(Ercogener) Outlet started",
                "ERCOGENER_STOP_OUTLET_FAILURE": "(Ercogener) Failed to stop outlet. Reason: reason",
                "ERCOGENER_STOP_OUTLET_SUCCESS": "(Ercogener) Outlet stopped",
                "FIRMWARE_STATUS": "Charger {chargeboxid} {status}",
                "LAN_DEVICE": "Charger {chargeboxid} {lan_device_status}",
                "LAN_DEVICE_BOOTED": "Charger {chargeboxid} booted",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Charger firmware {chargeboxid} is now {current_firmware} (beforehand {previous_firmware})",
                "LAN_DEVICE_MODEL_CHANGE": "Charger model {chargeboxid} is now {current_model} (beforehand {previous_model})",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Charger {chargeboxid} now uses {current_protocol} (beforehand {previous_protocol})",
                "LAN_DEVICE_VENDOR_CHANGE": "Charger vendor {chargeboxid} is now {current_vendor} (beforehand {previous_vendor})",
                "LAN_PUBLIC_IP_CHANGE": "LAN {lan} now has public IP {current_ip} (beforehand {previous_ip})",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId})",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId})",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Failed to set charger {chargeboxid} state to {availability}. Reason: {reason}",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Charger {chargeboxid} successfully set to state {availability}",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache of charger {chargeboxid}. Reason: {reason}",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache of charger {chargeboxid} successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Charger {chargeboxid} refused get diagnostics command. Reason: {reason}",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Charger {chargeboxid} accepted order, diagnostic will be generated soon",
                "REMOTE_RESET_FAILURE": "Failed to reset charger {chargeboxid}. Reason: {reason}",
                "REMOTE_RESET_SUCCESS": "Charger {chargeboxid} successfully reset",
                "REMOTE_START_FAILURE": "Failed to start a session on connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_START_SUCCESS": "Session start successfully allowed on connector {connector_id} on charger {chargeboxid}",
                "REMOTE_STOP_FAILURE": "Failed to stop session {session} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_STOP_SUCCESS": "Session {session} successfully stopped on charger {chargeboxid}",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Failed to unlock connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Connector {connector_id} successfully unlocked on charger {chargeboxid}",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Charger {chargeboxid} refused order to update firmware (filename: {filename}). Reason: {reason}",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Charger {chargeboxid} accepted order to update firmware (filename: {filename})",
                "SESSION_AUTHORIZED": "Session {session} authorized for contract {auth_id} (UID: {token_uid})",
                "SESSION_DENIED": "Session {sessionLink} with UID {token_uid} not authorized and will not be completed. Reason:",
                "SESSION_ENDED": "Session {session} is over. {invalid_msg} Consumption: {consumption}. Duration:",
                "SESSION_ENERGY_UPDATED": "Session {session} consumption {consumption} (total: {current_wh})",
                "SESSION_FORCED": "Session {sessionLink} with UID {token_uid} started without authorization",
                "SESSION_STARTED": "Session {session} has started. Contract: {auth_id}. UID: {token_uid}",
                "SESSION_START_BLOCKED": "Unknown session blocked by tag {idTag} on charger {chargeboxid}",
                "SESSION_TIMED_OUT": "Session {sessionLink} (authorized at {authorizeDate}) expired and will not be completed",
                "SESSION_TOTAL_COST_UPDATED": "Session {session} cost {current_total_cost} (beforehand {previous_total_cost})",
                "TOKEN_AUTHORIZE_ACCEPTED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-success\">authorized<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<strong>",
                "TOKEN_AUTHORIZE_DENIED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-danger\">did not authorize<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<\/strong> for the following reason: ({allowed}) <strong>{reason}<\/strong>",
                "TOKEN_AUTHORIZE_UNKNOWN": "<strong class=\"has-text-warning\">No provider<\/strong> recognized token <strong>{tokenUid}<\/strong>",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector {connector_id} set to {status}",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session stopped by charger {chargeboxid}",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Consumption updated by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session authorized by charger {chargeboxid}",
                "default": "Sorry, no interpretation possible",
                "moreDetails": "See details"
            },
            "noContextualData": "No contextual data available",
            "orderSentBy": "Order sent by {email}",
            "paymentTerminal": {
                "errorMessage": "Error message:",
                "noErrorMessage": "No error message",
                "noReceipt": "No receipt for this session",
                "number": "Payment terminal number: {number}",
                "receiptData": "Receipt data:"
            },
            "remoteFailedReason": {
                "NOT_SUPPORTED": "Order not supported by charger",
                "REJECTED": "Order rejected by charger",
                "TIMEOUT": "No response from charger",
                "default": "Unknown reason",
                "null": "Unknown reason"
            },
            "sessionStatus": {
                "invalid": "Session failed"
            },
            "statusReason": {
                "ADMINISTRATIVE_DECLARATION": "Administrative status updated by a supervisor",
                "ALL_CONNECTORS_FAULTED": "All connectors are faulted",
                "ALL_CONNECTORS_UNAVAILABLE": "All connectors are unavailable",
                "BLOCKED_EVSE": "Access to EVSE is blocked by an obstacle",
                "NO_CONNECTOR": "EVSE has no connector",
                "NO_LAN_DEVICE": "EVSE is attached to no charger",
                "OFFLINE_LAN_DEVICE": "Charger is offline",
                "ONGOING_RESERVATION": "A reservation is ongoing",
                "ONGOING_SESSION": "A session is ongoing",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Ordered by the electricity supplier",
                "default": "Everything is fine",
                "null": "Unknown reason"
            },
            "title": "Business logs {name}"
        },
        "cdrs": {
            "csv": {
                "confirmTitle": "CSV from {from} to {to}",
                "default": "Do you confirm export?",
                "tooHeavy": "The file is being generated and will be sent to {email}"
            },
            "list": {
                "authId": "Contract",
                "breadcrumb": "CDRs",
                "connector": "Connecteur",
                "cpo": "Network",
                "created": "Created at",
                "emsp": "Provider",
                "evse": "CP",
                "externalRef": "External reference",
                "lastSent": "Last sent",
                "location": "Location",
                "ref": "Reference",
                "scopeTitle": "CDRs of {name}",
                "startDateTime": "Start",
                "stopDateTime": "End",
                "title": "CDRs",
                "totalCost": "Total price",
                "totalEnergy": "kWh",
                "vat": "VAT",
                "withAdvenir": "With CDRs for Advenir",
                "withChildrenOperators": "Sessions from sub-networks"
            },
            "show": {
                "auth": "Authorization ID",
                "badge": "Tag ID",
                "breadcrumb": "CDR",
                "cdrOfSessionRef": "CDR of #{ref}",
                "cost": "Price",
                "energy": "Energy",
                "exportToPDF": "Export to PDF",
                "isWhiteListed": "is white-listed",
                "preTaxPrice": "Price excl. VAT",
                "ref": "Reference",
                "relations": "Relations",
                "remark": "Comments",
                "title": "Charge details record #{ref}",
                "totalCost": "Total price",
                "totalParkingTime": "Parking time",
                "totalTime": "Total time"
            }
        },
        "commons": {
            "From": "From",
            "Now": "Now",
            "To": "To",
            "active": "Active",
            "add": "New",
            "agreement": "Agreement",
            "all": "All",
            "asyncSearch": "Type to search",
            "authId": "Contract",
            "autoRefreshDisabled": "Auto refresh not available for now",
            "autoRefreshOff": "Auto refresh off",
            "autoRefreshOn": "Auto refresh on",
            "back": "Back",
            "by": "by {user}",
            "cancel": "Cancel",
            "clear": "Clear",
            "common": "Common",
            "confirm": "Confirm",
            "cpo": "Network",
            "created_at": "Created date",
            "creationPlaceholder": "Creation",
            "curve": "Curve",
            "date": "Date",
            "dateRange": "Filter by date",
            "delete": "Delete",
            "durationUnits": {
                "days": "day(s)",
                "hours": "h",
                "minutes": "m",
                "months": "month(s)",
                "seconds": "s"
            },
            "durationWithDays": "{nb_days} day(s) and {standard_duration}",
            "edit": "Edit",
            "edition": "{name}",
            "emi3": "Private eMI3 ID",
            "emsp": "Provider",
            "end": "End",
            "error403": "Not authorized",
            "error404": "Page not found",
            "evse": "CP",
            "exclVat": "excl. VAT",
            "export": "Export",
            "exportToCSV": "Export to CSV",
            "exportToPDF": "Export to PDF",
            "false": "False",
            "filterOn": "Filter",
            "from": "from",
            "fullPrice": "excl. VAT: {exclVat} \/ VAT: {vat} \/ {vatRate} %",
            "globalError": "An error occurred",
            "histogram": "Histogram",
            "hotline": "Hotline",
            "import": "Import",
            "inactive": "Inactive",
            "inclVat": "incl. VAT",
            "isActive": "Active",
            "kW": "kW",
            "kWh": "kWh",
            "last_update": "Updated",
            "location": "Location",
            "locationsList": "Locations",
            "month": "Month",
            "no": "No",
            "noResult": "No result",
            "noResultAsync": "No results, type to search",
            "noSmartphone": "Not available for smartphone",
            "notDefined": "Undefined",
            "ocpiModules": "OCPI modules",
            "of": "of {data}",
            "onlyCommaAllowed": "Only \",\" is allowed as a separator",
            "optional": "optional",
            "optionsSelected": "{number} option(s) selected",
            "placeholderSelect": "Select option",
            "preTaxCost": "{amount}  excl. VAT, {vat} VAT",
            "process": "Process",
            "quarter": "Quarter",
            "rangeHint": "Data from {from} to {to}",
            "reference": "Reference",
            "refunds": "Refunds",
            "schedule": "Schedule",
            "search": "Search",
            "session": "Session",
            "start": "Start",
            "status": "Status",
            "submit": "Confirm",
            "tagId": "Token",
            "tariff": "Tariff",
            "to": "to",
            "today": "Today",
            "true": "True",
            "type": "Type",
            "updated_at": "Updated",
            "vat": "VAT",
            "vatRate": "VAT rate",
            "year": "Year",
            "years": "Years",
            "yes": "Yes"
        },
        "connectors": {
            "actions": {
                "start": "Start charge",
                "stop": "Stop charge",
                "unlock": "Unlock"
            },
            "form": {
                "addConnectorTitle": "Add connector",
                "amps": "Amperage (A)",
                "cable": "Cable attached",
                "create": "Add connector",
                "customPower": "Custom power",
                "delete": "Delete",
                "edit": "Edit",
                "editConnectorTitle": "Edit connector",
                "invalid": "Connectors are invalid",
                "maxPower": "Max power: {maxPower} kW",
                "ocppNumber": "OCPP connector ID",
                "ocppNumberIsUnavailable": "is not available",
                "ocppNumberUnavailable": "This connector ID is not available",
                "power": "Power",
                "powerType": "Power type",
                "tariff": "Tariff",
                "type": "Type",
                "voltage": "Voltage (V)"
            },
            "list": {
                "errorCode": "Error code",
                "evseName": "CP name",
                "evseRef": "CP ref",
                "lastStatusChangeAvailable": "Solved since",
                "lastStatusChangeIssue": "Default since",
                "maintenanceAvailableTitle": "Connectors: issue solved",
                "maintenanceIssuesSubtitle": "List of connectors that have reported an error code for at least 1 hour",
                "maintenanceIssuesTitle": "Error codes",
                "standard": "Type \/ Power"
            },
            "powerTypes": {
                "AC_1_PHASE": "1 phase",
                "AC_3_PHASE": "3 phase",
                "DC": "Direct current"
            },
            "show": {
                "cable": "Cable attached",
                "cannotRemove": "Minimum one connector required",
                "confirmDeletion": "Please confirm deletion",
                "connectorCreated": "Connector added",
                "connectorEdited": "Connector edited",
                "connectorRemoved": "Connector removed",
                "defaultTariff": "Default tariff",
                "localisation": "Location",
                "locked": "Locked",
                "noRestrictions": "No restriction",
                "noTariff": "No tariff defined",
                "ocppNumber": "OCPP connector ID",
                "ocppNumberMismatch": "The connector does not match the logs",
                "power": "Power",
                "socketFalse": "Cable attached",
                "socketTrue": "No cable attached",
                "tariff": "Tariff",
                "tariffApply": "Tariff applied",
                "title": "Connector #",
                "type": "Type",
                "unlocked": "Unlocked"
            },
            "standard": {
                "chademo": "Chademo",
                "domestic_a": "Standard\/Domestic type A",
                "domestic_b": "Standard\/Domestic type B",
                "domestic_c": "Standard\/Domestic type C",
                "domestic_d": "Standard\/Domestic type D",
                "domestic_e": "Standard\/Domestic type E",
                "domestic_f": "Standard\/Domestic type F",
                "domestic_g": "Standard\/Domestic type G",
                "domestic_h": "Standard\/Domestic type H",
                "domestic_i": "Standard\/Domestic type I",
                "domestic_j": "Standard\/Domestic type J",
                "domestic_k": "Standard\/Domestic type K",
                "domestic_l": "Standard\/Domestic type L",
                "iec_60309_2_single_16": "IEC 60309-Industrial single phase 16",
                "iec_60309_2_three_16": "IEC 60309-Industrial three phase 16",
                "iec_60309_2_three_32": "IEC 60309-Industrial three phase 32",
                "iec_60309_2_three_64": "IEC 60309-Industrial three phase 64",
                "iec_62196_t1": "IEC 62196 Type 1",
                "iec_62196_t1_combo": "Combo Type 1",
                "iec_62196_t2": "IEC 62196 Type 2",
                "iec_62196_t2_combo": "Combo Type 2",
                "iec_62196_t3a": "IEC 62196 Type 3A",
                "iec_62196_t3c": "IEC 62196 Type 3C",
                "other": "Other connectors",
                "tesla_r": "Tesla Connector Roadster-type",
                "tesla_s": "Tesla Connector Model-S-type"
            },
            "status": {
                "available": "Available",
                "charging": "Charging",
                "faulted": "Faulted",
                "finishing": "Finishing",
                "issue": "Issue",
                "occupied": "Occupied",
                "preparing": "Preparing",
                "reserved": "Reserved",
                "suspendedev": "Suspended EV",
                "suspendedevse": "Suspended EVSE",
                "unavailable": "Unavailable"
            }
        },
        "contact-groups": {
            "actions": {
                "assignGroup": "Add an existing group",
                "delete": "Delete",
                "remove": "Remove",
                "replaceGroup": "Replace group"
            },
            "create": {
                "feedback": "Group successfully created",
                "title": "Create a contact group"
            },
            "edit": {
                "addExistingGroup": "Add an existing group",
                "edit": "Edit",
                "feedback": "Group successfully edited",
                "searchContactGroups": "Search contact groups"
            },
            "form": {
                "affiliations": "Affiliations",
                "assign": "Assign",
                "contacts": "Contacts",
                "description": "Description",
                "info": "Group information",
                "language": "Language",
                "location": "Location",
                "name": "Name of the group",
                "nameExists": "This group already exists",
                "network": "Network",
                "or": "or",
                "planning": "Planning",
                "searchContacts": "Search contacts",
                "searchContactsEmpty": "Add at least one contact to the group"
            },
            "list": {
                "confirmDelete": "Are you sure you want to delete this contact group?",
                "delete": "Delete",
                "duplicate": "Duplicate",
                "incompleteSchedule": "Warning: Incomplete schedule"
            },
            "planning": {
                "add": "Add",
                "addContact": "Add contact",
                "addContactTitle": "Add contact for {day}",
                "addSchedule": "Add a schedule",
                "begin": "Begin",
                "empty": "No contact defined",
                "end": "End",
                "incomplete": "Planning incomplete",
                "schedule": "Planning",
                "selectContact": "Select a contact"
            },
            "show": {
                "breadcrumb": "Contact group {name}",
                "contacts": "Contacts",
                "description": "Description",
                "groupInfo": "Group information",
                "inheritedFrom": "inherited from",
                "language": "Language",
                "name": "Group name",
                "planningContactInfo": "Each contact in this group will get an emergency alert via email. The contacts registered when the emergency occurs will be informed via the contact method(s) chosen : SMS and\/or phone call.",
                "scopeDeleteGroup": "Are you sure you want to delete group {group} ? This action will remove the group from all networks and stations to which it is affiliated.",
                "scopeRemoveGroup": "Are you sure you want to remove group {group} from the network?",
                "supportGroup": "Maintenance group"
            },
            "toast": {
                "deleted": "Contact group has been deleted"
            }
        },
        "contacts": {
            "affiliations": {
                "actions": "Actions",
                "affiliationsOf": "Assignments of",
                "deletePopupTextLocation": "Do you really want to delete this assignment? This will delete all assignments between the contact and the station.",
                "deletePopupTextNetwork": "Do you really want to delete this assignment?\n            This will also delete all assignments between the contact and the network's stations.\n        ",
                "deletePopupTitleLocation": "Assignment removal",
                "deletePopupTitleNetwork": "Assignment removal",
                "locations": "Stations",
                "network": "Network",
                "role": "Role",
                "title": "Contact assignments",
                "type": "Type"
            },
            "breadcrumb": {
                "affiliations": "Assignments",
                "contact": "Contacts",
                "edit": "Edit",
                "groups": "Contact Groups"
            },
            "contactMethods": {
                "email": "Email",
                "phone": "Call",
                "sms": "Text message"
            },
            "create": {
                "title": "Create contact"
            },
            "edit": {
                "close": "Close",
                "edit": "Edit",
                "editContactsTitle": "Edit contacts"
            },
            "error": {
                "contactEmailAlreadyTakenAdmin": "email is already taken",
                "contactEmailAlreadyTakenNotAdmin": "The contact already exists, to assign it to your network please contact exploitation@freshmile.com"
            },
            "form": {
                "addContactTitle": "Create contact",
                "alwaysOneOwnerContact": "The network must have at least one owner contact.",
                "buttonAddContact": "Create new contact",
                "buttonAddType": "Add a type",
                "buttonLink": "Link",
                "call": "Call",
                "company": "Company",
                "contact_methods": "Contact method(s)",
                "contactsOfNetwork": "Network contacts",
                "countrySelectorLabel": "Country code",
                "editContactTitle": "Edit contact",
                "email": "Email",
                "errorDeleteType": "The contact must have at least one type",
                "errorUpdate": "An error occurred",
                "linkContactExisting": "Link existing contact",
                "linkContactNotExisting": "Link a new contact",
                "name": "Contact name",
                "nameExists": "Name already in use",
                "notesInput": "Notes",
                "optional": "Optional",
                "phone_number": "Phone number",
                "placeholderUser": "Search by name, email address",
                "searchContact": "Search existing contact",
                "sms_number": "Text number",
                "textSearch": "Search existing contact, select a type, then click the link button.",
                "textSearchGroup": "Search an existing contact and click on the link button.",
                "titleContactsOfNetwork": "Contact(s)",
                "type": "Contact type",
                "userLink": "Linked user"
            },
            "groups": {
                "actions": "Actions",
                "addGroupContact": "Create a contact group",
                "affiliationOfGroup": "Direct affiliation",
                "confirmDelete": "Do you really want to remove the contact from this contact group?",
                "contact_methods": "Contact method(s)",
                "contacts": "Contacts",
                "groupOfContact": "Contact group",
                "groupOfContactPlaceholder": "Search a contact group",
                "modalTitle": "Add a contact group to the contact",
                "nameOfGroup": "Group name",
                "or": "OR",
                "title": "Contact groups",
                "updated": "Updated"
            },
            "list": {
                "actions": "Actions",
                "affiliated": "Direct affiliate",
                "allTypes": "All contact types",
                "breadcrumb": "Contacts",
                "confirmDelete": "Are you sure you want to delete this contact?",
                "contact_methods": "Contact methods",
                "contacts": "Contacts",
                "contactsGroups": "Contact groups",
                "email": "Email",
                "filterType": "Filter by type",
                "groupName": "Group name",
                "name": "Full name",
                "phone_number": "Phone number",
                "sms_number": "Text message",
                "title": "Contacts and Groups",
                "type": "Type",
                "types": "Types"
            },
            "show": {
                "accessContactPage": "Access the contact sheet",
                "company": "Company",
                "contactMethods": "Contact method(s)",
                "email": "Email",
                "groups": "Groups",
                "nameOfContact": "Contact name",
                "networkAndLocations": "Networks and stations",
                "notes": "Supervision notes",
                "otherContacts": "Other contacts",
                "phone_number": "Phone number",
                "sms_number": "Text message",
                "title": "Contact",
                "type": "Contact type:",
                "userLink": "Linked user"
            },
            "toast": {
                "deleted": "Contact deleted"
            },
            "types": {
                "ADMINISTRATIVE": "Administrative",
                "EMERGENCY": "Emergency",
                "INDIVIDUAL": "Individual",
                "INSTALLER": "Installer",
                "MAINTENANCE": "Maintenance",
                "OWNER": "Owner",
                "undefined": "Undefined"
            }
        },
        "countries": {
            "ABW": "Aruba",
            "AFG": "Afghanistan",
            "AGO": "Angola",
            "AIA": "Anguilla",
            "ALB": "Åland Islands",
            "AND": "Andorra",
            "ARE": "United Arab Emirates",
            "ARG": "Argentina",
            "ARM": "Armenia",
            "ASM": "American Samoa",
            "ATA": "Antarctica",
            "ATF": "French Southern Territories",
            "ATG": "Antigua and Barbuda",
            "AUS": "Australia",
            "AUT": "Austria",
            "AZE": "Azerbaijan",
            "BDI": "Burundi",
            "BEL": "Belgium",
            "BEN": "Benin",
            "BES": "Bonaire, Sint Eustatius and Saba",
            "BFA": "Burkina Faso",
            "BGD": "Bangladesh",
            "BGR": "Bulgaria",
            "BHR": "Bahrain",
            "BHS": "Bahamas",
            "BIH": "Bosnia and Herzegovina",
            "BLM": "Saint Barthélemy",
            "BLR": "Belarus",
            "BLZ": "Belize",
            "BMU": "Bermuda",
            "BOL": "Bolivia (Plurinational State of)",
            "BRA": "Brazil",
            "BRB": "Barbados",
            "BRN": "Brunei Darussalam",
            "BTN": "Bhutan",
            "BVT": "Bouvet Island",
            "BWA": "Botswana",
            "CAF": "Central African Republic",
            "CAN": "Canada",
            "CCK": "Cocos (Keeling) Islands",
            "CHE": "Switzerland",
            "CHL": "Chile",
            "CHN": "China",
            "CIV": "Côte d'Ivoire",
            "CMR": "Cameroon",
            "COD": "Congo (Democratic Republic of the)",
            "COG": "Congo",
            "COK": "Cook Islands",
            "COL": "Colombia",
            "COM": "Comoros",
            "CPV": "Cabo Verde",
            "CRI": "Costa Rica",
            "CUB": "Cuba",
            "CUW": "Curaçao",
            "CXR": "Christmas Island",
            "CYM": "Cayman Islands",
            "CYP": "Cyprus",
            "CZE": "Czechia",
            "DEU": "Germany",
            "DJI": "Djibouti",
            "DMA": "Dominica",
            "DNK": "Denmark",
            "DOM": "Dominican Republic",
            "DZA": "Algeria",
            "ECU": "Ecuador",
            "EGY": "Egypt",
            "ERI": "Eritrea",
            "ESH": "Western Sahara",
            "ESP": "Spain",
            "EST": "Estonia",
            "ETH": "Ethiopia",
            "FIN": "Finland",
            "FJI": "Fiji",
            "FLK": "Falkland Islands (Malvinas)",
            "FRA": "France",
            "FRO": "Faroe Islands",
            "FSM": "Micronesia (Federated States of)",
            "GAB": "Gabon",
            "GBR": "United Kingdom of Great Britain and Northern Ireland",
            "GEO": "Georgia",
            "GGY": "Guernsey",
            "GHA": "Ghana",
            "GIB": "Gibraltar",
            "GIN": "Guinea",
            "GLP": "Guadeloupe",
            "GMB": "Gambia",
            "GNB": "Guinea-Bissau",
            "GNQ": "Equatorial Guinea",
            "GRC": "Greece",
            "GRD": "Grenada",
            "GRL": "Greenland",
            "GTM": "Guatemala",
            "GUF": "French Guiana",
            "GUM": "Guam",
            "GUY": "Guyana",
            "HKG": "Hong Kong",
            "HMD": "Heard Island and McDonald Islands",
            "HND": "Honduras",
            "HRV": "Croatia",
            "HTI": "Haiti",
            "HUN": "Hungary",
            "IDN": "Indonesia",
            "IMN": "Isle of Man",
            "IND": "India",
            "IOT": "British Indian Ocean Territory",
            "IRL": "Ireland",
            "IRN": "Iran (Islamic Republic of)",
            "IRQ": "Iraq",
            "ISL": "Iceland",
            "ISR": "Israel",
            "ITA": "Italy",
            "JAM": "Jamaica",
            "JEY": "Jersey",
            "JOR": "Jordan",
            "JPN": "Japan",
            "KAZ": "Kazakhstan",
            "KEN": "Kenya",
            "KGZ": "Kyrgyzstan",
            "KHM": "Cambodia",
            "KIR": "Kiribati",
            "KNA": "Saint Kitts and Nevis",
            "KOR": "Korea (Republic of)",
            "KWT": "Kuwait",
            "LAO": "Lao People's Democratic Republic",
            "LBN": "Lebanon",
            "LBR": "Liberia",
            "LBY": "Libya",
            "LCA": "Saint Lucia",
            "LIE": "Liechtenstein",
            "LKA": "Sri Lanka",
            "LSO": "Lesotho",
            "LTU": "Lithuania",
            "LUX": "Luxembourg",
            "LVA": "Latvia",
            "MAC": "Macao",
            "MAF": "Saint Martin (French part)",
            "MAR": "Morocco",
            "MCO": "Monaco",
            "MDA": "Moldova (Republic of)",
            "MDG": "Madagascar",
            "MDV": "Maldives",
            "MEX": "Mexico",
            "MHL": "Marshall Islands",
            "MKD": "Macedonia (the former Yugoslav Republic of)",
            "MLI": "Mali",
            "MLT": "Malta",
            "MMR": "Myanmar",
            "MNE": "Montenegro",
            "MNG": "Mongolia",
            "MNP": "Northern Mariana Islands",
            "MOZ": "Mozambique",
            "MRT": "Mauritania",
            "MSR": "Montserrat",
            "MTQ": "Martinique",
            "MUS": "Mauritius",
            "MWI": "Malawi",
            "MYS": "Malaysia",
            "MYT": "Mayotte",
            "NAM": "Namibia",
            "NCL": "New Caledonia",
            "NER": "Niger",
            "NFK": "Norfolk Island",
            "NGA": "Nigeria",
            "NIC": "Nicaragua",
            "NIU": "Niue",
            "NLD": "Netherlands",
            "NOR": "Norway",
            "NPL": "Nepal",
            "NRU": "Nauru",
            "NZL": "New Zealand",
            "OMN": "Oman",
            "PAK": "Pakistan",
            "PAN": "Panama",
            "PCN": "Pitcairn",
            "PER": "Peru",
            "PHL": "Philippines",
            "PLW": "Palau",
            "PNG": "Papua New Guinea",
            "POL": "Poland",
            "PRI": "Puerto Rico",
            "PRK": "Korea (Democratic People's Republic of)",
            "PRT": "Portugal",
            "PRY": "Paraguay",
            "PSE": "Palestine, State of",
            "PYF": "French Polynesia",
            "QAT": "Qatar",
            "REU": "Réunion",
            "ROU": "Romania",
            "RUS": "Russian Federation",
            "RWA": "Rwanda",
            "SAU": "Saudi Arabia",
            "SDN": "Sudan",
            "SEN": "Senegal",
            "SGP": "Singapore",
            "SGS": "South Georgia and the South Sandwich Islands",
            "SHN": "Saint Helena, Ascension and Tristan da Cunha",
            "SJM": "Svalbard and Jan Mayen",
            "SLB": "Solomon Islands",
            "SLE": "Sierra Leone",
            "SLV": "El Salvador",
            "SMR": "San Marino",
            "SOM": "Somalia",
            "SPM": "Saint Pierre and Miquelon",
            "SRB": "Serbia",
            "SSD": "South Sudan",
            "STP": "Sao Tome and Principe",
            "SUR": "Suriname",
            "SVK": "Slovakia",
            "SVN": "Slovenia",
            "SWE": "Sweden",
            "SWZ": "Eswatini",
            "SXM": "Sint Maarten (Dutch part)",
            "SYC": "Seychelles",
            "SYR": "Syrian Arab Republic",
            "TCA": "Turks and Caicos Islands",
            "TCD": "Chad",
            "TGO": "Togo",
            "THA": "Thailand",
            "TJK": "Tajikistan",
            "TKL": "Tokelau",
            "TKM": "Turkmenistan",
            "TLS": "Timor-Leste",
            "TON": "Tonga",
            "TTO": "Trinidad and Tobago",
            "TUN": "Tunisia",
            "TUR": "Turkey",
            "TUV": "Tuvalu",
            "TWN": "Taiwan, Province of China",
            "TZA": "Tanzania, United Republic of",
            "UGA": "Uganda",
            "UKR": "Ukraine",
            "UMI": "United States Minor Outlying Islands",
            "URY": "Uruguay",
            "USA": "United States of America",
            "UZB": "Uzbekistan",
            "VAT": "Holy See",
            "VCT": "Saint Vincent and the Grenadines",
            "VEN": "Venezuela (Bolivarian Republic of)",
            "VGB": "Virgin Islands (British)",
            "VIR": "Virgin Islands (U.S.)",
            "VNM": "Viet Nam",
            "VUT": "Vanuatu",
            "WLF": "Wallis and Futuna",
            "WSM": "Samoa",
            "YEM": "Yemen",
            "ZAF": "South Africa",
            "ZMB": "Zambia",
            "ZWE": "Zimbabwe"
        },
        "dashboard": {
            "activity": {
                "consumptionTitle": "Energy (MWh)",
                "sessionsTitle": "Charging sessions (units)",
                "turnoverTitle": "Sales ({currency})"
            },
            "availableEvses": "Available charging points",
            "availableEvsesPercentage": "{1} {current} unavailable CP out of {total} (Availability: {percentage}%)| {current} unavailable CP out of {total} (Availability: {percentage}%)",
            "currentSessions": "Ongoing sessions",
            "currentSessionsPercentage": "{percentage}% of {total} CP currently charging",
            "exportTranslations": "Export translations",
            "importExportTranslations": "Import\/Export translations",
            "importTranslations": "Import translations",
            "loadMap": "Load network map",
            "networkStatus": "Real-time network status",
            "openTickets": "Open tickets",
            "title": "Dashboard"
        },
        "days": {
            "dayNamesCalendar": [
                "Su",
                "Mo",
                "Tu",
                "We",
                "Th",
                "Fr",
                "Sa"
            ],
            "friday": "Friday",
            "monday": "Monday",
            "monthNamesCalendar": [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            "saturday": "Saturday",
            "sunday": "Sunday",
            "thursday": "Thursday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday"
        },
        "devices": {
            "actions": {
                "breadcrumb": "Remote actions",
                "change": "Change",
                "changeAvailability": "Change availability",
                "changeConfig": "Change configuration",
                "changeConfigPlaceholder": "Configuration name",
                "clearCache": "Clear cache",
                "cnr": {
                    "rearm": "Rearm device",
                    "rearmFailed": "Failed to rearm device",
                    "rearmSuccess": "Device successfully rearmed",
                    "stop": "Stop",
                    "stopSuccess": "Standard plug successfully stopped",
                    "supply": "Energy delivery over standard plug (1h)",
                    "supplySince": "Ongoing energy delivery since: {date}",
                    "supplySuccess": "Standard plug successfully started for 1 hour",
                    "title": "CNR automaton"
                },
                "configReadonly": "(Not editable)",
                "configValue": "Configuration value",
                "configurationPlaceholder": "Select or type here",
                "customConfig": "Custom configuration",
                "get": "Get",
                "getConfiguration": "Get configuration",
                "getDiagnostics": "Get diagnostics",
                "getDiagnosticsManual": "Send diagnostic via URL",
                "ping": "Ping",
                "protocol": "Protocol: {protocol}",
                "reset": "Reset",
                "selectFile": "Select file",
                "title": "Remote actions for {chargeboxid}",
                "unknownConfig": "Unknown configuration",
                "updateFirmware": "Update firmware",
                "updateFirmwareManual": "Update firmware via URL",
                "upload": "Upload",
                "urlPlaceholder": "Enter URL",
                "value": "Value:"
            },
            "create": {
                "breadcrumb": "New charger",
                "cpoTitle": "New charger in {name}",
                "feedback": "Charger successfully added",
                "lanTitle": "New charger connected to {name}",
                "locationTitle": "New charger in {name}",
                "title": "New charger"
            },
            "edit": {
                "breadcrumb": "Edit",
                "feedback": "Charger successfully edited",
                "title": "Edit"
            },
            "form": {
                "addLan": "Add LAN",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID is available",
                "chargeboxidUnavailable": "Chargebox ID is not available",
                "definition": "Charger definition",
                "deviceProtocol": "Protocol",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "Edit LAN",
                "isDhcp": "Is DHCP",
                "lan": "LAN",
                "lanDescription": "LAN description",
                "lanName": "Name",
                "localIp": "Local IP address",
                "localIpIsInvalid": "Local IP address is invalid",
                "localPort": "Local port",
                "model": "Model",
                "modelIntro": "Use an existing model",
                "newLanName": "LAN #",
                "offlineEvses": "Not yet appaired EVSE",
                "publicIp": "Public IP address",
                "publicPort": "Public port",
                "selectLocation": "Location",
                "submit": "Add",
                "technicalInfos": "Technical information",
                "unknownModel": "Unknown model",
                "url": "URL"
            },
            "list": {
                "addDevice": "Add charger",
                "all": "All",
                "breadcrumb": "CBID",
                "chargeboxid": "Chargebox ID",
                "cpoTitle": "CBID of {name}",
                "firmware": "Firmware",
                "isOnline": "Online",
                "lan": "LAN",
                "lastRequestOffline": "Offline since",
                "lastRequestOnline": "Online since",
                "localIp": "Local IP address",
                "locationTitle": "CBID of {name}",
                "maintenanceOfflineSubtitle": "List of CBIDs having lost communication for at least 12 hours",
                "maintenanceOfflineTitle": "Communication losses",
                "maintenanceOnlineTitle": "CBID: issue solved",
                "model": "Model",
                "nbEvses": "EVSE",
                "offline": "Offline",
                "online": "Online",
                "protocol": "Protocol",
                "status": "Status",
                "title": "CBID",
                "updatedAt": "Updated",
                "vendor": "Vendor"
            },
            "ocpp": {
                "breadcrumb": "OCPP logs",
                "changeAvailabilityAvailable": "Charger is now available",
                "changeAvailabilityUnavailable": "Charger is now unavailable",
                "changeConfigSuccess": "New value successfully applied to {configuration}",
                "clearSuccess": "Cache successfully cleared",
                "commonSuccess": "Order sent",
                "diagnosticsOngoing": "Get diagnostics sent - waiting for charger",
                "diagnosticsReady": "Diagnostics ready",
                "failed": "Command failed",
                "getDiagnosticsFail": "Get diagnostics failed",
                "notSupported": "Command not supported",
                "pingError": "Ping failed",
                "pingSuccess": "Ping succeeded",
                "rejected": "Command rejected",
                "remoteFail": "Remote order failed",
                "resetSuccess": "Reset order sent - charger rebooting",
                "startSuccess": "Session successfully started",
                "stopSuccess": "Session successfully stopped",
                "timeout": "Command timed out",
                "unlockFailed": "Unlock failed",
                "unlockSuccess": "Unlock succeeded",
                "uploadFirmwareFailed": "Upload failed",
                "uploadFirmwareSuccess": "Upload succeeded"
            },
            "protocol": {
                "OCPP15J": "OCPP 1.5 J",
                "OCPP15S": "OCPP 1.5 S",
                "OCPP16J": "OCPP 1.6 J",
                "OCPP16S": "OCPP 1.6 S",
                "OCPP20J": "OCPP 2.0 J",
                "OCPP20S": "OCPP 2.0 S",
                "OTHER": "Other"
            },
            "show": {
                "DHCP": "DHCP",
                "NAT": "NAT",
                "deviceInfo": "Information about charger",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "firmware": "Firmware",
                "isOffline": "Offline",
                "isOnline": "Online",
                "lan": "LAN",
                "lastRequest": "Last request",
                "localIp": "Local IP address",
                "localPort": "Local port",
                "model": "Model",
                "network": "Network",
                "noOcppMessage": "No OCPP message sent",
                "noPairsConnected": "No peer",
                "ocppInfos": "OCPP",
                "pairs": "Peer",
                "protocol": "Protocol",
                "publicPort": "Public port",
                "url": "URL",
                "vendor": "Vendor"
            }
        },
        "emails": {
            "ExportReady": {
                "body-attached": "Please find attached the file you exported",
                "body-linked": "Please click the link below to download the file you exported",
                "download-link-text": "Download file",
                "subject": "Your exported file is ready"
            },
            "Issues": {
                "closed": {
                    "introduction": "The Fix ticket: #{id} {title} - {location} has been closed by {closer} in {closed_at}.",
                    "subject": "Closing a Fix maintenance ticket - Fix: #{id} - {locationName}"
                },
                "created": {
                    "introduction": "The following Fix ticket has just been created:",
                    "link": "Link of the ticket:",
                    "name": "Name of the ticket:",
                    "number": "Number of the ticket:",
                    "subject": "Opening a Fix maintenance ticket - Fix: #{id} - {locationName}",
                    "type": "Type of maintenance :"
                },
                "link": "Consult the ticket:",
                "messageCreated": {
                    "introduction": "{updater} just wrote a message in the Fix ticket: #{id} {title} - {location} the {created_at}.",
                    "subject": "A message has been added in a Fix maintenance ticket - Fix: #{id} - {locationName}"
                },
                "reminder": "Don't forget the importance of commenting on tickets, in order to follow their evolution and allow a better follow-up.",
                "reopened": {
                    "introduction": "The Fix ticket: #{id} {title} - {location} was reopened by {opener} on {updated_at}.",
                    "subject": "Reopening a Fix maintenance ticket - Fix: #{id} - {locationName}"
                },
                "updated": {
                    "introduction": "The Fix ticket: #{id} {title} - {location} was modified by {updater} on {updated_at}.",
                    "subject": "Modification of a maintenance ticket - Fix: #{id} - {locationName}"
                },
                "userQuoted": {
                    "introduction": "{dispatcher} just quoted you in the Fix ticket: #{id} {title} - {location} the {quoted_at}.",
                    "subject": "Quote in a maintenance ticket - Fix: #{id} - {locationName}"
                }
            },
            "ResetPassword": {
                "lines": {
                    "call_to_action": "Click on the button below to change your password",
                    "expiration": "The link will expire in {expirationTime} minutes",
                    "explanation": "You are receiving this message because we received a request to change your password",
                    "no_action_required": "If you did not initiate this request, please ignore this message"
                },
                "link": "Change password",
                "subject": "Change of password"
            },
            "SendRetrocessionEmployerPdf": {
                "cordially": "Best Regards",
                "explanation": "Please find enclosed the Retrocession Statement for business recharges made at your home charging station.",
                "greetings": "Hello,",
                "payment": "The transfer will be made in the next few days.",
                "subject": "Retrocession of professional recharges made at your home"
            },
            "SendRetrocessionPdf": {
                "cordially": "Best Regards",
                "explanation": "Please find attached the retrocession document for the latest quarter.",
                "greetings": "Hello,",
                "payment": "The transfer will be made in the next few days.",
                "subject": "Retrocession for the latest quarter - Freshmile"
            },
            "StationCheckApiTokenConfirmMail": {
                "explanation": "To verify your email address and access the terminal test interface, please click on the verification button below.",
                "fromName": "Freshmile Check",
                "link": "Email address verification",
                "linkComment": "If the button does not work, you can copy and paste the following link into your browser:",
                "subject": "Email address verification"
            },
            "UserFeedbackReportMail": {
                "subject": "Feedback from a user"
            },
            "common": {
                "apologize": "We apologize for any inconvenience",
                "contact": "For any question, you can contact us at the following address:",
                "greetings": "Hello {firstname} {lastname}",
                "login": "Log in",
                "thanks": "Thank you for using {appname}!"
            },
            "fromName": "Freshmile"
        },
        "evses": {
            "administrative_status": {
                "available": "In service",
                "inoperative": "Maintenance",
                "outoforder": "Set out of order",
                "planned": "Planned",
                "removed": "Removed",
                "unknown": "Non-communicating"
            },
            "charging_status": {
                "available": "Available",
                "charging": "Charging",
                "reserved": "Reserved",
                "unknown": "Unknown charging"
            },
            "create": {
                "breadcrumb": "Charge points",
                "cpoTitle": "New charge point with {name}",
                "evseList": "Charge points (list)",
                "feedback": "Charge point successfully added",
                "locationTitle": "New charge point to {name}",
                "title": "New charge point"
            },
            "edit": {
                "BOEditRef": "To change the reference of an evse after it has been created, please use the action \"Changer la ref d'un point de charge\" in the backoffice.",
                "feedback": "Charge point successfully updated",
                "title": "Edit"
            },
            "export-data-gouv": "data.gouv",
            "form": {
                "addConnector": "Add connector",
                "addDevice": "Add charger",
                "addLan": "Add LAN",
                "administrative": "Administrative status",
                "applyCoordinates": "Apply GPS coordinates",
                "asyncSearch": "Search by EVSE (reference, name)",
                "confirmFreeTariffSubmit": "This Evse is available for recharging. Confirm free?",
                "connectors": "Connectors",
                "customRefAvailable": "Reference is available",
                "customRefUnavailable": "Reference is not available",
                "device": "Chargebox ID",
                "directions": "Directions",
                "duplicate": "Create an n+1 duplicate of this charge point",
                "emi3": "eMI3 ID",
                "evseInfos": "Charging point information",
                "floorLevel": "Floor level",
                "hideMap": "Hide map",
                "lan": "LAN in this location",
                "lanDeviceCreationError": "Error",
                "lanDeviceCreationSuccess": "Charger successfully added",
                "latitude": "Latitude",
                "location": "Location",
                "locationInfos": "Location information",
                "locationPlaceholder": "Location",
                "locations": "Locations",
                "longitude": "Longitude",
                "name": "Custom name",
                "newDeviceTitle": "New charger in {name}",
                "optional": "(optional)",
                "parkingType": "Parking type",
                "physicalRef": "Physical reference",
                "ref": "REF",
                "showMap": "Show map",
                "submit": "Save"
            },
            "list": {
                "addEvse": "Add charge point",
                "all": "All",
                "breadcrumb": "Charge points",
                "byCpo": "Filter by network ID",
                "commissionedAt": "Commissioning",
                "connectors": "Connectors",
                "cpoTitle": "Charge points of {name}",
                "decommissionedAt": "Decommissioning",
                "device": "Charger",
                "emi3": "eMI3 ID",
                "free": "Free",
                "location": "Location",
                "locationTitle": "Charge points of {name}",
                "name": "Name",
                "paying": "Pay-for",
                "reference": "Reference",
                "status": "Status",
                "tariffs": "Tariffs",
                "title": "Charge points",
                "updatedAt": "Last update"
            },
            "operational_status": {
                "available": "Working",
                "blocked": "Blocked",
                "inoperative": "Inoperative",
                "outoforder": "Out of order",
                "unknown": "Unknown"
            },
            "show": {
                "administrative": "Administrative",
                "administrativeDetails": "Administrative status",
                "chargeStarted": "Charge started",
                "chargeStopped": "Charge stopped",
                "charging": "Charging",
                "chargingDetails": "Charging status",
                "connectors": "Connectors",
                "device": "Chargebox ID",
                "deviceIsConnected": "Connected",
                "directions": "Directions",
                "emi3": "eMI3 ID",
                "floorLevel": "Floor level",
                "misc": "Miscellaneous",
                "name": "Name",
                "noDescription": "No description",
                "noDeviceConnected": "No charger connected, EVSE unreachable",
                "onGoingSessionStarted": "Session started",
                "operational": "Operational",
                "operationalDetails": "Operational status",
                "pairs": "Peers",
                "parkingRestrictions": "Parking restrictions",
                "physicalReference": "Physical reference",
                "ref": "Reference",
                "stopCharge": "Stop charge"
            },
            "status": {
                "alive": "Alive",
                "available": "Available",
                "blocked": "Blocked",
                "charging": "Charging",
                "inoperative": "Inoperative",
                "obstructed": "Obstructed",
                "outoforder": "Out of order",
                "planned": "Planned",
                "removed": "Removed",
                "reserved": "Reserved",
                "test": "Test",
                "unavailable": "Unavailable",
                "unknown": "Non-communicating",
                "unobstructed": "Unobstructed"
            }
        },
        "fix": {
            "bot": {
                "automatic_action_close": {
                    "LanDevice_reconnected": "All charging point CBIDs have been reconnected. The fix ticket was closed automatically.",
                    "charge_completed": "A valid charge has been made on one of the charging points. The fix ticket was closed automatically.",
                    "connector_available": "No error is reported on the charging point connectors. The fix ticket was closed automatically.",
                    "inactive_emergency_issue": "Without any news from the user or the emergency contact, Freshmile considers the Emergency Fix ticket as resolved. It has been closed automatically."
                },
                "automatic_action_close_default": "The fix ticket was closed automatically.",
                "automatic_action_create_default": "The fix ticket was opened automatically.",
                "automatic_action_update_default": "The ticket fix has been changed automatically.",
                "chargeCompleted": "Charge completed",
                "chargeCompletedTitle": "\"COMPLETED + > 2min + > 500Wh\" on at least one Evse linked to a ticket has taken place",
                "connectorAvailable": "Connecteur operationnal",
                "connectorAvailableTitle": "When all Evse connectors linked to a ticket return a StatusNotificationRequest: NoError",
                "disabled": "Disabled",
                "lanDeviceConnected": "CBID connected",
                "lanDeviceConnectedTitle": "When all CBIDs of Evse linked to a ticket are (re)connected"
            },
            "create": {
                "addTask": "Add",
                "allOptions": "All options",
                "assignTo": "Assign ticket to",
                "autoManagement": "Automatic closing",
                "breadcrumb": "Create ticket",
                "clickUpload": "Click to choose a file",
                "dropUpload": "Drag files or click. Max size: {max} Kb",
                "fewerOptions": "Fewer options",
                "issueTitle": "Ticket title",
                "linkTo": "Link ticket to",
                "optionnalEntity": "Leave blank to assign ticket to network {operator}",
                "specificEvse": "Specify EVSEs (or the whole location by default)",
                "subscribeTo": "Subscribe users",
                "title": "Fix - New ticket",
                "titleScope": "Create ticket for {name}"
            },
            "dashboard": {
                "automaticActionsApplied": "Automatically closed",
                "createdAt": "Created {date} by {email}",
                "edit": "Edit",
                "entityFilter": "Search",
                "exportAll": "Export all",
                "filterSelection": "{number} entities selected",
                "new": "New ticket",
                "show": "Show",
                "showMoreBtn": "Show more",
                "statusFilter": "Filter by status",
                "title": "Fix - Dashboard",
                "updatedAt": "Updated {date} by {email}",
                "withMsg": "Includes messages and descriptions"
            },
            "duplicate": {
                "duplicate": "Duplicate",
                "issueTitle": "Ticket title",
                "linkMessages": "Duplicate messages",
                "linkModels": "Duplicate entities",
                "linkTags": "Duplicate tags",
                "linkTasks": "Duplicate tasks",
                "success": "Ticket duplicated",
                "title": "Duplicate a ticket"
            },
            "emergency-issue-title": {
                "LOCKED_CABLE": "Emergency: Locked cable ({evseRef})",
                "SECURITY": "Emergency: Security issue ({evseRef})"
            },
            "entity": {
                "connectors": "Connectors",
                "devices": "CBID",
                "evses": "EVSE",
                "lans": "LAN",
                "locations": "Location",
                "networks": "Network",
                "status": "Status",
                "supervisors": "Assigned",
                "tags": "Tags"
            },
            "history": {
                "title": "Fix - History"
            },
            "list": {
                "action": "Actions",
                "assigned": "Assigned",
                "author": "Author",
                "automaticActionsApplied": "Automatic closing",
                "begin": "Start date",
                "breadcrumb0": "Corrective maintenance",
                "breadcrumb1": "Preventive maintenance",
                "breadcrumb3": "Information",
                "closeSelection": "Close selection",
                "closedAt": "Closed on",
                "closedBy": "Closed by",
                "completedAt": "Completed on",
                "confirmClose": "Close ticket",
                "correctiveTitle": "Corrective maintenance tickets",
                "correctiveTitleScope": "Corrective maintenance tickets of {name}",
                "created": "Created on",
                "description": "Description",
                "end": "Due date",
                "entities": "Related entity",
                "historyTitleScope": "Ticket history of {name}",
                "incidentDate": "Incident on",
                "informationTitle": "Information",
                "informationTitleScope": "Information of {name}",
                "interventionAt": "Intervention on",
                "name": "Name",
                "preventiveTitle": "Preventive maintenance tickets",
                "preventiveTitleScope": "Preventive maintenance tickets of {name}",
                "startedAt": "Started on",
                "status": "Status",
                "tags": "Tags",
                "tasks": "Tasks",
                "title": "Title",
                "type": "Maintenance type",
                "updateSelection": "Update selection",
                "updatedAt": "Updated on",
                "updatedBy": "Updated by"
            },
            "scope": {
                "createIssue": "Create a ticket",
                "ongoingIssues": "Open tickets : {count}"
            },
            "show": {
                "about": "Freshmile Fix, ticket #{id}",
                "activity": "Activity",
                "add": "Add",
                "addedBy": "Added by {email}",
                "attachments": "Attachments",
                "close": "Close ticket",
                "closedAt": "Closed on {date}",
                "closedAtBy": "Closed on {date} by {email}",
                "comments": "Comments",
                "completeClose": "You can complete missing dates before closing the ticket",
                "completedDate": "Resolution date",
                "confirmDelete": "Would you like to clear the value?",
                "confirmDeleteFile": "Would you like to delete the file?",
                "confirmDeleteTask": "Would you like to delete the task?",
                "deleteMessageAnswers": "Delete answers?",
                "deleteMessageTitle": "Would you like to delete the message?",
                "description": "Description",
                "download": "Download",
                "interventionDate": "Intervention date",
                "lastUpdate": "Updated on: {date}",
                "lastUpdateBy": "Updated on: {date} by {email}",
                "mailBody": "Hello,%0D%0A%0D%0AWe invite you to consult the ticket \"{name}\" : {url}%0D%0A%0D%0AThanks,",
                "messageComment": "Comment",
                "messageEdit": "Edit",
                "messageReply": "Reply",
                "messages": "Messages",
                "noMessage": "No messages yet",
                "noTask": "No tasks yet",
                "noUpload": "No files yet",
                "original": "Original ticket:",
                "reopen": "Reopen ticket",
                "replyTo": "Reply to {email}",
                "resolvedBy": "Resolved by {email} on {date}",
                "subscribe": "Subscribe",
                "subscribed": "Subscribers",
                "taskDescription": "Task description",
                "title": "Fix #{id}",
                "todoList": "To do list",
                "unsubscribe": "Unsubscribe",
                "update": "Update",
                "urlPrompt": "Enter a URL:"
            },
            "stats": {
                "emergency": {
                    "average_between_opening_intervention_emergencies": "Average emergency response time",
                    "emergency-by-evse-table": {
                        "title": "Ranking of stations with the highest number of emergency breakdowns"
                    },
                    "title": "Fix emergency maintenance statistics",
                    "total_ticket_emergencies_fix": "Total number of Fix emergency tickets created"
                },
                "maintenance": {
                    "issue-count-table": {
                        "title": "Ranking of the stations with the highest number of breakdowns"
                    },
                    "issue-type-table": {
                        "title": "Ranking of network failure types"
                    },
                    "title": "Fix maintenance statistics",
                    "total_tickets_fix_created_by_network": "Total number of Fix tickets created",
                    "total_tickets_fix_resolve_by_network": "Total number of Fix tickets solved"
                },
                "network": {
                    "average_between_opening_intervention": "Average response time",
                    "average_failures_occurrence_resolution": "Average time to resolution",
                    "evse-unavailability-table": {
                        "title": "Ranking of evses with the highest unavailability rate in hours"
                    },
                    "title": "General network maintenance statistics"
                },
                "noIssue": "No Issue",
                "pdf": {
                    "fileName": "Fix – Stats",
                    "issue_type": "Types of failure",
                    "rate": "Rate (number)"
                },
                "title": "Fix – Stats"
            },
            "status": {
                "ABANDONED": "Abandoned",
                "AWAITING": "Waiting",
                "AWAITING_REPORT": "Awaiting Report",
                "BEING_PROCESSED": "Being processed",
                "COMPLETED": "Completed",
                "NEW": "New",
                "ONGOING": "Ongoing",
                "PLANNED": "Planned",
                "dashboard": {
                    "CORRECTIVE": {
                        "AWAITING": "Waiting",
                        "BEING_PROCESSED": "Being Processed",
                        "NEW": "New"
                    },
                    "PREVENTIVE": {
                        "AWAITING": "Scheduled",
                        "AWAITING_REPORT": "Awaiting report",
                        "ONGOING": "Supported",
                        "PLANNED": "To plan"
                    }
                }
            },
            "supervisor": {
                "closed": "Closed",
                "open": "Open",
                "title": "My tickets"
            },
            "tags": {
                "addTitle": "Add a tag",
                "color": "Colour",
                "confirmDelete": "Would you like to remove the tag?",
                "editTitle": "Edit tag",
                "title": "Tag management"
            },
            "title": "Fix",
            "toast": {
                "issue": {
                    "clipboard": "Link to ticket copied",
                    "updated": "Ticket updated"
                },
                "message": {
                    "delete": "Message deleted",
                    "updated": "Message updated"
                },
                "quote": {
                    "failed": "Failed to quote user"
                },
                "subscription": {
                    "add": {
                        "failed": "Failed to subscribe user to this issue",
                        "success": "User successfully subscribed"
                    },
                    "remove": {
                        "failed": "Failed to unsubscribe user from this issue",
                        "success": "User successfully unsubscribed"
                    }
                },
                "tag": {
                    "added": "Tag successfully created",
                    "deleted": "Tag deleted",
                    "updated": "Tag updated"
                },
                "task": {
                    "added": "Task added",
                    "deleted": "Task deleted",
                    "updated": "Task updated"
                },
                "upload": {
                    "added": "File(s) added",
                    "deleted": "File(s) deleted",
                    "ignored": "The following files were ignored because they are too heavy ({max} kB): {files}"
                }
            },
            "type": {
                "corrective": "Corrective maintenance",
                "information": "Information",
                "preventive": "Preventive maintenance",
                "soonLate": "Close delays and deadlines"
            }
        },
        "header": {
            "alerts": "Manage alerts",
            "cdr": "CDRs",
            "contact": "Contacts",
            "dashboard": "Dashboard",
            "device": "CBID",
            "evse": "CP",
            "fix": {
                "dashboard": "Fix - Dashboard",
                "history": "Fix - History",
                "stats": "Fix - Stats",
                "tags": "Tags",
                "title": "Fix"
            },
            "generateQRcodes": "Generate QR codes",
            "generateRetro": "Generator of retrocession .pdf",
            "location": "Locations",
            "locationsList": "Lijst met locaties",
            "locations_mass_import": "Location mass import",
            "logout": "Logout",
            "network": "Networks",
            "others": "Others",
            "reports": "Activations",
            "reservation": "Reservations",
            "retrocessionGroups": "Retrocession groups",
            "retrocessionLogs": "Retrocession logs",
            "retrocessions": "Retrocessions",
            "role": "Role:",
            "session": "Sessions",
            "settings": "Settings",
            "stats": "Stats",
            "statsInSubmenu": "General",
            "tariff": "Tariffs",
            "ticket": "Tickets",
            "translations": "Translations"
        },
        "languages": {
            "de": "German",
            "en": "English",
            "es": "Spanish",
            "fr": "French",
            "gb": "English",
            "gn": "Deactivate translation",
            "nl": "Dutch",
            "undefined": "Undefined"
        },
        "lans": {
            "create": {
                "breadcrumb": "New LAN",
                "feedback": "LAN successfully added",
                "title": "New LAN in {name}"
            },
            "edit": {
                "breadcrumb": "Edit",
                "feedback": "LAN successfully updated",
                "title": "Edit"
            },
            "form": {
                "addLan": "Add LAN",
                "availableEvses": "Available CP",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID is available",
                "chargeboxidUnavailable": "Chargebox ID is not available",
                "description": "Description",
                "deviceDefinition": "Optional=> Charger definition",
                "deviceProtocol": "Protocol",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "Edit LAN",
                "gateway": "Gateway",
                "gatewayIsInvalid": "Invalid gateway",
                "isDhcp": "Is DHCP",
                "lan": "LAN",
                "lanCreation": "New LAN",
                "lanCreationError": "Error",
                "lanCreationSuccess": "LAN successfully added",
                "lanDescription": "LAN description",
                "lanDeviceCreation": "New LAN",
                "lanName": "Name",
                "localIp": "Local IP address",
                "localPort": "Local port",
                "model": "Model",
                "modelPlaceholder": "Model",
                "name": "Name",
                "newLanName": "LAN #",
                "offlineEvses": "Offline CP",
                "optional": "(optional)",
                "proxy": "Proxy",
                "publicIp": "Public IP address",
                "publicIpIsInvalid": "Invalid public IP address",
                "publicPort": "Public port",
                "selectLocation": "Location",
                "submit": "Add new charger",
                "subnetMask": "Subnet mask",
                "subnetMaskIsInvalid": "Invalid subnet mask",
                "technicalInfos": "Technical information",
                "unknownModel": "Unknown model",
                "url": "URL",
                "wirelessLogic": "Wireless Logic"
            },
            "list": {
                "breadcrumb": "LAN",
                "create": "Add",
                "gateway": "Gateway",
                "lanDevices": "Chargers",
                "name": "Name",
                "proxy": "Proxy",
                "publicIp": "Public IP address",
                "subnetMask": "Subnet mask",
                "title": "LAN of {name}",
                "updatedAt": "Last update"
            },
            "proxies": {
                "MAIN": "Main",
                "NONE": "None",
                "WIRELESSLOGIC": "Wireless Logic"
            },
            "show": {
                "addDevice": "Add CBID",
                "connectedDevices": "Connected CBID",
                "description": "Description",
                "deviceIsConnected": "Connected",
                "edit": "Edit",
                "gateway": "Gateway",
                "lastUpdate": "Last update",
                "name": "Name",
                "nat": "NAT",
                "protocol": "Protocol",
                "proxy": "Proxy",
                "publicIp": "Public IP address",
                "subnetMask": "Subnet mask",
                "url": "URL"
            }
        },
        "locations": {
            "create": {
                "breadcrumb": "New location",
                "cpoTitle": "New location in {name}",
                "feedback": "Location successfully added",
                "locationsList": "Locations",
                "title": "New location"
            },
            "edit": {
                "edit": "Edit",
                "feedback": "Location successfully updated"
            },
            "form": {
                "addOpeningHourFor": "Add an opening hour for {day}",
                "address": "Address",
                "asyncSearch": "Search by name, address, city, post code",
                "chargingWhenClosed": "Charging when closed",
                "city": "City",
                "country": "Country",
                "countryPlaceholder": "Country",
                "cpoPlaceholder": "Network",
                "directions": "Access information",
                "displayMap": "Display map",
                "emi3": "eMI3 ID",
                "facilities": "Facilities",
                "facilitiesPlaceholder": "Facilities",
                "hideMap": "Hide map",
                "informations": "Information",
                "invalidHourMessage": "Invalid",
                "latitude": "Latitude",
                "latitudePlaceholder": "Latitude",
                "localisation": "Location",
                "longitude": "Longitude",
                "longitudePlaceholder": "Longitude",
                "name": "Name",
                "network": "Network",
                "openingHoursInfos": "Opening hours",
                "optional": "(optional)",
                "postalcode": "Post code",
                "resetPosition": "Reset position",
                "submit": "Save",
                "time_zone": "Timezone",
                "twentyfourseven": "Open 24\/7",
                "type": "Type",
                "typePlaceholder": "Type"
            },
            "list": {
                "addLocation": "Add location",
                "breadcrumb": "Locations",
                "chargePoints": "Charge points",
                "city": "City",
                "country": "Country",
                "cpoId": "Network ID",
                "cpoTitle": "Locations of {name}",
                "emi3": "eMI3 ID",
                "name": "Name",
                "timeZone": "Timezone",
                "title": "Locations",
                "type": "Type",
                "updatedAt": "Updated"
            },
            "show": {
                "address": "Address",
                "chargingWhenClosed": "Charging when closed",
                "city": "City",
                "country": "Country",
                "cpo": "Network",
                "directions": "Access information",
                "edit": "Edit",
                "emi3": "eMI3 ID",
                "facilities": "Services",
                "lastUpdate": "Updated",
                "localisation": "Location",
                "location": "Location",
                "locationInfos": "Location information",
                "locations": "Locations",
                "no": "No",
                "noRegularHours": "No opening hours configured",
                "openingHoursInfos": "Opening hours",
                "twentyfourseven": "Open 24\/7",
                "type": "Type",
                "yes": "Yes"
            }
        },
        "login": {
            "email": "E-mail",
            "intro": "Track your network",
            "loginSubmit": "Login",
            "password": "Password",
            "passwordReset": "Reset password"
        },
        "maintenance-alert": {
            "commons": {
                "list": {
                    "last-valid-session-date": "Last valid session date"
                }
            },
            "downtime": {
                "list": {
                    "subtitle": "List of CBIDs that have lost communication for at least 12 hours",
                    "title": "Communication losses"
                }
            },
            "error-code": {
                "list": {
                    "subtitle": "List of connectors that have reported an error code for at least 1 hour",
                    "title": "Error codes"
                }
            },
            "no-energy-rate": {
                "list": {
                    "no-energy-count": "Count without consumption",
                    "no-energy-rate": "% without consumption",
                    "subtitle": "List of CBIDs with at least 20% of valid sessions reporting 0 kWh of energy in the last 30 days.",
                    "title": "Energy absences",
                    "with-energy-count": "Count with consumption"
                }
            },
            "session-failure-rate": {
                "list": {
                    "failure-rate": "% invalid (<30 days)",
                    "invalid-count": "Invalid count",
                    "subtitle": "List of CBIDs with at least 60% of invalid sessions in the last 30 days.",
                    "title": "Charging ratios",
                    "valid-count": "Valid count"
                }
            },
            "usage-rate": {
                "list": {
                    "session-count-last30d": "Session count (<30 days)",
                    "session-count-prior-month": "Session count (30;60 days)",
                    "subtitle": "List of CBIDs that have recorded a decrease of at least 50% in usage compared to the last 30 days.",
                    "title": "Usage drops",
                    "usage-rate": "% decrease"
                }
            }
        },
        "networks": {
            "list": {
                "breadcrumb": "Networks"
            }
        },
        "notifications": {
            "DOWNTIME": "Communication Losses",
            "ERROR_CODE": "Error Codes",
            "NO_ENERGY_RATE": "Energy Absences",
            "SESSION_FAILURE_RATE": "Load Ratios",
            "USAGE_RATE": "Utilization Drops",
            "confirmSelection": "Confirm selection",
            "selectAll": "Select all",
            "tabs": {
                "handled": "Processed",
                "unhandled": "Pending"
            },
            "text": "Maintenance"
        },
        "ocpi": {
            "locationFacilities": {
                "AIRPORT": "Airport",
                "BUS_STOP": "Bus stop",
                "CAFE": "Cafe",
                "CARPOOL_PARKING": "Carpool",
                "FUEL_STATION": "Gas station",
                "HOTEL": "Hotel",
                "MALL": "Mall",
                "MUSEUM": "Museum",
                "NATURE": "Nature",
                "RECREATION_AREA": "Recreation area",
                "RESTAURANT": "Restaurant",
                "SPORT": "Sport",
                "SUPERMARKET": "Supermarket",
                "TAXI_STAND": "Taxi stand",
                "TRAIN_STATION": "Train station",
                "WIFI": "Wifi"
            },
            "locationTypes": {
                "ON_STREET": "On-street parking",
                "OTHER": "Other",
                "PARKING_GARAGE": "Multistorey car park",
                "PARKING_LOT": "Parking lot",
                "UNDERGROUND_GARAGE": "Underground car park",
                "UNKNOWN": "Unknown"
            },
            "logs": {
                "CLIENT": "Client",
                "REQUEST": "Request",
                "RESPONSE": "Response",
                "SERVER": "Server",
                "breadcrumb": "OCPI",
                "context": "Context",
                "date": "Date",
                "distant_operator": "Operator",
                "filterPlaceholder": "Search by message",
                "message": "Message",
                "method": "Method",
                "model": "Object",
                "module": "Module",
                "ocpi": "OCPI logs",
                "request_type": "Type",
                "title": "OCPI logs of {name}",
                "way": "Way"
            },
            "parkingRestrictions": {
                "CUSTOMERS": "Reserved for guests",
                "DISABLED": "Reserved for disabled people",
                "EV_ONLY": "Reserved for EV",
                "MOTORCYCLES": "Reserved for motorcycles or scooters",
                "PLUGGED": "Reserved for EV while charging"
            },
            "priceComponentTypes": {
                "DURATION": "Duration",
                "ENERGY": "Energy",
                "FLAT": "Flat",
                "PARKING_TIME": "Parking time",
                "STOP": "Stop",
                "TIME": "Time"
            },
            "reservationStatus": {
                "ACTIVE": "Active",
                "CANCELLED": "Cancelled",
                "ERROR": "Error",
                "FINISHED": "Finished",
                "NOSHOW": "No Show"
            }
        },
        "ocpp": {
            "logs": {
                "REQUEST": "Request",
                "RESPONSE": "Response",
                "breadcrumb": "OCPP logs",
                "filterPlaceholder": "Search by message",
                "message": "Message",
                "protocol": "Protocol",
                "requestType": "Request type",
                "sentBy": "Issuer",
                "title": "OCPP logs of {name}"
            }
        },
        "operators": {
            "capabilities": {
                "CHARGING_PROFILE_CAPABLE": "Charge profile",
                "CREDIT_CARD_PAYABLE": "Point of sale",
                "REMOTE_START_STOP_CAPABLE": "Charge with smartphone",
                "RESERVABLE": "Reservation",
                "RESET_CAPABLE": "Reset",
                "RFID_READER": "RFID",
                "UNLOCK_CAPABLE": "Remote unlock",
                "info": {
                    "CHARGING_PROFILE_CAPABLE": "Coming soon",
                    "CREDIT_CARD_PAYABLE": "Users can pay with a credit \/ debit card on the spot",
                    "REMOTE_START_STOP_CAPABLE": "Users can start and stop remotely from smartphone",
                    "RESERVABLE": "Users can book a charge point to charge",
                    "RESET_CAPABLE": "Hotline can reset charger from a distance",
                    "RFID_READER": "Users can start and stop with an RFID card",
                    "UNLOCK_CAPABLE": "Hotline can unlock cables from a distance"
                }
            },
            "configuration": {
                "breadcrumb": "Configurations",
                "capability_set_by_parent": "Can't be changed because set by parent {emi3}",
                "manyEvsesWarning": "All charge points will be updated… please wait",
                "needSubmit": "Please save to confirm changes",
                "technics": "Technical configuration",
                "title": "Configuration of {name}"
            },
            "create": {
                "feedback": "Network successfully added",
                "subCPOTitle": "New sub-network to {name}",
                "title": "New network"
            },
            "edit": {
                "breadcrumb": "Edit",
                "feedback": "Network successfully updated",
                "title": "Edit"
            },
            "form": {
                "allowIssueClose": "Allow automatic closing of a ticket",
                "allowIssueCloseDesc": "The option will be offered when the ticket is created. If changed, it will not impact existing tickets.",
                "businessInfos": "Business information",
                "businessName": "Name",
                "businessURL": "Website",
                "comInterval": "Communication interval",
                "commission": "Commission and bank fees",
                "consoInterval": "Meter values interval",
                "crc": "CRC",
                "defaultTariff": "Default tariff",
                "defaultTariffPlaceholder": "Default tariff",
                "defaultVAT": "Default VAT",
                "detectionLoop": "Detection loop",
                "emi3IsUndefined": "Undefined eMI3 ID",
                "emi3SuccessMessage": "is available",
                "emi3UnavailableMessage": "This eMI3 ID is not available. Please try another one",
                "generateEmi3": "Generate",
                "heartbeat_interval": "Heartbeat interval",
                "inheritance": "Extend to sub-networks",
                "initialState": "Initial state",
                "interopConfig": "Access configuration",
                "invalidEmi3Message": "is not a valid eMI3 ID. Please try again",
                "maintenance": "Maintenance",
                "metervalues_sample_interval": "Meter values interval",
                "name": "Name",
                "optional": "(optional)",
                "parent": "Parent",
                "parentPlaceholder": "Select a parent",
                "presence": "Detection device",
                "privateEmi3": "Private eMI3 ID",
                "publicEmi3": "Public eMI3 ID",
                "publicEmi3Placeholder": "Select an eMI3 ID",
                "refPlaceholder": "Can be filled with the name",
                "refUnavailableMessage": "This reference is not available. Please try another one",
                "retrocession": "Retrocession",
                "retrocessionGroup": "Retrocession group",
                "sameAsParent": "Same as parent",
                "sameAsPrivate": "This operator",
                "segment": "Segment",
                "submit": "Save",
                "support": "Support",
                "timezone": "Time zone",
                "timezonePlaceholder": "Time zone"
            },
            "hotline": {
                "phoneNumber": "Phone number",
                "schedule": "Schedule",
                "schedules": {
                    "Mon-Fri 7\/22": "Monday to Friday, 7am to 10pm",
                    "Mon-Fri 9\/17": "Monday to Friday, 9am to 5pm",
                    "Mon-Fri H24": "Monday to Friday, 24h",
                    "Mon-Sun 7\/22": "Everyday, 7am to 10pm",
                    "Mon-Sun 9\/17": "Everyday, 9am to 5pm",
                    "Mon-Sun H24": "Everyday, 24\/7"
                }
            },
            "list": {
                "VAT": "VAT",
                "breadcrumb": "Networks",
                "children": "Sub-networks",
                "create": "Add a network",
                "default_tariff": "Tariff",
                "emi3": "eMI3 ID",
                "evses": "Charge points",
                "exportWithchildrens": "Include children",
                "linkedCPOTitle": "Linked networks of {name}",
                "locations": "Locations",
                "name": "Name",
                "network": "Network",
                "ongoingIssues": "Open tickets",
                "operator": "Network",
                "operatorsList": "Networks",
                "parent": "Parent",
                "parent_id": "Parent ID",
                "parent_ref": "Parent ID",
                "public_emi3": "Public eMI3 ID",
                "segment": "Segment",
                "subCPOTitle": "Sub-networks of {name}",
                "title": "Networks",
                "updated_at": "Updated"
            },
            "map": {
                "breadcrumb": "Map",
                "noLocation": "There is no location in this network",
                "options": {
                    "all": "All",
                    "charging": "Ongoing charge",
                    "issue": "Out of order"
                },
                "title": "Map of {name}"
            },
            "segments": {
                "COMPANIES": "Companies",
                "RESIDENTIAL": "Residential",
                "ROADS": "Roads",
                "SHOPS": "Shops",
                "UNINFORMED": "Not specified"
            },
            "show": {
                "VAT": "VAT",
                "business": "Business",
                "businessName": "Name",
                "businessURL": "Website",
                "crc": "Hotline",
                "maintenance": "Maintenance",
                "parent": "Parent",
                "privateEmi3": "Private eMI3 ID",
                "publicEmi3": "Public eMI3 ID",
                "segment": "Segment",
                "support": "Support",
                "tariff": "Tariff",
                "timezone": "Time zone",
                "undefined": "Not defined"
            }
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "password": "At least 6 letters or digits. Both passwords must match",
            "reset": "Password reset",
            "sent": "Email sent",
            "token": "Error",
            "user": "Wrong credentials"
        },
        "payment": {
            "iban": "Direct debit",
            "methods": {
                "expires:": "Expires on:",
                "invalid": "Invalid",
                "valid": "Valid"
            },
            "refunds": {
                "addFormTitle": "Refund",
                "alreadyProcessed": "Refund already processed",
                "amountExceedRefund": "Amount exceed the refund price",
                "amountIncVat": "Amount (incl. VAT)",
                "confirmDelete": "Do you really want to delete this refund ?",
                "confirmDeleteTitle": "Delete refund",
                "created": "Refund created",
                "deleted": "Refund deleted",
                "editFormTitle": "Edit a refund",
                "edited": "Refund edited",
                "export": "Export refund",
                "highAmountWarn": "Refund over 100 {currency}",
                "inRetrocession": "Integrated in retrocession",
                "includeRetro": "To be included in retrocessions",
                "method": "Method",
                "moveConnection": "You must be logged in to Move to process refunds.",
                "processedAt": "Processed at",
                "reason": "Justification",
                "refund": "Refund",
                "refundIssue": "There might be an error with the stripe or prepaid account refund",
                "refunded": "Refunded",
                "session": "Refund for session from {date} on {evse_ref}",
                "stripeAutoRefund": "Automatic refund via Stripe is not possible, please refund the user manually.",
                "subscription_with_order": "Refund for subscription to plan \"{plan_name}\" (order from {order_date}).",
                "subscription_without_order": "Refund for subscription to plan \"{plan_name}\".",
                "token_with_order": "Token refund n°{token_number} (order from {order_date}).",
                "token_without_order": "Token refund n°{token_number}.",
                "way": {
                    "manual": "Manual",
                    "prepaid": "Prepaid",
                    "prepaid_account": "Prepaid",
                    "stripe": "Stripe"
                }
            },
            "status": {
                "AUTHORIZED": "Authorized",
                "CANCELLED": "Cancelled",
                "CAPTURED": "Captured",
                "DONE": "Done",
                "FAILED": "Failed",
                "PENDING_USER": "Pending",
                "REFUNDED": "Refunded"
            },
            "transaction_labels": {
                "credit": "Credit {CURRENCY}{amount} on prepaid account",
                "credit_invoice_refund": "Refund for invoice {invoice_ref}",
                "debit": "Debit {CURRENCY}{amount} from prepaid account",
                "invoice": "Invoice {ref}",
                "order": "Order {ref}",
                "payment": "Invoice {invoice_ref} (Payment {payment_ref})",
                "session": "Charge {ref}",
                "subscription": "Subscription {ref} (until {end_at})"
            }
        },
        "reservations": {
            "add": "Add",
            "authId": "Contract",
            "authIdPlaceholder": "Should match a Move eMAID",
            "breadcrumb": "Reservations",
            "calendar": "Calendar",
            "cancel": "Cancel",
            "cancelationIsSuccessful": "Reservation cancelled",
            "create": {
                "authId": "Contract",
                "end": "End",
                "locationId": "Location",
                "start": "Start",
                "submit": "Save",
                "title": "New reservation"
            },
            "creationIsSuccessful": "Reservation added",
            "day": "Day",
            "list": {
                "breadcrumb": "Reservation"
            },
            "location": "Location",
            "locationTitle": "Reservations at {name}",
            "month": "Month",
            "noReservationsThatDay": "No reservation this day",
            "noReservationsThatMonth": "No reservation this month",
            "noReservationsThatWeek": "No reservation this week",
            "reservation": "Reservation",
            "status": {
                "ACTIVE": "Active",
                "CANCELLED": "Cancelled",
                "ERROR": "Error",
                "FINISHED": "Finished",
                "NOSHOW": "No show"
            },
            "time": "Time",
            "title": "Reservations",
            "week": "Week"
        },
        "retrocession-group-logs": {
            "list": {
                "breadcrumb": "Retrocession group logs",
                "created_at": "Date",
                "message": "Message",
                "retrocessionGroupName": "Retrocession group name",
                "scopeTitle": "Retrocession group logs from {name}",
                "title": "Retrocession group logs",
                "type": "Event",
                "user": "Supervisor"
            },
            "types": {
                "ChangeCommission": "Change in commission",
                "ChangeName": "Change of name",
                "ChangeNetwork": "Change the network",
                "ChangeRib": "Change of bank details"
            }
        },
        "retrocession-groups": {
            "create": {
                "breadcrumb": "Créer un groupe de retrocessions",
                "title": "Créer un groupe de retrocessions"
            },
            "edit": {
                "title": "Modifier un groupe de retrocessions"
            },
            "form": {
                "addExisting": "Add an existing retrocession group",
                "applyPastSessions": "Apply changes to past sessions",
                "applyPastSessionsMessage": "Be careful, this is a heavy operation that can take a few seconds or even a few minutes.",
                "assign": "Assign an existing retrocession group",
                "asyncSearch": "Select an existing group",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact (optional)",
                "create": "Create a new retrocession group",
                "general": "General",
                "group": "Retrocession group",
                "name": "Name"
            },
            "iban": {
                "bankAccountName": "Account Name",
                "bic": "BIC",
                "errorBic": "The BIC must be in a valid format of the SEPA zone",
                "errorIban": "The IBAN must be in a valid format of the SEPA zone",
                "iban": "IBAN",
                "modalDeleteConfirm": "The bank account details have been successfully deleted",
                "modalDeleteContent": "If you delete bank account details of the retrocession group, we will not be able to pay you your retrocession.",
                "modalDeleteError": "An error occurred while deleting the bank account details",
                "modalDeleteTitle": "Delete a bank account detail",
                "modalDeleteWarning": "Be careful, you are about to delete bank account details.",
                "modalUpdateTitle": "Edit the RIB of the retrocession group"
            },
            "list": {
                "breadcrumb": "Retrocession groups",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "created_at": "Creation date",
                "has_bank_info": "RIB completed",
                "name": "Retrocession group name",
                "nb_networks": "Number of networks",
                "title": "Retrocession groups"
            },
            "network": {
                "add": "Add",
                "continue": "Continue",
                "create": "Create a new group",
                "removeMessage": "You are about to remove the retrocession group from {name}. Removing the handover group from {name} also removes it from all of its subnets.",
                "removeTitle": "Remove the retrocession group",
                "replace": "Replace retrocession group"
            },
            "show": {
                "bankInfo": "Banking information",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "contactDetail": "Contact",
                "created_at": "Creation date",
                "general": "General informations",
                "name": "Retrocession group name",
                "rib": "RIB"
            },
            "toast": {
                "added": "The retrocession group has been successfully assigned",
                "removed": "The retrocession group has been successfully removed",
                "session-updated": "The sessions has been successfully updated"
            }
        },
        "retrocessions": {
            "exportRefundsOfRetrocession": "Export of retrocession refunds",
            "modal": {
                "amountExceedInitialAmount": "The retrocession amount cannot exceed the initial price",
                "amountWithVat": "Amount Incl. VAT",
                "createSuccess": "The retrocession has been successfully created",
                "deleteSuccess": "The retrocession has been successfully deleted",
                "editSuccess": "The retrocession has been successfully updated",
                "reason": "Justification",
                "retrocede": "Retrocede",
                "sessionRetrocession": "Session Retrocession"
            },
            "refundOfRetrocession": "Refund of Retrocession"
        },
        "sessions": {
            "authorizationReasons": {
                "AUTHORIZED_BY_CPO": "CPO",
                "AUTHORIZED_BY_EMSP": "EMSP",
                "NOT_AUTHORIZED_BY_ANY_EMSP": "No EMSP",
                "NO_AUTHORIZATION_CHECK": "No check",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Electricity supplier",
                "PAYMENT_TERMINAL": "POS",
                "UNKNOWN": "Unknown",
                "description": {
                    "AUTHORIZED_BY_CPO": "Authorized by CPO",
                    "AUTHORIZED_BY_EMSP": "Authorized by EMSP",
                    "NOT_AUTHORIZED_BY_ANY_EMSP": "Not authorized by any EMSP",
                    "NO_AUTHORIZATION_CHECK": "Authorization not requested by charger",
                    "ORDERED_BY_ELECTRICITY_SUPPLIER": "Order received from the electricity supplier",
                    "PAYMENT_TERMINAL": "Point of sale (payment terminal on site)"
                }
            },
            "create": {
                "agreementsList": "Agreements",
                "breadcrumb": "Add",
                "title": "Add agreement"
            },
            "csv": {
                "confirmTitle": "CSV from {from} to {to}",
                "default": "Do you confirm export?",
                "retrocessionExporterTitle": "Export for retrocessions",
                "tooHeavy": "The file is being generated and will be sent to {email}"
            },
            "curve": {
                "breadcrumb": "Curves",
                "title": "Curves for #{ref}"
            },
            "edit": {
                "agreementsList": "Agreements",
                "breadcrumb": "Edit",
                "title": "Edit agreement"
            },
            "failureReasons": {
                "AUTHORIZATION_TIMEOUT": "Time out",
                "EMI3_MINIMUM_DURATION": "< 2 min",
                "INACTIVE": "Inactive",
                "LOCATION_FULLY_BOOKED": "Reserved location",
                "NO_AUTHORIZATION": "Not authorized",
                "UNKNOWN": "Unknown",
                "description": {
                    "AUTHORIZATION_TIMEOUT": "Authorization timed out",
                    "EMI3_MINIMUM_DURATION": "Application of eMI3 recommended threshold",
                    "NO_AUTHORIZATION": "Not authorized by any EMSP"
                }
            },
            "form": {
                "bridges": "Via",
                "cpo": "Network",
                "emsp": "Provider",
                "emspPlaceholder": "Search a provider",
                "includeSubCpos": "Include sub-networks",
                "includeSubEmsps": "Include sub-providers",
                "isActive": "Active",
                "ocpiModules": "OCPI modules",
                "searchPlaceholder": "Type here",
                "submit": "Save"
            },
            "list": {
                "active": "Active",
                "addAgreement": "Add agreement",
                "all": "All",
                "authorizationReason": "Authorization reason",
                "breadcrumb": "Sessions",
                "completed": "Completed",
                "connector": "Connector",
                "contrat": "Contract",
                "cpoTitle": "Sessions of {name}",
                "end": "End",
                "evse": "Charge point",
                "evseTitle": "Sessions of {name}",
                "failureReason": "Failure reason",
                "free": "Free",
                "invalid": "Invalid",
                "kwh": "kWh",
                "locationTitle": "Sessions of {name}",
                "no": "No",
                "ocpiModules": "OCPI modules",
                "ongoing": "Ongoing",
                "optionsSelected": "Options selected",
                "pending": "Pending",
                "price": "Price",
                "source": "Origin",
                "start": "Start",
                "station": "Location",
                "status": "Status",
                "stopReceivedAt": "Billing",
                "tag": "Tag",
                "tariff": "Tariff",
                "tariffPos": "Tariff POS",
                "title": "Sessions",
                "withChildrenOperators": "Sessions from sub-networks",
                "yes": "Yes"
            },
            "show": {
                "agreements": "Agreements",
                "auth": "Authorisation",
                "badge": "Card",
                "bridges": "Bridges",
                "cpo": "Network",
                "curveBreadcrumb": "Curve",
                "curveTitle": "Curve for",
                "edit": "Edit",
                "emsp": "Provider",
                "energy": "Energy",
                "energyLabel": "Energy (kWh)",
                "false": "False",
                "finalConsumption": "Final energy",
                "fullPrice": "{amount} {currency}",
                "includeSubCpos": "Include sub-networks",
                "includeSubEmsps": "Include sub-providers",
                "isActive": "Active",
                "isWhiteListed": "White-listed",
                "lastPeriod": "Last period",
                "lastUpdate": "Updated=>",
                "noBridges": "Direct",
                "notEnoughChargingData": "Not enough charging data to build a graph",
                "ongoing": "Ongoing",
                "periodDate": "Date",
                "power": "Power",
                "powerLabel": "Power (kW)",
                "preTaxPrice": "{amount} excl. taxes, {vat}% VAT",
                "price": "Price",
                "relations": "Relations",
                "statusIsInvalid": "Session failed. No charge",
                "statusIsPending": "Session has not started yet",
                "stopReceivedAt": "Billing date",
                "stopSession": "Stop session",
                "stopSuccess": "Session successfully stopped",
                "title": "Charging session #{ref}",
                "true": "True"
            },
            "source": {
                "CPO_REMOTE_ORDER": "CPO",
                "EMSP_REMOTE_ORDER": "EMSP",
                "STATION": "Charger"
            },
            "status": {
                "active": "Ongoing",
                "completed": "Finished",
                "invalid": "Invalid",
                "pending": "Pending",
                "valid": "Valid"
            }
        },
        "settings": {
            "accessibleOperators": "Accessible operators (networks you have access to )",
            "changeLang": "Change language",
            "currentPermission": "Your current role:",
            "darkTheme": "Dark theme",
            "display": "Display",
            "fix": {
                "disabled": {
                    "detail": "You won't receive any notification.",
                    "title": "Disabled"
                },
                "fromDisabled": {
                    "toMonitoring": "You will be subscribed to all the tickets of your network",
                    "toParticipating": "You will be subscribed to all tickets to which you are assigned, and unsubscribed from all others",
                    "toQuoting": "You will be unsubscribed from all tickets, you will receive notifications when you are mentioned in an issue"
                },
                "fromMonitoring": {
                    "toDisabled": "You will be unsubscribed from all tickets",
                    "toParticipating": "You will be subscribed to all tickets to which you are assigned, and unsubscribed from all others",
                    "toQuoting": "You will be unsubscribed from all tickets, you will receive notifications when you are mentioned in an issue"
                },
                "fromParticipating": {
                    "toDisabled": "You will be unsubscribed from all tickets",
                    "toMonitoring": "You will be subscribed to all the tickets of your network",
                    "toQuoting": "You will be unsubscribed from all tickets, you will receive notifications when you are mentioned in an issue"
                },
                "fromQuoting": {
                    "toDisabled": "You will be unsubscribed from all tickets",
                    "toMonitoring": "You will be subscribed to all the tickets of your network",
                    "toParticipating": "You will be subscribed to all tickets to which you are assigned, and unsubscribed from all others"
                },
                "isSubscribable": "I do not wish to be subscribed to tickets by other supervisors",
                "monitoring": {
                    "detail": "By default, you will be subscribed to all tickets in your network. So you will receive notifications for any activity.",
                    "title": "Monitoring"
                },
                "participating": {
                    "detail": "You will be subscribed to all tickets to which you are assigned, mentioned or subscribed.",
                    "title": "Participating"
                },
                "quoting": {
                    "detail": "You will only receive notifications when you are mentioned in a ticket or comment. You will not be subscribed to these tickets.",
                    "title": "In case of a mention"
                },
                "title": "Mail notification settings for Fix"
            },
            "fixedHeight": "Fixed height (Scroll in the table)",
            "hasAtLeastOneEmspInfo": "* In Park you cannot select a provider",
            "others": "Others",
            "preview": "Preview",
            "reduceMargin": "Reduce margins",
            "success": "Preferences saved",
            "switchPermission": "Switch role",
            "tables": "Tables",
            "title": "Settings",
            "truncateHeader": "Reduce column names"
        },
        "sidebar": {
            "items": {
                "actions": "Actions",
                "activation": "Rapport d'activation",
                "affiliations": "Affiliations",
                "agreements": "Agreements",
                "audit": "Activity",
                "business": "Business logs",
                "cdrs": "CDRs",
                "configurations": "Configurations",
                "corrective": "Corrective maintenance",
                "costDetails": "Cost details",
                "curve": "Curves",
                "details": "Details",
                "devices": "Chargebox ID",
                "duplicate": "Duplicate",
                "edit": "Edit",
                "evses": "Charge points",
                "fix": "Fix",
                "groups": "Groups",
                "history": "History",
                "information": "Information",
                "lans": "LAN",
                "linked_networks": "Linked networks",
                "locations": "Locations",
                "logs": "Logs",
                "map": "Map",
                "ocpi": "OCPI logs",
                "ocpp": "OCPP logs",
                "preventive": "Preventive maintenance",
                "reservations": "Reservations",
                "sessions": "Sessions",
                "subcpos": "Sub-networks",
                "tariffs": "Tariffs"
            }
        },
        "station-check": {
            "breadcrumb": {
                "logs": "Logs",
                "report": "Commissioning"
            },
            "csv": {
                "confirmTitle": "Confirm your export"
            },
            "list": {
                "device_ok": "PdC Info correct",
                "location_ok": "Station Info correct",
                "nb_cbids": "Number of CBID",
                "nb_fail": "Fail",
                "nb_success": "Tested & successful",
                "nb_tested": "Tested",
                "title": "Commissioning of Terminals"
            },
            "logs": {
                "emptyEmailReplace": "Installer",
                "keys": {
                    "lan_device_commissioned": "CBID commissioned",
                    "lan_device_feedback_comment": "CBID Info Comment added",
                    "lan_device_feedback_comment_withdrawn": "CBID Info Comment withdrawn",
                    "lan_device_feedback_ok": "CBID Info correct",
                    "lan_device_feedback_resolved": "CBID feedback resolved",
                    "lan_device_feedbacks_all_done": "All CBID info processed",
                    "lan_device_test_is_online_ko": "Connection Test KO",
                    "lan_device_test_is_online_ok": "Connection Test OK",
                    "lan_device_test_remote_orders_ko": "Remote Commands Test KO",
                    "lan_device_test_remote_orders_ok": "Remote Commands Test OK",
                    "lan_device_validated": "CBID validated",
                    "report_archived_automatically": "Report archived automatically",
                    "report_archived_manually": "Report archived manually",
                    "report_location_feedback_ok": "Station Info correct",
                    "report_location_feedback_updated": "Station Info updated",
                    "report_status_incomplete": "Report Status incomplete",
                    "report_status_to_ko": "Report Status KO",
                    "report_status_to_ok": "Report Status OK"
                },
                "tableContact": "Related Contact",
                "tableMessage": "Message",
                "tableType": "Type",
                "title": "Activation logs of",
                "type": {
                    "LanDeviceFeedback": "Feedback",
                    "LanDeviceTest": "Tests",
                    "Location": "Station",
                    "Supervision": "Supervision"
                }
            },
            "show": {
                "address": "Address",
                "allPaid": "All paid",
                "archiveReport": "Archive this report",
                "archived": "Archived",
                "buttonValidateWithCommissioning": "Validate selection with commissioning",
                "buttonValidateWithoutCommissioning": "Validate selection without commissioning",
                "cbidUntested": "Untested",
                "comment": "Report",
                "commentHistory": "Global comments history",
                "commissioned": "In service",
                "company": "Company",
                "dateGeneration": "Status generation date",
                "dateUpdate": "Update date",
                "email": "Email address",
                "firstname": "First name",
                "fitter": "Fitter",
                "fitterSay": "Installer says",
                "informationsStation": "Station Information",
                "installerHistory": "Installer history",
                "is_online": "Connection test",
                "listCbidTested": "List of CBID OK",
                "listUntestedAndFailedCbid": "List of CBID NOK",
                "modalArchivedConfirmation": "The report has been successfully archived.",
                "modalArchivedConfirmationFailed": "The report could not be archived.",
                "modalArchivedContent": "You are about to archive this report.",
                "modalArchivedQuestion": "Do you want to continue?",
                "modalArchivedTitle": "Archive a Report",
                "modalCommentConfirmation": "The comment has been successfully marked as resolved.",
                "modalCommentConfirmationFailed": "Comment resolution failed.",
                "modalCommentContent": "You are about to mark this comment as resolved. It will disappear as a result.",
                "modalCommentQuestion": "Do you want to continue?",
                "modalCommentTitle": "Mark as resolved",
                "modalFailedCommissioningContent": "It seems the connection to the following terminals has been lost since the last test.",
                "modalFailedCommissioningTitle": "Terminal Commissioning Failed",
                "name": "Name",
                "noCbidToDisplay": "No CBID to display here",
                "noComments": "No comments",
                "numberOfCbid": "Number of CBID",
                "ocpp-numbers-missmatch": "Warning, the connector IDs do not seem to match, please check the logs.",
                "oneIsFree": "At least one is free",
                "phone": "Phone",
                "pointOfCharge": "Charge Points",
                "pricing": "Pricing",
                "remote_orders_ok": "Remote Orders Test",
                "report": "Report",
                "selectAllCbid": "Select all CBID",
                "showOcppLogs": "Show logs",
                "status": "Status",
                "statusOf": "Status of",
                "terminalComments": "Comments",
                "testerMostRecent": "Information of the most recent installer",
                "testsStatus": "Test status",
                "timestamp": "Timestamp",
                "toValidated": "To be validated",
                "validated": "validated",
                "waitingCommissioning": "Waiting for commissioning"
            },
            "status": {
                "archived": "Archived",
                "incomplete": "Incomplete",
                "nok": "NOK",
                "ok": "Ready"
            }
        },
        "stats": {
            "CO2AvoidedEmissions": "CO2",
            "CarbonMonoxideAvoidedEmissions": "CO",
            "DistanceAvoidedThermalVehicle": "Distance avoided in conventional vehicles",
            "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons and NOx",
            "IncomeByNetwork": "Sales by network (incl. VAT)",
            "IncomeByProvider": "Sales by provider (incl. VAT)",
            "IncomePerLocation": "Sales by location (incl. VAT)",
            "MTBF": "MTBF",
            "MTTR": "MTTR",
            "NoxAvoidedEmissions": "NOx",
            "ParticleAvoidedEmissions": "Particles",
            "SavedFuel": "Avoided fuel consumption",
            "SessionsByConnectorType": "Sessions by connector",
            "SessionsByConsumption": "Sessions by consumption",
            "SessionsByDay": "Sessions by day",
            "SessionsByDuration": "Sessions by duration",
            "SessionsByHours": "Sessions by hours",
            "SessionsByMonth": "Sessions by month",
            "SessionsByNetwork": "Sessions by network",
            "SessionsByPower": "Sessions by power",
            "SessionsByProvider": "Sessions by provider",
            "SessionsPerLocation": "Sessions by location",
            "availability": "Availability",
            "availabilityDuration": "Availability duration",
            "average": "Average",
            "avoidedEmissions": "Avoided emissions",
            "descriptions": {
                "AberrationRate": "Aberration rate",
                "AverageChargingDuration": "While the vehicle draws energy from the station",
                "AverageConnectionDuration": "While the vehicle is parked and connected to the station, including when it has stopped charging",
                "AverageConsumptionBySession": "Average consumption by session",
                "CO2AvoidedEmissions": "CO2 emissions avoided thanks to the use of EVs charging on the stations",
                "CarbonMonoxideAvoidedEmissions": "CO emissions avoided thanks to the use of EVs charging on the stations",
                "CleanSessionNumber": "Number of qualified sessions",
                "DistanceAvoidedThermalVehicle": "Estimate of distance covered by EVs charging on the stations",
                "EffectiveChargingOverConnectionRate": "Ratio actual charging time \/ total connection time",
                "ExcludedSession": "Excluded sessions",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons and NOx avoided emissions",
                "MTBF": "Mean time between failures",
                "MTTR": "Mean time to repair",
                "NoxAvoidedEmissions": "NOx emissions avoided thanks to the use of EVs charging on the stations",
                "ParticleAvoidedEmissions": "Particles emissions avoided thanks to the use of EVs charging on the stations",
                "SavedFuel": "Figures are based on the current mix of conventional vehicles in the European Union",
                "SessionNumber": "Number of sessions",
                "SuccessConsumption": "Consumption of qualified sessions",
                "TotalChargingDuration": "While the vehicle draws energy from the station",
                "TotalConnectionDuration": "While the vehicle is parked and connected to the station, including when it has stopped charging",
                "TotalConsumption": "Consumption of all sessions",
                "availabilityDuration": "Availability duration",
                "availability_device_count": "Stations",
                "availability_evse_count": "Charge points",
                "averageIncome": "Average cost of pay-for sessions. Sessions free of charge are excluded",
                "average_charge_time": "While the vehicle draws energy from the station",
                "average_plugin_time": "While the vehicle is parked and connected to the station, including when it has stopped charging",
                "device_offline_duration": "Stations - offline duration",
                "device_offline_duration_rate": "Stations - offline ratio",
                "evse_available_duration_rate": "Charge points - availability ratio",
                "evse_failure_count": "Issues",
                "evse_unavailable_duration": "Charge points - unavailability duration",
                "evse_unavailable_duration_rate": "Charge points - unavailability ratio",
                "failed_session_count": "Number of sessions considered as \"failed\" (< 2 min or < 500 Wh)",
                "failed_session_rate": "Ratio \"failed\" sessions \/ total number of sessions",
                "numberOfIssues": "Number of issues",
                "offlineDuration": "Offline duration",
                "sessionIssueRatio": "Ratio of sessions with issue",
                "successful_session_consumption": "Total consumption of qualified sessions",
                "successful_session_count": "Qualified sessions are charging sessions over 2 minutes",
                "successful_session_rate": "Ratio qualified sessions \/ total number of sessions",
                "totalIncome": "Total sales",
                "total_charge_time": "While the vehicle draws energy from the station",
                "total_plugin_time": "While the vehicle is parked and connected to the station, including when it has stopped charging",
                "total_session_consumption": "Total consumption",
                "total_session_count": "Statistics are based on the number of qualified sessions only",
                "unavailabilityDuration": "Unavailability duration"
            },
            "g": "g",
            "ie": "i.e.",
            "kg": "kg",
            "km": "km",
            "l": "litres",
            "labels": {
                "LessThan10": "< 10",
                "LessThan120": "< 120",
                "LessThan1440": "< 1440",
                "LessThan15": "< 15",
                "LessThan20": "< 20",
                "LessThan240": "< 240",
                "LessThan30": "< 30",
                "LessThan40": "< 40",
                "LessThan480": "< 480",
                "LessThan5": "< 5",
                "LessThan60": "< 60",
                "LessThan720": "< 720",
                "MoreThan1440": "> 1440",
                "MoreThan60": "> 60",
                "SessionsByConsumption": {
                    "_0_5_kwh": "0 ≤ kWh < 5",
                    "_10_20_kwh": "10 ≤ kWh < 20",
                    "_20_40_kwh": "20 ≤ kWh < 40",
                    "_40_60_kwh": "40 ≤ kWh < 60",
                    "_5_10_kwh": "5 ≤ kWh < 10",
                    "_60_kwh": "≥ 60 kWh"
                },
                "SessionsByDuration": {
                    "_0_15_min": "0 ≤ min < 15",
                    "_120_240_min": "2 ≤ h < 4",
                    "_1440_min": "≥ 24 h",
                    "_15_30_min": "15 ≤ min < 30",
                    "_240_480_min": "4 ≤ h < 8",
                    "_30_60_min": "30 ≤ min < 60",
                    "_480_720_min": "8 ≤ h < 12",
                    "_60_120_min": "1 ≤ h < 2",
                    "_720_1440_min": "12 ≤ h < 24"
                },
                "SessionsByPower": {
                    "_0_4_kw": "0 ≤ kW < 4",
                    "_100_150_kw": "100 ≤ kW < 150",
                    "_12_22_kw": "12 ≤ kW < 22",
                    "_150_kw": "≥ 150 kW",
                    "_22_50_kw": "22 ≤ kW < 50",
                    "_4_8_kw": "4 ≤ kW < 8",
                    "_50_100_kw": "50 ≤ kW < 100",
                    "_8_12_kw": "8 ≤ kW < 12"
                }
            },
            "liters": "litres",
            "mi": "miles",
            "miles": "miles",
            "noProvider": "No provider (P&C, payment terminal)",
            "noStats": "No statistics available",
            "noStatsMessage": "No data for this period",
            "numberOfIssues": "Number of issues",
            "numberOfSessions": "{number} sessions",
            "offlineDuration": "Offline duration",
            "pdf": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons and NOx",
                "IncomeByNetwork": "Sales by network (incl. VAT)",
                "IncomeByProvider": "Sales by provider (incl. VAT)",
                "IncomePerLocation": "Sales by location (incl. VAT)",
                "LessThan10kWh": "≤ 10 kWh",
                "LessThan12": "≤ 12 kW",
                "LessThan12kW": "≤ 12 kW",
                "LessThan20kWh": "≤ 20 kWh",
                "LessThan22": "≤ 22 kW",
                "LessThan22kW": "≤ 22 kW",
                "LessThan4": "≤ 4 kW",
                "LessThan40kWh": "≤ 40 kWh",
                "LessThan4kW": "≤ 4 kW",
                "LessThan5kWh": "≤ 5 kWh",
                "LessThan60kWh": "≤ 60 kWh",
                "LessThan8": "≤ 8 kW",
                "LessThan8kW": "≤ 8 kW",
                "MWh": "MWh",
                "MoreThan22": "All sessions",
                "MoreThan22kW": "All sessions",
                "MoreThan60kWh": "All sessions",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Particles",
                "SessionsByConnectorType": "Breakdown per connector",
                "SessionsByConsumption": "Sessions by consumption",
                "SessionsByConsumptionTitle": "Sessions by consumption",
                "SessionsByDay": "Sessions by day",
                "SessionsByDayTitle": "Sessions by day",
                "SessionsByDuration": "Sessions by duration",
                "SessionsByDurationTitle": "Sessions by duration",
                "SessionsByHours": "Sessions by hours",
                "SessionsByMonth": "Sessions by month",
                "SessionsByMonthTitle": "Sessions by month",
                "SessionsByNetwork": "Sessions by network",
                "SessionsByPower": "Sessions by power",
                "SessionsByPowerTitle": "Sessions by power",
                "SessionsByProvider": "Sessions by provider",
                "SessionsHistogramme": "Occupancy chart",
                "SessionsPerLocation": "Sessions per location",
                "aggregatedDuration": "Aggregated duration",
                "amountCurrency": "Amount in {currency}",
                "availability": "Availability",
                "average": "Average",
                "averageBasket": "Average price paid",
                "averageChargeTime": "Average charging time",
                "averageCostBySession": "Average cost by session",
                "averageDuration": "Average charging duration",
                "averagePluginTime": "Average duration while connected",
                "chargeTime": "Session duration",
                "connector": "Connector",
                "consumption": "Consumption",
                "data": "Data",
                "distanceAvoidedThermalVehicle": "Avoided distance in conventional vehicles",
                "duration": "Duration",
                "environmentImpact": "Environmental impact",
                "environmentInfos": "Environmental data",
                "fileName": "cpo-stats-",
                "group": "Provider",
                "hhmmss": "h,m,s",
                "income": "Sales (incl. VAT)",
                "kWh": "kWh",
                "kg": "kg",
                "km": "km",
                "lessThan15Min": "≤ 15 min",
                "lessThan1Hour": "≤ 1 hour",
                "lessThan2Hour": "≤ 2 hours",
                "lessThan30Min": "≤ 30 min",
                "lessThan4Hour": "≤ 4 hours",
                "liters": "litres",
                "location": "Location",
                "mainIndicators": "Main indicators",
                "miles": "miles",
                "month": "Months",
                "moreThan4Hour": ">  4 hours",
                "network": "Network",
                "nodata": "Not enough data",
                "number": "Number",
                "numberOfSessions": "Number of sessions",
                "occupationRate": "Occupation rate",
                "operator": "Provider",
                "others": "Others",
                "percent": "Percent",
                "period": "Period",
                "pluginTime": "Charging duration",
                "savedFuel": "Saved fuel",
                "sessionSuccessRate": "Qualified session rate",
                "successfulSessions": "Qualified sessions",
                "title": "Statistics",
                "total": "Total",
                "weekDay": "Week day"
            },
            "percentageOfDuration": "{percentage}% of total duration",
            "q1": "First quarter",
            "q2": "Second quarter",
            "q3": "Third quarter",
            "q4": "Fourth quarter",
            "rempConvVehicles": "Environmental data",
            "selectNetworkPlaceholder": "Select network",
            "sessionIssueRatio": "Session issue ratio",
            "sessionSimpleStats": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "DistanceAvoidedThermalVehicle": "Avoided distance in conventional vehicles",
                "EffectiveChargingOverConnectionRate": "Ratio actual charging time \/ total connection time",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbons and NOx",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Particles",
                "SavedFuel": "Saved fuel",
                "SessionNumber": "Number of sessions",
                "average_charge_time": "Average charging duration",
                "average_conso_by_session": "Average consumption by session",
                "average_plugin_time": "Average duration while connected",
                "chargingDuration": "Charging duration",
                "consumption": "Consumption",
                "failed_session_count": "Non qualified sessions",
                "failed_session_rate": "Aberration rate",
                "income": "Sales (incl. VAT)",
                "sessionDuration": "Session duration",
                "successful_session_consumption": "Consumption of qualified sessions",
                "successful_session_count": "Number of qualified sessions",
                "successful_session_rate": "Qualified sessions rate",
                "total_charge_time": "Total charging duration",
                "total_plugin_time": "Total duration while connected",
                "total_session_consumption": "Total consumption",
                "total_session_count": "Total number of sessions"
            },
            "successOnly": "Qualified sessions only",
            "title": "Statistics",
            "top5Remains": "Other",
            "total": "Total",
            "totalIncome": "Total sales: {income}{currency}",
            "totalIncomeLabel": "Total sales",
            "unavailabilityDuration": "Unavailability duration"
        },
        "stickers": {
            "bigStickers": "Include 10x10 stickers",
            "download": "Download",
            "evses": "Charge points",
            "exemple": "Exemple of compatible format:",
            "guide": "To generate all the QR codes inside a pdf please copy and paste your list inside the text area and click download. You can also display a preview of the PDF with the toggle",
            "noEvsesAvailable": "No CP available with this operator",
            "selectOperator": "Select an operator to import CP",
            "showPreview": "Show PDF preview",
            "title": "Charge point QR code generator"
        },
        "supervision": {
            "default_error": "Error",
            "downloadRecentBrowser": "Please update your browser",
            "following_errors": "Errors:",
            "not_allowed": "Access denied"
        },
        "table": {
            "columns": "Columns",
            "count": "One record|Showing {from} to {to} of {count} records|{count} records",
            "defaultOption": "Select {column}",
            "filter": "Filter:",
            "filterBy": "Filter by {column}",
            "filterPlaceholder": "Search",
            "first": "First",
            "goToFirstPage": "First",
            "goToLastPage": "Last",
            "last": "Last",
            "limit": "Records:",
            "loadPaging": "See the number of results",
            "loading": "Loading...",
            "noFilter": "No filter applied",
            "noResults": "No matching record",
            "page": "Page:",
            "undefined": "Undefined"
        },
        "tags": {
            "assign": "Assign tags",
            "asyncSearch": "Search for an existing tag",
            "asyncSearchFilter": "Filter tags",
            "delete": "Delete this tag",
            "description": "Description",
            "hideAllTags": "Hide all tags",
            "isCascaded": "Apply in cascade",
            "none": "No tags",
            "or": "or",
            "placeholder": "Placeholder",
            "preview": "Preview",
            "removeTagMessage": "You are about to remove the following tag from this network: \"{tag}\" <br> Do you want to continue?",
            "removeTagTitle": "Remove a tag",
            "settings": "Tag settings",
            "showAllTags": "Show all tags",
            "tags": "Tags",
            "title": "Object tag management",
            "toast": {
                "created": "The tag was created successfully.",
                "deleted": "The tag has been successfully deleted.",
                "updated": "The tag was successfully updated."
            }
        },
        "tariffs": {
            "create": {
                "breadcrumb": "New tariff",
                "cpoTitle": "New tariff with {name}",
                "feedback": "Tariff succesfully added",
                "tariffList": "Tariffs",
                "title": "New tariff"
            },
            "edit": {
                "breadcrumb": "Edit",
                "feedback": "Tariff succesfully edited",
                "tariffList": "Tariffs",
                "title": "Edit tariff"
            },
            "form": {
                "cgv": "Terms and conditions",
                "cpo": "Network",
                "currency": "Currency",
                "currencyPlaceholder": "Currency",
                "defaultTariffPlaceholder": "Find a rate",
                "description": "Description",
                "errorNameAlreadyExists": "A tariff with the same name already exists",
                "is_private": "Private",
                "maxPrice": "Maximum price",
                "name": "Name",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provision incl. VAT",
                "refPlaceholder": "Can be filled with the name",
                "refUnavailableMessage": "This reference is not available. Please try another one",
                "submit": "Save",
                "updatedAt": "Updated"
            },
            "list": {
                "addTariff": "Tariff",
                "breadcrumb": "Tariffs",
                "cpoTitle": "Tariffs of {name}",
                "currency": "Currency",
                "elements": "Price groups",
                "globalRestrictions": "Application threshold",
                "is_private": "Private",
                "name": "Name",
                "network": "Network",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provision incl. VAT",
                "title": "Tariffs",
                "updated_at": "Last update",
                "vat": "VAT"
            },
            "maxPrice": {
                "add": "Add",
                "confirmDelete": "Confirm shared max price deletion?",
                "maxPrice": "Maximum price",
                "maxPriceDeleted": "Shared maximum price deleted",
                "maxPriceEdited": "Shared maximum price updated",
                "noMaxPrices": "No maximum price defined",
                "searchAddGroup": "Search or add group",
                "title": "Shared maximum price",
                "tooltipPriceDelete": "Delete shared price"
            },
            "operators": {
                "CONSUMPTION": {
                    "<": "Consumption less than",
                    "<=": "Consuption less or equal than",
                    ">": "Consumption greater than",
                    ">=": "Consumption greater or equal than"
                },
                "DURATION": {
                    "<": "Duration less than",
                    "<=": "Duration less or equal than",
                    ">": "Duration greater than",
                    ">=": "Duration greater or equal than"
                },
                "and": "AND",
                "or": "OR"
            },
            "prices": {
                "DURATION_description": "Billing is based on the connection time, regardless of the vehicle's consumption",
                "ENERGY_description": "Billing depends on consumption",
                "FLAT_description": "Billing includes a fixed amount",
                "PARKING_TIME_description": "Billing depends on time without drawing energy",
                "STOP_description": "Charging session will stop above a given threshold",
                "TIME_description": "Billing depends on time while drawing energy",
                "addFormTitle": "New price",
                "addTooltip": "Add a new price",
                "confirmDeletion": "Confirm price deletion?",
                "created": "Price added",
                "deleteTooltip": "Delete price",
                "deleted": "Price deleted",
                "editFormTitle": "Edit price",
                "editTooltip": "Edit price",
                "edited": "Price edited",
                "minutes": "minutes",
                "stepSize": "Step",
                "type": "Type"
            },
            "restrictions": {
                "allDays": "Every day",
                "consumption": "Energy (kWh)",
                "created": "Price group successfully added",
                "date": "Date",
                "days": "Days",
                "deleteGroupTooltip": "Delete group",
                "duration": "Duration (minutes)",
                "editTooltip": "Edit restrictions",
                "edited": "Restriction succesfully edited",
                "formTitle": "Restrictions",
                "info": "Prices added in this group will obey to restrictions",
                "invalidConsumption": "Invalid energy value",
                "invalidDays": "Select at least one day",
                "invalidDuration": "Invalid duration value",
                "invalidPower": "Invalid power value",
                "max": "Max",
                "min": "Min",
                "power": "Power (kW)",
                "price": "Price",
                "removeDate": "Remove date",
                "time_after_consumption_end": "Time after the consumption has ended (minutes)",
                "updated": "Threshold updated"
            },
            "show": {
                "add": "Add",
                "addCondition": "Add a threshold",
                "addPriceFormTitle": "Add price",
                "breadcrumb": "Tariff",
                "cgvUrl": "URL terms and conditions",
                "conditions": "Application threshold",
                "confirmGroupDeletion": "Confirm group deletion?",
                "confirmNotificationDeletion": "Confirm notifications deletion?",
                "confirmTariffDeletion": "Confirm tariff deletion?",
                "consumption": "Energy (Wh)",
                "costPerPowerUpdated": "Cost per average power updated",
                "cpo": "Network",
                "createGroup": "New group",
                "currency": "Currency",
                "date": "Date",
                "days": "Days",
                "description": "Description",
                "duration": "Duration (minutes)",
                "edit": "Edit",
                "editPriceFormTitle": "Edit price",
                "elements": "Prices",
                "fixedPrice": "Fixed cost",
                "from": "From",
                "groupDeleted": "Group deleted",
                "hours": "Schedule",
                "invalidConsumption": "Invalid energy value",
                "invalidDays": "Select at least one day",
                "invalidDuration": "Invalid duration value",
                "invalidPower": "Invalid power value",
                "is_private": "Private",
                "lastUpdate": "Updated",
                "max": "Max",
                "maxPrice": "Maximum price",
                "min": "Min",
                "minutes": "minutes",
                "mustAddRestriction": "Prices added in this group will be subject to restrictions",
                "name": "Name",
                "noCgv": "No terms and conditions",
                "noConditions": "No threshold",
                "noDescription": "No description",
                "noPrices": "No prices",
                "notificationAdded": "Notification of tariff evolution added",
                "notificationCantBeAdd": "No applicable notification. There is a restriction related to power and\/or energy for the tariff",
                "notificationDeleted": "Notification of tariff evolution deleted",
                "notificationEmpty": "No configured notification",
                "notificationTitle": "Notification of tariff evolution",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "popup": {
                    "atTimeEvolve": "at time",
                    "beforeAndAtTimeEvolve": "before and at time",
                    "beforeEvolve": "before",
                    "chooseMomentTitle": "Please choose when users will receive a notification",
                    "evolveTariffText": "the tariff evolution",
                    "minBefore": "minutes before",
                    "minutes": "minutes",
                    "sendNotifText": "Send a notification",
                    "sendNotifTitle": "Sending of the notification",
                    "title": "Configure a notification on the tariff"
                },
                "power": "Power (kW)",
                "powerMax": "Maximum power",
                "powerMin": "Minimum power",
                "powerTimeTitle": "Average power over time",
                "price": "Price",
                "priceGroup": "Group {number}",
                "prices": "Prices",
                "provision": "Provision incl. VAT",
                "restrictionEdited": "Restriction succesfully edited",
                "restrictionFormTitle": "Restrictions",
                "restrictionIntro": "Define when tariff applies",
                "restrictions": "Restrictions",
                "stepSize": "Step",
                "tariffDeleted": "Tariff deleted",
                "to": "To",
                "type": "Type",
                "weekDays": "Week days",
                "weekEnd": "Week-end",
                "wh": "Wh"
            },
            "status": {
                "active": "Active",
                "inactive": "Inactive"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted",
            "active_url": "The {attribute} is not a valid URL",
            "after": "The {attribute} must be a date after {date}",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}",
            "alpha": "The {attribute} may only contain letters",
            "alpha_dash": "The {attribute} may only contain letters, numbers and dashes",
            "alpha_num": "The {attribute} may only contain letters and numbers",
            "array": "The {attribute} must be an array",
            "before": "The {attribute} must be a date before {date}",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items",
                "file": "The {attribute} must be between {min} and {max} kilobytes",
                "numeric": "The {attribute} must be between {min} and {max}",
                "string": "The {attribute} must be between {min} and {max} characters"
            },
            "boolean": "The {attribute} field must be true or false",
            "confirmed": "The {attribute} confirmation does not match",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "date": "The {attribute} is not a valid date",
            "date_format": "The {attribute} does not match the format {format}",
            "different": "The {attribute} and {other} must be different",
            "digits": "The {attribute} must be {digits} digits",
            "digits_between": "The {attribute} must be between {min} and {max} digits",
            "dimensions": "The {attribute} has invalid image dimensions",
            "distinct": "The {attribute} field has a duplicate value",
            "email": "The {attribute} must be a valid email address",
            "exists": "The selected {attribute} is invalid",
            "file": "The {attribute} must be a file",
            "filled": "The {attribute} field must have a value",
            "gt": {
                "array": "The {attribute} must have more than {value} items",
                "file": "The {attribute} must be greater than {value} kilobytes",
                "numeric": "The {attribute} must be greater than {value}",
                "string": "The {attribute} must be greater than {value} characters"
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more",
                "file": "The {attribute} must be greater than or equal {value} kilobytes",
                "numeric": "The {attribute} must be greater than or equal {value}",
                "string": "The {attribute} must be greater than or equal {value} characters"
            },
            "image": "The {attribute} must be an image",
            "in": "The selected {attribute} is invalid",
            "in_array": "The {attribute} field does not exist in {other}",
            "integer": "The {attribute} must be an integer",
            "ip": "The {attribute} must be a valid IP address",
            "ipv4": "The {attribute} must be a valid IPv4 address",
            "ipv6": "The {attribute} must be a valid IPv6 address",
            "json": "The {attribute} must be a valid JSON string",
            "lt": {
                "array": "The {attribute} must have less than {value} items",
                "file": "The {attribute} must be less than {value} kilobytes",
                "numeric": "The {attribute} must be less than {value}",
                "string": "The {attribute} must be less than {value} characters"
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items",
                "file": "The {attribute} must be less than or equal {value} kilobytes",
                "numeric": "The {attribute} must be less than or equal {value}",
                "string": "The {attribute} must be less than or equal {value} characters"
            },
            "max": {
                "array": "The {attribute} may not have more than {max} items",
                "file": "The {attribute} may not be greater than {max} kilobytes",
                "numeric": "The {attribute} may not be greater than {max}",
                "string": "The {attribute} may not be greater than {max} characters"
            },
            "mimes": "The {attribute} must be a file of type: {values}",
            "mimetypes": "The {attribute} must be a file of type: {values}",
            "min": {
                "array": "The {attribute} must have at least {min} items",
                "file": "The {attribute} must be at least {min} kilobytes",
                "numeric": "The {attribute} must be at least {min}",
                "string": "The {attribute} must be at least {min} characters"
            },
            "not_in": "The selected {attribute} is invalid",
            "not_regex": "The {attribute} format is invalid",
            "numeric": "The {attribute} must be a number",
            "present": "The {attribute} field must be present",
            "regex": "The {attribute} format is invalid",
            "required": "The {attribute} field is required",
            "required_if": "The {attribute} field is required when {other} is {value}",
            "required_unless": "The {attribute} field is required unless {other} is in {values}",
            "required_with": "The {attribute} field is required when {values} is present",
            "required_with_all": "The {attribute} field is required when {values} is present",
            "required_without": "The {attribute} field is required when {values} is not present",
            "required_without_all": "The {attribute} field is required when none of {values} are present",
            "same": "The {attribute} and {other} must match",
            "size": {
                "array": "The {attribute} must contain {size} items",
                "file": "The {attribute} must be {size} kilobytes",
                "numeric": "The {attribute} must be {size}",
                "string": "The {attribute} must be {size} characters"
            },
            "string": "The {attribute} must be a string",
            "timezone": "The {attribute} must be a valid zone",
            "unique": "The {attribute} has already been taken",
            "uploaded": "The {attribute} failed to upload",
            "url": "The {attribute} format is invalid"
        }
    },
    "es": {
        "agreements": {
            "create": {
                "agreementsList": "Acuerdos",
                "breadcrumb": "Añadir",
                "feedback": "El acuerdo se añadió con éxito",
                "title": "Añadir un nuevo acuerdo"
            },
            "edit": {
                "agreementsList": "Acuerdos",
                "breadcrumb": "Editar",
                "feedback": "El acuerdo se actualizó con éxito",
                "title": "Editar el acuerdo"
            },
            "form": {
                "alreadyExists": "Ya existe un acuerdo entre {cpo} - {emsp}",
                "cpo": "Red",
                "emsp": "Proveedor",
                "emspPlaceholder": "Buscar un proveedor",
                "includeSubCpos": "Incluir sub-redes",
                "includeSubEmsps": "Incluir sub-proveedores",
                "isActive": "Activo",
                "ocpiModules": "Módulos OCPI",
                "searchPlaceholder": "Escriba aquí",
                "submit": "Guardar",
                "tariffPlaceholder": "No se ha definida ningúna tarifa",
                "viaRoaming": "A través de la plataforma de interoperabilidad"
            },
            "list": {
                "addAgreement": "Agregar acuerdo",
                "autoliquidation": "Carga inversa",
                "breadcrumb": "Acuerdos (lista)",
                "cpoId": "Identificación de la red",
                "directFilter": "Redes de padres incluidas",
                "emspId": "Identificación del proveedor",
                "includeSubCpos": "Incluir sub-redes",
                "includeSubEmsps": "Incluir sub-proveedores",
                "no": "No",
                "noOcpiModules": "No hay módulos OCPI",
                "ocpiModules": "Módulos OCPI",
                "optionsSelected": "opciones seleccionadas",
                "tariff": "Tarifa específica",
                "title": "Acuerdos",
                "updated_at": "Última actualización",
                "yes": "Sí"
            },
            "show": {
                "agreements": "Acuerdos",
                "bridges": "Puentes",
                "cpo": "Red",
                "edit": "Editar",
                "emsp": "Proveedor",
                "false": "Falso",
                "includeSubCpos": "Incluir sub-redes",
                "includeSubEmsps": "Incluir sub-proveedores",
                "lastUpdate": "Última actualización",
                "noBridges": "Directo",
                "true": "Verdadero"
            }
        },
        "alert": {
            "alerts": "Alertas",
            "colorTitle": "Color del mensaje",
            "confirmPublish": "¿Está seguro de que quiere publicar esta alerta ?",
            "confirmUnPublish": "¿Está seguro de que quiere eliminar esta alerta ?",
            "createTitle": "Crear una nueva alerta",
            "currentAlert": "Alerta en curso",
            "delete": "Borrar la alerta actual",
            "disableFailed": "No se ha podido eliminar la alerta",
            "disabled": "La alerta ha sido eliminada",
            "editStatuses": "Editar el estado de las alertas",
            "editTitle": "Modificar la alerta actual",
            "messageClosed": "Puede encontrar estas alertas en la pestaña \"Configuración\"",
            "newAlert": "Nueva alerta",
            "publishFailed": "Fallo en la publicación de la alerta",
            "publishSuccessful": "La alerta ha sido publicada",
            "showOnlyActive": "Mostrar sólo las alertas activas",
            "title": "Gestionar las alertas",
            "type": {
                "danger": "Incidente",
                "info": "Información",
                "success": "Resolución",
                "warning": "Mantenimiento"
            },
            "unPublishFailed": "No se ha podido eliminar la alerta",
            "unPublishSuccessful": "La alerta ha sido eliminada.",
            "updateFailed": "Fallo en la eliminación de la alerta",
            "updated": "La alerta ha sido modificada"
        },
        "audit": {
            "ACTION": "Acción realizada",
            "CREATE": "Añadido",
            "DELETE": "Eliminado",
            "READ": "Consultado",
            "RESTORE": "Restaurado",
            "UPDATE": "Actualizado",
            "UPDATE_TRANSLATION": "Traducciones actualizadas",
            "breadcrumb": "Actividad",
            "noActivity": "No hay actividad",
            "title": "Actividad de {name}",
            "via": {
                "BACKOFFICE": "Back-office",
                "CLI": "CLI",
                "JOBS": "Tarea automática",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "SUPERVISION": "Supervisión"
            }
        },
        "auth": {
            "confirmPassword": "Confirmar la contraseña",
            "emailSent": "Correo electrónico enviado",
            "failed": "Contraseña o correo electrónico incorrectos",
            "login": "Ingresa en",
            "passResetSuccess": "La contraseña se ha restablecido con éxito",
            "permanent_ban": "Debido a demasiados intentos de inicio de sesión, su cuenta ha sido desactivada. Por favor, contacte a su administrador.",
            "register": "Registrar",
            "reset": "Restablecer la contraseña",
            "sendLink": "Enviar enlace para restablecer la contraseña",
            "successLogout": "Se ha cerrado la sesión con éxito",
            "temporary_ban": "Ha realizado demasiados intentos de inicio de sesión: Por favor, inténtelo de nuevo más tarde.",
            "throttle": "Demasiados intentos. Por favor, inténtelo de nuevo en {seconds} segundos"
        },
        "businessLogs": {
            "breadcrumb": "Logs",
            "byEvse": "Filter by EVSE",
            "context": "Context",
            "contextValues": {
                "CLI": "Server",
                "JOBS": "Task",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "OCPPJ": "OCPP-J",
                "SUPERVISION": "Supervision",
                "WEBSOCKET": "Websocket"
            },
            "diagnosticStatus": {
                "UploadFailed": "Failed to send diagnostic",
                "Uploaded": "Diagnostic successfully sent"
            },
            "event": "Event",
            "eventValues": {
                "ALREADY_COMPLETED_SESSION_ENDED": "End of already completed session",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Price asked by already completed session",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Energy updated by already completed session",
                "ALREADY_COMPLETED_SESSION_STARTED": "Start of already completed session",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "Rearming device",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "Start outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Change availability",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Clear cache",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Get diagnostics",
                "ATTEMPTING_REMOTE_RESET": "Reset",
                "ATTEMPTING_REMOTE_START": "Remote start",
                "ATTEMPTING_REMOTE_STOP": "Remote stop",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Unlock",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Update firmware",
                "CONNECTOR_STATUS_UPDATED": "Connector status",
                "DETECTION_SENSOR_BLOCKED": "Detection sensor blocked",
                "DETECTION_SENSOR_IGNORED": "Detection sensor ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Detection sensor unblocked",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Detection sensor unknown connector",
                "DIAGNOSTIC_STATUS": "Diagnostic status",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "Device rearmed successfully",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "Failure to rearm device",
                "ERCOGENER_START_OUTLET_FAILURE": "Outlet started successfully",
                "ERCOGENER_START_OUTLET_SUCCESS": "Failure to start outlet",
                "EVSE_STATUS_UPDATED": "EVSE status",
                "FIRMWARE_STATUS": "Firmware status",
                "LAN_DEVICE_BOOTED": "Charger boot",
                "LAN_DEVICE_DOWN": "Charger offline",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Firmware updated",
                "LAN_DEVICE_MODEL_CHANGE": "Model updated",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Protocol updated",
                "LAN_DEVICE_UP": "Charger online",
                "LAN_DEVICE_VENDOR_CHANGE": "Vendor updated",
                "LAN_PUBLIC_IP_CHANGE": "IP updated",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "Payment terminal offline failure report",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "Payment terminal offline success report",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment terminal online failure report",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment terminal online success report",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Change availability failure",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Change availability success",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Failed to get diagnostics",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Diagnostics successfully got",
                "REMOTE_RESET_FAILURE": "Failed to reset",
                "REMOTE_RESET_SUCCESS": "Successfully reset",
                "REMOTE_START_FAILURE": "Remote start failure",
                "REMOTE_START_SUCCESS": "Remote start success",
                "REMOTE_STOP_FAILURE": "Remote stop failure",
                "REMOTE_STOP_SUCCESS": "Remote stop success",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Unlock failure",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Unlock success",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Update firmware failure",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Update firmware success",
                "SESSION_AUTHORIZED": "Session authorized",
                "SESSION_DENIED": "Session denied",
                "SESSION_ENDED": "Session ended",
                "SESSION_ENERGY_UPDATED": "Energy updated",
                "SESSION_FORCED": "Session forced",
                "SESSION_STARTED": "Session started",
                "SESSION_START_BLOCKED": "Session start blocked",
                "SESSION_TIMED_OUT": "Session timed out",
                "SESSION_TOTAL_COST_UPDATED": "Price",
                "TOKEN_AUTHORIZE_ACCEPTED": "Token authorized",
                "TOKEN_AUTHORIZE_DENIED": "Token not authorized",
                "TOKEN_AUTHORIZE_UNKNOWN": "Token not recognized",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector status",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session ended",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Energy updated by unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session started"
            },
            "evseStatusUpdated": {
                "administrative": "Administrative status set from {previous_status} to {current_status}",
                "charging": "Charging status set from {previous_status} to {current_status}",
                "message": "EVSE {name} set from {previous_status} to {current_status}. Reason: {status_reason}",
                "operational": "Operational status set from {previous_status} to {current_status}"
            },
            "filterPlaceholder": "Search by context",
            "firmwareStatus": {
                "DownloadFailed": "Download firmware failure",
                "Downloaded": "Download firmware success",
                "InstallationFailed": "Install firmware failure",
                "Installed": "Install firmware success"
            },
            "lanDeviceStatus": {
                "down": "is no longer connected to the server",
                "up": "is now connected to the server"
            },
            "level": {
                "ALERT": "Alert log",
                "CRITICAL": "Critical log",
                "DEBUG": "Debug log",
                "EMERGENCY": "Emergency log",
                "ERROR": "Error log",
                "INFO": "Info log",
                "NOTICE": "Notice log",
                "WARNING": "Warning log"
            },
            "message": "Message",
            "messages": {
                "ALREADY_COMPLETED_SESSION_ENDED": "Session {sessionLink}, already ended, has been ended by charger {chargeboxid}",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Charger {chargeboxid} is asking price for session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Charger {chargeboxid} updated consumption of session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_STARTED": "Session {sessionLink} already ended has been authorized by charger {chargeboxid}",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "(Ercogener) Attempt to rearm device {chargeboxid}",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "(Ercogener) Attempt to start outlet",
                "ATTEMPTING_ERCOGENER_STOP_OUTLET": "(Ercogener) Attempt to stop outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Attempt to set station {chargeboxid} to state {availability}",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Attempt to clear cache of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Attempt to get diagonistcs of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_RESET": "Attempting to reset charger {chargeboxid}",
                "ATTEMPTING_REMOTE_START": "Attempting to start a session on charger {chargeboxid} and on connector {connector_id}",
                "ATTEMPTING_REMOTE_STOP": "Attempting to stop session {session} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Attempting to unlock connector {connector_id} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Attempting to update firmware of charger {chargeboxid} (filename: {filename})",
                "CONNECTOR_STATUS_UPDATED": "is set from {previous_status} to {current_status}",
                "DETECTION_SENSOR_BLOCKED": "An obstacle is detected on EVSE {evse}",
                "DETECTION_SENSOR_IGNORED": "Some information of charger {chargeboxid} about an obstacle was ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Obstacle on EVSE {evse} removed",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Charger {chargeboxid} detected an obstacle on unknown connector {connector_id}",
                "DIAGNOSTIC_STATUS": "Charger {chargeboxid} {status}",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "(Ercogener) Failed to rearm device {chargeboxid}. Reason: {reason}",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "(Ercogener) Device {chargeboxid} rearmed",
                "ERCOGENER_START_OUTLET_FAILURE": "(Ercogener) Failed to start outlet. Reason: reason",
                "ERCOGENER_START_OUTLET_SUCCESS": "(Ercogener) Outlet started",
                "ERCOGENER_STOP_OUTLET_FAILURE": "(Ercogener) Failed to stop outlet. Reason: reason",
                "ERCOGENER_STOP_OUTLET_SUCCESS": "(Ercogener) Outlet stopped",
                "FIRMWARE_STATUS": "Charger {chargeboxid} {status}",
                "LAN_DEVICE": "Charger {chargeboxid} {lan_device_status}",
                "LAN_DEVICE_BOOTED": "Charger {chargeboxid} booted",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Charger firmware {chargeboxid} is now {current_firmware} (beforehand {previous_firmware})",
                "LAN_DEVICE_MODEL_CHANGE": "Charger model {chargeboxid} is now {current_model} (beforehand {previous_model})",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Charger {chargeboxid} now uses {current_protocol} (beforehand {previous_protocol})",
                "LAN_DEVICE_VENDOR_CHANGE": "Charger vendor {chargeboxid} is now {current_vendor} (beforehand {previous_vendor})",
                "LAN_PUBLIC_IP_CHANGE": "LAN {lan} now has public IP {current_ip} (beforehand {previous_ip})",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId})",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId})",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Failed to set charger {chargeboxid} state to {availability}. Reason: {reason}",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Charger {chargeboxid} successfully set to state {availability}",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache of charger {chargeboxid}. Reason: {reason}",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache of charger {chargeboxid} successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Charger {chargeboxid} refused get diagnostics command. Reason: {reason}",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Charger {chargeboxid} accepted order, diagnostic will be generated soon",
                "REMOTE_RESET_FAILURE": "Failed to reset charger {chargeboxid}. Reason: {reason}",
                "REMOTE_RESET_SUCCESS": "Charger {chargeboxid} successfully reset",
                "REMOTE_START_FAILURE": "Failed to start a session on connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_START_SUCCESS": "Session start successfully allowed on connector {connector_id} on charger {chargeboxid}",
                "REMOTE_STOP_FAILURE": "Failed to stop session {session} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_STOP_SUCCESS": "Session {session} successfully stopped on charger {chargeboxid}",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Failed to unlock connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Connector {connector_id} successfully unlocked on charger {chargeboxid}",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Charger {chargeboxid} refused order to update firmware (filename: {filename}). Reason: {reason}",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Charger {chargeboxid} accepted order to update firmware (filename: {filename})",
                "SESSION_AUTHORIZED": "Session {session} authorized for contract {auth_id} (UID: {token_uid})",
                "SESSION_DENIED": "Session {sessionLink} with UID {token_uid} not authorized and will not be completed. Reason:",
                "SESSION_ENDED": "Session {session} is over. {invalid_msg} Consumption: {consumption}. Duration:",
                "SESSION_ENERGY_UPDATED": "Session {session} consumption {consumption} (total: {current_wh})",
                "SESSION_FORCED": "Session {sessionLink} with UID {token_uid} started without authorization",
                "SESSION_STARTED": "Session {session} has started. Contract: {auth_id}. UID: {token_uid}",
                "SESSION_START_BLOCKED": "Unknown session blocked by tag {idTag} on charger {chargeboxid}",
                "SESSION_TIMED_OUT": "Session {sessionLink} (authorized at {authorizeDate}) expired and will not be completed",
                "SESSION_TOTAL_COST_UPDATED": "Session {session} cost {current_total_cost} (beforehand {previous_total_cost})",
                "TOKEN_AUTHORIZE_ACCEPTED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-success\">authorized<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<strong>",
                "TOKEN_AUTHORIZE_DENIED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-danger\">did not authorize<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<\/strong> for the following reason: ({allowed}) <strong>{reason}<\/strong>",
                "TOKEN_AUTHORIZE_UNKNOWN": "<strong class=\"has-text-warning\">No provider<\/strong> recognized token <strong>{tokenUid}<\/strong>",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector {connector_id} set to {status}",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session stopped by charger {chargeboxid}",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Consumption updated by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session authorized by charger {chargeboxid}",
                "default": "Sorry, no interpretation possible",
                "moreDetails": "See details"
            },
            "noContextualData": "No contextual data available",
            "orderSentBy": "Order sent by {email}",
            "paymentTerminal": {
                "errorMessage": "Error message:",
                "noErrorMessage": "No error message",
                "noReceipt": "No receipt for this session",
                "number": "Payment terminal number: {number}",
                "receiptData": "Receipt data:"
            },
            "remoteFailedReason": {
                "NOT_SUPPORTED": "Order not supported by charger",
                "REJECTED": "Order rejected by charger",
                "TIMEOUT": "No response from charger",
                "default": "Unknown reason",
                "null": "Unknown reason"
            },
            "sessionStatus": {
                "invalid": "Session failed"
            },
            "statusReason": {
                "ADMINISTRATIVE_DECLARATION": "Administrative status updated by a supervisor",
                "ALL_CONNECTORS_FAULTED": "All connectors are faulted",
                "ALL_CONNECTORS_UNAVAILABLE": "All connectors are unavailable",
                "BLOCKED_EVSE": "Access to EVSE is blocked by an obstacle",
                "NO_CONNECTOR": "EVSE has no connector",
                "NO_LAN_DEVICE": "EVSE is attached to no charger",
                "OFFLINE_LAN_DEVICE": "Charger is offline",
                "ONGOING_RESERVATION": "A reservation is ongoing",
                "ONGOING_SESSION": "A session is ongoing",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Ordered by the electricity supplier",
                "default": "Everything is fine",
                "null": "Unknown reason"
            },
            "title": "Business logs {name}"
        },
        "cdrs": {
            "csv": {
                "confirmTitle": "CSV de {from} a {to}",
                "default": "¿Confirma la exportación?",
                "tooHeavy": "El archivo se está generando y se enviará a {email}"
            },
            "list": {
                "authId": "Contrato",
                "breadcrumb": "CDRs",
                "connector": "Conectores",
                "cpo": "Red",
                "created": "Creado en",
                "emsp": "Proveedor",
                "evse": "PdC",
                "externalRef": "Referencia externa",
                "lastSent": "Último enviado",
                "location": "Ubicaciones",
                "ref": "Reference",
                "scopeTitle": "CDRs of {name}",
                "startDateTime": "Inicio",
                "stopDateTime": "Fin",
                "title": "CDRs",
                "totalCost": "Total price",
                "totalEnergy": "kWh",
                "vat": "IVA",
                "withAdvenir": "With CDRs for Advenir",
                "withChildrenOperators": "Sesiones de las sub-redes"
            },
            "show": {
                "auth": "Authorization ID",
                "badge": "Tag ID",
                "breadcrumb": "CDR",
                "cdrOfSessionRef": "CDR of #{ref}",
                "cost": "Price",
                "energy": "Energy",
                "exportToPDF": "Export to PDF",
                "isWhiteListed": "is white-listed",
                "preTaxPrice": "Price excl. VAT",
                "ref": "Reference",
                "relations": "Relations",
                "remark": "Comments",
                "title": "Charge details record #{ref}",
                "totalCost": "Total price",
                "totalParkingTime": "Parking time",
                "totalTime": "Total time"
            }
        },
        "commons": {
            "From": "De",
            "Now": "Ahora",
            "To": "A",
            "active": "Activo",
            "add": "Nuevo",
            "agreement": "Acuerdo",
            "all": "Todos",
            "asyncSearch": "Escribar para buscar",
            "authId": "Contrato",
            "autoRefreshDisabled": "La actualización automática no está disponible por ahora",
            "autoRefreshOff": "Auto refresco off",
            "autoRefreshOn": "Auto refresco en",
            "back": "Volver",
            "by": "por {user}",
            "cancel": "Cancelar",
            "clear": "Vaciar",
            "common": "Común",
            "confirm": "Confirme",
            "cpo": "Red",
            "created_at": "Created date",
            "creationPlaceholder": "Creación",
            "curve": "Curva",
            "date": "Fecha",
            "dateRange": "Filtrar por fecha",
            "delete": "Delete",
            "durationUnits": {
                "days": "día(s)",
                "hours": "h",
                "minutes": "m",
                "months": "mes(es)",
                "seconds": "s"
            },
            "durationWithDays": "{nb_days} día(s) y {standard_duration}",
            "edit": "Editar",
            "edition": "{name}",
            "emi3": "eMI3-ID privado",
            "emsp": "Proveedor",
            "end": "Fin",
            "error403": "No autorizado",
            "error404": "Página no encontrada",
            "evse": "PdC",
            "exclVat": "Sin IVA",
            "export": "Exportación",
            "exportToCSV": "Exportar a CSV",
            "exportToPDF": "Exportar a PDF",
            "false": "Falso",
            "filterOn": "Filter",
            "from": "de",
            "fullPrice": "Sin IVA: {exclVat} \/ IVA: {vat} \/ {vatRate} %",
            "globalError": "Se produjo un error",
            "histogram": "Histograma",
            "hotline": "Hotline",
            "import": "Importar",
            "inactive": "Inactivo",
            "inclVat": "incl. IVA",
            "isActive": "Activo",
            "kW": "kW",
            "kWh": "kWh",
            "last_update": "Última actualización",
            "location": "Ubicación",
            "locationsList": "Ubicaciones",
            "month": "Mes",
            "no": "No",
            "noResult": "No hay resultados",
            "noResultAsync": "No hay resultados, escriba aqui",
            "noSmartphone": "No está disponible para el smartphone",
            "notDefined": "Sin definir",
            "ocpiModules": "Módulos OCPI",
            "of": "de {data}",
            "onlyCommaAllowed": "Sólo \",\" se permite como separador",
            "optional": "opcional",
            "optionsSelected": "{number} opción(es) seleccionada(s)",
            "placeholderSelect": "Seleccione la opción",
            "preTaxCost": "{amount} sin impuestos, {vat} IVA",
            "process": "Proceso",
            "quarter": "Trimestre",
            "rangeHint": "Data from {from} to {to}",
            "reference": "Referencia",
            "refunds": "Reembolsos",
            "schedule": "Horarios",
            "search": "Buscar",
            "session": "Sesión",
            "start": "Inicio",
            "status": "Estado",
            "submit": "Confirmar",
            "tagId": "Token",
            "tariff": "Tarifa",
            "to": "a",
            "today": "Today",
            "true": "Verdadero",
            "type": "Tipo",
            "updated_at": "Actualizado",
            "vat": "IVA",
            "vatRate": "Tasa IVA",
            "year": "Año",
            "years": "Años",
            "yes": "Sí"
        },
        "connectors": {
            "actions": {
                "start": "Empezar a cargar",
                "stop": "Detener la carga",
                "unlock": "Desbloquear"
            },
            "form": {
                "addConnectorTitle": "Añadir el conector",
                "amps": "Amperage (A)",
                "cable": "El cable está conectado",
                "create": "Añadir el conector",
                "customPower": "Custom power",
                "delete": "Delete",
                "edit": "Editar",
                "editConnectorTitle": "Editar el conector",
                "invalid": "Los conectores no son válidos",
                "maxPower": "Max power: {maxPower} kW",
                "ocppNumber": "ID del conector OCPP",
                "ocppNumberIsUnavailable": "no está disponible",
                "ocppNumberUnavailable": "Esta identificación de conector no está disponible",
                "power": "Potencia",
                "powerType": "Power type",
                "tariff": "Tarifa",
                "type": "Tipo",
                "voltage": "Voltage (V)"
            },
            "list": {
                "errorCode": "Código de error",
                "evseName": "Nombre PdC",
                "evseRef": "Ref PdC",
                "lastStatusChangeAvailable": "Resuelto desde",
                "lastStatusChangeIssue": "Por defecto desde",
                "maintenanceAvailableTitle": "Connectors: issue solved",
                "maintenanceIssuesSubtitle": "List of connectors that have reported an error code for at least 1 hour",
                "maintenanceIssuesTitle": "Error codes",
                "standard": "Tipo \/ Potencia"
            },
            "powerTypes": {
                "AC_1_PHASE": "1 phase",
                "AC_3_PHASE": "3 phase",
                "DC": "Direct current"
            },
            "show": {
                "cable": "El cable está conectado",
                "cannotRemove": "Se requiere al menos un conector",
                "confirmDeletion": "Por favor, confirme la eliminación",
                "connectorCreated": "Conector creado",
                "connectorEdited": "Conector editado",
                "connectorRemoved": "Conector retirado",
                "defaultTariff": "Tarifas por defecto",
                "localisation": "Ubicación",
                "locked": "Bloqueado",
                "noRestrictions": "No hay restricciones",
                "noTariff": "No se ha definido ningún tarifa",
                "ocppNumber": "OCPP-ID del conector",
                "power": "Potencia",
                "socketFalse": "El cable está conectado",
                "socketTrue": "No hay ningún cable conectado",
                "tariff": "Tarifa",
                "tariffApply": "Tarifa aplicada",
                "title": "Conector n°",
                "type": "Tipo",
                "unlocked": "Desbloqueado"
            },
            "standard": {
                "chademo": "Chademo",
                "domestic_a": "Standard\/Domestic type A",
                "domestic_b": "Standard\/Domestic type B",
                "domestic_c": "Standard\/Domestic type C",
                "domestic_d": "Standard\/Domestic type D",
                "domestic_e": "Standard\/Domestic type E",
                "domestic_f": "Standard\/Domestic type F",
                "domestic_g": "Standard\/Domestic type G",
                "domestic_h": "Standard\/Domestic type H",
                "domestic_i": "Standard\/Domestic type I",
                "domestic_j": "Standard\/Domestic type J",
                "domestic_k": "Standard\/Domestic type K",
                "domestic_l": "Standard\/Domestic type L",
                "iec_60309_2_single_16": "IEC 60309-Industrial single phase 16",
                "iec_60309_2_three_16": "IEC 60309-Industrial three phase 16",
                "iec_60309_2_three_32": "IEC 60309-Industrial three phase 32",
                "iec_60309_2_three_64": "IEC 60309-Industrial three phase 64",
                "iec_62196_t1": "IEC 62196 Type 1",
                "iec_62196_t1_combo": "Combo Type 1",
                "iec_62196_t2": "IEC 62196 Type 2",
                "iec_62196_t2_combo": "Combo Type 2",
                "iec_62196_t3a": "IEC 62196 Type 3A",
                "iec_62196_t3c": "IEC 62196 Type 3C",
                "other": "Otros conectores",
                "tesla_r": "Tesla Connector Roadster-type",
                "tesla_s": "Tesla Connector Model-S-type"
            },
            "status": {
                "available": "Disponible",
                "charging": "Cargando",
                "faulted": "Fallo",
                "finishing": "Terminando",
                "issue": "Edición",
                "occupied": "Ocupado",
                "preparing": "Preparando",
                "reserved": "Reservado",
                "suspendedev": "VE suspendido",
                "suspendedevse": "PdC suspendido",
                "unavailable": "No disponible"
            }
        },
        "contact-groups": {
            "actions": {
                "assignGroup": "Add an existing group",
                "delete": "Delete",
                "remove": "Remove",
                "replaceGroup": "Replace group"
            },
            "create": {
                "feedback": "Group successfully created",
                "title": "Create a contact group"
            },
            "edit": {
                "addExistingGroup": "Add an existing group",
                "edit": "Edit",
                "feedback": "Group successfully edited",
                "searchContactGroups": "Search contact groups"
            },
            "form": {
                "affiliations": "Affiliations",
                "assign": "Assign",
                "contacts": "Contacts",
                "description": "Description",
                "info": "Group information",
                "language": "Speaked language",
                "location": "Location",
                "name": "Name of the group",
                "nameExists": "This group already exists",
                "network": "Network",
                "or": "or",
                "planning": "Planning",
                "searchContacts": "Search contacts",
                "searchContactsEmpty": "Add at least one contact to the group"
            },
            "list": {
                "confirmDelete": "Are you sure you want to delete this contact group?",
                "delete": "Delete",
                "duplicate": "Duplicate",
                "incompleteSchedule": "Warning: Incomplete schedule"
            },
            "planning": {
                "add": "Add",
                "addContact": "Add contact",
                "addContactTitle": "Add contact for {day}",
                "addSchedule": "Add a schedule",
                "begin": "Begin",
                "empty": "No contact defined",
                "end": "End",
                "incomplete": "Planning incomplete",
                "schedule": "Planning",
                "selectContact": "Select a contact"
            },
            "show": {
                "breadcrumb": "Contact group {name}",
                "contacts": "Contacts",
                "description": "Description",
                "groupInfo": "Group information",
                "inheritedFrom": "inherited from",
                "language": "Language",
                "name": "Group name",
                "planningContactInfo": "Each contact in this group will get an emergency alert via email. The contacts registered when the emergency occurs will be informed via the contact method(s) chosen : SMS and\/or phone call.",
                "scopeDeleteGroup": "Are you sure you want to delete group {group} ? This action will remove the group from all networks and stations to which it is affiliated.",
                "scopeRemoveGroup": "Are you sure you want to remove group {group} from the network?",
                "supportGroup": "Maintenance group"
            },
            "toast": {
                "deleted": "Contact group has been deleted"
            }
        },
        "contacts": {
            "affiliations": {
                "actions": "Acciones",
                "affiliationsOf": "Afiliaciones de",
                "deletePopupTextLocation": "¿Realmente quiere eliminar esta afiliación?",
                "deletePopupTextNetwork": "¿Realmente quiere eliminar esta afiliación? \n            Esto también eliminará todas las afiliaciones entre el contacto y las estaciones de la red.\n        ",
                "deletePopupTitleLocation": "Eliminar la afiliación",
                "deletePopupTitleNetwork": "Eliminar la afiliación",
                "locations": "Estaciones",
                "network": "Red",
                "role": "Rol",
                "title": "Afiliaciones de contacto",
                "type": "Tipo"
            },
            "breadcrumb": {
                "affiliations": "Afiliaciones",
                "contact": "Contactos",
                "edit": "Editar",
                "groups": "Grupos de contactos"
            },
            "contactMethods": {
                "email": "Correo electrónico",
                "phone": "Llamada",
                "sms": "SMS"
            },
            "create": {
                "title": "Crear un contacto"
            },
            "edit": {
                "close": "Cerrar",
                "edit": "Editar",
                "editContactsTitle": "Editar contactos"
            },
            "error": {
                "contactEmailAlreadyTakenAdmin": "el correo electronico ya ha sido tomado",
                "contactEmailAlreadyTakenNotAdmin": "El contacto ya existe, para asignarlo a su red por favor contacte exploit@freshmile.com"
            },
            "form": {
                "addContactTitle": "Crear un contacto",
                "alwaysOneOwnerContact": "La red debe tener al menos un contacto propietario.",
                "buttonAddContact": "Crear un nuevo contacto",
                "buttonAddType": "Añadir un tipo",
                "buttonLink": "Vincular",
                "call": "Llamada",
                "company": "Empresa",
                "contact_methods": "Medio(s) de contacto",
                "contactsOfNetwork": "Contactos de la red",
                "countrySelectorLabel": "Código del país",
                "editContactTitle": "Editar un contacto",
                "email": "Correo electrónico",
                "errorDeleteType": "El contacto debe tener al menos un tipo",
                "errorUpdate": "Ocurrió un error",
                "linkContactExisting": "Vincular un contacto existente",
                "linkContactNotExisting": "Vincular un nuevo contacto",
                "name": "Nombre del contacto",
                "nameExists": "Nombre ya utilizado",
                "notesInput": "Notas",
                "optional": "Opcional",
                "phone_number": "Llamada",
                "placeholderUser": "Buscar por nombre, dirección de correo",
                "searchContact": "Buscar un contacto existente",
                "sms_number": "SMS",
                "textSearch": "Buscar un contacto existente y seleccionar un tipo, luego haga clic en el botón vincular.",
                "textSearchGroup": "Buscar un contacto existente y haga clic en el botón vincular.",
                "titleContactsOfNetwork": "Contacto(s)",
                "type": "Tipo de contacto",
                "userLink": "Usuario vinculado"
            },
            "groups": {
                "actions": "Acciones",
                "addGroupContact": "Crear un grupo de contacto",
                "affiliationOfGroup": "Afiliación directa",
                "confirmDelete": "¿Realmente desea eliminar el contacto de este grupo de contacto?",
                "contact_methods": "Medio(s) de contacto",
                "contacts": "Contactos",
                "groupOfContact": "Grupo de contacto",
                "groupOfContactPlaceholder": "Buscar un grupo de contacto",
                "modalTitle": "Añadir un grupo de contacto al contacto",
                "nameOfGroup": "Nombre del grupo",
                "or": "O",
                "title": "Grupos de contactos",
                "updated": "Actualizado"
            },
            "list": {
                "actions": "Acciones",
                "affiliated": "Afiliación directa",
                "allTypes": "Todos los tipos de contactos",
                "breadcrumb": "Contactos",
                "confirmDelete": "¿Realmente quiere eliminar este contacto?",
                "contact_methods": "Medios de contacto",
                "contacts": "Contactos",
                "contactsGroups": "Grupos de contactos",
                "email": "Correo electrónico",
                "filterType": "Filtrar por tipo",
                "groupName": "Nombre del grupo",
                "name": "Nombre completo",
                "phone_number": "Llamada",
                "sms_number": "SMS",
                "title": "Contactos y Grupos",
                "type": "Tipo",
                "types": "Tipos"
            },
            "show": {
                "accessContactPage": "Acceder a la ficha del contacto",
                "company": "Empresa",
                "contactMethods": "Medio(s) de contacto",
                "email": "Correo electrónico",
                "groups": "Grupos",
                "nameOfContact": "Nombre del contacto",
                "networkAndLocations": "Redes y estaciones",
                "notes": "Notas de supervisión",
                "otherContacts": "Otros contactos",
                "phone_number": "Llamada",
                "sms_number": "SMS",
                "title": "Contacto",
                "type": "Tipo de contacto:",
                "userLink": "Usuario vinculado"
            },
            "toast": {
                "deleted": "Contacto eliminado"
            },
            "types": {
                "ADMINISTRATIVE": "Administrador",
                "EMERGENCY": "Emergencia",
                "INDIVIDUAL": "Particular",
                "INSTALLER": "Instalador",
                "MAINTENANCE": "Mantenimiento",
                "OWNER": "Propietario",
                "undefined": "No definido"
            }
        },
        "countries": {
            "ABW": "Aruba",
            "AFG": "Afghanistan",
            "AGO": "Angola",
            "AIA": "Anguilla",
            "ALB": "Åland Islands",
            "AND": "Andorra",
            "ARE": "United Arab Emirates",
            "ARG": "Argentina",
            "ARM": "Armenia",
            "ASM": "American Samoa",
            "ATA": "Antarctica",
            "ATF": "French Southern Territories",
            "ATG": "Antigua and Barbuda",
            "AUS": "Australia",
            "AUT": "Austria",
            "AZE": "Azerbaijan",
            "BDI": "Burundi",
            "BEL": "Belgium",
            "BEN": "Benin",
            "BES": "Bonaire, Sint Eustatius and Saba",
            "BFA": "Burkina Faso",
            "BGD": "Bangladesh",
            "BGR": "Bulgaria",
            "BHR": "Bahrain",
            "BHS": "Bahamas",
            "BIH": "Bosnia and Herzegovina",
            "BLM": "Saint Barthélemy",
            "BLR": "Belarus",
            "BLZ": "Belize",
            "BMU": "Bermuda",
            "BOL": "Bolivia (Plurinational State of)",
            "BRA": "Brazil",
            "BRB": "Barbados",
            "BRN": "Brunei Darussalam",
            "BTN": "Bhutan",
            "BVT": "Bouvet Island",
            "BWA": "Botswana",
            "CAF": "Central African Republic",
            "CAN": "Canada",
            "CCK": "Cocos (Keeling) Islands",
            "CHE": "Switzerland",
            "CHL": "Chile",
            "CHN": "China",
            "CIV": "Côte d'Ivoire",
            "CMR": "Cameroon",
            "COD": "Congo (Democratic Republic of the)",
            "COG": "Congo",
            "COK": "Cook Islands",
            "COL": "Colombia",
            "COM": "Comoros",
            "CPV": "Cabo Verde",
            "CRI": "Costa Rica",
            "CUB": "Cuba",
            "CUW": "Curaçao",
            "CXR": "Christmas Island",
            "CYM": "Cayman Islands",
            "CYP": "Cyprus",
            "CZE": "Czechia",
            "DEU": "Germany",
            "DJI": "Djibouti",
            "DMA": "Dominica",
            "DNK": "Denmark",
            "DOM": "Dominican Republic",
            "DZA": "Algeria",
            "ECU": "Ecuador",
            "EGY": "Egypt",
            "ERI": "Eritrea",
            "ESH": "Western Sahara",
            "ESP": "Spain",
            "EST": "Estonia",
            "ETH": "Ethiopia",
            "FIN": "Finland",
            "FJI": "Fiji",
            "FLK": "Falkland Islands (Malvinas)",
            "FRA": "France",
            "FRO": "Faroe Islands",
            "FSM": "Micronesia (Federated States of)",
            "GAB": "Gabon",
            "GBR": "United Kingdom of Great Britain and Northern Ireland",
            "GEO": "Georgia",
            "GGY": "Guernsey",
            "GHA": "Ghana",
            "GIB": "Gibraltar",
            "GIN": "Guinea",
            "GLP": "Guadeloupe",
            "GMB": "Gambia",
            "GNB": "Guinea-Bissau",
            "GNQ": "Equatorial Guinea",
            "GRC": "Greece",
            "GRD": "Grenada",
            "GRL": "Greenland",
            "GTM": "Guatemala",
            "GUF": "French Guiana",
            "GUM": "Guam",
            "GUY": "Guyana",
            "HKG": "Hong Kong",
            "HMD": "Heard Island and McDonald Islands",
            "HND": "Honduras",
            "HRV": "Croatia",
            "HTI": "Haiti",
            "HUN": "Hungary",
            "IDN": "Indonesia",
            "IMN": "Isle of Man",
            "IND": "India",
            "IOT": "British Indian Ocean Territory",
            "IRL": "Ireland",
            "IRN": "Iran (Islamic Republic of)",
            "IRQ": "Iraq",
            "ISL": "Iceland",
            "ISR": "Israel",
            "ITA": "Italy",
            "JAM": "Jamaica",
            "JEY": "Jersey",
            "JOR": "Jordan",
            "JPN": "Japan",
            "KAZ": "Kazakhstan",
            "KEN": "Kenya",
            "KGZ": "Kyrgyzstan",
            "KHM": "Cambodia",
            "KIR": "Kiribati",
            "KNA": "Saint Kitts and Nevis",
            "KOR": "Korea (Republic of)",
            "KWT": "Kuwait",
            "LAO": "Lao People's Democratic Republic",
            "LBN": "Lebanon",
            "LBR": "Liberia",
            "LBY": "Libya",
            "LCA": "Saint Lucia",
            "LIE": "Liechtenstein",
            "LKA": "Sri Lanka",
            "LSO": "Lesotho",
            "LTU": "Lithuania",
            "LUX": "Luxembourg",
            "LVA": "Latvia",
            "MAC": "Macao",
            "MAF": "Saint Martin (French part)",
            "MAR": "Morocco",
            "MCO": "Monaco",
            "MDA": "Moldova (Republic of)",
            "MDG": "Madagascar",
            "MDV": "Maldives",
            "MEX": "Mexico",
            "MHL": "Marshall Islands",
            "MKD": "Macedonia (the former Yugoslav Republic of)",
            "MLI": "Mali",
            "MLT": "Malta",
            "MMR": "Myanmar",
            "MNE": "Montenegro",
            "MNG": "Mongolia",
            "MNP": "Northern Mariana Islands",
            "MOZ": "Mozambique",
            "MRT": "Mauritania",
            "MSR": "Montserrat",
            "MTQ": "Martinique",
            "MUS": "Mauritius",
            "MWI": "Malawi",
            "MYS": "Malaysia",
            "MYT": "Mayotte",
            "NAM": "Namibia",
            "NCL": "New Caledonia",
            "NER": "Niger",
            "NFK": "Norfolk Island",
            "NGA": "Nigeria",
            "NIC": "Nicaragua",
            "NIU": "Niue",
            "NLD": "Netherlands",
            "NOR": "Norway",
            "NPL": "Nepal",
            "NRU": "Nauru",
            "NZL": "New Zealand",
            "OMN": "Oman",
            "PAK": "Pakistan",
            "PAN": "Panama",
            "PCN": "Pitcairn",
            "PER": "Peru",
            "PHL": "Philippines",
            "PLW": "Palau",
            "PNG": "Papua New Guinea",
            "POL": "Poland",
            "PRI": "Puerto Rico",
            "PRK": "Korea (Democratic People's Republic of)",
            "PRT": "Portugal",
            "PRY": "Paraguay",
            "PSE": "Palestine, State of",
            "PYF": "French Polynesia",
            "QAT": "Qatar",
            "REU": "Réunion",
            "ROU": "Romania",
            "RUS": "Russian Federation",
            "RWA": "Rwanda",
            "SAU": "Saudi Arabia",
            "SDN": "Sudan",
            "SEN": "Senegal",
            "SGP": "Singapore",
            "SGS": "South Georgia and the South Sandwich Islands",
            "SHN": "Saint Helena, Ascension and Tristan da Cunha",
            "SJM": "Svalbard and Jan Mayen",
            "SLB": "Solomon Islands",
            "SLE": "Sierra Leone",
            "SLV": "El Salvador",
            "SMR": "San Marino",
            "SOM": "Somalia",
            "SPM": "Saint Pierre and Miquelon",
            "SRB": "Serbia",
            "SSD": "South Sudan",
            "STP": "Sao Tome and Principe",
            "SUR": "Suriname",
            "SVK": "Slovakia",
            "SVN": "Slovenia",
            "SWE": "Sweden",
            "SWZ": "Eswatini",
            "SXM": "Sint Maarten (Dutch part)",
            "SYC": "Seychelles",
            "SYR": "Syrian Arab Republic",
            "TCA": "Turks and Caicos Islands",
            "TCD": "Chad",
            "TGO": "Togo",
            "THA": "Thailand",
            "TJK": "Tajikistan",
            "TKL": "Tokelau",
            "TKM": "Turkmenistan",
            "TLS": "Timor-Leste",
            "TON": "Tonga",
            "TTO": "Trinidad and Tobago",
            "TUN": "Tunisia",
            "TUR": "Turkey",
            "TUV": "Tuvalu",
            "TWN": "Taiwan, Province of China",
            "TZA": "Tanzania, United Republic of",
            "UGA": "Uganda",
            "UKR": "Ukraine",
            "UMI": "United States Minor Outlying Islands",
            "URY": "Uruguay",
            "USA": "United States of America",
            "UZB": "Uzbekistan",
            "VAT": "Holy See",
            "VCT": "Saint Vincent and the Grenadines",
            "VEN": "Venezuela (Bolivarian Republic of)",
            "VGB": "Virgin Islands (British)",
            "VIR": "Virgin Islands (U.S.)",
            "VNM": "Viet Nam",
            "VUT": "Vanuatu",
            "WLF": "Wallis and Futuna",
            "WSM": "Samoa",
            "YEM": "Yemen",
            "ZAF": "South Africa",
            "ZMB": "Zambia",
            "ZWE": "Zimbabwe"
        },
        "dashboard": {
            "activity": {
                "consumptionTitle": "Energía (MWh)",
                "sessionsTitle": "Sesiones de carga (unidades)",
                "turnoverTitle": "Ventas ({currency})"
            },
            "availableEvses": "Puntos de carga disponible",
            "availableEvsesPercentage": "{1} {current} PdC no disponible fuera de {total}. (Disponibilidad: {percentage}%)| {current} PdC no disponible de {total} (Disponibilidad: {percentage}%)",
            "currentSessions": "Sesiones en curso",
            "currentSessionsPercentage": "{percentage}% de {total} PdC se están cargando",
            "exportTranslations": "Exportar traducciones",
            "importExportTranslations": "Importar\/exportar traducciones",
            "importTranslations": "Importar traducciones",
            "loadMap": "Cargar el mapa de la red",
            "networkStatus": "Estado de la red en tiempo real",
            "openTickets": "Ticketes abiertos",
            "title": "Tablero de mandos"
        },
        "days": {
            "dayNamesCalendar": [
                "Do",
                "Lu",
                "Ma",
                "Mi",
                "Ju",
                "Vi",
                "Sá"
            ],
            "friday": "Viernes",
            "monday": "Lunes",
            "monthNamesCalendar": [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre"
            ],
            "saturday": "Sábado",
            "sunday": "Domingo",
            "thursday": "Jueves",
            "tuesday": "Martes",
            "wednesday": "Miércoles"
        },
        "devices": {
            "actions": {
                "breadcrumb": "Acciones remotas",
                "change": "Cambiar",
                "changeAvailability": "Disponibilidad de cambio",
                "changeConfig": "Cambiar la configuración",
                "changeConfigPlaceholder": "Nombre de la configuración",
                "clearCache": "Despejar la memoria caché",
                "cnr": {
                    "rearm": "Dispositivo de rearme",
                    "rearmFailed": "Fallo en el rearme del dispositivo",
                    "rearmSuccess": "El dispositivo se rearmó con éxito",
                    "stop": "Stop",
                    "stopSuccess": "El enchufe estándar se detuvo con éxito",
                    "supply": "Entrega de energía por medio de un enchufe estándar (1h)",
                    "supplySince": "Entrega de energía en curso desde: {fecha}",
                    "supplySuccess": "El enchufe estándar se inició con éxito durante 1 hora",
                    "title": "Autómata de la CNR"
                },
                "configReadonly": "(No editable)",
                "configValue": "Valor de configuración",
                "configurationPlaceholder": "Seleccione o escriba aquí",
                "customConfig": "Configuración personalizada",
                "get": "Consigue",
                "getConfiguration": "Obtener la configuración",
                "getDiagnostics": "Obtener diagnósticos",
                "getDiagnosticsManual": "Send diagnostic via URL",
                "ping": "Ping",
                "protocol": "Protocolo: {protocol}",
                "reset": "Reiniciar",
                "selectFile": "Seleccione el archivo",
                "title": "Acciones remotas para {chargeboxid}",
                "unknownConfig": "Configuración desconocida",
                "updateFirmware": "Actualizar el firmware",
                "updateFirmwareManual": "Update firmware via URL",
                "upload": "Subir",
                "urlPlaceholder": "Enter URL",
                "value": "Valor:"
            },
            "create": {
                "breadcrumb": "Nuevo cargador",
                "cpoTitle": "Nuevo cargador en {name}",
                "feedback": "El cargador se ha añadido con éxito",
                "lanTitle": "Nuevo cargador conectado a {name}",
                "locationTitle": "Nuevo cargador en {name}",
                "title": "Nuevo cargador"
            },
            "edit": {
                "breadcrumb": "Editar",
                "feedback": "Cargador editado con éxito",
                "title": "Editar"
            },
            "form": {
                "addLan": "Añadir LAN",
                "chargeboxid": "Identificación de la caja de carga",
                "chargeboxidAvailable": "El Chargebox ID está disponible",
                "chargeboxidUnavailable": "El Chargebox ID no está disponible",
                "definition": "Definición de cargador",
                "deviceProtocol": "Protocolo",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "Editar LAN",
                "isDhcp": "¿Es el DHCP",
                "lan": "LAN",
                "lanDescription": "Descripción de la LAN",
                "lanName": "Nombre",
                "localIp": "Dirección IP local",
                "localIpIsInvalid": "La dirección IP local no es válida",
                "localPort": "Puerto local",
                "model": "Modelo",
                "modelIntro": "Usar un modelo existente",
                "newLanName": "LAN #",
                "offlineEvses": "Aún no ha aparecido el PdC",
                "publicIp": "Dirección IP pública",
                "publicPort": "Puerto público",
                "selectLocation": "Ubicación",
                "submit": "Añadir",
                "technicalInfos": "Información técnica",
                "unknownModel": "Modelo desconocido",
                "url": "URL"
            },
            "list": {
                "addDevice": "Añadir el cargador",
                "all": "Todos",
                "breadcrumb": "Cargadores",
                "chargeboxid": "Chargebox ID",
                "cpoTitle": "Chargebox ID de {name}",
                "firmware": "Firmware",
                "isOnline": "En línea",
                "lan": "LAN",
                "lastRequestOffline": "Desconectado desde",
                "lastRequestOnline": "En línea desde",
                "localIp": "Dirección IP local",
                "locationTitle": "CBID de {name}",
                "maintenanceOfflineSubtitle": "List of CBIDs having lost communication for at least 12 hours",
                "maintenanceOfflineTitle": "Communication losses",
                "maintenanceOnlineTitle": "CBID: issue solved",
                "model": "Modelo",
                "nbEvses": "PdC",
                "offline": "Fuera de línea",
                "online": "En línea",
                "protocol": "Protocolo",
                "status": "Estado",
                "title": "CBID",
                "updatedAt": "Actualizado",
                "vendor": "Vendedor"
            },
            "ocpp": {
                "breadcrumb": "Logs OCPP",
                "changeAvailabilityAvailable": "El cargador ya está disponible",
                "changeAvailabilityUnavailable": "El cargador ya no está disponible",
                "changeConfigSuccess": "Nuevo valor aplicado con éxito a la {configuraci}ón",
                "clearSuccess": "La caché se ha limpiado con éxito",
                "commonSuccess": "Order sent",
                "diagnosticsOngoing": "Envía los diagnósticos - esperando el cargador",
                "diagnosticsReady": "Diagnóstico listo",
                "failed": "El comando falló",
                "getDiagnosticsFail": "Hacer que los diagnósticos fallaran",
                "notSupported": "Comando no apoyado",
                "pingError": "Ping falló",
                "pingSuccess": "Ping tuvo éxito",
                "rejected": "Comando rechazado",
                "remoteFail": "La orden remota falló",
                "resetSuccess": "Orden de reinicio enviada - reinicio del cargador",
                "startSuccess": "La sesión comenzó con éxito",
                "stopSuccess": "La sesión se detuvo con éxito",
                "timeout": "El comando se ha agotado",
                "unlockFailed": "El desbloqueo falló",
                "unlockSuccess": "El desbloqueo tuvo éxito",
                "uploadFirmwareFailed": "La carga falló",
                "uploadFirmwareSuccess": "La carga tuvo éxito"
            },
            "protocol": {
                "OCPP15J": "OCPP 1.5 J",
                "OCPP15S": "OCPP 1.5 S",
                "OCPP16J": "OCPP 1.6 J",
                "OCPP16S": "OCPP 1.6 S",
                "OCPP20J": "OCPP 2.0 J",
                "OCPP20S": "OCPP 2.0 S",
                "OTHER": "Otros"
            },
            "show": {
                "DHCP": "DHCP",
                "NAT": "NAT",
                "deviceInfo": "Información sobre el cargador",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "firmware": "Firmware",
                "isOffline": "Fuera de línea",
                "isOnline": "En línea",
                "lan": "LAN",
                "lastRequest": "Ultima petición",
                "localIp": "Dirección IP local",
                "localPort": "Puerto local",
                "model": "Modelo",
                "network": "Red",
                "noOcppMessage": "no se ha enviado ningún mensaje OCPP",
                "noPairsConnected": "No hay parangón",
                "ocppInfos": "OCPP",
                "pairs": "Pares",
                "protocol": "Protocolo",
                "publicPort": "Puerto público",
                "url": "URL",
                "vendor": "Vendedor"
            }
        },
        "emails": {
            "ExportReady": {
                "body-attached": "Por favor, encuentra adjunto el archivo CSV que requirió",
                "body-linked": "Por favor, haga clic en el enlace de abajo para descargar el archivo CSV que ha requerido",
                "download-link-text": "Descargue el archivo",
                "subject": "Su archivo CSV está listo"
            },
            "Issues": {
                "closed": {
                    "introduction": "El ticket Fix: #{id} {title} - {location} ha sido cerrado por {closer} en {closed_at}.",
                    "subject": "Cierre de un ticket de mantenimiento - Fix: #{id} - {locationName}"
                },
                "created": {
                    "introduction": "Se acaba de crear el siguiente Fix ticket:",
                    "link": "Enlace de ticket:",
                    "name": "Nombre del ticket:",
                    "number": "Número de ticket:",
                    "subject": "Abrir un ticket de mantenimiento en - Fix: #{id} - {locationName}",
                    "type": "Tipo de mantenimiento:"
                },
                "link": "Ver el ticket :",
                "messageCreated": {
                    "introduction": "{updater} acaba de escribir un mensaje en el ticket Fix: #{id} {title} - {location} the {created_at}.",
                    "subject": "Se ha añadido un mensaje a un ticket de mantenimiento - Fix: #{id} - {locationName}"
                },
                "reminder": "No olvides la importancia de comentar los tickets, para poder seguir su evolución y controlarlos más de cerca.",
                "reopened": {
                    "introduction": "El ticket Fix: #{id} {title} - {location} fue reabierto por {opener} en {updated_at}.",
                    "subject": "Reapertura de un ticket de mantenimiento - Fix: #{id} - {locationName}"
                },
                "updated": {
                    "introduction": "El ticket Fix: #{id} {title} - {location} fue modificado por {updater} el {updated_at}.",
                    "subject": "Modificación de un ticket de mantenimiento - Fix: #{id} - {locationName}"
                },
                "userQuoted": {
                    "introduction": "{el} expedidor acaba de citarle en el ticket Fix : #{id} {title} - {location} le {quoted_at}.",
                    "subject": "Cita en un ticket de mantenimiento - Fix: #{id} - {locationName}"
                }
            },
            "ResetPassword": {
                "lines": {
                    "call_to_action": "Haga clic en el enlace para cambiar la contraseña",
                    "expiration": "Este enlace expirará en {expirationTime} minutos",
                    "explanation": "Usted está recibiendo este mensaje porque hemos recibido una solicitud para cambiar su contraseña",
                    "no_action_required": "Si usted no inició esta solicitud, por favor ignore este mensaje"
                },
                "link": "Cambiar la contraseña",
                "subject": "Cambio de contraseña"
            },
            "SendRetrocessionEmployerPdf": {
                "cordially": "Sinceramente,",
                "explanation": "Le adjuntamos la Declaración de reembolso por recarga profesional en su punto de recarga doméstica.",
                "greetings": "Hola,",
                "payment": "La transferencia se realizará en los próximos días.",
                "subject": "Retrocesión de las recargas comerciales realizadas en su domicilio"
            },
            "SendRetrocessionPdf": {
                "cordially": "Sinceramente,",
                "explanation": "Se adjuntan los documentos de retrocesión del último trimestre.",
                "greetings": "Hola,",
                "payment": "La transferencia se realizará en los próximos días.",
                "subject": "Retroceso en el último trimestre - Freshmile"
            },
            "common": {
                "apologize": "Discúlpenos por cualquier inconveniente",
                "contact": "Si tiene alguna duda, puede ponerse en contacto con nosotros en la siguiente dirección:",
                "greetings": "Hola {firstname} {lastname}",
                "login": "Entrar",
                "thanks": "Gracias por usar {appname}!"
            },
            "fromName": "Freshmile"
        },
        "evses": {
            "administrative_status": {
                "available": "En servicio",
                "inoperative": "Mantenimiento",
                "outoforder": "Salga del orden",
                "planned": "Planificado",
                "removed": "Eliminado",
                "unknown": "No comunicante"
            },
            "charging_status": {
                "available": "Disponible en",
                "charging": "Cargando",
                "reserved": "Reservado",
                "unknown": "Carga desconocida"
            },
            "create": {
                "breadcrumb": "Puntos de carga",
                "cpoTitle": "Nuevo punto de carga con: name",
                "evseList": "Puntos de carga (lista)",
                "feedback": "El punto de carga se añadió con éxito",
                "locationTitle": "Nuevo punto de carga para: name",
                "title": "Nuevo punto de carga"
            },
            "edit": {
                "BOEditRef": "To change the reference of an evse after it has been created, please use the action \"Changer la ref d'un point de charge\" in the backoffice.",
                "feedback": "El punto de carga se actualizó con éxito",
                "title": "Editar"
            },
            "export-data-gouv": "data.gouv",
            "form": {
                "addConnector": "Añadir el conector",
                "addDevice": "Añadir el cargador",
                "addLan": "Añadir LAN",
                "administrative": "Estado administrativo",
                "applyCoordinates": "Aplicar las coordenadas GPS",
                "asyncSearch": "Search by EVSE (reference, name)",
                "connectors": "Conectores",
                "customRefAvailable": "La referencia está disponible",
                "customRefUnavailable": "La referencia no está disponible",
                "device": "Chargebox ID",
                "directions": "Direcciones",
                "duplicate": "Crear un duplicado n+1 de este punto de carga",
                "emi3": "eMI3 ID",
                "evseInfos": "Charging point information",
                "floorLevel": "Nivel",
                "hideMap": "Ocultar el mapa",
                "lan": "LAN en esta ubicación",
                "lanDeviceCreationError": "Error",
                "lanDeviceCreationSuccess": "El cargador se ha añadido con éxito",
                "latitude": "Latitud",
                "location": "Ubicación",
                "locationInfos": "Información sobre la ubicación",
                "locationPlaceholder": "Ubicación",
                "locations": "Ubicaciones",
                "longitude": "Longitud",
                "name": "Nombre personalizado",
                "newDeviceTitle": "Nuevo cargador en {name}",
                "optional": "(opcional)",
                "parkingType": "Tipo de estacionamiento",
                "physicalRef": "Referencia física",
                "ref": "REF",
                "showMap": "Mostrar mapa",
                "submit": "Guardar"
            },
            "list": {
                "addEvse": "Añade el punto de carga",
                "all": "Todos",
                "breadcrumb": "Puntos de carga",
                "byCpo": "Filtrar por ID de la red",
                "commissionedAt": "Puesta en marcha",
                "connectors": "Conectores",
                "cpoTitle": "Puntos de carga de {name}",
                "decommissionedAt": "Desmantelamiento",
                "device": "Cargador",
                "emi3": "eMI3 ID",
                "free": "Gratis",
                "location": "Ubicación",
                "locationTitle": "Puntos de carga de {name}",
                "name": "Nombre",
                "paying": "No es gratis",
                "reference": "Referencia",
                "status": "Estado",
                "tariffs": "Tarifas",
                "title": "Puntos de carga",
                "updatedAt": "Última actualización"
            },
            "operational_status": {
                "available": "Trabajando",
                "blocked": "Bloqueado",
                "inoperative": "Inoperante",
                "outoforder": "Fuera de servicio",
                "unknown": "Desconocido"
            },
            "show": {
                "administrative": "Administrativo",
                "administrativeDetails": "Estado administrativo",
                "chargeStarted": "La carga comenzó",
                "chargeStopped": "La carga se detuvo",
                "charging": "Cargando",
                "chargingDetails": "Estado de la carga",
                "connectors": "Conectores",
                "device": "Chargebox ID",
                "deviceIsConnected": "Conectado",
                "directions": "Direcciones",
                "emi3": "eMI3 ID",
                "floorLevel": "Nivel",
                "misc": "Diversos",
                "name": "Nombre",
                "noDescription": "No hay descripción",
                "noDeviceConnected": "No hay cargador conectado, PdC inalcanzable",
                "onGoingSessionStarted": "La sesión comenzó",
                "operational": "Operativo",
                "operationalDetails": "Estado operativo",
                "pairs": "Pares",
                "parkingRestrictions": "Restricciones de estacionamiento",
                "physicalReference": "Referencia física",
                "ref": "Referencia",
                "stopCharge": "Detener la carga"
            },
            "status": {
                "alive": "Vivo",
                "available": "Disponible en",
                "blocked": "Bloqueado",
                "charging": "Cargando",
                "inoperative": "Inoperante",
                "obstructed": "Obstruido",
                "outoforder": "Fuera de servicio",
                "planned": "Planificado",
                "removed": "Eliminado",
                "reserved": "Reservado",
                "test": "Prueba",
                "unavailable": "No disponible",
                "unknown": "No comunicante",
                "unobstructed": "Sin obstáculos"
            }
        },
        "fix": {
            "bot": {
                "automatic_action_close": {
                    "LanDevice_reconnected": "All charging point CBIDs have been reconnected. The fix ticket was closed automatically.",
                    "charge_completed": "A valid charge has been made on one of the charging points. The fix ticket was closed automatically.",
                    "connector_available": "No error is reported on the charging point connectors. The fix ticket was closed automatically.",
                    "inactive_emergency_issue": "Sin noticias del usuario o del contacto de emergencia, Freshmile considera el ticket Emergency Fix está resuelta. Se ha cerrado automáticamente."
                },
                "automatic_action_close_default": "The fix ticket was closed automatically.",
                "automatic_action_create_default": "The fix ticket was opened automatically.",
                "automatic_action_update_default": "The ticket fix has been changed automatically.",
                "chargeCompleted": "Charge completed",
                "chargeCompletedTitle": "\"COMPLETED + > 2min + > 500Wh\" on at least one Evse linked to a ticket has taken place",
                "connectorAvailable": "Connecteur operationnal",
                "connectorAvailableTitle": "When all Evse connectors linked to a ticket return a StatusNotificationRequest: NoError",
                "disabled": "Disabled",
                "lanDeviceConnected": "CBID connected",
                "lanDeviceConnectedTitle": "When all CBIDs of Evse linked to a ticket are (re)connected"
            },
            "create": {
                "addTask": "Add",
                "allOptions": "All options",
                "assignTo": "Assign ticket to",
                "autoManagement": "Automatic closing",
                "breadcrumb": "Create ticket",
                "clickUpload": "Click to choose a file",
                "dropUpload": "Drag files or click. Max size: {max} Kb",
                "fewerOptions": "Fewer options",
                "issueTitle": "Ticket title",
                "linkTo": "Link ticket to",
                "optionnalEntity": "Leave blank to assign ticket to network {operator}",
                "specificEvse": "Specify EVSEs (or the whole location by default)",
                "subscribeTo": "Subscribe users",
                "title": "Fix - New ticket",
                "titleScope": "Create ticket for {name}"
            },
            "dashboard": {
                "automaticActionsApplied": "Automatically closed",
                "createdAt": "Created {date} by {email}",
                "edit": "Edit",
                "entityFilter": "Search",
                "exportAll": "Export all",
                "filterSelection": "{number} entities selected",
                "new": "New ticket",
                "show": "Show",
                "showMoreBtn": "Show more",
                "statusFilter": "Filter by status",
                "title": "Fix - Dashboard",
                "updatedAt": "Updated {date} by {email}",
                "withMsg": "Incluye mensajes y descripciones"
            },
            "duplicate": {
                "duplicate": "Duplicate",
                "issueTitle": "Ticket title",
                "linkMessages": "Duplicate messages",
                "linkModels": "Duplicate entities",
                "linkTags": "Duplicate tags",
                "linkTasks": "Duplicate tasks",
                "success": "Ticket duplicated",
                "title": "Duplicate a ticket"
            },
            "emergency-issue-title": {
                "LOCKED_CABLE": "Emergency: Locked cable ({evseRef})",
                "SECURITY": "Emergency: Security issue ({evseRef})"
            },
            "entity": {
                "connectors": "Connectors",
                "devices": "CBID",
                "evses": "EVSE",
                "lans": "LAN",
                "locations": "Location",
                "networks": "Network",
                "status": "Status",
                "supervisors": "Assigned",
                "tags": "Tags"
            },
            "history": {
                "title": "Fix - History"
            },
            "list": {
                "action": "Actions",
                "assigned": "Assigned",
                "author": "Author",
                "automaticActionsApplied": "Automatic closing",
                "begin": "Start date",
                "breadcrumb0": "Corrective maintenance",
                "breadcrumb1": "Preventive maintenance",
                "breadcrumb3": "Information",
                "closeSelection": "Cerrar selección",
                "closedAt": "Closed on",
                "closedBy": "Closed by",
                "completedAt": "Completed on",
                "confirmClose": "Close ticket",
                "correctiveTitle": "Corrective maintenance tickets",
                "correctiveTitleScope": "Corrective maintenance tickets of {name}",
                "created": "Created on",
                "description": "Description",
                "end": "Due date",
                "entities": "Related entity",
                "historyTitleScope": "Ticket history of {name}",
                "incidentDate": "Incident on",
                "informationTitle": "Information",
                "informationTitleScope": "Information of {name}",
                "interventionAt": "Intervention on",
                "name": "Name",
                "preventiveTitle": "Preventive maintenance tickets",
                "preventiveTitleScope": "Preventive maintenance tickets of {name}",
                "startedAt": "Started on",
                "status": "Status",
                "tags": "Tags",
                "tasks": "Tasks",
                "title": "Title",
                "type": "Maintenance type",
                "updateSelection": "Selección de actualización",
                "updatedAt": "Updated on",
                "updatedBy": "Updated by"
            },
            "scope": {
                "createIssue": "Create a ticket",
                "ongoingIssues": "Open tickets : {count}"
            },
            "show": {
                "about": "Freshmile Fix, ticket #{id}",
                "activity": "Activity",
                "add": "Add",
                "addedBy": "Added by {email}",
                "attachments": "Attachments",
                "close": "Close ticket",
                "closedAt": "Closed on {date}",
                "closedAtBy": "Closed on {date} by {email}",
                "comments": "Comments",
                "completeClose": "You can complete missing dates before closing the ticket",
                "completedDate": "Resolution date",
                "confirmDelete": "Would you like to clear the value?",
                "confirmDeleteFile": "Would you like to delete the file?",
                "confirmDeleteTask": "Would you like to delete the task?",
                "deleteMessageAnswers": "Delete answers?",
                "deleteMessageTitle": "Would you like to delete the message?",
                "description": "Description",
                "download": "Download",
                "interventionDate": "Intervention date",
                "lastUpdate": "Updated on: {date}",
                "lastUpdateBy": "Updated on: {date} by {email}",
                "mailBody": "Hello,%0D%0A%0D%0AWe invite you to consult the ticket \"{name}\" : {url}%0D%0A%0D%0AThanks,",
                "messageComment": "Comment",
                "messageEdit": "Edit",
                "messageReply": "Reply",
                "messages": "Messages",
                "noMessage": "No messages yet",
                "noTask": "No tasks yet",
                "noUpload": "No files yet",
                "original": "Original ticket:",
                "reopen": "Reopen ticket",
                "replyTo": "Reply to {email}",
                "resolvedBy": "Resolved by {email} on {date}",
                "subscribe": "Subscribe",
                "subscribed": "Subscribers",
                "taskDescription": "Task description",
                "title": "Fix #{id}",
                "todoList": "To do list",
                "unsubscribe": "Unsubscribe",
                "update": "Update",
                "urlPrompt": "Enter a URL:"
            },
            "stats": {
                "emergency": {
                    "average_between_opening_intervention_emergencies": "Average emergency response time",
                    "emergency-by-evse-table": {
                        "title": "Clasificación de las estaciones con mayor número de averías de emergencia"
                    },
                    "title": "Estadísticas de mantenimiento Fix de emergencia",
                    "total_ticket_emergencies_fix": "Número total de solicitudes de reparación de emergencia creadas"
                },
                "maintenance": {
                    "issue-count-table": {
                        "title": "Clasificación de las estaciones con mayor número de averías"
                    },
                    "issue-type-table": {
                        "title": "Clasificación de los tipos de fallo de la red"
                    },
                    "title": "Estadísticas de mantenimiento Fix",
                    "total_tickets_fix_created_by_network": "Número total de tickets Fix creados",
                    "total_tickets_fix_resolve_by_network": "Número total de tickets Fix solucionados"
                },
                "network": {
                    "average_between_opening_intervention": "Tiempo medio de respuesta",
                    "average_failures_occurrence_resolution": "Plazo medio de resolución",
                    "evse-unavailability-table": {
                        "title": "Clasificación de los puntos de recarga con mayor tasa de indisponibilidad en horas"
                    },
                    "title": "Estadísticas generales de mantenimiento de la red"
                },
                "noIssue": "No Issue",
                "pdf": {
                    "fileName": "Fix – Estadísticas",
                    "issue_type": "Tipo de fallo",
                    "rate": "Tasa (número)"
                },
                "title": "Fix – Estadísticas"
            },
            "status": {
                "ABANDONED": "Abandoned",
                "AWAITING": "Waiting",
                "AWAITING_REPORT": "Awaiting Report",
                "BEING_PROCESSED": "Being processed",
                "COMPLETED": "Completed",
                "NEW": "New",
                "ONGOING": "Ongoing",
                "PLANNED": "Planned",
                "dashboard": {
                    "CORRECTIVE": {
                        "AWAITING": "Waiting",
                        "BEING_PROCESSED": "Being Processed",
                        "NEW": "New"
                    },
                    "PREVENTIVE": {
                        "AWAITING": "Scheduled",
                        "AWAITING_REPORT": "Awaiting report",
                        "ONGOING": "Supported",
                        "PLANNED": "To plan"
                    }
                }
            },
            "supervisor": {
                "closed": "Closed",
                "open": "Open",
                "title": "My tickets"
            },
            "tags": {
                "addTitle": "Add a tag",
                "color": "Colour",
                "confirmDelete": "Would you like to remove the tag?",
                "editTitle": "Edit tag",
                "title": "Tag management"
            },
            "title": "Fix",
            "toast": {
                "issue": {
                    "clipboard": "Link to ticket copied",
                    "updated": "Ticket updated"
                },
                "message": {
                    "delete": "Message deleted",
                    "updated": "Message updated"
                },
                "quote": {
                    "failed": "No se puede citar a un usuario"
                },
                "subscription": {
                    "add": {
                        "failed": "Failed to subscribe user to this issue",
                        "success": "User successfully subscribed"
                    },
                    "remove": {
                        "failed": "Failed to unsubscribe user from this issue",
                        "success": "User successfully unsubscribed"
                    }
                },
                "tag": {
                    "added": "Tag successfully created",
                    "deleted": "Tag deleted",
                    "updated": "Tag updated"
                },
                "task": {
                    "added": "Task added",
                    "deleted": "Task deleted",
                    "updated": "Task updated"
                },
                "upload": {
                    "added": "File(s) added",
                    "deleted": "File(s) deleted",
                    "ignored": "The following files were ignored because they are too heavy ({max} kB): {files}"
                }
            },
            "type": {
                "corrective": "Corrective maintenance",
                "information": "Information",
                "preventive": "Preventive maintenance",
                "soonLate": "Close delays and deadlines"
            }
        },
        "header": {
            "alerts": "Gestionar las alertas",
            "cdr": "CDRs",
            "contact": "Contacts",
            "dashboard": "Tablero de mandos",
            "device": "CBID",
            "evse": "PdC",
            "fix": {
                "dashboard": "Fix - Tablero de mandos",
                "history": "Fix - History",
                "stats": "Fix - Estadísticas",
                "tags": "Tags",
                "title": "Fix"
            },
            "generateQRcodes": "Generar códigos QR",
            "generateRetro": "Generator of retrocession .pdf",
            "location": "Ubicaciones",
            "locations_mass_import": "Importación masiva de las ubicaciónes",
            "logout": "Cierre de sesión",
            "network": "Redes",
            "others": "Otros",
            "reports": "Activaciones",
            "reservation": "Reservas",
            "retrocessionGroups": "Retrocession groups",
            "retrocessionLogs": "Retrocession logs",
            "retrocessions": "Retrocessions",
            "role": "Papel",
            "session": "Sesiones",
            "settings": "Ajustes",
            "stats": "Estadísticas",
            "statsInSubmenu": "General",
            "tariff": "Tarifas",
            "ticket": "Tickets",
            "translations": "Traducciones"
        },
        "languages": {
            "de": "Alemán",
            "en": "Inglés",
            "es": "Español",
            "fr": "Francés",
            "gb": "Inglés",
            "gn": "Desactivar la traducción",
            "nl": "Holandés",
            "undefined": "Sin definir"
        },
        "lans": {
            "create": {
                "breadcrumb": "Nueva LAN",
                "feedback": "LAN añadió con éxito",
                "title": "Nueva LAN en {name}"
            },
            "edit": {
                "breadcrumb": "Editar",
                "feedback": "LAN actualizado con éxito",
                "title": "Editar"
            },
            "form": {
                "addLan": "Añadir LAN",
                "availableEvses": "PdC disponible",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID está disponible",
                "chargeboxidUnavailable": "Chargebox ID no está disponible",
                "description": "Descripción",
                "deviceDefinition": "Opcional=> Definición de cargador",
                "deviceProtocol": "Protocolo",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "Editar LAN",
                "gateway": "Gateway",
                "gatewayIsInvalid": "Gateway inválida",
                "isDhcp": "¿Es el DHCP",
                "lan": "LAN",
                "lanCreation": "Nueva LAN",
                "lanCreationError": "Error",
                "lanCreationSuccess": "LAN añadió con éxito",
                "lanDescription": "Descripción de la LAN",
                "lanDeviceCreation": "Nueva LAN",
                "lanName": "Nombre",
                "localIp": "Dirección IP local",
                "localPort": "Puerto local",
                "model": "Modelo",
                "modelPlaceholder": "Modelo",
                "name": "Nombre",
                "newLanName": "LAN #",
                "offlineEvses": "PdC fuera de línea",
                "optional": "(opcional)",
                "proxy": "Proxy",
                "publicIp": "Dirección IP pública",
                "publicIpIsInvalid": "Dirección IP pública inválida",
                "publicPort": "Puerto público",
                "selectLocation": "Ubicación",
                "submit": "Añadir un nuevo cargador",
                "subnetMask": "Máscara de subred",
                "subnetMaskIsInvalid": "Máscara de subred inválida",
                "technicalInfos": "Información técnica",
                "unknownModel": "Modelo desconocido",
                "url": "URL",
                "wirelessLogic": "Wireless Logic"
            },
            "list": {
                "breadcrumb": "LAN",
                "create": "Añadir",
                "gateway": "Gateway",
                "lanDevices": "Cargadores",
                "name": "Nombre",
                "proxy": "Proxy",
                "publicIp": "Dirección IP pública",
                "subnetMask": "Máscara de subred",
                "title": "LAN de {name}",
                "updatedAt": "Última actualización"
            },
            "proxies": {
                "MAIN": "Main",
                "NONE": "None",
                "WIRELESSLOGIC": "Wireless Logic"
            },
            "show": {
                "addDevice": "Añadir CBID",
                "connectedDevices": "CBID conectados",
                "description": "Descripción",
                "deviceIsConnected": "Conectado",
                "edit": "Edita",
                "gateway": "Gateway",
                "lastUpdate": "Última actualización",
                "name": "Nombre",
                "nat": "NAT",
                "protocol": "Protocolo",
                "proxy": "Proxy",
                "publicIp": "Dirección IP pública",
                "subnetMask": "Máscara de subred",
                "url": "URL"
            }
        },
        "locations": {
            "create": {
                "breadcrumb": "Nueva ubicación",
                "cpoTitle": "Nueva ubicación en {name}",
                "feedback": "Ubicación añadida con éxito",
                "locationsList": "Ubicaciones",
                "title": "Nueva ubicación"
            },
            "edit": {
                "edit": "Editar",
                "feedback": "La ubicación se actualizó con éxito"
            },
            "form": {
                "addOpeningHourFor": "Agregar un horario para {day}",
                "address": "Dirección",
                "asyncSearch": "Buscar por nombre, dirección, ciudad, código postal",
                "chargingWhenClosed": "Cargando cuando está cerrado...",
                "city": "Ciudad",
                "country": "País",
                "countryPlaceholder": "País",
                "cpoPlaceholder": "Red",
                "directions": "Información de acceso",
                "displayMap": "Mostrar el mapa",
                "emi3": "eMI3 ID",
                "facilities": "Instalaciones",
                "facilitiesPlaceholder": "Instalaciones",
                "hideMap": "Ocultar el mapa",
                "informations": "Información",
                "invalidHourMessage": "Inválido",
                "latitude": "Latitud",
                "latitudePlaceholder": "Latitud",
                "localisation": "Ubicación",
                "longitude": "Longitud",
                "longitudePlaceholder": "Longitud",
                "name": "Nombre",
                "network": "Red",
                "openingHoursInfos": "Información sobre el horario de apertura",
                "optional": "(opcional)",
                "postalcode": "Código postal",
                "resetPosition": "Reajustar la posición",
                "submit": "Guardar",
                "time_zone": "Timezone",
                "twentyfourseven": "Abierto 24\/7",
                "type": "Tipo",
                "typePlaceholder": "Tipo"
            },
            "list": {
                "addLocation": "Añadir la ubicación",
                "breadcrumb": "Ubicaciones",
                "chargePoints": "Puntos de carga",
                "city": "Ciudad",
                "country": "País",
                "cpoId": "Identificación de la red",
                "cpoTitle": "Ubicaciones de {name}",
                "emi3": "eMI3 ID",
                "name": "Nombre",
                "timeZone": "Timezone",
                "title": "Ubicaciones",
                "type": "Tipo",
                "updatedAt": "Última actualización"
            },
            "show": {
                "address": "Dirección",
                "chargingWhenClosed": "Cargando cuando está cerrado",
                "city": "Ciudad",
                "country": "País",
                "cpo": "Red",
                "directions": "Información de acceso",
                "edit": "Edita",
                "emi3": "eMI3 ID",
                "facilities": "Servicios",
                "lastUpdate": "Última actualización",
                "localisation": "Ubicación",
                "location": "Ubicación",
                "locationInfos": "Información sobre la ubicación",
                "locations": "Ubicaciones",
                "no": "No",
                "noRegularHours": "No opening hours configured",
                "openingHoursInfos": "Horario de apertura",
                "twentyfourseven": "Abierto 24\/7",
                "type": "Tipo",
                "yes": "Sí"
            }
        },
        "login": {
            "email": "Correo electrónico",
            "intro": "Siga su red",
            "loginSubmit": "Conexión",
            "password": "Contraseña",
            "passwordReset": "Restablecer la contraseña"
        },
        "maintenance-alert": {
            "commons": {
                "list": {
                    "last-valid-session-date": "Dernière session valide"
                }
            },
            "downtime": {
                "list": {
                    "subtitle": "Lista de CBID que han perdido la comunicación al menos 12 horas",
                    "title": "Pérdidas de comunicación"
                }
            },
            "error-code": {
                "list": {
                    "subtitle": "Lista de conectores que han reportado un código de error desde hace al menos 1 hora",
                    "title": "Códigos de error"
                }
            },
            "no-energy-rate": {
                "list": {
                    "no-energy-count": "Núm sin consumo",
                    "no-energy-rate": "% sin consumo",
                    "subtitle": "Lista de CBID que tienen al menos un 20% de sesiones válidas con un consumo de energía de 0 kWh en los últimos 30 días.",
                    "title": "Ausencias de energía",
                    "with-energy-count": "Núm con consumo"
                }
            },
            "session-failure-rate": {
                "list": {
                    "failure-rate": "% inválidas (<30 días)",
                    "invalid-count": "Núm inválido",
                    "subtitle": "Lista de CBID que tienen al menos un 60% de sesiones inválidas en los últimos 30 días.",
                    "title": "Índices de falla de sesiones",
                    "valid-count": "Núm válido"
                }
            },
            "usage-rate": {
                "list": {
                    "session-count-last30d": "Núm de sesiones (<30 días)",
                    "session-count-prior-month": "Núm de sesiones (30;60 días)",
                    "subtitle": "Lista de CBID que han registrado una disminución de al menos el 50% en el uso en comparación con los últimos 30 días.",
                    "title": "Disminuciones de uso",
                    "usage-rate": "% de disminución"
                }
            }
        },
        "networks": {
            "list": {
                "breadcrumb": "Redes"
            }
        },
        "notifications": {
            "DOWNTIME": "Pérdidas de comunicación",
            "ERROR_CODE": "Códigos de errores",
            "NO_ENERGY_RATE": "Ausencias de energía",
            "SESSION_FAILURE_RATE": "Ratios de fallos de sesión",
            "USAGE_RATE": "Descensos de utilización",
            "confirmSelection": "Confirmar la selección",
            "selectAll": "Seleccione todo",
            "tabs": {
                "handled": "Procesado",
                "unhandled": "Pendiente"
            },
            "text": "Maintenance"
        },
        "ocpi": {
            "locationFacilities": {
                "AIRPORT": "Aeropuerto",
                "BUS_STOP": "Parada de autobús",
                "CAFE": "Café",
                "CARPOOL_PARKING": "Compartir coche",
                "FUEL_STATION": "Estación de servicio.",
                "HOTEL": "Hotel",
                "MALL": "Centro comercial",
                "MUSEUM": "Museo",
                "NATURE": "Naturaleza",
                "RECREATION_AREA": "Zona de recreo",
                "RESTAURANT": "Restaurante",
                "SPORT": "Deporte",
                "SUPERMARKET": "Supermercado",
                "TAXI_STAND": "Parada de taxis",
                "TRAIN_STATION": "Estación de tren",
                "WIFI": "Wifi"
            },
            "locationTypes": {
                "ON_STREET": "Aparcamiento en la calle",
                "OTHER": "Otros",
                "PARKING_GARAGE": "Aparcamiento de varias plantas",
                "PARKING_LOT": "Aparcamiento",
                "UNDERGROUND_GARAGE": "Aparcamiento subterráneo",
                "UNKNOWN": "Desconocido"
            },
            "logs": {
                "CLIENT": "Client",
                "REQUEST": "Request",
                "RESPONSE": "Response",
                "SERVER": "Server",
                "breadcrumb": "OCPI",
                "context": "Context",
                "date": "Date",
                "distant_operator": "Operator",
                "filterPlaceholder": "Search by message",
                "message": "Message",
                "method": "Method",
                "model": "Objeto",
                "module": "Modulo",
                "ocpi": "OCPI logs",
                "request_type": "Type",
                "title": "OCPI logs of {name}",
                "way": "Dirección"
            },
            "parkingRestrictions": {
                "CUSTOMERS": "Reservado para los huéspedes",
                "DISABLED": "Reservado para personas discapacitadas",
                "EV_ONLY": "Reservado para el EV",
                "MOTORCYCLES": "Reservado para motos o scooters",
                "PLUGGED": "Reservado para el EV mientras se carga"
            },
            "priceComponentTypes": {
                "DURATION": "Duration",
                "ENERGY": "Energía",
                "FLAT": "Tarifa plana",
                "PARKING_TIME": "Tiempo de estacionamiento",
                "STOP": "Stop",
                "TIME": "Tiempo"
            },
            "reservationStatus": {
                "ACTIVE": "Activo",
                "CANCELLED": "Cancelado",
                "ERROR": "Error",
                "FINISHED": "Terminado",
                "NOSHOW": "No Show"
            }
        },
        "ocpp": {
            "logs": {
                "REQUEST": "Request",
                "RESPONSE": "Response",
                "breadcrumb": "OCPP logs",
                "filterPlaceholder": "Search by message",
                "message": "Message",
                "protocol": "Protocolo",
                "requestType": "Tipo",
                "sentBy": "Emisor",
                "title": "OCPP logs de {name}"
            }
        },
        "operators": {
            "capabilities": {
                "CHARGING_PROFILE_CAPABLE": "Perfil de carga",
                "CREDIT_CARD_PAYABLE": "Punto de venta",
                "REMOTE_START_STOP_CAPABLE": "Cargar con el smartphone",
                "RESERVABLE": "Reserva",
                "RESET_CAPABLE": "Reiniciar",
                "RFID_READER": "RFID",
                "UNLOCK_CAPABLE": "Desbloqueo remoto",
                "info": {
                    "CHARGING_PROFILE_CAPABLE": "Próximamente",
                    "CREDIT_CARD_PAYABLE": "Los usuarios pueden pagar con una tarjeta de crédito o débito en el acto",
                    "REMOTE_START_STOP_CAPABLE": "Los usuarios pueden iniciar y detenerse remotamente desde el teléfono",
                    "RESERVABLE": "Los usuarios pueden reservar un espacio para cargar",
                    "RESET_CAPABLE": "La línea directa puede reiniciar el cargador a distancia",
                    "RFID_READER": "Los usuarios pueden empezar y parar con una tarjeta RFID",
                    "UNLOCK_CAPABLE": "La línea directa puede desbloquear los cables a distancia"
                }
            },
            "configuration": {
                "breadcrumb": "Configuraciones",
                "capability_set_by_parent": "No se puede cambiar porque se establece por el padre {emi3}",
                "manyEvsesWarning": "Todo el EVSE será actualizado... por favor espere",
                "needSubmit": "Por favor, guarde para confirmar los cambios",
                "technics": "Configuración técnica",
                "title": "Configuración de {name}"
            },
            "create": {
                "feedback": "La red se ha añadido con éxito",
                "subCPOTitle": "Nueva sub-red a {name}",
                "title": "Nueva red"
            },
            "edit": {
                "breadcrumb": "Editar",
                "feedback": "La red se actualizó con éxito",
                "title": "Editar"
            },
            "form": {
                "allowIssueClose": "Allow automatic closing of a ticket",
                "allowIssueCloseDesc": "La opción se ofrecerá cuando se cree el ticket. Si se cambia, no afectará a los boletos existentes.",
                "businessInfos": "Información de negocios",
                "businessName": "Nombre",
                "businessURL": "Página web",
                "comInterval": "Intervalo de comunicación",
                "commission": "Comisiones y gastos bancarios",
                "consoInterval": "Intervalo de valores medidos",
                "crc": "CRC",
                "defaultTariff": "Tarifas por defecto",
                "defaultTariffPlaceholder": "Tarifas por defecto",
                "defaultVAT": "IVA sobre las ventas por defecto",
                "detectionLoop": "Bucle de detección",
                "emi3IsUndefined": "eMI3 ID no definido",
                "emi3SuccessMessage": "está disponible",
                "emi3UnavailableMessage": "Este eMI3 ID no está disponible. Por favor, pruebe con otro",
                "generateEmi3": "Generar",
                "heartbeat_interval": "Intervalo de latidos del corazón",
                "inheritance": "Ampliar a las subredes",
                "initialState": "Estado inicial",
                "interopConfig": "Configuración de acceso",
                "invalidEmi3Message": "no es uno válido eMI3 ID. Por favor, inténtelo de nuevo",
                "maintenance": "Mantenimiento",
                "metervalues_sample_interval": "Intervalo de valores medidos",
                "name": "Nombre",
                "optional": "(opcional)",
                "parent": "Padre",
                "parentPlaceholder": "Seleccione un padre",
                "presence": "Dispositivo de detección",
                "privateEmi3": "ID de eMI3 privado",
                "publicEmi3": "ID de eMI3 público",
                "publicEmi3Placeholder": "Seleccione un eMI3 ID",
                "refPlaceholder": "Puede ser llenado con el nombre",
                "refUnavailableMessage": "Esta referencia no está disponible. Por favor, pruebe con otra",
                "retrocession": "Retrocesión",
                "retrocessionGroup": "Grupo de retrocesión",
                "sameAsParent": "Lo mismo que los padres",
                "sameAsPrivate": "Este operador",
                "segment": "Segment",
                "submit": "Guardar",
                "support": "Support",
                "timezone": "Zona horaria",
                "timezonePlaceholder": "Zona horaria"
            },
            "hotline": {
                "phoneNumber": "Número de teléfono",
                "schedule": "Horas",
                "schedules": {
                    "Mon-Fri 7\/22": "De lunes a viernes, de 7 a 10 de la noche",
                    "Mon-Fri 9\/17": "De lunes a viernes, de 9 a 17 horas",
                    "Mon-Fri H24": "De lunes a viernes, 24 horas",
                    "Mon-Sun 7\/22": "Todos los días, de 7 a 10 de la noche",
                    "Mon-Sun 9\/17": "Todos los días, de 9 a 5 de la tarde",
                    "Mon-Sun H24": "Todos los días, 24 horas"
                }
            },
            "list": {
                "VAT": "IVA o impuesto sobre las ventas",
                "breadcrumb": "Redes",
                "children": "Sub-redes",
                "create": "Añadir una red",
                "default_tariff": "Tarifa",
                "emi3": "eMI3 ID",
                "evses": "Puntos de carga",
                "exportWithchildrens": "Incluir a los niños",
                "linkedCPOTitle": "Linked networks of {name}",
                "locations": "Ubicaciones",
                "name": "Nombre",
                "network": "Red",
                "ongoingIssues": "Open tickets",
                "operator": "Red",
                "operatorsList": "Redes",
                "parent": "Padre",
                "parent_id": "IP padre",
                "parent_ref": "IP padre",
                "public_emi3": "ID de eMI3 público",
                "segment": "Segment",
                "subCPOTitle": "Sub-redes de {name}",
                "title": "Redes",
                "updated_at": "Última actualización"
            },
            "map": {
                "breadcrumb": "Mapa",
                "noLocation": "No hay ninguna ubicación en esta red",
                "options": {
                    "all": "Todos",
                    "charging": "Carga en curso",
                    "issue": "Fuera de servicio"
                },
                "title": "Mapa de: name"
            },
            "segments": {
                "COMPANIES": "Companies",
                "RESIDENTIAL": "Residential",
                "ROADS": "Roads",
                "SHOPS": "Shops",
                "UNINFORMED": "Not specified"
            },
            "show": {
                "VAT": "IVA",
                "business": "Otros",
                "businessName": "Nombre",
                "businessURL": "Página web",
                "crc": "Línea directa",
                "maintenance": "Mantenimiento",
                "parent": "Padre",
                "privateEmi3": "eMI3 ID privado",
                "publicEmi3": "eMI3 ID público",
                "segment": "Segment",
                "support": "Apoyo",
                "tariff": "Tarifa",
                "timezone": "Zona horaria",
                "undefined": "No está definido"
            }
        },
        "pagination": {
            "next": "Siguiente &raquo;",
            "previous": "&laquo; Anterior"
        },
        "passwords": {
            "password": "Al menos 6 letras o dígitos. Ambas contraseñas deben coincidir",
            "reset": "Restablecer la contraseña",
            "sent": "Correo electrónico enviado",
            "token": "Error",
            "user": "Contraseña o correo electrónico incorrectos"
        },
        "payment": {
            "iban": "Débito directo",
            "methods": {
                "expires:": "Expira en:",
                "invalid": "Inválido",
                "valid": "Valid"
            },
            "refunds": {
                "addFormTitle": "Make a refund",
                "alreadyProcessed": "Refund already processed",
                "amountExceedRefund": "Amount exceed refund price",
                "amountIncVat": "Amount (incl. VAT)",
                "confirmDelete": "Do you really want to delete this refund ?",
                "confirmDeleteTitle": "Delete refund",
                "created": "Refund created",
                "deleted": "Refund deleted",
                "editFormTitle": "Edit a refund",
                "edited": "Refund edited",
                "export": "Export refund",
                "highAmountWarn": "Refund above 100 {currency}",
                "inRetrocession": "Integrated in retrocession",
                "includeRetro": "To be included in retrocessions",
                "method": "Method",
                "moveConnection": "You need to be logged in in Move to be able to process refunds.",
                "processedAt": "Processed at",
                "reason": "Justification",
                "refund": "Refund",
                "refundIssue": "There might be an error with the stripe or prepaid account refund",
                "refunded": "Reembolsado",
                "session": "Refund for session from {date} on {evse_ref}",
                "stripeAutoRefund": "Automatic refund via Stripe is not possible, please refund the user manually.",
                "subscription_with_order": "Refund for subscription to plan \"{plan_name}\" (order from {order_date}).",
                "subscription_without_order": "Refund for subscription to plan \"{plan_name}\".",
                "token_with_order": "Refund for token n°{token_number} (order from {order_date}).",
                "token_without_order": "Refund for token n°{token_number}.",
                "way": {
                    "manual": "Manual",
                    "prepaid": "Prepaid",
                    "prepaid_account": "Prepaid",
                    "stripe": "Stripe"
                }
            },
            "status": {
                "AUTHORIZED": "Autorizado",
                "CANCELLED": "Cancelado",
                "CAPTURED": "Recuperado",
                "DONE": "Validado",
                "FAILED": "Fallido",
                "PENDING_USER": "Esperando",
                "REFUNDED": "Reembolsado"
            },
            "transaction_labels": {
                "credit": "Crédito en la cuenta de prepago:  {amount} {CURRENCY}",
                "credit_invoice_refund": "Reembolso de la factura {invoice_ref}",
                "debit": "Débito de la cuenta de prepago:  {amount} {CURRENCY}",
                "invoice": "Factura {ref}",
                "order": "Ordena {ref}",
                "payment": "Factura {invoice_ref} (Pago {payment_ref})",
                "session": "Sesión de carga {ref}",
                "subscription": "Suscripción {ref} (hasta {end_at})"
            }
        },
        "reservations": {
            "add": "Añadir",
            "authId": "Contrato",
            "authIdPlaceholder": "Should match a Move eMAID",
            "breadcrumb": "Reservas",
            "calendar": "Calendario",
            "cancel": "Cancelar",
            "cancelationIsSuccessful": "Reserva cancelada",
            "create": {
                "authId": "Contrato",
                "end": "Fin",
                "locationId": "Ubicación",
                "start": "Comienza",
                "submit": "Guardar",
                "title": "Añadir una reserva"
            },
            "creationIsSuccessful": "Se ha añadido una reserva",
            "day": "Día",
            "list": {
                "breadcrumb": "Reserva"
            },
            "location": "Ubicación",
            "locationTitle": "Reservas a: name",
            "month": "Mes",
            "noReservationsThatDay": "No hay reservas este día",
            "noReservationsThatMonth": "No hay reservas este mes",
            "noReservationsThatWeek": "No hay reservas esta semana",
            "reservation": "Reserva",
            "status": {
                "ACTIVE": "Activo",
                "CANCELLED": "Cancelado",
                "ERROR": "Error",
                "FINISHED": "Terminado",
                "NOSHOW": "No se muestra"
            },
            "time": "Tiempo",
            "title": "Reservas",
            "week": "Semana"
        },
        "retrocession-group-logs": {
            "list": {
                "breadcrumb": "Retrocession group logs",
                "created_at": "Date",
                "message": "Message",
                "retrocessionGroupName": "Retrocession group name",
                "scopeTitle": "Retrocession group logs from {name}",
                "title": "Retrocession group logs",
                "type": "Event",
                "user": "Supervisor"
            },
            "types": {
                "ChangeCommission": "Change in commission",
                "ChangeName": "Change of name",
                "ChangeNetwork": "Change the network",
                "ChangeRib": "Change of bank details"
            }
        },
        "retrocession-groups": {
            "create": {
                "breadcrumb": "Créer un groupe de retrocessions",
                "title": "Créer un groupe de retrocessions"
            },
            "edit": {
                "title": "Modifier un groupe de retrocessions"
            },
            "form": {
                "addExisting": "Add an existing retrocession group",
                "applyPastSessions": "Apply changes to past sessions",
                "applyPastSessionsMessage": "Be careful, this is a heavy operation that can take a few seconds or even a few minutes.",
                "assign": "Assign an existing retrocession group",
                "asyncSearch": "Select an existing group",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact (optional)",
                "create": "Create a new retrocession group",
                "general": "General",
                "group": "Retrocession group",
                "name": "Name"
            },
            "iban": {
                "bankAccountName": "Account Name",
                "bic": "BIC",
                "errorBic": "The BIC must be in a valid format of the SEPA zone",
                "errorIban": "The IBAN must be in a valid format of the SEPA zone",
                "iban": "IBAN",
                "modalDeleteConfirm": "The bank account details have been successfully deleted",
                "modalDeleteContent": "If you delete bank account details of the retrocession group, we will not be able to pay you your retrocession.",
                "modalDeleteError": "An error occurred while deleting the bank account details",
                "modalDeleteTitle": "Delete a bank account detail",
                "modalDeleteWarning": "Be careful, you are about to delete bank account details.",
                "modalUpdateTitle": "Edit the RIB of the retrocession group"
            },
            "list": {
                "breadcrumb": "Retrocession groups",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "created_at": "Creation date",
                "has_bank_info": "RIB completed",
                "name": "Retrocession group name",
                "nb_networks": "Number of networks",
                "title": "Retrocession groups"
            },
            "network": {
                "add": "Add",
                "continue": "Continue",
                "create": "Create a new group",
                "removeMessage": "You are about to remove the retrocession group from {name}. Removing the handover group from {name} also removes it from all of its subnets.",
                "removeTitle": "Remove the retrocession group",
                "replace": "Replace retrocession group"
            },
            "show": {
                "bankInfo": "Banking information",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "contactDetail": "Contact",
                "created_at": "Creation date",
                "general": "General informations",
                "name": "Retrocession group name",
                "rib": "RIB"
            },
            "toast": {
                "added": "The retrocession group has been successfully assigned",
                "removed": "The retrocession group has been successfully removed",
                "session-updated": "The sessions has been successfully updated"
            }
        },
        "retrocessions": {
            "exportRefundsOfRetrocession": "Exportación de reembolsos de retrocesión",
            "modal": {
                "amountExceedInitialAmount": "El monto de la retrocesión no puede exceder el precio inicial",
                "amountWithVat": "Monto con IVA",
                "createSuccess": "La retrocesión ha sido creada con éxito",
                "deleteSuccess": "La retrocesión ha sido eliminada con éxito",
                "editSuccess": "La retrocesión ha sido modificada con éxito",
                "reason": "Justificación",
                "retrocede": "Retroceder",
                "sessionRetrocession": "Retrocesión de sesión"
            },
            "refundOfRetrocession": "Reembolso de retrocesión"
        },
        "sessions": {
            "authorizationReasons": {
                "AUTHORIZED_BY_CPO": "CPO",
                "AUTHORIZED_BY_EMSP": "EMSP",
                "NOT_AUTHORIZED_BY_ANY_EMSP": "No EMSP",
                "NO_AUTHORIZATION_CHECK": "No hay control",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Proveedor de electricidad",
                "PAYMENT_TERMINAL": "POS",
                "UNKNOWN": "Desconocido",
                "description": {
                    "AUTHORIZED_BY_CPO": "Autorizado por la CPO",
                    "AUTHORIZED_BY_EMSP": "Autorizado por la EMSP",
                    "NOT_AUTHORIZED_BY_ANY_EMSP": "No autorizado por ninguna EMSP",
                    "NO_AUTHORIZATION_CHECK": "Autorización no solicitada por el cargador",
                    "ORDERED_BY_ELECTRICITY_SUPPLIER": "Orden recibida del proveedor de electricidad",
                    "PAYMENT_TERMINAL": "Punto de venta (terminal de pago en el sitio)"
                }
            },
            "create": {
                "agreementsList": "Acuerdos",
                "breadcrumb": "Añadir",
                "title": "Añadir acuerdo"
            },
            "csv": {
                "confirmTitle": "CSV de {from} a {to}",
                "default": "¿Confirma la exportación?",
                "retrocessionExporterTitle": "Export for retrocessions",
                "tooHeavy": "El archivo se está generando y se enviará a {email}"
            },
            "curve": {
                "breadcrumb": "Curvas",
                "title": "Curvas para #{ref}"
            },
            "edit": {
                "agreementsList": "Acuerdos",
                "breadcrumb": "Editar",
                "title": "Editar el acuerdo"
            },
            "failureReasons": {
                "AUTHORIZATION_TIMEOUT": "Tiempo de espera",
                "EMI3_MINIMUM_DURATION": "< 2 min",
                "INACTIVE": "Inactiva",
                "LOCATION_FULLY_BOOKED": "Espacio reservado",
                "NO_AUTHORIZATION": "No autorizado",
                "UNKNOWN": "Desconocido",
                "description": {
                    "AUTHORIZATION_TIMEOUT": "La autorización se ha agotado",
                    "EMI3_MINIMUM_DURATION": "Aplicación del umbral recomendado de eMI3",
                    "NO_AUTHORIZATION": "No autorizado por ninguna EMSP"
                }
            },
            "form": {
                "bridges": "A través de",
                "cpo": "Red",
                "emsp": "Proveedor",
                "emspPlaceholder": "Buscar un proveedor",
                "includeSubCpos": "Incluir sub-redes",
                "includeSubEmsps": "Incluir sub-proveedores",
                "isActive": "Activo",
                "ocpiModules": "Módulos OCPI",
                "searchPlaceholder": "Escriba aquí",
                "submit": "Guardar"
            },
            "list": {
                "active": "Activo",
                "addAgreement": "Añadir acuerdo",
                "all": "Todos",
                "authorizationReason": "Razón de la autorización",
                "breadcrumb": "Sesiones",
                "completed": "Completado",
                "connector": "Conector",
                "contrat": "Contrato",
                "cpoTitle": "Sesiones de {name}",
                "end": "Fin",
                "evse": "PdC",
                "evseTitle": "Sesiones de {name}",
                "failureReason": "Razón del fracaso",
                "free": "Gratis",
                "invalid": "Inválido",
                "kwh": "kWh",
                "locationTitle": "Sesiones de {name}",
                "no": "No",
                "ocpiModules": "Módulos OCPI",
                "ongoing": "En curso",
                "optionsSelected": "Opciones seleccionadas",
                "pending": "Pendiente",
                "price": "Precio",
                "source": "Origen",
                "start": "Inicio",
                "station": "Ubicación",
                "status": "Estado",
                "stopReceivedAt": "Billing",
                "tag": "Simbólico",
                "tariff": "Tarifa",
                "tariffPos": "Tariff POS",
                "title": "Sesiones",
                "withChildrenOperators": "Sesiones de las sub-redes",
                "yes": "Sí"
            },
            "show": {
                "agreements": "Acuerdos",
                "auth": "Autorización",
                "badge": "Simbólico",
                "bridges": "Puentes",
                "cpo": "Red",
                "curveBreadcrumb": "Curva",
                "curveTitle": "Curva para",
                "edit": "Edita",
                "emsp": "Proveedor",
                "energy": "Energía",
                "energyLabel": "Energía (kWh)",
                "false": "Falso",
                "finalConsumption": "Energía final",
                "fullPrice": "{amount} {currency}",
                "includeSubCpos": "Incluir sub-redes",
                "includeSubEmsps": "Incluir sub-proveedores",
                "isActive": "Activo",
                "isWhiteListed": "En la lista blanca",
                "lastPeriod": "El último período",
                "lastUpdate": "Última actualización=>",
                "noBridges": "Directo",
                "notEnoughChargingData": "No hay suficientes datos de carga para construir un gráfico",
                "ongoing": "En curso",
                "periodDate": "Fecha",
                "power": "Potencia",
                "powerLabel": "Potencia (kW)",
                "preTaxPrice": "{amount} sin impuestos, {vat}% IVA",
                "price": "Precio",
                "relations": "Relaciones",
                "statusIsInvalid": "La sesión fracasó. Sin cargo",
                "statusIsPending": "La sesión no ha comenzado todavía",
                "stopReceivedAt": "Billing date",
                "stopSession": "Detener la sesión",
                "stopSuccess": "La sesión se detuvo con éxito",
                "title": "Sesión de carga #{ref}",
                "true": "Verdadero"
            },
            "source": {
                "CPO_REMOTE_ORDER": "CPO",
                "EMSP_REMOTE_ORDER": "EMSP",
                "STATION": "Cargador"
            },
            "status": {
                "active": "En curso",
                "completed": "Terminado",
                "invalid": "Inválido",
                "pending": "Pendiente",
                "valid": "Válida"
            }
        },
        "settings": {
            "accessibleOperators": "Operadores accesibles (redes a las que tiene acceso)",
            "changeLang": "Cambiar el idioma",
            "currentPermission": "Tu papel actual:",
            "darkTheme": "Tema oscuro",
            "display": "Display",
            "fix": {
                "disabled": {
                    "detail": "No recibirá ninguna notificación",
                    "title": "Desactivado"
                },
                "fromDisabled": {
                    "toMonitoring": "Estarás suscrito a todas las entradas de tu red",
                    "toParticipating": "Estará suscrito a todas las entradas a las que esté asignado, y se dará de baja de todas las demás",
                    "toQuoting": "Se le dará de baja de todas las entradas, recibirá notificaciones cuando se le mencione en una entrada"
                },
                "fromMonitoring": {
                    "toDisabled": "Se le dará de baja de todas las entradas",
                    "toParticipating": "Estará suscrito a todas las entradas a las que esté asignado, y se dará de baja de todas las demás",
                    "toQuoting": "Se le dará de baja de todas las entradas, recibirá notificaciones cuando se le mencione en una entrada"
                },
                "fromParticipating": {
                    "toDisabled": "Se le dará de baja de todas las entradas",
                    "toMonitoring": "Estarás suscrito a todas las entradas de tu red",
                    "toQuoting": "Se le dará de baja de todas las entradas, recibirá notificaciones cuando se le mencione en una entrada"
                },
                "fromQuoting": {
                    "toDisabled": "Se le dará de baja de todas las entradas",
                    "toMonitoring": "Estarás suscrito a todas las entradas de tu red",
                    "toParticipating": "Estará suscrito a todas las entradas a las que esté asignado, y se dará de baja de todas las demás"
                },
                "isSubscribable": "No deseo que otros supervisores me suscriban a las entradas",
                "monitoring": {
                    "detail": "Por defecto, estará suscrito a todos los tickets de su red. Por lo tanto, recibirá notificaciones de cualquier actividad.",
                    "title": "Monitorización"
                },
                "participating": {
                    "detail": "Estará suscrito a todas las entradas a las que esté asignado, mencionado o suscrito.",
                    "title": "Participación"
                },
                "quoting": {
                    "detail": "Sólo recibirá notificaciones cuando se le mencione en un ticket o comentario. No se suscribirá a estas entradas.",
                    "title": "En el caso de una referencia"
                },
                "title": "Configuración de las notificaciones de correo para Fix"
            },
            "fixedHeight": "Fixed height (Scroll in the table)",
            "hasAtLeastOneEmspInfo": "* In Park you cannot select a provider",
            "others": "Others",
            "preview": "Preview",
            "reduceMargin": "Reduce margins",
            "success": "Preferencias guardadas",
            "switchPermission": "Cambiar el papel",
            "tables": "Tables",
            "title": "Ajustes",
            "truncateHeader": "Reduce column names"
        },
        "sidebar": {
            "items": {
                "actions": "Acciones",
                "affiliations": "Asignaciones",
                "agreements": "Acuerdos",
                "audit": "Actividad",
                "business": "Logs de trabajo",
                "cdrs": "CDRs",
                "configurations": "Configuraciones",
                "corrective": "Corrective maintenance",
                "costDetails": "Detalles del costo",
                "curve": "Curvas",
                "details": "Detalles",
                "devices": "Chargebox ID",
                "duplicate": "Duplicate",
                "edit": "Editar",
                "evses": "Puntos de carga",
                "fix": "Fix",
                "groups": "Grupos",
                "history": "History",
                "information": "Information",
                "lans": "LAN",
                "linked_networks": "Linked networks",
                "locations": "Ubicaciones",
                "logs": "Logs",
                "map": "Mapa",
                "ocpi": "Logs OCPI",
                "ocpp": "Logs OCPP",
                "preventive": "Preventive maintenance",
                "reservations": "Reservas",
                "sessions": "Sesiones",
                "subcpos": "Sub-redes",
                "tariffs": "Tarifas"
            }
        },
        "station-check": {
            "breadcrumb": {
                "logs": "Registros",
                "report": "Puesta en marcha"
            },
            "csv": {
                "confirmTitle": "Confirm your export"
            },
            "list": {
                "device_ok": "PdC Info correct",
                "location_ok": "Station Info correct",
                "nb_cbids": "Number of CBID",
                "nb_fail": "Fail",
                "nb_success": "Tested & successful",
                "nb_tested": "Tested",
                "title": "Commissioning of Terminals"
            },
            "logs": {
                "emptyEmailReplace": "Installer",
                "keys": {
                    "lan_device_commissioned": "CBID commissioned",
                    "lan_device_feedback_comment": "CBID Info Comment added",
                    "lan_device_feedback_comment_withdrawn": "CBID Info Comment withdrawn",
                    "lan_device_feedback_ok": "CBID Info correct",
                    "lan_device_feedback_resolved": "CBID feedback resolved",
                    "lan_device_feedbacks_all_done": "All CBID info processed",
                    "lan_device_test_is_online_ko": "Connection Test KO",
                    "lan_device_test_is_online_ok": "Connection Test OK",
                    "lan_device_test_remote_orders_ko": "Remote Commands Test KO",
                    "lan_device_test_remote_orders_ok": "Remote Commands Test OK",
                    "lan_device_validated": "CBID validated",
                    "report_archived_automatically": "Report archived automatically",
                    "report_archived_manually": "Report archived manually",
                    "report_location_feedback_ok": "Station Info correct",
                    "report_location_feedback_updated": "Station Info updated",
                    "report_status_incomplete": "Report Status incomplete",
                    "report_status_to_ko": "Report Status KO",
                    "report_status_to_ok": "Report Status OK"
                },
                "tableContact": "Related Contact",
                "tableMessage": "Message",
                "tableType": "Type",
                "title": "Activation logs of",
                "type": {
                    "LanDeviceFeedback": "Feedback",
                    "LanDeviceTest": "Tests",
                    "Location": "Station",
                    "Supervision": "Supervision"
                }
            },
            "show": {
                "address": "Address",
                "allPaid": "All paid",
                "archiveReport": "Archive this report",
                "archived": "Archived",
                "buttonValidateWithCommissioning": "Validate selection with commissioning",
                "buttonValidateWithoutCommissioning": "Validate selection without commissioning",
                "cbidUntested": "Untested",
                "comment": "Report",
                "commentHistory": "Global comments history",
                "commissioned": "In service",
                "company": "Company",
                "dateGeneration": "Status generation date",
                "dateUpdate": "Update date",
                "email": "Email address",
                "firstname": "First name",
                "fitter": "Fitter",
                "fitterSay": "Installer says",
                "informationsStation": "Station Information",
                "installerHistory": "Installer history",
                "is_online": "Connection test",
                "listCbidTested": "List of CBID OK",
                "listUntestedAndFailedCbid": "List of CBID NOK",
                "modalArchivedConfirmation": "The report has been successfully archived.",
                "modalArchivedConfirmationFailed": "The report could not be archived.",
                "modalArchivedContent": "You are about to archive this report.",
                "modalArchivedQuestion": "Do you want to continue?",
                "modalArchivedTitle": "Archive a Report",
                "modalCommentConfirmation": "The comment has been successfully marked as resolved.",
                "modalCommentConfirmationFailed": "Comment resolution failed.",
                "modalCommentContent": "You are about to mark this comment as resolved. It will disappear as a result.",
                "modalCommentQuestion": "Do you want to continue?",
                "modalCommentTitle": "Mark as resolved",
                "modalFailedCommissioningContent": "It seems the connection to the following terminals has been lost since the last test.",
                "modalFailedCommissioningTitle": "Terminal Commissioning Failed",
                "name": "Name",
                "noCbidToDisplay": "No CBID to display here",
                "noComments": "No comments",
                "numberOfCbid": "Number of CBID",
                "ocpp-numbers-missmatch": "Warning, the connector IDs do not seem to match, please check the logs.",
                "oneIsFree": "At least one is free",
                "phone": "Phone",
                "pointOfCharge": "Charge Points",
                "pricing": "Pricing",
                "remote_orders_ok": "Remote Orders Test",
                "report": "Report",
                "selectAllCbid": "Select all CBID",
                "showOcppLogs": "Show logs",
                "status": "Status",
                "statusOf": "Status of",
                "terminalComments": "Comments",
                "testerMostRecent": "Information of the most recent installer",
                "testsStatus": "Test status",
                "timestamp": "Timestamp",
                "toValidated": "To be validated",
                "validated": "validated",
                "waitingCommissioning": "Waiting for commissioning"
            },
            "status": {
                "archived": "Archived",
                "incomplete": "Incomplete",
                "nok": "NOK",
                "ok": "Ready"
            }
        },
        "stats": {
            "CO2AvoidedEmissions": "CO2",
            "CarbonMonoxideAvoidedEmissions": "CO",
            "DistanceAvoidedThermalVehicle": "Distancia evitada en vehículos convencionales",
            "HydrocarbonAndNoxAvoidedEmissions": "Hidrocarburos y NOx",
            "IncomeByNetwork": "Ventas por red (incl. IVA)",
            "IncomeByProvider": "Ventas por proveedor (incluido el IVA)",
            "IncomePerLocation": "Ventas por ubicación (incl. IVA)",
            "MTBF": "MTBF",
            "MTTR": "MTTR",
            "NoxAvoidedEmissions": "NOx",
            "ParticleAvoidedEmissions": "Partículas",
            "SavedFuel": "Consumo de combustible evitado",
            "SessionsByConnectorType": "Sesiones por conector",
            "SessionsByConsumption": "Sesiones por consumo",
            "SessionsByDay": "Sesiones por día",
            "SessionsByDuration": "Sesiones por duración",
            "SessionsByHours": "Sesiones por horas",
            "SessionsByMonth": "Sesiones por mes",
            "SessionsByNetwork": "Sesiones por red",
            "SessionsByPower": "Sesiones por la potencia",
            "SessionsByProvider": "Sesiones por proveedor",
            "SessionsPerLocation": "Sesiones por lugar",
            "availability": "Disponibilidad",
            "availabilityDuration": "Duración de la disponibilidad",
            "average": "Promedio",
            "avoidedEmissions": "Emisiones evitadas",
            "descriptions": {
                "AberrationRate": "Tasa de aberración",
                "AverageChargingDuration": "Mientras el vehículo extrae energía de la estación",
                "AverageConnectionDuration": "Mientras el vehículo está estacionado y conectado a la estación, incluso cuando ha dejado de cargarse",
                "AverageConsumptionBySession": "Consumo medio por sesión",
                "CO2AvoidedEmissions": "Emisiones de CO2 evitadas gracias al uso de EVs cargados en las estaciones",
                "CarbonMonoxideAvoidedEmissions": "Emisiones de CO evitadas gracias al uso de EVs que se cargan en las estaciones",
                "CleanSessionNumber": "Número de sesiones calificadas",
                "DistanceAvoidedThermalVehicle": "Estimación de la distancia recorrida por los EVs que se cargan en las estaciones",
                "EffectiveChargingOverConnectionRate": "Relación entre el tiempo de carga real y el tiempo total de conexión",
                "ExcludedSession": "Sesiones excluidas",
                "HydrocarbonAndNoxAvoidedEmissions": "Los hidrocarburos y los NOx evitaron las emisiones",
                "MTBF": "Tiempo medio entre fallos",
                "MTTR": "Tiempo medio de reparación",
                "NoxAvoidedEmissions": "Emisiones de NOx evitadas gracias al uso de EVs que se cargan en las estaciones",
                "ParticleAvoidedEmissions": "Emisiones de partículas evitadas gracias al uso de EVs que se cargan en las estaciones",
                "SavedFuel": "Las cifras se basan en la mezcla actual de vehículos convencionales en la Unión Europea",
                "SessionNumber": "Número de sesiones",
                "SuccessConsumption": "Consumo de sesiones calificadas",
                "TotalChargingDuration": "Mientras el vehículo extrae energía de la estación",
                "TotalConnectionDuration": "Mientras el vehículo está estacionado y conectado a la estación, incluso cuando ha dejado de cargarse",
                "TotalConsumption": "Consumo de todas las sesiones",
                "availabilityDuration": "Duración de la disponibilidad",
                "availability_device_count": "Estaciones",
                "availability_evse_count": "Puntos de carga",
                "averageIncome": "Costo promedio de las sesiones de pago. Las sesiones gratuitas están excluidas",
                "average_charge_time": "Mientras el vehículo extrae energía de la estación",
                "average_plugin_time": "Mientras el vehículo está estacionado y conectado a la estación, incluso cuando ha dejado de cargarse",
                "device_offline_duration": "Estaciones - duración fuera de línea",
                "device_offline_duration_rate": "Estaciones - tasa fuera de línea",
                "evse_available_duration_rate": "Puntos de carga - tasa de disponibilidad",
                "evse_failure_count": "Problemas",
                "evse_unavailable_duration": "Puntos de carga - duración de indisponibilidad",
                "evse_unavailable_duration_rate": "Puntos de carga - tasa de indisponibilidad",
                "failed_session_count": "Número de sesiones consideradas como \"fallidas\" (< 2 min o < 500 Wh)",
                "failed_session_rate": "Relación sesiones \"fallidas\" \/ número total de sesiones",
                "numberOfIssues": "Número de problemas",
                "offlineDuration": "Duración fuera de línea",
                "sessionIssueRatio": "Tasa de las sesiones con problemas",
                "successful_session_consumption": "Consumo total de sesiones calificadas",
                "successful_session_count": "Las sesiones calificadas son sesiones de carga de más de 2 minutos",
                "successful_session_rate": "Relación sesiones calificadas \/ número total de sesiones",
                "totalIncome": "Volumen de ventas total",
                "total_charge_time": "Mientras el vehículo extrae energía de la estación",
                "total_plugin_time": "Mientras el vehículo está estacionado y conectado a la estación, incluso cuando ha dejado de cargarse",
                "total_session_consumption": "El consumo total",
                "total_session_count": "Las estadísticas se basan únicamente en el número de sesiones calificadas",
                "unavailabilityDuration": "Duración de la indisponibilidad"
            },
            "g": "g",
            "ie": "es decir",
            "kg": "kg",
            "km": "km",
            "l": "litros",
            "labels": {
                "LessThan10": "< 10",
                "LessThan120": "< 120",
                "LessThan1440": "< 1440",
                "LessThan15": "< 15",
                "LessThan20": "< 20",
                "LessThan240": "< 240",
                "LessThan30": "< 30",
                "LessThan40": "< 40",
                "LessThan480": "< 480",
                "LessThan5": "< 5",
                "LessThan60": "< 60",
                "LessThan720": "< 720",
                "MoreThan1440": "> 1440",
                "MoreThan60": "> 60",
                "SessionsByConsumption": {
                    "_0_5_kwh": "0 ≤ kWh < 5",
                    "_10_20_kwh": "10 ≤ kWh < 20",
                    "_20_40_kwh": "20 ≤ kWh < 40",
                    "_40_60_kwh": "40 ≤ kWh < 60",
                    "_5_10_kwh": "5 ≤ kWh < 10",
                    "_60_kwh": "≥ 60 kWh"
                },
                "SessionsByDuration": {
                    "_0_15_min": "0 ≤ min < 15",
                    "_120_240_min": "2 ≤ h < 4",
                    "_1440_min": "≥ 24 h",
                    "_15_30_min": "15 ≤ min < 30",
                    "_240_480_min": "4 ≤ h < 8",
                    "_30_60_min": "30 ≤ min < 60",
                    "_480_720_min": "8 ≤ h < 12",
                    "_60_120_min": "1 ≤ h < 2",
                    "_720_1440_min": "12 ≤ h < 24"
                },
                "SessionsByPower": {
                    "_0_4_kw": "0 ≤ kW < 4",
                    "_100_150_kw": "100 ≤ kW < 150",
                    "_12_22_kw": "12 ≤ kW < 22",
                    "_150_kw": "≥ 150 kW",
                    "_22_50_kw": "22 ≤ kW < 50",
                    "_4_8_kw": "4 ≤ kW < 8",
                    "_50_100_kw": "50 ≤ kW < 100",
                    "_8_12_kw": "8 ≤ kW < 12"
                }
            },
            "liters": "litros",
            "mi": "millas",
            "miles": "millas",
            "noProvider": "Ningún proveedor (P&C, terminal de pago)",
            "noStats": "No hay estadísticas disponibles",
            "noStatsMessage": "No hay datos para este período",
            "numberOfIssues": "Número de ejemplares",
            "numberOfSessions": "{number} sesiones",
            "offlineDuration": "Duración fuera de línea",
            "pdf": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "HydrocarbonAndNoxAvoidedEmissions": "Hidrocarburos y NOx",
                "IncomeByNetwork": "Ventas por red (incl. IVA)",
                "IncomeByProvider": "Ventas por proveedor (incl. IVA)",
                "IncomePerLocation": "Ventas por ubicación (incl. IVA)",
                "LessThan10kWh": "≤ 10 kWh",
                "LessThan12": "≤ 12 kW",
                "LessThan12kW": "≤ 12 kW",
                "LessThan20kWh": "≤ 20 kWh",
                "LessThan22": "≤ 22 kW",
                "LessThan22kW": "≤ 22 kW",
                "LessThan4": "≤ 4 kW",
                "LessThan40kWh": "≤ 40 kWh",
                "LessThan4kW": "≤ 4 kW",
                "LessThan5kWh": "≤ 5 kWh",
                "LessThan60kWh": "≤ 60 kWh",
                "LessThan8": "≤ 8 kW",
                "LessThan8kW": "≤ 8 kW",
                "MWh": "MWh",
                "MoreThan22": "Todas las sesiones",
                "MoreThan22kW": "Todas las sesiones",
                "MoreThan60kWh": "Todas las sesiones",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Partículas",
                "SessionsByConnectorType": "Desglose por conector",
                "SessionsByConsumption": "Sesiones por consumo",
                "SessionsByConsumptionTitle": "Sesiones por consumo",
                "SessionsByDay": "Sesiones por día",
                "SessionsByDayTitle": "Sesiones por día",
                "SessionsByDuration": "Sesiones por duración",
                "SessionsByDurationTitle": "Sesiones por duración",
                "SessionsByHours": "Sesiones por horas",
                "SessionsByMonth": "Sesiones por mes",
                "SessionsByMonthTitle": "Sesiones por mes",
                "SessionsByNetwork": "Sesiones por red",
                "SessionsByPower": "Sesiones por la potencia",
                "SessionsByPowerTitle": "Sesiones por la potencia",
                "SessionsByProvider": "Sesiones por proveedor",
                "SessionsHistogramme": "Tabla de ocupación",
                "SessionsPerLocation": "Sesiones por lugar",
                "aggregatedDuration": "Duración agregada",
                "amountCurrency": "Cantidad en {currency}",
                "availability": "Disponibilidad",
                "average": "Promedio",
                "averageBasket": "Precio medio pagado",
                "averageChargeTime": "Tiempo medio de carga",
                "averageCostBySession": "Costo promedio por sesión",
                "averageDuration": "Duración media de la carga",
                "averagePluginTime": "Duración media mientras está conectado",
                "chargeTime": "Duración de la sesión",
                "connector": "Conector",
                "consumption": "Consumo",
                "data": "Datos",
                "distanceAvoidedThermalVehicle": "Distancia evitada en vehículos convencionales",
                "duration": "Duración",
                "environmentImpact": "El impacto ambiental",
                "environmentInfos": "Datos medioambientales",
                "fileName": "cpo-stats-",
                "group": "Proveedor",
                "hhmmss": "h, m, s",
                "income": "Ventas (incl. IVA)",
                "kWh": "kWh",
                "kg": "kg",
                "km": "km",
                "lessThan15Min": "≤ 15 min",
                "lessThan1Hour": "≤ 1 hora",
                "lessThan2Hour": "≤ 2 horas",
                "lessThan30Min": "≤ 30 min",
                "lessThan4Hour": "≤ 4 horas",
                "liters": "litros",
                "location": "Ubicación",
                "mainIndicators": "Principales indicadores",
                "miles": "millas",
                "month": "Mes",
                "moreThan4Hour": ">  4 horas",
                "network": "Red",
                "nodata": "No hay suficientes datos",
                "number": "Número",
                "numberOfSessions": "Número de sesiones",
                "occupationRate": "Tasa de ocupación",
                "operator": "Proveedor",
                "others": "Otros",
                "percent": "Porcentaje",
                "period": "Período",
                "pluginTime": "Duración de la carga",
                "savedFuel": "Ahorro de combustible",
                "sessionSuccessRate": "Tasa de sesión calificada",
                "successfulSessions": "Sesiones calificadas",
                "title": "Estadísticas",
                "total": "Total",
                "weekDay": "Día de la semana"
            },
            "percentageOfDuration": "{percentage}% de la duración total",
            "q1": "Primer trimestre",
            "q2": "Segundo trimestre",
            "q3": "Tercer trimestre",
            "q4": "Cuarto trimestre",
            "rempConvVehicles": "Datos medioambientales",
            "selectNetworkPlaceholder": "Seleccione la red",
            "sessionIssueRatio": "Relación de emisión de la sesión",
            "sessionSimpleStats": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "DistanceAvoidedThermalVehicle": "Distancia evitada en vehículos convencionales",
                "EffectiveChargingOverConnectionRate": "Relación entre el tiempo de carga real y el tiempo total de conexión",
                "HydrocarbonAndNoxAvoidedEmissions": "Hidrocarburos y NOx",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Partículas",
                "SavedFuel": "Ahorro de combustible",
                "SessionNumber": "Número de sesiones",
                "average_charge_time": "Duración media de la carga",
                "average_conso_by_session": "Consumo medio por sesión",
                "average_plugin_time": "Duración media mientras está conectado",
                "chargingDuration": "Duración de la carga",
                "consumption": "Consumo",
                "failed_session_count": "Sesiones no cualificadas",
                "failed_session_rate": "Tasa de aberración",
                "income": "Ventas (incl. IVA)",
                "sessionDuration": "Duración de la sesión",
                "successful_session_consumption": "Consumo de sesiones calificadas",
                "successful_session_count": "Número de sesiones calificadas",
                "successful_session_rate": "Tasa de sesiones calificadas",
                "total_charge_time": "Duración total de la carga",
                "total_plugin_time": "Duración total mientras esté conectado",
                "total_session_consumption": "El consumo total",
                "total_session_count": "Número total de sesiones"
            },
            "successOnly": "Sólo sesiones calificadas",
            "title": "Estadísticas",
            "top5Remains": "Otros",
            "total": "Total",
            "totalIncome": "Ventas totales: {income}{currency}",
            "totalIncomeLabel": "Ventas totales",
            "unavailabilityDuration": "Duración de la indisponibilidad"
        },
        "stickers": {
            "bigStickers": "Include 10x10 stickers",
            "download": "Descargar",
            "evses": "Puntos de carga",
            "exemple": "Ejemplo de formato compatible:",
            "guide": "Para generar todos los códigos QR dentro de un pdf por favor copie y pegue su lista dentro del área de texto y haga clic en descargar. También puede mostrar una vista previa del PDF con la tecla de cambio",
            "noEvsesAvailable": "No hay PdC disponibles con este operador",
            "selectOperator": "Seleccione un operador para importar PdC",
            "showPreview": "Mostrar vista previa en PDF",
            "title": "Generador de código QR para PdC"
        },
        "supervision": {
            "default_error": "Error",
            "downloadRecentBrowser": "Por favor, actualice su navegador",
            "following_errors": "Errores:",
            "not_allowed": "Acceso denegado"
        },
        "table": {
            "columns": "Columnas",
            "count": "Un registro|Mostrando {from} a {to} de {count} registros|{count} registros",
            "defaultOption": "Seleccione {column}",
            "filter": "Filtro:",
            "filterBy": "Filtrar por {column}",
            "filterPlaceholder": "Buscar",
            "first": "Primero",
            "goToFirstPage": "Primero",
            "goToLastPage": "Último",
            "last": "Último",
            "limit": "Registros:",
            "loadPaging": "Ver el número de resultados",
            "loading": "Cargando...",
            "noFilter": "No se ha aplicado ningún filtro",
            "noResults": "No hay registros que coincidan",
            "page": "Página:",
            "undefined": "Sin definir"
        },
        "tariffs": {
            "create": {
                "breadcrumb": "Añadir la tarifa",
                "cpoTitle": "Nueva tarifa con {name}",
                "feedback": "Tarifas añadidas con éxito",
                "tariffList": "Tarifas",
                "title": "Añadir una nueva tarifa"
            },
            "edit": {
                "breadcrumb": "Editar",
                "feedback": "Tarifa actualizada con éxito",
                "tariffList": "Tarifas",
                "title": "Editar tarifas"
            },
            "form": {
                "cgv": "Términos y condiciones",
                "cpo": "Red",
                "currency": "Moneda",
                "currencyPlaceholder": "Moneda",
                "defaultTariffPlaceholder": "Buscar una tarifa",
                "description": "Descripción",
                "errorNameAlreadyExists": "A tariff with the same name already exists",
                "is_private": "Privado",
                "maxPrice": "Precio máximo",
                "name": "Nombre",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provisión incl. IVA",
                "refPlaceholder": "Puede ser llenado con el nombre",
                "refUnavailableMessage": "Esta referencia no está disponible. Por favor, pruebe con otra",
                "submit": "Guardar",
                "updatedAt": "Última actualización"
            },
            "list": {
                "addTariff": "Tarifa",
                "breadcrumb": "Tarifas",
                "cpoTitle": "Tarifas de: name",
                "currency": "Moneda",
                "elements": "Grupos de precios",
                "globalRestrictions": "Umbral de aplicación",
                "is_private": "Privado",
                "name": "Nombre",
                "network": "Red",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provisión incl. IVA",
                "title": "Tarifas",
                "updated_at": "Última actualización",
                "vat": "IVA"
            },
            "maxPrice": {
                "add": "Añadir",
                "confirmDelete": "¿Confirma la eliminación del precio máximo compartido?",
                "maxPrice": "Precio máximo",
                "maxPriceDeleted": "Precio máximo compartido suprimido",
                "maxPriceEdited": "El precio máximo compartido actualizado",
                "noMaxPrices": "No se ha definido un precio máximo",
                "searchAddGroup": "Buscar o añadir un grupo",
                "title": "Precio máximo compartido",
                "tooltipPriceDelete": "Eliminar el precio compartido"
            },
            "operators": {
                "CONSUMPTION": {
                    "<": "Consumo menor que",
                    "<=": "Consumo menor o igual que",
                    ">": "Consumo mayor que",
                    ">=": "Consumo mayor o igual que"
                },
                "DURATION": {
                    "<": "Duración menor que",
                    "<=": "Duración menor o igual que",
                    ">": "Duración mayor que",
                    ">=": "Duración mayor o igual que"
                },
                "and": "Y",
                "or": "O"
            },
            "prices": {
                "DURATION_description": "Billing is based on the connection time, regardless of the vehicle's consumption",
                "ENERGY_description": "La facturación depende del consumo",
                "FLAT_description": "La facturación incluye una cantidad fija",
                "PARKING_TIME_description": "La facturación depende del tiempo sin consumir energía",
                "STOP_description": "La carga se detendrá por encima de un cierto umbral",
                "TIME_description": "La facturación depende del tiempo mientras se extrae la energía",
                "addFormTitle": "Nuevo precio",
                "addTooltip": "Añadir un nuevo precio",
                "confirmDeletion": "¿Confirmar la eliminación del precio?",
                "created": "Precio añadido",
                "deleteTooltip": "Borrar el precio",
                "deleted": "Precio eliminado",
                "editFormTitle": "Editar el precio",
                "editTooltip": "Editar el precio",
                "edited": "Precio editado",
                "minutes": "minutos",
                "stepSize": "Paso",
                "type": "Tipo"
            },
            "restrictions": {
                "allDays": "Todos los días",
                "consumption": "Energía (kWh)",
                "created": "El grupo de precios se ha añadido con éxito",
                "date": "Fecha",
                "days": "Días",
                "deleteGroupTooltip": "Eliminar grupo",
                "duration": "Duración (minutos)",
                "editTooltip": "Editar las restricciones",
                "edited": "Restricción editada con éxito",
                "formTitle": "Restricciones",
                "info": "Los precios añadidos en este grupo obedecerán a las restricciones",
                "invalidConsumption": "Valor energético inválido",
                "invalidDays": "Seleccione al menos un día",
                "invalidDuration": "Valor de duración inválido",
                "invalidPower": "Valor de potencia inválido",
                "max": "Max",
                "min": "Min",
                "power": "Potencia (kW)",
                "price": "Precio",
                "removeDate": "Quitar la fecha",
                "time_after_consumption_end": "Time after the consumption has ended (minutes)",
                "updated": "Umbral actualizado"
            },
            "show": {
                "add": "Add",
                "addCondition": "Añade un umbral",
                "addPriceFormTitle": "Añadir el precio",
                "breadcrumb": "Tarifa",
                "cgvUrl": "URL términos y condiciones",
                "conditions": "Umbral de aplicación",
                "confirmGroupDeletion": "¿Confirmar la eliminación del grupo?",
                "confirmNotificationDeletion": "¿Confirmar la eliminación de notificaciones?",
                "confirmTariffDeletion": "¿Confirmar la eliminación de la tarifa?",
                "consumption": "Energía (Wh)",
                "costPerPowerUpdated": "Cost per average power updated",
                "cpo": "Red",
                "createGroup": "Añadir un nuevo grupo",
                "currency": "Moneda",
                "date": "Fecha",
                "days": "Días",
                "description": "Descripción",
                "duration": "Duración (minutos)",
                "edit": "Edita",
                "editPriceFormTitle": "Editar el precio",
                "elements": "Precios",
                "fixedPrice": "Fixed cost",
                "from": "De",
                "groupDeleted": "Grupo eliminado",
                "hours": "Programación",
                "invalidConsumption": "Valor energético inválido",
                "invalidDays": "Seleccione al menos un día",
                "invalidDuration": "Valor de duración inválido",
                "invalidPower": "Valor de potencia inválido",
                "is_private": "Privado",
                "lastUpdate": "Última actualización:",
                "max": "Max",
                "maxPrice": "Precio máximo",
                "min": "Min",
                "minutes": "minutos",
                "mustAddRestriction": "Los precios añadidos en este grupo estarán sujetos a restricciones",
                "name": "Nombre",
                "noCgv": "No hay términos y condiciones",
                "noConditions": "No hay umbral",
                "noDescription": "No hay descripción",
                "noPrices": "No hay precios",
                "notificationAdded": "Notificación de evolución arancelaria añadida",
                "notificationCantBeAdd": "No hay notificación aplicable. Existe una restricción relacionada con la potencia y\/o la energía para la tarifa",
                "notificationDeleted": "Notificación de la evolución de las tarifas suprimida",
                "notificationEmpty": "Sin notificación configurada",
                "notificationTitle": "Notificación de la evolución de las tarifas",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "popup": {
                    "atTimeEvolve": "a la vez",
                    "beforeAndAtTimeEvolve": "antes y en el momento",
                    "beforeEvolve": "antes de",
                    "chooseMomentTitle": "Elija cuándo recibirán la notificación los usuarios",
                    "evolveTariffText": "la evolución de las tarifas",
                    "minBefore": "minutos antes",
                    "minutes": "minutos",
                    "sendNotifText": "Enviar una notificación",
                    "sendNotifTitle": "Envío de la notificación",
                    "title": "Configurar una notificación sobre la tarifa"
                },
                "power": "Potencia (kW)",
                "powerMax": "Maximum power",
                "powerMin": "Minimum power",
                "powerTimeTitle": "Average power over time",
                "price": "Precio",
                "priceGroup": "Grupo: númber",
                "prices": "Precios",
                "provision": "Provisión incl. IVA",
                "restrictionEdited": "Restricción editada con éxito",
                "restrictionFormTitle": "Restricciones",
                "restrictionIntro": "Definir cuándo se aplica la tarifa",
                "restrictions": "Restricciones",
                "stepSize": "Paso",
                "tariffDeleted": "Aranceles suprimidos",
                "to": "A",
                "type": "Tipo",
                "weekDays": "Días de la semana",
                "weekEnd": "Fin de semana",
                "wh": "Wh"
            },
            "status": {
                "active": "Activos",
                "inactive": "Inactivos"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted",
            "active_url": "The {attribute} is not a valid URL",
            "after": "The {attribute} must be a date after {date}",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}",
            "alpha": "The {attribute} may only contain letters",
            "alpha_dash": "The {attribute} may only contain letters, numbers and dashes",
            "alpha_num": "The {attribute} may only contain letters and numbers",
            "array": "The {attribute} must be an array",
            "before": "The {attribute} must be a date before {date}",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items",
                "file": "The {attribute} must be between {min} and {max} kilobytes",
                "numeric": "The {attribute} must be between {min} and {max}",
                "string": "The {attribute} must be between {min} and {max} characters"
            },
            "boolean": "The {attribute} field must be true or false",
            "confirmed": "The {attribute} confirmation does not match",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "date": "The {attribute} is not a valid date",
            "date_format": "The {attribute} does not match the format {format}",
            "different": "The {attribute} and {other} must be different",
            "digits": "The {attribute} must be {digits} digits",
            "digits_between": "The {attribute} must be between {min} and {max} digits",
            "dimensions": "The {attribute} has invalid image dimensions",
            "distinct": "The {attribute} field has a duplicate value",
            "email": "The {attribute} must be a valid email address",
            "exists": "The selected {attribute} is invalid",
            "file": "The {attribute} must be a file",
            "filled": "The {attribute} field must have a value",
            "gt": {
                "array": "The {attribute} must have more than {value} items",
                "file": "The {attribute} must be greater than {value} kilobytes",
                "numeric": "The {attribute} must be greater than {value}",
                "string": "The {attribute} must be greater than {value} characters"
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more",
                "file": "The {attribute} must be greater than or equal {value} kilobytes",
                "numeric": "The {attribute} must be greater than or equal {value}",
                "string": "The {attribute} must be greater than or equal {value} characters"
            },
            "image": "The {attribute} must be an image",
            "in": "The selected {attribute} is invalid",
            "in_array": "The {attribute} field does not exist in {other}",
            "integer": "The {attribute} must be an integer",
            "ip": "The {attribute} must be a valid IP address",
            "ipv4": "The {attribute} must be a valid IPv4 address",
            "ipv6": "The {attribute} must be a valid IPv6 address",
            "json": "The {attribute} must be a valid JSON string",
            "lt": {
                "array": "The {attribute} must have less than {value} items",
                "file": "The {attribute} must be less than {value} kilobytes",
                "numeric": "The {attribute} must be less than {value}",
                "string": "The {attribute} must be less than {value} characters"
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items",
                "file": "The {attribute} must be less than or equal {value} kilobytes",
                "numeric": "The {attribute} must be less than or equal {value}",
                "string": "The {attribute} must be less than or equal {value} characters"
            },
            "max": {
                "array": "The {attribute} may not have more than {max} items",
                "file": "The {attribute} may not be greater than {max} kilobytes",
                "numeric": "The {attribute} may not be greater than {max}",
                "string": "The {attribute} may not be greater than {max} characters"
            },
            "mimes": "The {attribute} must be a file of type: {values}",
            "mimetypes": "The {attribute} must be a file of type: {values}",
            "min": {
                "array": "The {attribute} must have at least {min} items",
                "file": "The {attribute} must be at least {min} kilobytes",
                "numeric": "The {attribute} must be at least {min}",
                "string": "The {attribute} must be at least {min} characters"
            },
            "not_in": "The selected {attribute} is invalid",
            "not_regex": "The {attribute} format is invalid",
            "numeric": "The {attribute} must be a number",
            "present": "The {attribute} field must be present",
            "regex": "The {attribute} format is invalid",
            "required": "The {attribute} field is required",
            "required_if": "The {attribute} field is required when {other} is {value}",
            "required_unless": "The {attribute} field is required unless {other} is in {values}",
            "required_with": "The {attribute} field is required when {values} is present",
            "required_with_all": "The {attribute} field is required when {values} is present",
            "required_without": "The {attribute} field is required when {values} is not present",
            "required_without_all": "The {attribute} field is required when none of {values} are present",
            "same": "The {attribute} and {other} must match",
            "size": {
                "array": "The {attribute} must contain {size} items",
                "file": "The {attribute} must be {size} kilobytes",
                "numeric": "The {attribute} must be {size}",
                "string": "The {attribute} must be {size} characters"
            },
            "string": "The {attribute} must be a string",
            "timezone": "The {attribute} must be a valid zone",
            "unique": "The {attribute} has already been taken",
            "uploaded": "The {attribute} failed to upload",
            "url": "The {attribute} format is invalid"
        }
    },
    "fr": {
        "agreements": {
            "create": {
                "agreementsList": "Accords",
                "breadcrumb": "Créer",
                "feedback": "Accord créé",
                "title": "Créer un nouvel accord"
            },
            "edit": {
                "agreementsList": "Accords",
                "breadcrumb": "Modifier",
                "feedback": "Accord mis à jour",
                "title": "Modifier un accord"
            },
            "form": {
                "alreadyExists": "Accord déjà existant entre {cpo}- {emsp}",
                "cpo": "Réseau",
                "emsp": "Fournisseur",
                "emspPlaceholder": "Rechercher un fournisseur",
                "includeSubCpos": "Inclure les sous-réseaux",
                "includeSubEmsps": "Inclure les sous-fournisseurs",
                "isActive": "Actif",
                "ocpiModules": "Modules OCPI",
                "searchPlaceholder": "Ecrire ici",
                "submit": "Enregistrer",
                "tariffPlaceholder": "Pas de tarif défini",
                "viaRoaming": "Via plateforme d'intéropérabilité"
            },
            "list": {
                "addAgreement": "Créer un accord",
                "autoliquidation": "Autoliquidation",
                "breadcrumb": "Accords (liste)",
                "cpoId": "ID réseau",
                "directFilter": "Réseaux parents inclus",
                "emspId": "ID fournisseur",
                "includeSubCpos": "Inclure les sous-réseaux",
                "includeSubEmsps": "Inclure les sous-fournisseurs",
                "no": "Non",
                "noOcpiModules": "Pas de module OCPI",
                "ocpiModules": "Modules OCPI",
                "optionsSelected": "options sélectionnées",
                "tariff": "Tarif spécifique",
                "title": "Accords",
                "updated_at": "Mis à jour",
                "yes": "Oui"
            },
            "show": {
                "agreements": "Accords",
                "bridges": "Ponts",
                "cpo": "Réseau",
                "edit": "Modifier",
                "emsp": "Fournisseur",
                "false": "Faux",
                "includeSubCpos": "Inclure les sous-réseaux",
                "includeSubEmsps": "Inclure les sous-fournisseurs",
                "lastUpdate": "Mis à jour",
                "noBridges": "Direct",
                "true": "Vrai"
            }
        },
        "alert": {
            "alerts": "Alertes",
            "colorTitle": "Couleur du message",
            "confirmPublish": "Êtes-vous certain(e) de vouloir publier cette alerte ?",
            "confirmUnPublish": "Êtes-vous certain(e) de vouloir supprimer cette alerte ?",
            "createTitle": "Créer une nouvelle alerte",
            "currentAlert": "Alerte en cours",
            "delete": "Supprimer l'alerte en cours",
            "disableFailed": "Échec de la suppression de l'alerte",
            "disabled": "L'alerte a été supprimée",
            "editStatuses": "Modifier le statut des alertes",
            "editTitle": "Modifier l'alerte en cours",
            "messageClosed": "Vous pouvez retrouver ces alertes dans l’onglet “Paramètres”",
            "newAlert": "Nouvelle alerte",
            "publishFailed": "Échec de la publication de l'alerte",
            "publishSuccessful": "L’alerte a bien été publiée",
            "showOnlyActive": "Afficher uniquement les alertes actives",
            "title": "Gérer les alertes",
            "type": {
                "danger": "Incident",
                "info": "Information",
                "success": "Résolution",
                "warning": "Maintenance"
            },
            "unPublishFailed": "Échec de la suppression de l'alerte",
            "unPublishSuccessful": "L'alerte a été supprimée.",
            "updateFailed": "Échec de la suppression de l'alerte",
            "updated": "L'alerte à bien été modifiée"
        },
        "audit": {
            "ACTION": "Action réalisée",
            "CREATE": "Créé",
            "DELETE": "Supprimé",
            "READ": "Consulté",
            "RESTORE": "Restauré",
            "UPDATE": "Mis à jour",
            "UPDATE_TRANSLATION": "Traductions mises à jour",
            "breadcrumb": "Activité",
            "noActivity": "Pas d'activité",
            "title": "Activité de {name}",
            "via": {
                "BACKOFFICE": "Back-office",
                "CLI": "CLI",
                "JOBS": "Tâche automatique",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "SUPERVISION": "Supervision"
            }
        },
        "auth": {
            "confirmPassword": "Confirmer le mot de passe",
            "emailSent": "Email envoyé",
            "failed": "Identifiants erronés",
            "login": "Connexion",
            "passResetSuccess": "Mot de passe modifié",
            "permanent_ban": "Suite à un trop grand nombre de tentatives de connexion, votre compte a été désactivé. Veuillez contacter votre administrateur.",
            "register": "Inscription",
            "reset": "Modifier le mot de passe",
            "sendLink": "Envoi du lien pour modifier le mot de passe",
            "successLogout": "Déconnecté",
            "temporary_ban": "Vous avez effectué trop de tentatives de connexion : veuillez ré-essayer plus tard.",
            "throttle": "Trop de tentatives. Merci d'essayer à nouveau dans {seconds} secondes"
        },
        "breadcrumbs": {
            "operators": {
                "create": "Créer",
                "list ": "Réseaux (liste)",
                "operators": "Réseaux"
            }
        },
        "businessLogs": {
            "breadcrumb": "Logs",
            "byEvse": "Filter by EVSE",
            "context": "Context",
            "contextValues": {
                "CLI": "Server",
                "JOBS": "Task",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "OCPPJ": "OCPP-J",
                "SUPERVISION": "Supervision",
                "WEBSOCKET": "Websocket"
            },
            "diagnosticStatus": {
                "UploadFailed": "Failed to send diagnostic",
                "Uploaded": "Diagnostic successfully sent"
            },
            "event": "Event",
            "eventValues": {
                "ALREADY_COMPLETED_SESSION_ENDED": "End of already completed session",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Price asked by already completed session",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Energy updated by already completed session",
                "ALREADY_COMPLETED_SESSION_STARTED": "Start of already completed session",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "Rearming device",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "Start outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Change availability",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Clear cache",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Get diagnostics",
                "ATTEMPTING_REMOTE_RESET": "Reset",
                "ATTEMPTING_REMOTE_START": "Remote start",
                "ATTEMPTING_REMOTE_STOP": "Remote stop",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Unlock",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Update firmware",
                "CONNECTOR_STATUS_UPDATED": "Connector status",
                "DETECTION_SENSOR_BLOCKED": "Detection sensor blocked",
                "DETECTION_SENSOR_IGNORED": "Detection sensor ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Detection sensor unblocked",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Detection sensor unknown connector",
                "DIAGNOSTIC_STATUS": "Diagnostic status",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "Device rearmed successfully",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "Failure to rearm device",
                "ERCOGENER_START_OUTLET_FAILURE": "Outlet started successfully",
                "ERCOGENER_START_OUTLET_SUCCESS": "Failure to start outlet",
                "EVSE_STATUS_UPDATED": "EVSE status",
                "FIRMWARE_STATUS": "Firmware status",
                "LAN_DEVICE_BOOTED": "Charger boot",
                "LAN_DEVICE_DOWN": "Charger offline",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Firmware updated",
                "LAN_DEVICE_MODEL_CHANGE": "Model updated",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Protocol updated",
                "LAN_DEVICE_UP": "Charger online",
                "LAN_DEVICE_VENDOR_CHANGE": "Vendor updated",
                "LAN_PUBLIC_IP_CHANGE": "IP updated",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "Payment terminal offline failure report",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "Payment terminal offline success report",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment terminal online failure report",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment terminal online success report",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Change availability failure",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Change availability success",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Failed to get diagnostics",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Diagnostics successfully got",
                "REMOTE_RESET_FAILURE": "Failed to reset",
                "REMOTE_RESET_SUCCESS": "Successfully reset",
                "REMOTE_START_FAILURE": "Remote start failure",
                "REMOTE_START_SUCCESS": "Remote start success",
                "REMOTE_STOP_FAILURE": "Remote stop failure",
                "REMOTE_STOP_SUCCESS": "Remote stop success",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Unlock failure",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Unlock success",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Update firmware failure",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Update firmware success",
                "SESSION_AUTHORIZED": "Session authorized",
                "SESSION_DENIED": "Session denied",
                "SESSION_ENDED": "Session ended",
                "SESSION_ENERGY_UPDATED": "Energy updated",
                "SESSION_FORCED": "Session forced",
                "SESSION_STARTED": "Session started",
                "SESSION_START_BLOCKED": "Session start blocked",
                "SESSION_TIMED_OUT": "Session timed out",
                "SESSION_TOTAL_COST_UPDATED": "Price",
                "TOKEN_AUTHORIZE_ACCEPTED": "Badge autorisé",
                "TOKEN_AUTHORIZE_DENIED": "Badge refusé",
                "TOKEN_AUTHORIZE_UNKNOWN": "Badge non reconnu",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector status",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session ended",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Energy updated by unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session started"
            },
            "evseStatusUpdated": {
                "administrative": "Administrative status set from {previous_status} to {current_status}",
                "charging": "Charging status set from {previous_status} to {current_status}",
                "message": "EVSE {name} set from {previous_status} to {current_status}. Reason: {status_reason}",
                "operational": "Operational status set from {previous_status} to {current_status}"
            },
            "filterPlaceholder": "Search by context",
            "firmwareStatus": {
                "DownloadFailed": "Download firmware failure",
                "Downloaded": "Download firmware success",
                "InstallationFailed": "Install firmware failure",
                "Installed": "Install firmware success"
            },
            "lanDeviceStatus": {
                "down": "is no longer connected to the server",
                "up": "is now connected to the server"
            },
            "level": {
                "ALERT": "Alert log",
                "CRITICAL": "Critical log",
                "DEBUG": "Debug log",
                "EMERGENCY": "Emergency log",
                "ERROR": "Error log",
                "INFO": "Info log",
                "NOTICE": "Notice log",
                "WARNING": "Warning log"
            },
            "message": "Message",
            "messages": {
                "ALREADY_COMPLETED_SESSION_ENDED": "Session {sessionLink}, already ended, has been ended by charger {chargeboxid}",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Charger {chargeboxid} is asking price for session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Charger {chargeboxid} updated consumption of session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_STARTED": "Session {sessionLink} already ended has been authorized by charger {chargeboxid}",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "(Ercogener) Attempt to rearm device {chargeboxid}",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "(Ercogener) Attempt to start outlet",
                "ATTEMPTING_ERCOGENER_STOP_OUTLET": "(Ercogener) Attempt to stop outlet",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Attempt to set station {chargeboxid} to state {availability}",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Attempt to clear cache of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Attempt to get diagonistcs of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_RESET": "Attempting to reset charger {chargeboxid}",
                "ATTEMPTING_REMOTE_START": "Attempting to start a session on charger {chargeboxid} and on connector {connector_id}",
                "ATTEMPTING_REMOTE_STOP": "Attempting to stop session {session} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Attempting to unlock connector {connector_id} on charger {chargeboxid}",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Attempting to update firmware of charger {chargeboxid} (filename: {filename})",
                "CONNECTOR_STATUS_UPDATED": "is set from {previous_status} to {current_status}",
                "DETECTION_SENSOR_BLOCKED": "An obstacle is detected on EVSE {evse}",
                "DETECTION_SENSOR_IGNORED": "Some information of charger {chargeboxid} about an obstacle was ignored",
                "DETECTION_SENSOR_UNBLOCKED": "Obstacle on EVSE {evse} removed",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Charger {chargeboxid} detected an obstacle on unknown connector {connector_id}",
                "DIAGNOSTIC_STATUS": "Charger {chargeboxid} {status}",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "(Ercogener) Failed to rearm device {chargeboxid}. Reason: {reason}",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "(Ercogener) Device {chargeboxid} rearmed",
                "ERCOGENER_START_OUTLET_FAILURE": "(Ercogener) Failed to start outlet. Reason: reason",
                "ERCOGENER_START_OUTLET_SUCCESS": "(Ercogener) Outlet started",
                "ERCOGENER_STOP_OUTLET_FAILURE": "(Ercogener) Failed to stop outlet. Reason: reason",
                "ERCOGENER_STOP_OUTLET_SUCCESS": "(Ercogener) Outlet stopped",
                "FIRMWARE_STATUS": "Charger {chargeboxid} {status}",
                "LAN_DEVICE": "Charger {chargeboxid} {lan_device_status}",
                "LAN_DEVICE_BOOTED": "Charger {chargeboxid} booted",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Charger firmware {chargeboxid} is now {current_firmware} (beforehand {previous_firmware})",
                "LAN_DEVICE_MODEL_CHANGE": "Charger model {chargeboxid} is now {current_model} (beforehand {previous_model})",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Charger {chargeboxid} now uses {current_protocol} (beforehand {previous_protocol})",
                "LAN_DEVICE_VENDOR_CHANGE": "Charger vendor {chargeboxid} is now {current_vendor} (beforehand {previous_vendor})",
                "LAN_PUBLIC_IP_CHANGE": "LAN {lan} now has public IP {current_ip} (beforehand {previous_ip})",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "During an offline period, payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId}). Price: {price}",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-danger\"> failed <\/strong> on point of sale (ticket id {ticketId})",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId})",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Failed to set charger {chargeboxid} state to {availability}. Reason: {reason}",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Charger {chargeboxid} successfully set to state {availability}",
                "REMOTE_CLEAR_CACHE_FAILURE": "Failed to clear cache of charger {chargeboxid}. Reason: {reason}",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache of charger {chargeboxid} successfully cleared",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Charger {chargeboxid} refused get diagnostics command. Reason: {reason}",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Charger {chargeboxid} accepted order, diagnostic will be generated soon",
                "REMOTE_RESET_FAILURE": "Failed to reset charger {chargeboxid}. Reason: {reason}",
                "REMOTE_RESET_SUCCESS": "Charger {chargeboxid} successfully reset",
                "REMOTE_START_FAILURE": "Failed to start a session on connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_START_SUCCESS": "Session start successfully allowed on connector {connector_id} on charger {chargeboxid}",
                "REMOTE_STOP_FAILURE": "Failed to stop session {session} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_STOP_SUCCESS": "Session {session} successfully stopped on charger {chargeboxid}",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Failed to unlock connector {connector_id} on charger {chargeboxid}. Reason: {reason}",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Connector {connector_id} successfully unlocked on charger {chargeboxid}",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Charger {chargeboxid} refused order to update firmware (filename: {filename}). Reason: {reason}",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Charger {chargeboxid} accepted order to update firmware (filename: {filename})",
                "SESSION_AUTHORIZED": "Session {session} authorized for contract {auth_id} (UID: {token_uid})",
                "SESSION_DENIED": "Session {sessionLink} with UID {token_uid} not authorized and will not be completed. Reason:",
                "SESSION_ENDED": "Session {session} is over. {invalid_msg} Consumption: {consumption}. Duration:",
                "SESSION_ENERGY_UPDATED": "Session {session} consumption {consumption} (total: {current_wh})",
                "SESSION_FORCED": "Session {sessionLink} with UID {token_uid} started without authorization",
                "SESSION_STARTED": "Session {session} has started. Contract: {auth_id}. UID: {token_uid}",
                "SESSION_START_BLOCKED": "Unknown session blocked by tag {idTag} on charger {chargeboxid}",
                "SESSION_TIMED_OUT": "Session {sessionLink} (authorized at {authorizeDate}) expired and will not be completed",
                "SESSION_TOTAL_COST_UPDATED": "Session {session} cost {current_total_cost} (beforehand {previous_total_cost})",
                "TOKEN_AUTHORIZE_ACCEPTED": "Le fournisseur <strong>{providerName}<\/strong> <strong class=\"has-text-success\">a autorisé<\/strong> le badge <strong>{tokenUid}<\/strong> sur la station <strong>{locationName}<\/strong>",
                "TOKEN_AUTHORIZE_DENIED": "Le fournisseur <strong>{providerName}<\/strong> <strong class=\"has-text-danger\">a refusé<\/strong> le badge <strong>{tokenUid}<\/strong> sur la station <strong>{locationName}<\/strong> pour la raison suivante : ({allowed}) <strong>{reason}<\/strong>",
                "TOKEN_AUTHORIZE_UNKNOWN": "<strong class=\"has-text-warning\">Aucun fournisseur<\/strong> n'a reconnu le badge <strong>{tokenUid}<\/strong>",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Unknown connector {connector_id} set to {status}",
                "UNKNOWN_SESSION_ASKING_PRICE": "Price asked by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_ENDED": "Unknown session stopped by charger {chargeboxid}",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Consumption updated by charger {chargeboxid} for unknown session",
                "UNKNOWN_SESSION_STARTED": "Unknown session authorized by charger {chargeboxid}",
                "default": "Sorry, no interpretation possible",
                "moreDetails": "Plus de détails"
            },
            "noContextualData": "No contextual data available",
            "orderSentBy": "Order sent by {email}",
            "paymentTerminal": {
                "errorMessage": "Error message:",
                "noErrorMessage": "No error message",
                "noReceipt": "No receipt for this session",
                "number": "Payment terminal number: {number}",
                "receiptData": "Receipt data:"
            },
            "remoteFailedReason": {
                "NOT_SUPPORTED": "Order not supported by charger",
                "REJECTED": "Order rejected by charger",
                "TIMEOUT": "No response from charger",
                "default": "Unknown reason",
                "null": "Unknown reason"
            },
            "sessionStatus": {
                "invalid": "Session failed"
            },
            "statusReason": {
                "ADMINISTRATIVE_DECLARATION": "Administrative status updated by a supervisor",
                "ALL_CONNECTORS_FAULTED": "All connectors are faulted",
                "ALL_CONNECTORS_UNAVAILABLE": "All connectors are unavailable",
                "BLOCKED_EVSE": "Access to EVSE is blocked by an obstacle",
                "NO_CONNECTOR": "EVSE has no connector",
                "NO_LAN_DEVICE": "EVSE is attached to no charger",
                "OFFLINE_LAN_DEVICE": "Charger is offline",
                "ONGOING_RESERVATION": "A reservation is ongoing",
                "ONGOING_SESSION": "A session is ongoing",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Ordonné par le fournisseur d'électricité",
                "default": "Everything is fine",
                "null": "Unknown reason"
            },
            "title": "Business logs {name}"
        },
        "cdrs": {
            "csv": {
                "confirmTitle": "CSV du {from} au {to}",
                "default": "Confirmer l'export ?",
                "tooHeavy": "Le fichier est en cours de création et sera envoyé par email à {email}"
            },
            "list": {
                "authId": "Contrat",
                "breadcrumb": "CDRs",
                "connector": "Connecteur",
                "cpo": "Réseau",
                "created": "Créé le",
                "emsp": "Fournisseur",
                "evse": "PdC",
                "externalRef": "Référence externe",
                "lastSent": "Dernier envoi",
                "location": "Station",
                "ref": "Reference",
                "scopeTitle": "CDRs de {name}",
                "startDateTime": "Début",
                "stopDateTime": "Fin",
                "title": "CDRs",
                "totalCost": "Prix total",
                "totalEnergy": "kWh",
                "vat": "TVA",
                "withAdvenir": "Inclure les CDRs pour Advenir",
                "withChildrenOperators": "CDR des sous-réseaux"
            },
            "show": {
                "auth": "Authorization ID",
                "badge": "Tag ID",
                "breadcrumb": "CDR",
                "cdrOfSessionRef": "CDR of #{ref}",
                "cost": "Price",
                "energy": "Energy",
                "exportToPDF": "Export to PDF",
                "isWhiteListed": "is white-listed",
                "preTaxPrice": "Price excl. VAT",
                "ref": "Reference",
                "relations": "Relations",
                "remark": "Comments",
                "title": "Charge details record #{ref}",
                "totalCost": "Total price",
                "totalParkingTime": "Parking time",
                "totalTime": "Total time"
            }
        },
        "commons": {
            "From": "De",
            "Now": "Maintenant",
            "To": "A",
            "active": "Actif",
            "add": "Nouveau",
            "agreement": "Accord",
            "all": "Tous",
            "asyncSearch": "Rechercher",
            "authId": "Contrat",
            "autoRefreshDisabled": "Pas de rafraîchissement automatique des données pour le moment",
            "autoRefreshOff": "Rafraîchissement automatique désactivé",
            "autoRefreshOn": "Rafraîchissement automatique activé",
            "back": "Retour",
            "by": "par {user}",
            "cancel": "Annuler",
            "clear": "Vider",
            "common": "Général",
            "confirm": "Confirmer",
            "cpo": "Réseau",
            "created_at": "Date de création",
            "creationPlaceholder": "Création",
            "curve": "Courbe",
            "date": "Date",
            "dateRange": "Filtrer par date",
            "delete": "Supprimer",
            "durationUnits": {
                "days": "jour(s)",
                "hours": "h",
                "minutes": "m",
                "months": "mois",
                "seconds": "s"
            },
            "durationWithDays": "{nb_days} jour(s) et {standard_duration}",
            "edit": "Modifier",
            "edition": "{name}",
            "emi3": "ID eMI3 privé",
            "emsp": "Fournisseur",
            "end": "Fin",
            "error403": "Non autorisé",
            "error404": "Page non trouvée",
            "evse": "PdC",
            "exclVat": "HT",
            "export": "Export",
            "exportToCSV": "Export CSV",
            "exportToPDF": "Export PDF",
            "false": "Faux",
            "filterOn": "Filtrer",
            "from": "de",
            "fullPrice": "HT : {exclVat} \/ TVA : {vat} \/ {vatRate} %",
            "globalError": "Une erreur est survenue",
            "histogram": "Histogramme",
            "hotline": "Assistance",
            "import": "Import",
            "inactive": "Inactif",
            "inclVat": "TTC",
            "isActive": "Actif",
            "kW": "kW",
            "kWh": "kWh",
            "last_update": "Mis à jour",
            "location": "Station",
            "locationsList": "Stations",
            "month": "Mois",
            "no": "Non",
            "noResult": "Pas de résultat",
            "noResultAsync": "Pas de résultat, écrivez pour rechercher",
            "noSmartphone": "Pas disponible pour smartphone",
            "notDefined": "Non défini",
            "ocpiModules": "Modules OCPI",
            "of": "de {data}",
            "onlyCommaAllowed": "Seul le séparateur \",\" est autorisé",
            "optional": "optionnel",
            "optionsSelected": "{number} option(s) sélectionnées",
            "placeholderSelect": "Choisir une option",
            "preTaxCost": "{amount}  HT, {vat} TVA",
            "process": "Traitement",
            "quarter": "Trimestre",
            "rangeHint": "Données du {from} au {to}",
            "reference": "Référence",
            "refunds": "Remboursement",
            "saveInProgress": "Sauvegarde en cours...",
            "saveUpToDate": "Sauvegarde à jour",
            "schedule": "Horaires",
            "search": "Rechercher",
            "session": "Session",
            "start": "Début",
            "status": "Etat",
            "submit": "Valider",
            "tagId": "Jeton",
            "tariff": "Tarif",
            "to": "à",
            "today": "Aujourd'hui",
            "true": "Vrai",
            "type": "Type",
            "updated_at": "Mis à jour",
            "vat": "TVA",
            "vatRate": "Taux de TVA",
            "year": "Année",
            "years": "Années",
            "yes": "Oui"
        },
        "connectors": {
            "actions": {
                "start": "Démarrer la charge",
                "stop": "Arrêter la charge",
                "unlock": "Déverrouiller"
            },
            "form": {
                "addConnectorTitle": "Créer un connecteur",
                "amps": "Ampères (A)",
                "cable": "Câble attaché",
                "create": "Créer un connecteur",
                "customPower": "Puissance personnalisée",
                "delete": "Supprimer",
                "edit": "Modifier",
                "editConnectorTitle": "Modifier le connecteur",
                "invalid": "Connecteurs invalides",
                "maxPower": "Puissance max. : {maxPower} kW",
                "ocppNumber": "ID connecteur OCPP",
                "ocppNumberIsUnavailable": "n'est pas disponible",
                "ocppNumberUnavailable": "ID connecteur non disponible",
                "power": "Puissance",
                "powerType": "Type de puissance",
                "tariff": "Tarif",
                "type": "Type",
                "voltage": "Voltage (V)"
            },
            "list": {
                "errorCode": "Code d'erreur",
                "evseName": "Nom PdC",
                "evseRef": "Réf. PdC",
                "lastStatusChangeAvailable": "Résolu depuis",
                "lastStatusChangeIssue": "En défaut depuis",
                "maintenanceAvailableTitle": "Connecteurs : pannes résolues",
                "maintenanceIssuesSubtitle": "Liste des connecteurs ayant remontés un code erreur depuis au moins 1h",
                "maintenanceIssuesTitle": "Codes erreurs",
                "standard": "Type \/ Puissance"
            },
            "powerTypes": {
                "AC_1_PHASE": "1 phase",
                "AC_3_PHASE": "3 phases",
                "DC": "Courant continu"
            },
            "show": {
                "cable": "Câble attaché",
                "cannotRemove": "Un connecteur au minimum",
                "confirmDeletion": "Confirmer la suppression",
                "connectorCreated": "Connecteur créé",
                "connectorEdited": "Connecteur modifié",
                "connectorRemoved": "Connecteur supprimé",
                "defaultTariff": "Tarif par défaut",
                "localisation": "Station",
                "locked": "Verrouillé",
                "noRestrictions": "Pas de restriction",
                "noTariff": "Pas de tarif défini",
                "ocppNumber": "ID connecteur OCPP",
                "ocppNumberMismatch": "Le connecteur ne correspond pas aux logs",
                "power": "Puissance",
                "socketFalse": "Câble attaché",
                "socketTrue": "Pas de câble attaché",
                "tariff": "Tarif",
                "tariffApply": "Tarif appliqué",
                "title": "Connecteur n°",
                "type": "Type",
                "unlocked": "Déverrouillé"
            },
            "standard": {
                "chademo": "Chademo",
                "domestic_a": "Standard\/Domestic type A",
                "domestic_b": "Standard\/Domestic type B",
                "domestic_c": "Standard\/Domestic type C",
                "domestic_d": "Standard\/Domestic type D",
                "domestic_e": "Standard\/Domestic type E",
                "domestic_f": "Standard\/Domestic type F",
                "domestic_g": "Standard\/Domestic type G",
                "domestic_h": "Standard\/Domestic type H",
                "domestic_i": "Standard\/Domestic type I",
                "domestic_j": "Standard\/Domestic type J",
                "domestic_k": "Standard\/Domestic type K",
                "domestic_l": "Standard\/Domestic type L",
                "iec_60309_2_single_16": "IEC 60309-Industrial single phase 16",
                "iec_60309_2_three_16": "IEC 60309-Industrial three phase 16",
                "iec_60309_2_three_32": "IEC 60309-Industrial three phase 32",
                "iec_60309_2_three_64": "IEC 60309-Industrial three phase 64",
                "iec_62196_t1": "IEC 62196 Type 1",
                "iec_62196_t1_combo": "Combo Type 1",
                "iec_62196_t2": "IEC 62196 Type 2",
                "iec_62196_t2_combo": "Combo Type 2",
                "iec_62196_t3a": "IEC 62196 Type 3A",
                "iec_62196_t3c": "IEC 62196 Type 3C",
                "other": "Autres connecteurs",
                "tesla_r": "Tesla Connector Roadster-type",
                "tesla_s": "Tesla Connector Model-S-type"
            },
            "status": {
                "available": "Disponible",
                "charging": "En charge",
                "faulted": "Défaut",
                "finishing": "En fin",
                "issue": "Problème",
                "occupied": "Occupé",
                "preparing": "En préparation",
                "reserved": "Réservé",
                "suspendedev": "VE suspendu",
                "suspendedevse": "PdC suspendu",
                "unavailable": "Indisponible"
            }
        },
        "contact-groups": {
            "actions": {
                "assignGroup": "Ajouter un groupe existant",
                "delete": "Supprimer",
                "remove": "Retirer",
                "replaceGroup": "Remplacer le groupe"
            },
            "create": {
                "feedback": "Groupe créé avec succès",
                "title": "Créer un groupe de contacts"
            },
            "edit": {
                "addExistingGroup": "Ajouter un groupe existant",
                "edit": "Modifier",
                "feedback": "Groupe modifié avec succès",
                "searchContactGroups": "Rechercher des groupes de contacts"
            },
            "form": {
                "affiliations": "Affiliations",
                "assign": "Assigner",
                "contacts": "Contacts",
                "description": "Description",
                "info": "Information du groupe",
                "language": "Langue de communication",
                "location": "Station",
                "name": "Nom du groupe",
                "nameExists": "Ce groupe existe déjà",
                "network": "Réseau",
                "or": "ou",
                "planning": "Planning",
                "searchContacts": "Lier un contact existant",
                "searchContactsEmpty": "Ajoutez au moins un contact au groupe"
            },
            "list": {
                "confirmDelete": "Souhaitez-vous réellement supprimer ce groupe de contacts ?",
                "delete": "Supprimer",
                "duplicate": "Dupliquer",
                "incompleteSchedule": "Attention: Planning incomplet"
            },
            "planning": {
                "add": "Ajouter",
                "addContact": "Ajouter un contact",
                "addContactTitle": "Ajouter un contact pour {day}",
                "addSchedule": "Ajouter un horaire",
                "begin": "Début",
                "empty": "Aucun contact défini",
                "end": "Fin",
                "incomplete": "Planning incomplet",
                "schedule": "Planning",
                "selectContact": "Sélectionner un contact"
            },
            "show": {
                "breadcrumb": "Groupe de contact {name}",
                "contacts": "Contacts",
                "description": "Description",
                "groupInfo": "Information du groupe",
                "inheritedFrom": "hérité de",
                "language": "Language",
                "name": "Nom du groupe",
                "planningContactInfo": "Tous les contacts de ce groupe seront informés d’une urgence par un email. Les contacts inscrits dans le planning aux jours et aux heures de l’urgence seront informés via le(s) moyen(s) de contact renseigné(s) : SMS et\/ou Appel.",
                "scopeDeleteGroup": "Souhaitez-vous réellement supprimer le groupe {group} ? Cette action supprimera le groupe de tous les réseaux et de toutes les stations auxquels il est affilié.",
                "scopeRemoveGroup": "Souhaitez-vous réellement retirer le groupe {group} du réseau ?",
                "supportGroup": "Groupe de maintenance"
            },
            "toast": {
                "deleted": "Le groupe de contacts a été supprimé"
            }
        },
        "contacts": {
            "affiliations": {
                "actions": "Actions",
                "affiliationsLocation": "Affectations stations sans affectation réseau",
                "affiliationsNetwork": "Affectations réseaux",
                "affiliationsOf": "Affectations de",
                "deletePopupTextLocation": "Voulez-vous vraiment supprimer cette affectation ?",
                "deletePopupTextNetwork": "Voulez-vous vraiment supprimer cette affectation ?\n            Cela supprimera également toutes les affectations entre le contact et les stations du réseau.\n        ",
                "deletePopupTitleLocation": "Suppression de l'affectation",
                "deletePopupTitleNetwork": "Suppression de l'affectation",
                "locations": "Stations",
                "network": "Réseau",
                "role": "Rôle",
                "title": "Contact Affectations",
                "type": "Type"
            },
            "breadcrumb": {
                "affiliations": "Affectations",
                "contact": "Contacts",
                "edit": "Modifier",
                "groups": "Groupes de contacts"
            },
            "contactMethods": {
                "email": "Email",
                "phone": "Appel",
                "sms": "SMS"
            },
            "create": {
                "title": "Créer un contact"
            },
            "edit": {
                "close": "Fermer",
                "edit": "Modifier",
                "editContactsTitle": "Modifier les contacts"
            },
            "error": {
                "contactEmailAlreadyTakenAdmin": "email est déjà pris",
                "contactEmailAlreadyTakenNotAdmin": "Le contact existe déjà, pour l'affecter à votre réseau veuillez contacter exploitation@freshmile.com"
            },
            "form": {
                "addContactTitle": "Créer un contact",
                "alwaysOneOwnerContact": "Le réseau doit avoir au moins un contact propriétaire.",
                "buttonAddContact": "Créer un nouveau contact",
                "buttonAddType": "Ajouter un type",
                "buttonLink": "Lier",
                "call": "Appel",
                "company": "Entreprise",
                "contact_methods": "Moyen(s) de contact",
                "contactsOfNetwork": "Contacts du réseau",
                "countrySelectorLabel": "Code pays",
                "createUser": "Créer un utilisateur associé au contact",
                "editContactTitle": "Modifier un contact",
                "email": "Email",
                "errorDeleteType": "Le contact doit avoir au moins un type",
                "errorUpdate": "Une erreur est survenue",
                "linkContactExisting": "Lier un contact existant",
                "linkContactNotExisting": "Lier un nouveau contact",
                "name": "Nom du contact",
                "nameExists": "Nom déjà utilisé",
                "notesInput": "Notes",
                "optional": "Optionnel",
                "phone_number": "Appel",
                "placeholderUser": "Rechercher par nom, adresse email",
                "searchContact": "Rechercher un contact existant",
                "sms_number": "SMS",
                "textSearch": "Rechercher un contact existant et sélectionnez un type, puis cliquer sur le bouton lier.",
                "textSearchGroup": "Rechercher un contact existant et cliquer sur le bouton lier.",
                "titleContactsOfNetwork": "Contact(s)",
                "type": "Type de contact",
                "userLink": "Utilisateur lié"
            },
            "groups": {
                "actions": "Actions",
                "addGroupContact": "Créer un groupe de contact",
                "affiliationOfGroup": "Affiliation directe",
                "confirmDelete": "Souhaitez-vous réellement supprimer le contact de ce groupe de contact ?",
                "contact_methods": "Moyen(s) de contact",
                "contacts": "Contacts",
                "groupOfContact": "Groupe de contact",
                "groupOfContactPlaceholder": "Rechercher un groupe de contact",
                "modalTitle": "Ajouter un groupe de contact au contact",
                "nameOfGroup": "Nom du groupe",
                "or": "OU",
                "title": "Groupes de contacts",
                "updated": "Mis à jour"
            },
            "list": {
                "actions": "Actions",
                "affiliated": "Affiliation directe",
                "allTypes": "Tous les types de contacts",
                "breadcrumb": "Contacts",
                "confirmDelete": "Voulez-vous vraiment supprimer ce contact?",
                "contact_methods": "Moyens de contact",
                "contacts": "Contacts",
                "contactsGroups": "Groupes de contacts",
                "email": "Email",
                "filterType": "Filtrer par type",
                "groupName": "Nom du groupe",
                "name": "Nom complet",
                "phone_number": "Appel",
                "sms_number": "SMS",
                "title": "Contacts et Groupes",
                "type": "Type",
                "types": "Types"
            },
            "show": {
                "accessContactPage": "Accéder à la fiche du contact",
                "company": "Entreprise",
                "contactMethods": "Moyen(s) de contact",
                "email": "Email",
                "groups": "Groupes",
                "nameOfContact": "Nom du contact",
                "networkAndLocations": "Réseaux et stations",
                "notes": "Notes supervision",
                "otherContacts": "Autres contacts",
                "phone_number": "Appel",
                "sms_number": "SMS",
                "title": "Contact",
                "type": "Type de contact:",
                "userLink": "Utilisateur lié"
            },
            "toast": {
                "clipboard": "Copié avec succès.",
                "deleted": "Contact supprimé"
            },
            "types": {
                "ADMINISTRATIVE": "Gestionnaire",
                "EMERGENCY": "Urgence",
                "INDIVIDUAL": "Particulier",
                "INSTALLER": "Installateur",
                "MAINTENANCE": "Maintenance",
                "OWNER": "Propriétaire",
                "undefined": "Non défini"
            }
        },
        "countries": {
            "ABW": "Aruba",
            "AFG": "Afghanistan",
            "AGO": "Angola",
            "AIA": "Anguilla",
            "ALA": "Îles Åland",
            "ALB": "Albanie",
            "AND": "Andorre",
            "ARE": "Émirats Arabes Unis",
            "ARG": "Argentine",
            "ARM": "Arménie",
            "ASM": "Samoa Américaines",
            "ATA": "Antarctique",
            "ATF": "Terres Australes Françaises",
            "ATG": "Antigua-et-Barbuda",
            "AUS": "Australie",
            "AUT": "Autriche",
            "AZE": "Azerbaïdjan",
            "BDI": "Burundi",
            "BEL": "Belgique",
            "BEN": "Bénin",
            "BES": "Bonaire, Saint-Eustache et Saba",
            "BFA": "Burkina Faso",
            "BGD": "Bangladesh",
            "BGR": "Bulgarie",
            "BHR": "Bahreïn",
            "BHS": "Bahamas",
            "BIH": "Bosnie-Herzégovine",
            "BLM": "Saint-Barthélemy",
            "BLR": "Biélorussie",
            "BLZ": "Belize",
            "BMU": "Bermudes",
            "BOL": "Bolivie",
            "BRA": "Brésil",
            "BRB": "Barbade",
            "BRN": "Brunei Darussalam",
            "BTN": "Bhoutan",
            "BVT": "Île Bouvet",
            "BWA": "Botswana",
            "CAF": "République Centrafricaine",
            "CAN": "Canada",
            "CCK": "Îles Cocos (Keeling)",
            "CHE": "Suisse",
            "CHL": "Chili",
            "CHN": "Chine",
            "CIV": "Côte d'Ivoire",
            "CMR": "Cameroun",
            "COD": "Congo (République Démocratique du)",
            "COG": "Congo",
            "COK": "Îles Cook",
            "COL": "Colombie",
            "COM": "Comores",
            "CPV": "Cap-Vert",
            "CRI": "Costa Rica",
            "CUB": "Cuba",
            "CUW": "Curaçao",
            "CXR": "Île Christmas",
            "CYM": "Îles Caïmans",
            "CYP": "Chypre",
            "CZE": "Tchéquie",
            "DEU": "Allemagne",
            "DJI": "Djibouti",
            "DMA": "Dominique",
            "DNK": "Danemark",
            "DOM": "République Dominicaine",
            "DZA": "Algérie",
            "ECU": "Équateur",
            "EGY": "Égypte",
            "ERI": "Érythrée",
            "ESH": "Sahara Occidental",
            "ESP": "Espagne",
            "EST": "Estonie",
            "ETH": "Éthiopie",
            "FIN": "Finlande",
            "FJI": "Fidji",
            "FLK": "Îles Falkland (Malvinas)",
            "FRA": "France",
            "FRO": "Îles Féroé",
            "FSM": "Micronésie (États fédérés de)",
            "GAB": "Gabon",
            "GBR": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
            "GEO": "Géorgie",
            "GGY": "Guernesey",
            "GHA": "Ghana",
            "GIB": "Gibraltar",
            "GIN": "Guinée",
            "GLP": "Guadeloupe",
            "GMB": "Gambie",
            "GNB": "Guinée-Bissau",
            "GNQ": "Guinée Équatoriale",
            "GRC": "Grèce",
            "GRD": "Grenade",
            "GRL": "Groenland",
            "GTM": "Guatemala",
            "GUF": "Guyane Française",
            "GUM": "Guam",
            "GUY": "Guyane",
            "HKG": "Hong Kong",
            "HMD": "Îles Heard et McDonald",
            "HND": "Honduras",
            "HRV": "Croatie",
            "HTI": "Haïti",
            "HUN": "Hongrie",
            "IDN": "Indonésie",
            "IMN": "Île de Man",
            "IND": "Inde",
            "IOT": "Territoire britannique de l’océan Indien",
            "IRL": "Irlande",
            "IRN": "Iran (République Islamique d')",
            "IRQ": "Irak",
            "ISL": "Islande",
            "ISR": "Israël",
            "ITA": "Italie",
            "JAM": "Jamaïque",
            "JEY": "Jersey",
            "JOR": "Jordanie",
            "JPN": "Japon",
            "KAZ": "Kazakhstan",
            "KEN": "Kenya",
            "KGZ": "Kirghizistan",
            "KHM": "Cambodge",
            "KIR": "Kiribati",
            "KNA": "Saint-Kitts-et-Nevis",
            "KOR": "Corée (République de)",
            "KWT": "Koweït",
            "LAO": "République Démocratique Populaire Lao",
            "LBN": "Liban",
            "LBR": "Libéria",
            "LBY": "Libye",
            "LCA": "Sainte-Lucie",
            "LIE": "Liechtenstein",
            "LKA": "Sri Lanka",
            "LSO": "Lesotho",
            "LTU": "Lituanie",
            "LUX": "Luxembourg",
            "LVA": "Lettonie",
            "MAC": "Macao",
            "MAF": "Saint-Martin (partie française)",
            "MAR": "Maroc",
            "MCO": "Monaco",
            "MDA": "Moldavie (République de)",
            "MDG": "Madagascar",
            "MDV": "Maldives",
            "MEX": "Mexique",
            "MHL": "Îles Marshall",
            "MKD": "Macédoine du Nord",
            "MLI": "Mali",
            "MLT": "Malte",
            "MMR": "Myanmar",
            "MNE": "Monténégro",
            "MNG": "Mongolie",
            "MNP": "Îles Mariannes du Nord",
            "MOZ": "Mozambique",
            "MRT": "Mauritanie",
            "MSR": "Montserrat",
            "MTQ": "Martinique",
            "MUS": "Maurice",
            "MWI": "Malawi",
            "MYS": "Malaisie",
            "MYT": "Mayotte",
            "NAM": "Namibie",
            "NCL": "Nouvelle-Calédonie",
            "NER": "Niger",
            "NFK": "Île Norfolk",
            "NGA": "Nigéria",
            "NIC": "Nicaragua",
            "NIU": "Niue",
            "NLD": "Pays-Bas",
            "NOR": "Norvège",
            "NPL": "Népal",
            "NRU": "Nauru",
            "NZL": "Nouvelle-Zélande",
            "OMN": "Oman",
            "PAK": "Pakistan",
            "PAN": "Panama",
            "PCN": "Îles Pitcairn",
            "PER": "Pérou",
            "PHL": "Philippines",
            "PLW": "Palaos",
            "PNG": "Papouasie-Nouvelle-Guinée",
            "POL": "Pologne",
            "PRI": "Porto Rico",
            "PRK": "Corée (République Populaire Démocratique de)",
            "PRT": "Portugal",
            "PRY": "Paraguay",
            "PSE": "Palestine, État de",
            "PYF": "Polynésie française",
            "QAT": "Qatar",
            "REU": "Réunion",
            "ROU": "Roumanie",
            "RUS": "Fédération de Russie",
            "RWA": "Rwanda",
            "SAU": "Arabie Saoudite",
            "SDN": "Soudan",
            "SEN": "Sénégal",
            "SGP": "Singapour",
            "SGS": "Géorgie du Sud et les Îles Sandwich du Sud",
            "SHN": "Sainte-Hélène, Ascension et Tristan da Cunha",
            "SJM": "Svalbard et Jan Mayen",
            "SLB": "Îles Salomon",
            "SLE": "Sierra Leone",
            "SLV": "Salvador",
            "SMR": "Saint-Marin",
            "SOM": "Somalie",
            "SPM": "Saint Pierre and Miquelon",
            "SRB": "Serbie",
            "SSD": "Soudan du Sud",
            "STP": "Sao Tomé-et-Principe",
            "SUR": "Suriname",
            "SVK": "Slovaquie",
            "SVN": "Slovénie",
            "SWE": "Suède",
            "SWZ": "Eswatini",
            "SXM": "Saint-Martin (partie néerlandaise)",
            "SYC": "Seychelles",
            "SYR": "République arabe syrienne",
            "TCA": "Îles Turques-et-Caïques",
            "TCD": "Tchad",
            "TGO": "Togo",
            "THA": "Thaïlande",
            "TJK": "Tadjikistan",
            "TKL": "Tokelau",
            "TKM": "Turkménistan",
            "TLS": "Timor-Leste",
            "TON": "Tonga",
            "TTO": "Trinité-et-Tobago",
            "TUN": "Tunisie",
            "TUR": "Turquie",
            "TUV": "Tuvalu",
            "TWN": "Taïwan, Province de Chine",
            "TZA": "Tanzanie, République-Unie de",
            "UGA": "Ouganda",
            "UKR": "Ukraine",
            "UMI": "Îles mineures éloignées des États-Unis",
            "URY": "Uruguay",
            "USA": "États-Unis d'Amérique",
            "UZB": "Ouzbékistan",
            "VAT": "Saint-Siège",
            "VCT": "Saint-Vincent-et-les Grenadines",
            "VEN": "Venezuela (République bolivarienne du)",
            "VGB": "Îles Vierges britanniques",
            "VIR": "Îles Vierges des États-Unis",
            "VNM": "Viet Nam",
            "VUT": "Vanuatu",
            "WLF": "Wallis-et-Futuna",
            "WSM": "Samoa",
            "YEM": "Yémen",
            "ZAF": "Afrique du Sud",
            "ZMB": "Zambie",
            "ZWE": "Zimbabwe"
        },
        "dashboard": {
            "activity": {
                "consumptionTitle": "Energie (MWh)",
                "sessionsTitle": "Sessions de charge (unités)",
                "turnoverTitle": "Chiffre d'affaires ({currency})"
            },
            "availableEvses": "Points de charge fonctionnels",
            "availableEvsesPercentage": "{1} {current} PdC indisponible(s) sur {total} (Disponibilité : {percentage}%)| {current} PdC indisponible(s) sur {total} (Disponibilité : {percentage}%)",
            "currentSessions": "Sessions en cours",
            "currentSessionsPercentage": "{percentage}% de {total} points de charge en cours de charge",
            "exportTranslations": "Exporter les traductions",
            "importExportTranslations": "Importer\/exporter les traductions",
            "importTranslations": "Importer les traductions",
            "loadMap": "Charger la carte du réseau",
            "networkStatus": "Etat du réseau en temps réel",
            "openTickets": "Tickets ouverts",
            "title": "Tableau de bord"
        },
        "days": {
            "dayNamesCalendar": [
                "Di",
                "Lu",
                "Ma",
                "Me",
                "Je",
                "Ve",
                "Sa"
            ],
            "friday": "Vendredi",
            "monday": "Lundi",
            "monthNamesCalendar": [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            "saturday": "Samedi",
            "sunday": "Dimanche",
            "thursday": "Jeudi",
            "tuesday": "Mardi",
            "wednesday": "Mercredi"
        },
        "devices": {
            "actions": {
                "breadcrumb": "Actions distantes",
                "change": "Changer",
                "changeAvailability": "Changer la disponibilité",
                "changeConfig": "Changer la configuration",
                "changeConfigPlaceholder": "Nom de la configuration",
                "clearCache": "Vider le cache",
                "cnr": {
                    "rearm": "Réarmer",
                    "rearmFailed": "Echec du réarmement",
                    "rearmSuccess": "Appareil réarmé avec succès",
                    "stop": "Stop",
                    "stopSuccess": "Prise domestique arrêtée",
                    "supply": "Alimenter (1h)",
                    "supplySince": "Fourniture d'électricité depuis : {date}",
                    "supplySuccess": "Prise domestique démarrée avec succès pour 1 heure",
                    "title": "Automate CNR"
                },
                "configReadonly": "(Non modifiable)",
                "configValue": "Valeur de configuration",
                "configurationPlaceholder": "Sélectionner ou écrire ici",
                "customConfig": "Configuration sur mesure",
                "get": "Obtenir",
                "getConfiguration": "Obtenir la configuration",
                "getDiagnostics": "Obtenir le diagnostic",
                "getDiagnosticsManual": "Envoyer le diagnostic via URL",
                "ping": "Ping",
                "protocol": "Protocole : {protocol}",
                "reset": "Reset",
                "selectFile": "Choisir un fichier",
                "title": "Actions distantes pour {chargeboxid}",
                "unknownConfig": "Configuration inconnue",
                "updateFirmware": "Mettre à jour le firmware",
                "updateFirmwareManual": "Mettre à jour le firmware via URL",
                "upload": "Télécharger",
                "urlPlaceholder": "Entrer une URL",
                "value": "Valeur:"
            },
            "create": {
                "breadcrumb": "Nouvelle borne",
                "cpoTitle": "Nouvelle borne dans {name}",
                "feedback": "Borne créée",
                "lanTitle": "Nouvelle borne connectée à {name}",
                "locationTitle": "Nouvelle borne dans {name}",
                "title": "Nouvelle borne"
            },
            "edit": {
                "breadcrumb": "Modifier",
                "feedback": "Borne modifiée",
                "title": "Modifier"
            },
            "form": {
                "addLan": "Créer LAN",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID disponible",
                "chargeboxidUnavailable": "Chargebox ID non disponible",
                "definition": "Définition de la borne",
                "deviceProtocol": "Protocole",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "Modifier LAN",
                "isDhcp": "DHCP actif",
                "lan": "Réseau local",
                "lanDescription": "Description LAN",
                "lanName": "Nom",
                "localIp": "Adresse IP locale",
                "localIpIsInvalid": "Adresse IP locale invalide",
                "localPort": "Port local",
                "model": "Modèle",
                "modelIntro": "Utiliser un modèle existant",
                "newLanName": "LAN n°",
                "offlineEvses": "PdC appairable(s)",
                "publicIp": "Adresse IP publique",
                "publicPort": "Port public",
                "selectLocation": "Station",
                "submit": "Créer LAN",
                "technicalInfos": "Information technique",
                "unknownModel": "Modèle inconnu",
                "url": "URL"
            },
            "list": {
                "addDevice": "Créer borne",
                "all": "Tous",
                "breadcrumb": "Bornes",
                "chargeboxid": "Chargebox ID",
                "cpoTitle": "CBID de {name}",
                "firmware": "Firmware",
                "isOnline": "En ligne",
                "lan": "Réseau local",
                "lastRequestOffline": "Hors ligne depuis",
                "lastRequestOnline": "En ligne depuis",
                "localIp": "Adresse IP locale",
                "locationTitle": "CBID de {name}",
                "model": "Modèle",
                "nbEvses": "PdC",
                "offline": "Hors ligne",
                "online": "En ligne",
                "protocol": "Protocole",
                "status": "Etat",
                "title": "CBID",
                "updatedAt": "Mis à jour",
                "vendor": "Fabricant"
            },
            "ocpp": {
                "breadcrumb": "Logs OCPP",
                "changeAvailabilityAvailable": "Borne disponible",
                "changeAvailabilityUnavailable": "Borne indisponible",
                "changeConfigSuccess": "Nouvelle valeur appliquée à {configuration}",
                "clearSuccess": "Cache vidé",
                "commonSuccess": "Commande envoyée",
                "diagnosticsOngoing": "Ordre de get diagnostic envoyé - en attente de la borne",
                "diagnosticsReady": "Diagnostic prêt",
                "failed": "Commande échouée",
                "getDiagnosticsFail": "Get diagnostics échoué",
                "notSupported": "Commande non supportée",
                "pingError": "Ping échoué",
                "pingSuccess": "Ping réussi",
                "rejected": "Commande rejetée",
                "remoteFail": "Ordre distant échoué",
                "resetSuccess": "Ordre de redémarrage envoyé - la borne redémarre",
                "startSuccess": "Session démarrée",
                "stopSuccess": "Session arrêtée",
                "timeout": "Commande expirée (time out)",
                "unlockFailed": "Déverrouillage échoué",
                "unlockSuccess": "Déverrouillage réussi",
                "uploadFirmwareFailed": "Téléchargement échoué",
                "uploadFirmwareSuccess": "Téléchargement réussi"
            },
            "protocol": {
                "OCPP15J": "OCPP 1.5 J",
                "OCPP15S": "OCPP 1.5 S",
                "OCPP16J": "OCPP 1.6 J",
                "OCPP16S": "OCPP 1.6 S",
                "OCPP20J": "OCPP 2.0 J",
                "OCPP20S": "OCPP 2.0 S",
                "OTHER": "Autre"
            },
            "show": {
                "DHCP": "DHCP",
                "NAT": "NAT",
                "deviceInfo": "Informations sur la borne",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "firmware": "Firmware",
                "isOffline": "Hors ligne",
                "isOnline": "En ligne",
                "lan": "Réseau local",
                "lastRequest": "Dernière requête",
                "localIp": "Adresse IP locale",
                "localPort": "Port local",
                "model": "Modèle",
                "network": "Réseau",
                "noOcppMessage": "Aucun message OCPP envoyé",
                "noPairsConnected": "Pas de pair",
                "ocppInfos": "OCPP",
                "pairs": "Pairs",
                "protocol": "Protocole",
                "publicPort": "Port public",
                "url": "URL",
                "vendor": "Fabricant"
            }
        },
        "emails": {
            "ExportReady": {
                "body-attached": "Veuillez trouver ci-joint le fichier demandé",
                "body-linked": "Merci de cliquer sur le lien ci-dessous pour télécharger le fichier demandé",
                "download-link-text": "Télécharger le fichier",
                "subject": "Votre export est prêt"
            },
            "Issues": {
                "closed": {
                    "introduction": "Le ticket Fix : #{id} {title} - {location} a été fermé par {closer} le {closed_at}.",
                    "subject": "Fermeture d'un ticket de maintenance - Fix: #{id} - {locationName}"
                },
                "created": {
                    "introduction": "Le ticket Fix suivant vient d'être crée :",
                    "link": "Lien du ticket :",
                    "name": "Nom du ticket :",
                    "number": "Numéro du ticket :",
                    "subject": "Ouverture d'un ticket de maintenance - Fix: #{id} - {locationName}",
                    "type": "Type de maintenance :"
                },
                "link": "Consulter le ticket :",
                "messageCreated": {
                    "introduction": "{updater} vient d'écrire un message dans le ticket Fix : #{id} {title} - {location} le {created_at}.",
                    "subject": "Un message a été ajouté dans un ticket de maintenance - Fix: #{id} - {locationName}"
                },
                "reminder": "N'oubliez pas l'importance de commenter les tickets, afin de suivre l'évolution et ainsi de permettre un meilleur suivi",
                "reopened": {
                    "introduction": "Le ticket Fix : #{id} {title} - {location} a été réouvert par {opener} le {updated_at}.",
                    "subject": "Réouverture d'un ticket de maintenance - Fix: #{id} - {locationName}"
                },
                "updated": {
                    "introduction": "Le ticket Fix : #{id} {title} - {location} a été modifié par {updater} le {updated_at}.",
                    "subject": "Modification d'un ticket de maintenance - Fix: #{id} - {locationName}"
                },
                "userQuoted": {
                    "introduction": "{dispatcher} vient de vous citer dans le ticket Fix : #{id} {title} - {location} le {quoted_at}.",
                    "subject": "Citation dans un ticket de maintenance - Fix: #{id} - {locationName}"
                }
            },
            "ResetPassword": {
                "lines": {
                    "call_to_action": "Cliquez sur le bouton ci-dessous pour changer votre mot de passe",
                    "expiration": "Ce lien expire dans {expirationTime} minutes",
                    "explanation": "Vous recevez ce message car nous avons reçu une demande de changement de votre mot de passe",
                    "no_action_required": "Si vous n'êtes pas à l'initiative de cette demande, ne tenez pas compte de ce message"
                },
                "link": "Changez le mot de passe",
                "subject": "Changement de mot de passe"
            },
            "SendRetrocessionEmployerPdf": {
                "cordially": "Cordialement",
                "explanation": "Veuillez trouver ci-joint l’État nominatif de rétrocession des recharges effectuées à titre professionnel, sur la borne de votre domicile.",
                "greetings": "Bonjour,",
                "payment": "Le virement sera effectué dans les prochains jours.",
                "subject": "Rétrocession des recharges professionnelles effectuées à votre domicile"
            },
            "SendRetrocessionPdf": {
                "cordially": "Cordialement",
                "explanation": "Veuillez trouver ci-joint votre document de rétrocession pour le trimestre dernier.",
                "greetings": "Bonjour,",
                "payment": "Le virement sera effectué dans les prochains jours.",
                "subject": "Rétrocession du dernier trimestre - Freshmile"
            },
            "StationCheckApiTokenConfirmMail": {
                "explanation": "Pour vérifier votre adresse e-mail et accéder à l'interface de test du terminal, veuillez cliquer sur le bouton de vérification ci-dessous.",
                "fromName": "Freshmile Check",
                "link": "Vérification de l’adresse e-mail",
                "linkComment": "Si le bouton ne fonctionne pas, vous pouvez copier et coller le lien suivant dans votre navigateur :",
                "subject": "Vérification de l’adresse e-mail"
            },
            "UserFeedbackReportMail": {
                "subject": "Retour d’un utilisateur"
            },
            "common": {
                "apologize": "Nous vous présentons nos excuses pour tout désagrément",
                "contact": "Pour toute question, vous pouvez nous contacter à l'adresse suivante:",
                "greetings": "Bonjour {firstname} {lastname}",
                "login": "Connexion",
                "thanks": "Merci d'utiliser {appname} !"
            },
            "fromName": "Freshmile"
        },
        "evses": {
            "administrative_status": {
                "available": "En service",
                "inoperative": "Maintenance",
                "outoforder": "Mis hors service",
                "planned": "Planifié",
                "removed": "Retiré",
                "unknown": "Non-communicant"
            },
            "charging_status": {
                "available": "Disponible",
                "charging": "En charge",
                "reserved": "Réservé",
                "unknown": "Charge inconnue"
            },
            "create": {
                "breadcrumb": "Nouveau PdC",
                "cpoTitle": "Nouveau point de charge dans {name}",
                "evseList": "Points de charge (liste)",
                "feedback": "Point de charge créé",
                "locationTitle": "Nouveau point de charge dans {name}",
                "title": "Nouveau point de charge"
            },
            "edit": {
                "BOEditRef": "Pour modifier la référence d'un point de charge après qu'il ait été créé, merci d'utiliser l'action \"Changer la ref d'un point de charge\" dans le backoffice.",
                "feedback": "Point de charge modifié",
                "title": "Modifier"
            },
            "export-data-gouv": "data.gouv",
            "form": {
                "addConnector": "Créer un connecteur",
                "addDevice": "Créer une borne",
                "addLan": "Créer un LAN",
                "administrative": "Etat administratif",
                "applyCoordinates": "Appliquer les coordonnées GPS",
                "asyncSearch": "Rechercher par PdC (référence, nom)",
                "confirmFreeTariffSubmit": "Ce PdC est disponible à la recharge. Confirmer la gratuité ?",
                "connectors": "Connecteurs",
                "customRefAvailable": "Référence disponible",
                "customRefUnavailable": "Référence indisponible",
                "device": "Chargebox ID",
                "directions": "Itinéraire",
                "duplicate": "Créer un duplicata n+1 de ce point de charge",
                "emi3": "ID eMI3",
                "evseInfos": "Informations du point de charge",
                "floorLevel": "Etage",
                "hideMap": "Cacher la carte",
                "lan": "LAN dans cette station",
                "lanDeviceCreationError": "Erreur",
                "lanDeviceCreationSuccess": "Borne créée",
                "latitude": "Latitude",
                "location": "Station",
                "locationInfos": "Information de station",
                "locationPlaceholder": "Station",
                "locations": "Stations",
                "longitude": "Longitude",
                "name": "Nom personnalisé",
                "newDeviceTitle": "Nouvelle borne dans {name}",
                "optional": "(optionnel)",
                "parkingType": "Type de parking",
                "physicalRef": "Référence physique",
                "ref": "REF",
                "showMap": "Afficher la carte",
                "submit": "Enregistrer"
            },
            "list": {
                "addEvse": "Créer un point de charge",
                "all": "Tous",
                "breadcrumb": "Points de charge",
                "byCpo": "Filtrer par ID réseau",
                "commissionedAt": "Mise en service",
                "connectors": "Connecteurs",
                "cpoTitle": "Points de charge de {name}",
                "decommissionedAt": "Désactivation",
                "device": "Borne",
                "emi3": "ID eMI3",
                "free": "Gratuit",
                "location": "Station",
                "locationTitle": "Points de charge dans {name}",
                "name": "Nom",
                "paying": "Payant",
                "reference": "Référence",
                "status": "Etat",
                "tariffs": "Tarifs",
                "title": "Points de charge",
                "updatedAt": "Mis à jour"
            },
            "operational_status": {
                "available": "En fonctionnement",
                "blocked": "Bloqué",
                "inoperative": "Non opérationnel",
                "outoforder": "Hors service",
                "unknown": "Inconnu"
            },
            "show": {
                "administrative": "Administratif",
                "administrativeDetails": "Etat administratif",
                "chargeStarted": "Charge démarrée",
                "chargeStopped": "Charge arrêtée",
                "charging": "En charge",
                "chargingDetails": "Etat de charge",
                "connectors": "Connecteurs",
                "device": "Chargebox ID",
                "deviceIsConnected": "Connecté",
                "directions": "Itinéraire",
                "emi3": "ID eMI3",
                "floorLevel": "Etage",
                "misc": "Divers",
                "name": "Nom",
                "noDescription": "Aucune description",
                "noDeviceConnected": "Pas de borne connectée, point de charge inatteignable",
                "onGoingSessionStarted": "Session démarrée",
                "operational": "Opérationnel",
                "operationalDetails": "Statut opérationnel",
                "pairs": "Pairs",
                "parkingRestrictions": "Restrictions de parking",
                "physicalReference": "Référence physique",
                "ref": "Référence",
                "stopCharge": "Arrêter la charge"
            },
            "status": {
                "alive": "Fonctionnel",
                "available": "Disponible",
                "blocked": "Bloqué",
                "charging": "En charge",
                "inoperative": "Non opérationnel",
                "obstructed": "Obstrué",
                "outoforder": "Hors service",
                "planned": "Planifié",
                "removed": "Retiré",
                "reserved": "Réservé",
                "test": "Test",
                "unavailable": "Indisponible",
                "unknown": "Non-communicant",
                "unobstructed": "Non obstrué"
            }
        },
        "fix": {
            "bot": {
                "automatic_action_close": {
                    "LanDevice_reconnected": "Tous les CBID des points de charge ont été reconnectés. Le ticket fix a été fermé automatiquement.",
                    "charge_completed": "Une charge valide a été réalisée sur un des points de charge. Le ticket fix a été fermé automatiquement.",
                    "connector_available": "Aucune erreur ne remonte sur les connecteur du point de charge. Le ticket fix a été fermé automatiquement.",
                    "inactive_emergency_issue": "Sans nouvelles de l'utilisateur ou du contact d'urgence, Freshmile considère que le ticket Fix d'urgence est résolu. Il a été fermé automatiquement."
                },
                "automatic_action_close_default": "Le ticket fix a été fermé automatiquement.",
                "automatic_action_create_default": "Le ticket fix a été ouvert automatiquement.",
                "automatic_action_update_default": "Le ticket fix a été modifié automatiquement.",
                "chargeCompleted": "Charge complétée",
                "chargeCompletedTitle": "\"COMPLETED + > 2min + > 500Wh\" sur au moins un Pdc lié à un ticket a eu lieu",
                "connectorAvailable": "Connecteur opérationnel",
                "connectorAvailableTitle": "Lorsque tous les connecteurs des PdC liés à un ticket renvoient un StatusNotificationRequest : NoError",
                "disabled": "Désactivée",
                "lanDeviceConnected": "CBID connecté",
                "lanDeviceConnectedTitle": "Lorsque tous les CBID des PdC liés à un ticket sont (re)connectés"
            },
            "create": {
                "addTask": "Ajouter",
                "allOptions": "Toutes les options",
                "assignTo": "Assigner le ticket à",
                "autoManagement": "Fermeture automatique",
                "breadcrumb": "Créer un ticket",
                "clickUpload": "Cliquer pour choisir un fichier",
                "dropUpload": "Glisser des fichiers ou cliquer. Taille maximale : {max} Kb",
                "fewerOptions": "Moins d'options",
                "issueTitle": "Titre du ticket",
                "linkTo": "Lier le ticket à",
                "optionnalEntity": "Laissez vide pour assigner au réseau {operator}",
                "specificEvse": "Spécifier des PdC (toute la station par défaut)",
                "subscribeTo": "Abonner des utilisateurs",
                "title": "Fix - Nouveau ticket",
                "titleScope": "Créer un ticket pour {name}"
            },
            "dashboard": {
                "automaticActionsApplied": "Fermées automatiquement",
                "createdAt": "Créé le {date} par {email}",
                "edit": "Modifier",
                "entityFilter": "Rechercher",
                "exportAll": "Tout exporter",
                "filterSelection": "{number} entités sélectionnées",
                "new": "Nouveau ticket",
                "show": "Voir",
                "showMoreBtn": "Voir plus",
                "statusFilter": "Filtrer par état",
                "title": "Fix - Tableau de bord",
                "updatedAt": "Mis à jour le {date} par {email}",
                "withMsg": "Messages et descriptions inclus"
            },
            "duplicate": {
                "duplicate": "Dupliquer",
                "issueTitle": "Titre du ticket",
                "linkMessages": "Dupliquer les messages",
                "linkModels": "Dupliquer les entités",
                "linkTags": "Dupliquer les tags",
                "linkTasks": "Dupliquer les tâches",
                "success": "Ticket dupliqué",
                "title": "Dupliquer un ticket"
            },
            "emergency-issue-title": {
                "LOCKED_CABLE": "Urgence : Câble bloqué ({evseRef})",
                "SECURITY": "Urgence : Problème de sécurité ({evseRef})"
            },
            "entity": {
                "connectors": "Connecteurs",
                "devices": "CBID",
                "evses": "PdC",
                "lans": "Réseau local",
                "locations": "Station",
                "networks": "Réseau",
                "status": "Etat",
                "supervisors": "Assigné",
                "tags": "Tags"
            },
            "history": {
                "title": "Fix - Historique"
            },
            "list": {
                "action": "Actions",
                "assigned": "Assignés",
                "author": "Auteur",
                "automaticActionsApplied": "Fermeture automatique",
                "begin": "Date de début",
                "breadcrumb0": "Maintenance curative",
                "breadcrumb1": "Maintenance préventive",
                "breadcrumb3": "Informations",
                "closeSelection": "Fermer sélection",
                "closedAt": "Fermé le",
                "closedBy": "Fermé par",
                "completedAt": "Terminé le",
                "confirmClose": "Fermer le ticket",
                "correctiveTitle": "Tickets de maintenance curative",
                "correctiveTitleScope": "Tickets de maintenance curative de {name}",
                "created": "Date de création",
                "description": "Description",
                "end": "Date d’échéance",
                "entities": "Entité(s) liée(s)",
                "historyTitleScope": "Historique des tickets de {name}",
                "incidentDate": "Incident le",
                "informationTitle": "Informations",
                "informationTitleScope": "Informations de {name}",
                "interventionAt": "Intervention le",
                "name": "Nom",
                "preventiveTitle": "Tickets de maintenance préventive",
                "preventiveTitleScope": "Tickets de maintenance de {name}",
                "startedAt": "Pris en charge le",
                "status": "Etat",
                "tags": "Tags",
                "tasks": "Tâches",
                "title": "Titre",
                "type": "Type de maintenance",
                "updateSelection": "Mettre à jour sélection",
                "updatedAt": "Mis à jour",
                "updatedBy": "Mis à jour par"
            },
            "scope": {
                "createIssue": "Créer un ticket",
                "ongoingIssues": "Tickets en cours : {count}"
            },
            "show": {
                "about": "Freshmile Fix, ticket #{id}",
                "activity": "Activité",
                "add": "Ajouter",
                "addedBy": "Ajouté par {email}",
                "attachments": "Pièces jointes",
                "close": "Fermer le ticket",
                "closedAt": "Fermé le {date}",
                "closedAtBy": "Fermé le {date} par {email}",
                "comments": "Commentaires",
                "completeClose": "Vous pouvez compléter les dates manquantes avant de fermer le ticket",
                "completedDate": "Date de résolution",
                "confirmDelete": "Voulez-vous effacer la valeur ?",
                "confirmDeleteFile": "Voulez-vous effacer le fichier ?",
                "confirmDeleteTask": "Voulez-vous supprimer la tâche ?",
                "deleteMessageAnswers": "Supprimer les réponses ?",
                "deleteMessageTitle": "Voulez-vous effacer le message ?",
                "description": "Description",
                "download": "Télécharger",
                "interventionDate": "Date d'intervention",
                "lastUpdate": "Mise à jour: {date}",
                "lastUpdateBy": "Mise à jour: {date} par {email}",
                "mailBody": "Bonjour,%0D%0A%0D%0ANous vous invitons à consulter le ticket \"{name}\" : {url}%0D%0A%0D%0ACordialement,",
                "messageComment": "Commenter",
                "messageEdit": "Modifier",
                "messageReply": "Répondre",
                "messages": "Messages",
                "noMessage": "Pas de message pour le moment",
                "noTask": "Pas de tâche pour le moment",
                "noUpload": "Pas de pièce-jointe pour le moment",
                "original": "Ticket original :",
                "reopen": "Rouvrir le ticket",
                "replyTo": "Réponse à {email}",
                "resolvedBy": "Résolu par {email} le {date}",
                "subscribe": "S'abonner",
                "subscribed": "Abonnés",
                "taskDescription": "Description de la tâche",
                "title": "Fix #{id}",
                "todoList": "Liste des tâches",
                "unsubscribe": "Se désabonner",
                "update": "Mettre à jour",
                "urlPrompt": "Entrez une URL:"
            },
            "stats": {
                "emergency": {
                    "average_between_opening_intervention_emergencies": "Délai moyen d'intervention d'urgence",
                    "emergency-by-evse-table": {
                        "title": "Classement des stations avec le nombre de panne d’urgence le plus élevé"
                    },
                    "title": "Statistiques de maintenance Fix d’urgence",
                    "total_ticket_emergencies_fix": "Total de tickets Fix d’urgence crées"
                },
                "maintenance": {
                    "issue-count-table": {
                        "title": "Classement des stations avec le nombre de pannes le plus élevé"
                    },
                    "issue-type-table": {
                        "title": "Classement des types de pannes du réseau"
                    },
                    "title": "Statistiques de maintenance Fix",
                    "total_tickets_fix_created_by_network": "Total de tickets Fix crées",
                    "total_tickets_fix_resolve_by_network": "Total de tickets Fix résolus"
                },
                "network": {
                    "average_between_opening_intervention": "Délai moyen d’intervention",
                    "average_failures_occurrence_resolution": "Délai moyen de résolution",
                    "evse-unavailability-table": {
                        "title": "Classement des points de charge avec le taux d’indisponibilité et le plus élevé en heure"
                    },
                    "title": "Statistiques de maintenance générale du réseau"
                },
                "noIssue": "Aucun ticket",
                "pdf": {
                    "fileName": "Fix – Statistiques",
                    "issue_type": "Types de panne",
                    "rate": "Taux (nombre)"
                },
                "title": "Fix – Statistiques"
            },
            "status": {
                "ABANDONED": "Abandonné",
                "AWAITING": "En attente",
                "AWAITING_REPORT": "En attente de CR",
                "BEING_PROCESSED": "Pris en charge",
                "COMPLETED": "Terminé",
                "NEW": "Nouveau",
                "ONGOING": "En cours",
                "PLANNED": "Planifié",
                "dashboard": {
                    "CORRECTIVE": {
                        "AWAITING": "En attente",
                        "BEING_PROCESSED": "Prise en charge",
                        "NEW": "Nouveau"
                    },
                    "PREVENTIVE": {
                        "AWAITING": "Programmée",
                        "AWAITING_REPORT": "En attente de CR",
                        "ONGOING": "Prise en charge",
                        "PLANNED": "À planifier"
                    }
                }
            },
            "supervisor": {
                "closed": "Fermer",
                "open": "Ouvert",
                "title": "Mes tickets"
            },
            "tags": {
                "addTitle": "Ajouter un tag",
                "color": "Couleur",
                "confirmDelete": "Voulez-vous supprimer le tag ?",
                "editTitle": "Modifier un tag",
                "title": "Gestion des tags"
            },
            "title": "Fix",
            "toast": {
                "issue": {
                    "clipboard": "Lien du ticket copié",
                    "updated": "Ticket mis à jour"
                },
                "message": {
                    "delete": "Message supprimé",
                    "updated": "Message mis à jour"
                },
                "quote": {
                    "failed": "Impossible de citer un utilisateur"
                },
                "subscription": {
                    "add": {
                        "failed": "Impossible d'abonner l'utilisateur à ce ticket",
                        "success": "Utilisateur abonné avec succès"
                    },
                    "remove": {
                        "failed": "Impossible de désabonner l'utilisateur de ce ticket",
                        "success": "Utilisateur désabonné avec succès"
                    }
                },
                "tag": {
                    "added": "Tag créé avec succès",
                    "deleted": "Tag supprimé",
                    "updated": "Tag mis à jour"
                },
                "task": {
                    "added": "Tâche ajoutée",
                    "deleted": "Tâche supprimée",
                    "updated": "Tâche mise à jour"
                },
                "upload": {
                    "added": "Fichier(s) ajouté(s)",
                    "deleted": "Fichier(s) supprimé(s)",
                    "ignored": "Les fichiers suivants ont été ignorés car trop lourds ({max} kB) : {files}"
                }
            },
            "type": {
                "corrective": "Maintenance curative",
                "information": "Informations",
                "preventive": "Maintenance préventive",
                "soonLate": "Retards et échéances proches"
            }
        },
        "general": {
            "edition": "Edition de {name}",
            "of": "de {data}"
        },
        "header": {
            "alerts": "Gérer les alertes",
            "cdr": "CDRs",
            "contact": "Contacts",
            "dashboard": "Tableau de bord",
            "device": "CBID",
            "evse": "PdC",
            "fix": {
                "dashboard": "Fix - Tableau de bord",
                "history": "Fix - Historique",
                "stats": "Fix - Statistiques",
                "tags": "Tags",
                "title": "Fix"
            },
            "generateQRcodes": "Générer des QR codes",
            "generateRetro": "Générateur des .pdf de rétrocession",
            "invoice": "Factures",
            "location": "Stations",
            "locations_mass_import": "Import en masse de stations",
            "logout": "Déconnexion",
            "network": "Réseaux",
            "others": "Autres",
            "reports": "Activations",
            "reservation": "Réservations",
            "retrocessionGroups": "Groupes de rétrocessions",
            "retrocessionLogs": "Logs de rétrocessions",
            "retrocessions": "Rétrocessions",
            "role": "Rôle:",
            "session": "Sessions",
            "settings": "Paramètres",
            "stats": "Statistiques",
            "statsInSubmenu": "Générales",
            "tariff": "Tarifs",
            "ticket": "Tickets",
            "translations": "Traductions"
        },
        "languages": {
            "de": "Allemand",
            "en": "Anglais",
            "es": "Espagnol",
            "fr": "Français",
            "gb": "Anglais",
            "gn": "Désactiver la traduction",
            "nl": "Néerlandais",
            "undefined": "Indéfini"
        },
        "lans": {
            "create": {
                "breadcrumb": "Nouveau LAN",
                "feedback": "LAN créé",
                "title": "Nouveau LAN dans {name}"
            },
            "edit": {
                "breadcrumb": "Modifier",
                "feedback": "LAN modifié",
                "title": "Modifier"
            },
            "form": {
                "addLan": "Créer LAN",
                "availableEvses": "PdC disponible",
                "chargeboxid": "Chargebox ID",
                "chargeboxidAvailable": "Chargebox ID disponible",
                "chargeboxidUnavailable": "Chargebox ID indisponible",
                "description": "Description",
                "deviceDefinition": "Optionnel => définition de la borne",
                "deviceProtocol": "Protocole",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "Modifier LAN",
                "gateway": "Gateway",
                "gatewayIsInvalid": "Passerelle invalide",
                "isDhcp": "DHCP activé",
                "lan": "LAN",
                "lanCreation": "Nouveau LAN",
                "lanCreationError": "Erreur",
                "lanCreationSuccess": "LAN créé",
                "lanDescription": "Description du LAN",
                "lanDeviceCreation": "Nouveau LAN",
                "lanName": "Nom",
                "localIp": "Adresse IP locale",
                "localPort": "Port local",
                "model": "Modèle",
                "modelPlaceholder": "Modèle",
                "name": "Nom",
                "newLanName": "LAN n°",
                "offlineEvses": "PdC hors ligne",
                "optional": "(optionnel)",
                "proxy": "Proxy",
                "publicIp": "Adresse IP publique",
                "publicIpIsInvalid": "Adresse IP publique invalide",
                "publicPort": "Port public",
                "selectLocation": "Station",
                "submit": "Créer une nouvelle borne",
                "subnetMask": "Masque de sous-réseau",
                "subnetMaskIsInvalid": "Masque de sous-réseau invalide",
                "technicalInfos": "Information technique",
                "unknownModel": "Modèle inconnu",
                "url": "URL",
                "wirelessLogic": "Wireless Logic"
            },
            "list": {
                "breadcrumb": "LAN",
                "create": "Créer",
                "gateway": "Passerelle",
                "lanDevices": "Bornes",
                "name": "Nom",
                "proxy": "Proxy",
                "publicIp": "Adresse IP publique",
                "subnetMask": "Masque de sous-réseau",
                "title": "Réseaux locaux de {name}",
                "updatedAt": "Mis à jour"
            },
            "proxies": {
                "MAIN": "Principal",
                "NONE": "Aucun",
                "WIRELESSLOGIC": "Wireless Logic"
            },
            "show": {
                "addDevice": "Créer un CBID",
                "connectedDevices": "CBID connectés",
                "description": "Description",
                "deviceIsConnected": "Connecté",
                "edit": "Modifier",
                "gateway": "Passerelle",
                "lastUpdate": "Mis à jour",
                "name": "Nom",
                "nat": "NAT",
                "protocol": "Protocole",
                "proxy": "Proxy",
                "publicIp": "Adresse IP publique",
                "subnetMask": "Masque de sous-réseau",
                "url": "URL"
            }
        },
        "locations": {
            "create": {
                "breadcrumb": "Créer une station",
                "cpoTitle": "Nouvelle station dans {name}",
                "feedback": "Station créée",
                "locationsList": "Stations",
                "title": "Nouvelle station"
            },
            "edit": {
                "edit": "Modifier",
                "feedback": "Station modifiée"
            },
            "form": {
                "addOpeningHourFor": "Ajouter un horaire d'ouverture pour {day}",
                "address": "Adresse",
                "asyncSearch": "Rechercher par nom, adresse, ville, code postal",
                "chargingWhenClosed": "Charge pendant la fermeture",
                "city": "Ville",
                "country": "Pays",
                "countryPlaceholder": "Pays",
                "cpoPlaceholder": "Réseau",
                "directions": "Informations d'accès",
                "displayMap": "Afficher la carte",
                "emi3": "ID eMI3",
                "facilities": "Services",
                "facilitiesPlaceholder": "Services",
                "hideMap": "Cacher la carte",
                "informations": "Information",
                "invalidHourMessage": "Invalide",
                "latitude": "Latitude",
                "latitudePlaceholder": "Latitude",
                "localisation": "Station",
                "longitude": "Longitude",
                "longitudePlaceholder": "Longitude",
                "name": "Nom",
                "network": "Réseau",
                "openingHoursInfos": "Horaires d'ouverture",
                "optional": "(optionnel)",
                "postalcode": "Code postal",
                "resetPosition": "Réinitialiser la position",
                "submit": "Enregistrer",
                "time_zone": "Fuseau horaire",
                "twentyfourseven": "Ouvert 24\/7",
                "type": "Type",
                "typePlaceholder": "Type"
            },
            "list": {
                "addLocation": "Créer une station",
                "breadcrumb": "Stations",
                "chargePoints": "Points de charge",
                "city": "Ville",
                "country": "Pays",
                "cpoId": "ID réseau",
                "cpoTitle": "Stations de {name}",
                "emi3": "ID eMI3",
                "name": "Nom",
                "timeZone": "Fuseau horaire",
                "title": "Stations",
                "type": "Type",
                "updatedAt": "Mis à jour"
            },
            "show": {
                "address": "Adresse",
                "chargingWhenClosed": "Charge possible pendant la fermeture",
                "city": "Ville",
                "country": "Pays",
                "cpo": "Réseau",
                "directions": "Informations d'accès",
                "edit": "Modifier",
                "emi3": "ID eMI3",
                "facilities": "Services",
                "lastUpdate": "Mis à jour",
                "localisation": "Station",
                "location": "Station",
                "locationInfos": "Informations de la station",
                "locations": "Stations",
                "no": "Non",
                "noRegularHours": "Aucun horaire d’ouverture configuré",
                "openingHoursInfos": "Horaires d'ouverture",
                "twentyfourseven": "Ouvert 24\/7",
                "type": "Type",
                "yes": "Oui"
            }
        },
        "login": {
            "email": "Adresse e-mail",
            "intro": "Suivez votre réseau",
            "loginSubmit": "Connexion",
            "password": "Mot de passe",
            "passwordReset": "Modifier le mot de passe"
        },
        "maintenance-alert": {
            "commons": {
                "list": {
                    "last-valid-session-date": "Dernière session valide"
                }
            },
            "downtime": {
                "list": {
                    "subtitle": "Liste des CBID ayant perdus la communication au moins 12 heures",
                    "title": "Pertes de communication"
                }
            },
            "error-code": {
                "list": {
                    "subtitle": "Liste des connecteurs ayant remontés un code erreur depuis au moins 1h",
                    "title": "Codes erreurs"
                }
            },
            "no-energy-rate": {
                "list": {
                    "no-energy-count": "Nbr sans conso",
                    "no-energy-rate": "% sans conso",
                    "subtitle": "Liste de CBID ayant au moins 20% de sessions valides avec des remontées d’énergie 0 kWh sur les 30 derniers jours.",
                    "title": "Absences d’énergie",
                    "with-energy-count": "Nbr avec conso"
                }
            },
            "session-failure-rate": {
                "list": {
                    "failure-rate": "% invalides (<30 jours)",
                    "invalid-count": "Nbr invalid",
                    "subtitle": "Liste de CBID ayant au moins 60% de sessions invalides sur les 30 derniers jours.",
                    "title": "Ratios charges",
                    "valid-count": "Nbr valide"
                }
            },
            "usage-rate": {
                "list": {
                    "session-count-last30d": "Nbr sessions (<30 jours)",
                    "session-count-prior-month": "Nbr sessions (30;60 jours)",
                    "subtitle": "Liste de CBID ayant enregistré une baisse d’au moins 50% d’utilisation par rapport aux 30 derniers jours.",
                    "title": "Baisses d’utilisation",
                    "usage-rate": "% de baisse"
                }
            }
        },
        "networks": {
            "list": {
                "breadcrumb": "Réseaux"
            }
        },
        "notifications": {
            "DOWNTIME": "Pertes de communication",
            "ERROR_CODE": "Codes erreurs",
            "NO_ENERGY_RATE": "Absences d'énergie",
            "SESSION_FAILURE_RATE": "Ratios charges",
            "USAGE_RATE": "Baisses d'utilisation",
            "confirmSelection": "Confirmer la sélection",
            "selectAll": "Tout sélectionner",
            "tabs": {
                "handled": "Traité",
                "unhandled": "En attente"
            },
            "text": "Maintenance"
        },
        "ocpi": {
            "locationFacilities": {
                "AIRPORT": "Aéroport",
                "BUS_STOP": "Arrêt de bus",
                "CAFE": "Café",
                "CARPOOL_PARKING": "Covoiturage",
                "FUEL_STATION": "Station-service",
                "HOTEL": "Hôtel",
                "MALL": "Centre commercial",
                "MUSEUM": "Musée",
                "NATURE": "Nature",
                "RECREATION_AREA": "Aire de loisirs",
                "RESTAURANT": "Restaurant",
                "SPORT": "Sport",
                "SUPERMARKET": "Supermarché",
                "TAXI_STAND": "Station de taxis",
                "TRAIN_STATION": "Gare",
                "WIFI": "Wifi"
            },
            "locationTypes": {
                "ON_STREET": "Parking en voirie",
                "OTHER": "Autre",
                "PARKING_GARAGE": "Parking en silo",
                "PARKING_LOT": "Parking",
                "UNDERGROUND_GARAGE": "Parking souterrain",
                "UNKNOWN": "Inconnu"
            },
            "logs": {
                "CLIENT": "Client",
                "REQUEST": "Requête",
                "RESPONSE": "Réponse",
                "SERVER": "Serveur",
                "breadcrumb": "OCPI",
                "context": "Contexte",
                "date": "Date",
                "distant_operator": "Opérateur",
                "filterPlaceholder": "Recherche par message",
                "message": "Message",
                "method": "Méthode",
                "model": "Objet",
                "module": "Module",
                "ocpi": "Logs OCPI",
                "request_type": "Type",
                "title": "Logs OCPI de {name}",
                "way": "Direction"
            },
            "parkingRestrictions": {
                "CUSTOMERS": "Réservé aux clients",
                "DISABLED": "Réservé aux PMR",
                "EV_ONLY": "Réservé aux VE",
                "MOTORCYCLES": "Réservé aux deux-roues",
                "PLUGGED": "Réservé aux VE en charge"
            },
            "priceComponentTypes": {
                "DURATION": "Durée",
                "ENERGY": "Energie",
                "FLAT": "Forfait",
                "PARKING_TIME": "Temps de parking",
                "STOP": "Stop",
                "TIME": "Temps de charge"
            },
            "reservationStatus": {
                "ACTIVE": "Actif",
                "CANCELLED": "Annulé",
                "ERROR": "Erreur",
                "FINISHED": "Terminé",
                "NOSHOW": "Ne s'est pas présenté"
            }
        },
        "ocpp": {
            "logs": {
                "REQUEST": "Requête",
                "RESPONSE": "Réponse",
                "breadcrumb": "Logs OCPP",
                "filterPlaceholder": "Recherche par message",
                "message": "Message",
                "protocol": "Protocole",
                "requestType": "Type",
                "sentBy": "Emetteur",
                "title": "Logs OCPP de {name}"
            }
        },
        "operators": {
            "capabilities": {
                "CHARGING_PROFILE_CAPABLE": "Profil de charge",
                "CREDIT_CARD_PAYABLE": "Terminal de paiement",
                "REMOTE_START_STOP_CAPABLE": "Recharge par smartphone",
                "RESERVABLE": "Réservation",
                "RESET_CAPABLE": "Redémarrage",
                "RFID_READER": "RFID",
                "UNLOCK_CAPABLE": "Déverrouillage à distance",
                "info": {
                    "CHARGING_PROFILE_CAPABLE": "Bientôt disponible",
                    "CREDIT_CARD_PAYABLE": "Les utilisateurs peuvent payer à la borne avec une carte bancaire",
                    "REMOTE_START_STOP_CAPABLE": "Les utilisateurs peuvent démarrer et arrêter la charge par smartphone",
                    "RESERVABLE": "Les utilisateurs peuvent réserver un point de charge",
                    "RESET_CAPABLE": "L'assistance peut redémarrer la borne à distance",
                    "RFID_READER": "Les utilisateurs peuvent démarrer et arrêter la charge avec une carte RFID",
                    "UNLOCK_CAPABLE": "L'assistance peut déverrouiller les câbles à distance"
                }
            },
            "configuration": {
                "breadcrumb": "Configurations",
                "capability_set_by_parent": "Ne peut pas être modifié car défini par le parent {emi3}",
                "manyEvsesWarning": "Tous les points de charge se mettront à jour, le processus peut durer un certain temps. Merci de patienter",
                "needSubmit": "Enregistrer pour confirmer les modifications",
                "technics": "Configuration technique",
                "title": "Configuration de {name}"
            },
            "create": {
                "feedback": "Réseau créé",
                "subCPOTitle": "Nouveau sous réseau dans {name}",
                "title": "Nouveau réseau"
            },
            "edit": {
                "breadcrumb": "Modifier",
                "feedback": "Réseau mis à jour",
                "title": "Modifier"
            },
            "form": {
                "allowIssueClose": "Autoriser la fermeture automatique d'un ticket",
                "allowIssueCloseDesc": "L'option sera proposée à la création du ticket. Si modifiée, cela n'impactera pas les tickets existants.",
                "businessInfos": "Informations générales",
                "businessName": "Nom",
                "businessURL": "Site web",
                "comInterval": "Intervalle de communication",
                "commission": "Commission et frais bancaires",
                "consoInterval": "Intervalle de comptage d'énergie",
                "crc": "CRC",
                "defaultTariff": "Tarif par défaut",
                "defaultTariffPlaceholder": "Tarif par défaut",
                "defaultVAT": "TVA par défaut",
                "detectionLoop": "Capteur de présence",
                "emi3IsUndefined": "ID eMI3 non défini",
                "emi3SuccessMessage": "est disponible",
                "emi3UnavailableMessage": "Cet ID eMI3 n'est pas diponible, merci d'en essayer un autre",
                "generateEmi3": "Générer",
                "heartbeat_interval": "Intervalle de heartbeat",
                "inheritance": "Étendre aux sous-réseaux",
                "initialState": "Etat initial",
                "interopConfig": "Configuration des accès",
                "invalidEmi3Message": "n'est pas un ID eMI3 valide, merci d'en essayer un autre",
                "maintenance": "Maintenance",
                "metervalues_sample_interval": "Intervalle de comptage d'énergie",
                "name": "Nom",
                "optional": "(optionnel)",
                "parent": "Parent",
                "parentPlaceholder": "Choisir un parent",
                "presence": "Capteur de présence",
                "privateEmi3": "ID eMI3 privé",
                "publicEmi3": "ID eMI3 public",
                "publicEmi3Placeholder": "Choisir un ID eMI3",
                "refPlaceholder": "Peut se compléter avec le nom",
                "refUnavailableMessage": "Cette référence n'est pas disponible, merci d'en essayer une autre",
                "retrocession": "Rétrocession",
                "retrocessionGroup": "Groupe de rétrocession",
                "sameAsParent": "Identique au parent",
                "sameAsPrivate": "Cet opérateur",
                "segment": "Segment",
                "submit": "Enregistrer",
                "support": "Support",
                "timezone": "Fuseau horaire",
                "timezonePlaceholder": "Fuseau horaire"
            },
            "hotline": {
                "phoneNumber": "Numéro de téléphone",
                "schedule": "Horaires",
                "schedules": {
                    "Mon-Fri 7\/22": "Du lundi au vendredi, de 7h à 22h",
                    "Mon-Fri 9\/17": "Du lundi au vendredi, de 9h à 17h",
                    "Mon-Fri H24": "Du lundi au vendredi, 24h sur 24",
                    "Mon-Sun 7\/22": "Tous les jours, de 7h à 22h",
                    "Mon-Sun 9\/17": "Tous les jours, de 9h à 17h",
                    "Mon-Sun H24": "Tous les jours, 24h sur 24"
                }
            },
            "list": {
                "VAT": "TVA",
                "breadcrumb": "Réseaux",
                "children": "Sous-réseaux",
                "create": "Créer un réseau",
                "default_tariff": "Tarif",
                "emi3": "ID eMI3",
                "evses": "Points de charge",
                "exportWithchildrens": "Inclure les enfants",
                "linkedCPOTitle": "Réseaux liés à {name}",
                "locations": "Stations",
                "name": "Nom",
                "network": "Réseau",
                "ongoingIssues": "Tickets ouverts",
                "operator": "Réseau",
                "operatorsList": "Réseaux",
                "parent": "Parent",
                "parent_id": "ID parent",
                "parent_ref": "ID parent",
                "public_emi3": "ID eMI3 public",
                "segment": "Segment",
                "subCPOTitle": "Sous-réseaux de {name}",
                "title": "Réseaux",
                "updated_at": "Mis à jour"
            },
            "map": {
                "breadcrumb": "Carte",
                "noLocation": "Il n'y a pas de station dans ce réseau",
                "options": {
                    "all": "Tous",
                    "charging": "Charge en cours",
                    "issue": "En panne"
                },
                "title": "Carte de {name}"
            },
            "segments": {
                "COMPANIES": "Entreprise",
                "RESIDENTIAL": "Résidentiel",
                "ROADS": "Voirie",
                "SHOPS": "Commerce",
                "UNINFORMED": "Non renseigné"
            },
            "show": {
                "VAT": "TVA",
                "business": "Général",
                "businessName": "Nom",
                "businessURL": "Site web",
                "crc": "CRC",
                "maintenance": "Maintenance",
                "parent": "Parent",
                "privateEmi3": "ID eMI3 privé",
                "publicEmi3": "ID eMI3 public",
                "segment": "Segment",
                "support": "Support",
                "tariff": "Tarif",
                "timezone": "Fuseau horaire",
                "undefined": "Non défini"
            }
        },
        "pagination": {
            "next": "Suivant &raquo;",
            "previous": "&laquo; Précédent"
        },
        "passwords": {
            "password": "Au moins 6 lettres ou chiffres. Les deux mots de passe doivent correspondre",
            "reset": "Modifier le mot de passe",
            "sent": "Email envoyé",
            "token": "Erreur",
            "user": "Identifiants incorrects"
        },
        "payment": {
            "iban": "Prélèvement automatique",
            "methods": {
                "expires:": "Expire le :",
                "invalid": "Invalide",
                "valid": "Valide"
            },
            "refunds": {
                "addFormTitle": "Effectuer un remboursement",
                "alreadyProcessed": "Remboursement déjà effectué",
                "amountExceedRefund": "Le montant dépasse le prix du remboursement",
                "amountIncVat": "Montant (TTC)",
                "confirmDelete": "Souhaitez-vous réellement suppprimer ce remboursement ?",
                "confirmDeleteTitle": "Supprimer le remboursement",
                "created": "Remboursement créé",
                "deleted": "Remboursement supprimé",
                "editFormTitle": "Modifier un remboursement",
                "edited": "Remboursement modifié",
                "export": "Exporter les remboursements",
                "highAmountWarn": "Remboursement supérieur à 100 {currency}",
                "inRetrocession": "Intégré au rétrocession",
                "includeRetro": "A intégrer aux rétrocession",
                "method": "Méthode",
                "moveConnection": "Vous devez être connecté à Move pour pouvoir traiter les remboursements.",
                "processedAt": "Effectué le",
                "reason": "Justification",
                "refund": "Rembourser",
                "refundIssue": "Il se peut qu'il y ait une erreur dans le remboursement du compte stripe ou prépayé.",
                "refunded": "Remboursé",
                "session": "Remboursement session du {date} sur {evse_ref}",
                "stripeAutoRefund": "Le remboursement automatique via Stripe est impossible, veuillez rembourser manuellement l'utilisateur.",
                "subscription_with_order": "Remboursement souscription à l'abonnement \"{plan_name}\" (commande du {order_date}).",
                "subscription_without_order": "Remboursement souscription à l'abonnement \"{plan_name}\".",
                "token_with_order": "Remboursement badge n°{token_number} (commande du {order_date}).",
                "token_without_order": "Remboursement badge n°{token_number}.",
                "way": {
                    "manual": "Manuel",
                    "prepaid": "Solde",
                    "prepaid_account": "Solde",
                    "stripe": "Stripe"
                }
            },
            "status": {
                "AUTHORIZED": "Autorisé",
                "CANCELLED": "Annulé",
                "CAPTURED": "Prélevé",
                "DONE": "Validé",
                "FAILED": "Echoué",
                "PENDING_USER": "En attente",
                "REFUNDED": "Remboursé"
            },
            "transaction_labels": {
                "credit": "Crédit de {amount} {CURRENCY} sur le compte prépayé",
                "credit_invoice_refund": "Remboursement de la facture {invoice_ref}",
                "debit": "Débit de {amount} {CURRENCY} du compte prépayé",
                "invoice": "Facture {ref}",
                "order": "Commande {ref}",
                "payment": "Facture {invoice_ref} (Paiement {payment_ref})",
                "session": "Charge {ref}",
                "subscription": "Abonnement {ref} (jusqu'à {end_at})"
            }
        },
        "reservations": {
            "add": "Créer",
            "authId": "Contrat",
            "authIdPlaceholder": "Doit correspondre à un eMAID de Move",
            "breadcrumb": "Réservations",
            "calendar": "Calendrier",
            "cancel": "Annuler",
            "cancelationIsSuccessful": "Réservation annulée",
            "create": {
                "authId": "Contrat",
                "end": "Fin",
                "locationId": "Station",
                "start": "Début",
                "submit": "Enregistrer",
                "title": "Nouvelle réservation"
            },
            "creationIsSuccessful": "Réservation créée",
            "day": "Jour",
            "list": {
                "breadcrumb": "Réservation"
            },
            "location": "Station",
            "locationTitle": "Réservations dans {name}",
            "month": "Mois",
            "noReservationsThatDay": "Pas de réservation ce jour",
            "noReservationsThatMonth": "Pas de résevation ce mois",
            "noReservationsThatWeek": "Pas de réservation cette semaine",
            "reservation": "Réservation",
            "status": {
                "ACTIVE": "En cours",
                "CANCELLED": "Annulée",
                "ERROR": "Erreur",
                "FINISHED": "Terminée",
                "NOSHOW": "Ne s’est pas présenté"
            },
            "time": "Heure",
            "title": "Réservations",
            "week": "Semaine"
        },
        "retrocession-group-logs": {
            "list": {
                "breadcrumb": "Logs de rétrocession",
                "created_at": "Date",
                "message": "Message",
                "retrocessionGroupName": "Nom du groupe de rétrocession",
                "scopeTitle": "Logs de rétrocession de {name}",
                "title": "Logs de rétrocession",
                "type": "Event",
                "user": "Superviseur"
            },
            "types": {
                "ChangeCommission": "Modification de la commission",
                "ChangeName": "Modification du nom",
                "ChangeNetwork": "Modification du réseau",
                "ChangeRib": "Modification du RIB"
            }
        },
        "retrocession-groups": {
            "create": {
                "breadcrumb": "Créer un groupe de rétrocessions",
                "title": "Créer un groupe de rétrocessions"
            },
            "edit": {
                "title": "Modifier un groupe de rétrocessions"
            },
            "form": {
                "addExisting": "Ajouter un groupe de rétrocession existant",
                "applyPastSessions": "Appliquer les modifications à des sessions passées",
                "applyPastSessionsMessage": "Attention, il s’agît d’une opération lourde pouvant prendre quelques secondes voire quelques minutes",
                "assign": "Assigner un groupe de rétrocession existant",
                "asyncSearch": "Sélectionner un groupe existant",
                "commission": "Commissions et frais bancaires",
                "contact": "Contact de gestion administrative (optionnel)",
                "create": "Créer un nouveau groupe de rétrocession",
                "general": "Générale",
                "group": "Groupe de rétrocession",
                "name": "Nom"
            },
            "iban": {
                "bankAccountName": "Intitulé du compte",
                "bic": "BIC",
                "errorBic": "Le bic doit être dans un format valide de la zone SEPA",
                "errorIban": "L'IBAN doit être dans un format valide de la zone SEPA",
                "iban": "IBAN",
                "modalDeleteConfirm": "Le RIB a été supprimé avec succès",
                "modalDeleteContent": "Si vous supprimez le RIB de ce groupe de rétrocession, nous ne pourront pas vous verser vos rétrocessions.",
                "modalDeleteError": "Une erreur est survenue lors de la suppression du RIB",
                "modalDeleteTitle": "Supprimer un RIB",
                "modalDeleteWarning": "Attention, vous vous apprêtez à supprimer un RIB.",
                "modalUpdateTitle": "Modifier le RIB du groupe de rétrocession"
            },
            "list": {
                "breadcrumb": "Groupes de retrocessions",
                "commission": "Commissions et frais bancaires",
                "contact": "Contact de gestion administrative",
                "created_at": "Date de création",
                "has_bank_info": "RIB renseigné",
                "name": "Nom du groupe de rétrocession",
                "nb_networks": "Nombre de réseaux",
                "title": "Groupes de rétrocessions"
            },
            "network": {
                "add": "Ajouter",
                "continue": "Continuer",
                "create": "Créer un nouveau groupe",
                "removeMessage": "Vous vous apprêtez à retirer le groupe de rétrocession de {name}. En retirant le groupe de rétrocession de {name}, vous le retirez également de tous ses sous-réseaux.",
                "removeTitle": "Retirer le groupe de rétrocession",
                "replace": "Remplacer le groupe de rétrocession"
            },
            "show": {
                "bankInfo": "Informations bancaires",
                "commission": "Commissions et frais bancaires",
                "contact": "Contact de gestion administrative",
                "contactDetail": "Contact",
                "created_at": "Date de création",
                "general": "Informations générales",
                "name": "Nom du groupe de rétrocession",
                "rib": "RIB"
            },
            "toast": {
                "added": "Le groupe de rétrocession a été assigné avec succès",
                "removed": "Le groupe de rétrocession a été retiré avec succès",
                "session-updated": "Les sessions ont été mises à jour avec succès"
            }
        },
        "retrocessions": {
            "exportRefundsOfRetrocession": "Export des déductions de rétrocession",
            "modal": {
                "amountExceedInitialAmount": "Le montant de la rétrocession ne peut pas dépasser le prix initial",
                "amountWithVat": "Montant TTC",
                "createSuccess": "La rétrocession a bien été créée",
                "deleteSuccess": "La rétrocession a bien été supprimée",
                "editSuccess": "La rétrocession a bien été modifiée",
                "reason": "Justification",
                "retrocede": "Rétrocéder",
                "sessionRetrocession": "Rétrocession de session"
            },
            "refundOfRetrocession": "Déduction de rétrocession"
        },
        "sessions": {
            "authorizationReasons": {
                "AUTHORIZED_BY_CPO": "CPO",
                "AUTHORIZED_BY_EMSP": "EMSP",
                "NOT_AUTHORIZED_BY_ANY_EMSP": "Pas d'EMSP",
                "NO_AUTHORIZATION_CHECK": "Non vérifié",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Fournisseur d'énergie",
                "PAYMENT_TERMINAL": "TPE",
                "UNKNOWN": "Inconnu",
                "description": {
                    "AUTHORIZED_BY_CPO": "Autorisé par le CPO",
                    "AUTHORIZED_BY_EMSP": "Autorisé par l'EMSP",
                    "NOT_AUTHORIZED_BY_ANY_EMSP": "Autorisé par aucun EMSP",
                    "NO_AUTHORIZATION_CHECK": "Pas d'autorisation demandée par la borne",
                    "ORDERED_BY_ELECTRICITY_SUPPLIER": "Ordre reçu du fournisseur d'énergie",
                    "PAYMENT_TERMINAL": "Terminal de paiement électronique sur site"
                }
            },
            "create": {
                "agreementsList": "Accords",
                "breadcrumb": "Créer",
                "title": "Créer un accord"
            },
            "csv": {
                "confirmTitle": "CSV du {from} au {to}",
                "default": "Confirmer l'export ?",
                "retrocessionExporterTitle": "Export pour rétrocessions",
                "tooHeavy": "Le fichier est en cours de création et sera envoyé par email à {email}"
            },
            "curve": {
                "breadcrumb": "Courbes",
                "title": "Courbes pour #{ref}"
            },
            "edit": {
                "agreementsList": "Accords",
                "breadcrumb": "Modifier",
                "title": "Modifier l'accord"
            },
            "failureReasons": {
                "AUTHORIZATION_TIMEOUT": "Expiré",
                "EMI3_MINIMUM_DURATION": "< 2 min",
                "INACTIVE": "Inactive",
                "LOCATION_FULLY_BOOKED": "Emplacement réservé",
                "NO_AUTHORIZATION": "Non autorisé",
                "UNKNOWN": "Inconnu",
                "description": {
                    "AUTHORIZATION_TIMEOUT": "L'autorisation a expiré",
                    "EMI3_MINIMUM_DURATION": "Application des seuils recommandés par eMI3",
                    "NO_AUTHORIZATION": "Autorisé par aucun EMSP"
                }
            },
            "form": {
                "bridges": "Via",
                "cpo": "Réseau",
                "emsp": "Fournisseur",
                "emspPlaceholder": "Rechercher un fournisseur",
                "includeSubCpos": "Inclure les sous-réseaux",
                "includeSubEmsps": "Inclure les sous-fournisseurs",
                "isActive": "Actif",
                "ocpiModules": "Modules OCPI",
                "searchPlaceholder": "Ecrire ici",
                "submit": "Enregistrer"
            },
            "list": {
                "active": "Actif",
                "addAgreement": "Créer un accord",
                "all": "Tous",
                "authorizationReason": "Motif d'autorisation",
                "breadcrumb": "Sessions",
                "completed": "Effectué",
                "connector": "Connecteur",
                "contrat": "Contrat",
                "cpoTitle": "Sessions de {name}",
                "end": "Fin",
                "evse": "PdC",
                "evseTitle": "Sessions de {name}",
                "failureReason": "Motif d'échec",
                "free": "Gratuit",
                "invalid": "Invalide",
                "kwh": "kWh",
                "locationTitle": "Sessions de {name}",
                "no": "Non",
                "ocpiModules": "Modules OCPI",
                "ongoing": "En cours",
                "optionsSelected": "Options sélectionnées",
                "pending": "En attente",
                "price": "Prix",
                "source": "Origine",
                "start": "Début",
                "station": "Station",
                "status": "Etat",
                "stopReceivedAt": "Facturation",
                "tag": "Badge",
                "tariff": "Tarif",
                "tariffPos": "Tarif TPE",
                "title": "Sessions",
                "withChildrenOperators": "Sessions des sous-réseaux",
                "yes": "Oui"
            },
            "show": {
                "agreements": "Accords",
                "auth": "Autorisation",
                "badge": "Badge",
                "bridges": "Ponts",
                "cpo": "Réseau",
                "curveBreadcrumb": "Courbe",
                "curveTitle": "Courbe pour",
                "edit": "Modifier",
                "emsp": "Fournisseur",
                "energy": "Energie",
                "energyLabel": "Energie (kWh)",
                "false": "Faux",
                "finalConsumption": "Energie finale",
                "fullPrice": "{amount} {currency}",
                "includeSubCpos": "Inclure les sous-réseaux",
                "includeSubEmsps": "Inclure les sous-fournisseurs",
                "isActive": "Actif",
                "isWhiteListed": "En liste blanche",
                "lastPeriod": "Dernière période",
                "lastUpdate": "Mis à jour =>",
                "noBridges": "Direct",
                "notEnoughChargingData": "Pas assez de données pour tracer une courbe",
                "ongoing": "En cours",
                "periodDate": "Date",
                "power": "Puissance",
                "powerLabel": "Puissance (kW)",
                "preTaxPrice": "{amount} HT, {vat}% TVA",
                "price": "Prix",
                "relations": "Relations",
                "statusIsInvalid": "Session échouée. Pas de charge",
                "statusIsPending": "Sessions non démarrée",
                "stopReceivedAt": "Date de facturation",
                "stopSession": "Arrêter manuellement",
                "stopSuccess": "Session arrêtée",
                "title": "Session #{ref}",
                "true": "Vrai"
            },
            "source": {
                "CPO_REMOTE_ORDER": "CPO",
                "EMSP_REMOTE_ORDER": "EMSP",
                "STATION": "Borne"
            },
            "status": {
                "active": "En cours",
                "completed": "Terminé",
                "invalid": "Invalide",
                "pending": "En attente",
                "valid": "Valide"
            }
        },
        "settings": {
            "accessibleOperators": "Opérateurs accessibles (réseaux auxquels vous avez accès)",
            "changeLang": "Changer la langue",
            "currentPermission": "Votre rôle actuel :",
            "darkTheme": "Thème sombre",
            "display": "Personnaliser mon affichage",
            "fix": {
                "disabled": {
                    "detail": "Vous ne recevrez aucune notification",
                    "title": "Désactivé"
                },
                "fromDisabled": {
                    "toMonitoring": "Vous serez abonné à tous les tickets de votre réseau",
                    "toParticipating": "Vous serez abonné à tous les tickets auxquels vous êtes assigné, et désinscrit de tous les autres",
                    "toQuoting": "Vous serez désabonné de tous les tickets, vous recevrez les notifications lorsque vous serez mentionné dans un ticket"
                },
                "fromMonitoring": {
                    "toDisabled": "Vous serez désabonné de tous les tickets",
                    "toParticipating": "Vous serez abonné à tous les tickets auxquels vous êtes assigné, et désinscrit de tous les autres",
                    "toQuoting": "Vous serez désabonné de tous les tickets, vous recevrez les notifications lorsque vous serez mentionné dans un ticket"
                },
                "fromParticipating": {
                    "toDisabled": "Vous serez désabonné de tous les tickets",
                    "toMonitoring": "Vous serez abonné à tous les tickets de votre réseau",
                    "toQuoting": "Vous serez désabonné de tous les tickets, vous recevrez les notifications lorsque vous serez mentionné dans un ticket"
                },
                "fromQuoting": {
                    "toDisabled": "Vous serez désabonné de tous les tickets",
                    "toMonitoring": "Vous serez abonné à tous les tickets de votre réseau",
                    "toParticipating": "Vous serez abonné à tous les tickets auxquels vous êtes assigné, et désinscrit de tous les autres"
                },
                "isSubscribable": "Je ne souhaite pas être abonné à des tickets par d'autres superviseurs",
                "monitoring": {
                    "detail": "Par défaut, vous serez abonné à tous les tickets de votre réseau. Vous recevrez donc des notifications pour n’importe quelle activité.",
                    "title": "Surveillance"
                },
                "participating": {
                    "detail": "Vous serez abonné à tous les tickets auxquels vous êtes assigné, mentionné ou abonné.",
                    "title": "Participation"
                },
                "quoting": {
                    "detail": "Vous recevrez des notifications que lorsque vous serez mentionné dans un ticket ou un commentaire. Vous ne serez pas abonné à ces tickets.",
                    "title": "En cas de mention"
                },
                "title": "Paramètres de notifications mail pour Fix"
            },
            "fixedHeight": "Figer les volets (garder à l'écran le nom des colonnes lors du défilement)",
            "hasAtLeastOneEmspInfo": "* Dans Park vous ne pouvez pas sélectionner de fournisseur",
            "others": "Autres",
            "preview": "Aperçu",
            "reduceMargin": "Réduire les marges",
            "success": "Préférences enregistrées",
            "switchPermission": "Changer de rôle",
            "tables": "Listes",
            "title": "Paramètres",
            "truncateHeader": "Réduction des noms de colonnes"
        },
        "sidebar": {
            "items": {
                "actions": "Actions",
                "affiliations": "Affectations",
                "agreements": "Accords",
                "audit": "Activité",
                "business": "Logs métier",
                "cdrs": "CDRs",
                "configurations": "Configurations",
                "corrective": "Maintenance curative",
                "costDetails": "Détails du coût",
                "curve": "Courbes",
                "details": "Détails",
                "devices": "Chargebox ID",
                "duplicate": "Duplicate",
                "edit": "Modifier",
                "evses": "Points de charge",
                "fix": "Fix",
                "groups": "Groupes",
                "history": "Historique",
                "information": "Informations",
                "lans": "Réseaux locaux",
                "linked_networks": "Réseaux liés",
                "locations": "Stations",
                "logs": "Logs",
                "map": "Carte",
                "ocpi": "Logs OCPI",
                "ocpp": "Logs OCPP",
                "preventive": "Maintenance préventive",
                "reservations": "Réservations",
                "sessions": "Sessions",
                "subcpos": "Sous-réseaux",
                "tariffs": "Tarifs"
            }
        },
        "station-check": {
            "breadcrumb": {
                "logs": "Logs",
                "report": "Mise en service"
            },
            "csv": {
                "confirmTitle": "Confirmer votre exportation"
            },
            "list": {
                "device_ok": "Infos PdC correctes",
                "location_ok": "Infos station correctes",
                "nb_cbids": "Nombre de CBID",
                "nb_fail": "Echec",
                "nb_success": "Testés & réussis",
                "nb_tested": "Testés",
                "title": "Mise en service de bornes"
            },
            "logs": {
                "emptyEmailReplace": "Installateur",
                "keys": {
                    "lan_device_commissioned": "CBID mise en service",
                    "lan_device_feedback_comment": "Infos CBID Commentaire ajouté",
                    "lan_device_feedback_comment_withdrawn": "Infos CBID Commentaire retiré",
                    "lan_device_feedback_ok": "Infos CBID correctes",
                    "lan_device_feedback_resolved": "CBID eedback résolu",
                    "lan_device_feedbacks_all_done": "Toutes les infos des CBID sont traitées",
                    "lan_device_test_is_online_ko": "Test connection KO",
                    "lan_device_test_is_online_ok": "Test connection OK",
                    "lan_device_test_remote_orders_ko": "Test Commandes à distance KO",
                    "lan_device_test_remote_orders_ok": "Test Commandes à distance OK",
                    "lan_device_validated": "CBID validée",
                    "report_archived_automatically": "Rapport archivé automatiquement",
                    "report_archived_manually": "Rapport archivé manuellement",
                    "report_location_feedback_ok": "Infos station correctes",
                    "report_location_feedback_updated": "Infos station mises à jour",
                    "report_status_incomplete": "Statut du rapport incomplet",
                    "report_status_to_ko": "Statut du rapport KO",
                    "report_status_to_ok": "Statut du rapport OK"
                },
                "tableContact": "Contact lié",
                "tableMessage": "Message",
                "tableType": "Type",
                "title": "Logs d’activation de",
                "type": {
                    "LanDeviceFeedback": "Feedback",
                    "LanDeviceTest": "Tests",
                    "Location": "Station",
                    "Supervision": "Supervision"
                }
            },
            "show": {
                "address": "Adresse",
                "allPaid": "Tous payants",
                "archiveReport": "Archiver ce rapport",
                "archived": "Archivé",
                "buttonValidateWithCommissioning": "Valider sélection avec mise en service",
                "buttonValidateWithoutCommissioning": "Valider sélection sans mise en service",
                "cbidUntested": "Non testé",
                "comment": "Signalement",
                "commentHistory": "Historique commentaires globaux",
                "commissioned": "En service",
                "company": "Entreprise",
                "dateGeneration": "Date génération du statut",
                "dateUpdate": "Date de mise à jour",
                "email": "Adresse email",
                "firstname": "Prénom",
                "fitter": "Aménageur",
                "fitterSay": "Installateur dit",
                "informationsStation": "Informations de la station",
                "installerHistory": "Historique installateurs",
                "is_online": "Test de connexion",
                "listCbidTested": "Liste des CBID OK",
                "listUntestedAndFailedCbid": "Liste des CBID NOK",
                "modalArchivedConfirmation": "Le rapport a bien été archivé.",
                "modalArchivedConfirmationFailed": "Le rapport n'a pas pu être archivé.",
                "modalArchivedContent": "Vous vous apprêtez à archiver ce rapport.",
                "modalArchivedQuestion": "Voulez-vous continuer ?",
                "modalArchivedTitle": "Archiver un rapport",
                "modalCommentConfirmation": "Le commentaire a bien été marqué comme résolu.",
                "modalCommentConfirmationFailed": "La résolution du commentaire a échoué.",
                "modalCommentContent": "Vous vous apprêtez à marquer ce commentaire comme résolu. Cela aura pour effet de le faire disparaître.",
                "modalCommentQuestion": "Voulez-vous continuer ?",
                "modalCommentTitle": "Marquer comme résolu",
                "modalFailedCommissioningContent": "Il semblerait que la connexion aux bornes suivantes ait été perdue depuis le dernier test.",
                "modalFailedCommissioningTitle": "Mise en service de la borne échouée",
                "name": "Nom",
                "noCbidToDisplay": "Aucun CBID à afficher ici",
                "noComments": "Aucun commentaire",
                "numberOfCbid": "Nombre de CBID",
                "ocpp_numbers_missmatch": "Attention les connector ID ne semblent pas correspondre, veuillez consulter les logs",
                "ocpp_numbers_not_reported": "Aucun connector Id remonté par la borne",
                "oneIsFree": "Au moins un gratuit",
                "phone": "Téléphone",
                "pointOfCharge": "Points de charge",
                "pricing": "Tarif",
                "remote_orders_ok": "Test des ordres à distance",
                "report": "Signalement",
                "selectAllCbid": "Sélectionner tous les CBID",
                "showOcppLogs": "Voir les logs",
                "status": "Statut",
                "statusOf": "Statut de",
                "terminalComments": "Commentaires",
                "testerMostRecent": "Informations de l’installateur le plus récent",
                "testsStatus": "Etat des tests",
                "timestamp": "Timestamp",
                "toValidated": "A valider",
                "validated": "validé",
                "waitingCommissioning": "En attente de mise en service"
            },
            "status": {
                "archived": "Archivés",
                "incomplete": "Incomplet",
                "nok": "NOK",
                "ok": "Prêt"
            }
        },
        "stats": {
            "CO2AvoidedEmissions": "CO2",
            "CarbonMonoxideAvoidedEmissions": "CO",
            "DistanceAvoidedThermalVehicle": "Distance évitée en véhicules thermiques",
            "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbures et NOx",
            "IncomeByNetwork": "Chiffre d'affaires par réseau (TTC)",
            "IncomeByProvider": "Chiffre d'affaires par fournisseur (TTC)",
            "IncomePerLocation": "Chiffre d'affaires par station (TTC)",
            "MTBF": "MTBF",
            "MTTR": "MTTR",
            "NoxAvoidedEmissions": "NOx",
            "ParticleAvoidedEmissions": "Particules",
            "SavedFuel": "Consommation de carburant évitée",
            "SessionsByConnectorType": "Sessions par connecteur",
            "SessionsByConsumption": "Sessions par consommation",
            "SessionsByDay": "Sessions par jour",
            "SessionsByDuration": "Sessions par durée",
            "SessionsByHours": "Sessions par heure",
            "SessionsByMonth": "Sessions par mois",
            "SessionsByNetwork": "Sessions par réseau",
            "SessionsByPower": "Sessions par puissance",
            "SessionsByProvider": "Sessions par fournisseur",
            "SessionsPerLocation": "Sessions par station",
            "availability": "Disponibilité",
            "availabilityDuration": "Durée de disponibilité",
            "average": "Moyenne",
            "avoidedEmissions": "Emissions évitées",
            "descriptions": {
                "AberrationRate": "Taux d'aberration",
                "AverageChargingDuration": "Tant que le véhicule soutire de l'énergie à la borne",
                "AverageConnectionDuration": "Tant que le véhicule est branché à la borne, incluant le temps pendant lequel le véhicule est garé et a fini de charger",
                "AverageConsumptionBySession": "Consommation moyenne par session",
                "CO2AvoidedEmissions": "Emissions de CO2 évitées grâce à l'utilisation de VE ayant chargé sur les bornes",
                "CarbonMonoxideAvoidedEmissions": "Emissions de CO évitées grâce à l'utilisation de VE ayant chargé sur les bornes",
                "CleanSessionNumber": "Nombre de sessions qualifiées",
                "DistanceAvoidedThermalVehicle": "Estimation de la distance parcourue par les VE ayant chargé sur les bornes",
                "EffectiveChargingOverConnectionRate": "Ratio du temps de charge effectif \/ durée totale pendant laquelle le véhicule est branché à la borne",
                "ExcludedSession": "Sessions exclues",
                "HydrocarbonAndNoxAvoidedEmissions": "Emissions d'hydrocarbures et NOx évitées",
                "MTBF": "Temps moyen entre les pannes",
                "MTTR": "Temps moyen de temps de réparation",
                "NoxAvoidedEmissions": "Emissions de NOx évitées grâce à l'utilisation de VE ayant chargé sur les bornes",
                "ParticleAvoidedEmissions": "Emissions de particules évitées grâce à l'utilisation de VE ayant chargé sur les bornes",
                "SavedFuel": "Calculé sur la base de la répartition des motorisations du parc actuel de véhicules thermiques dans l'UE",
                "SessionNumber": "Nombre de sessions",
                "SuccessConsumption": "Consommation des sessions qualifiées",
                "TotalChargingDuration": "Tant que le véhicule soutire de l'énergie à la borne",
                "TotalConnectionDuration": "Tant que le véhicule est branché à la borne, incluant le temps pendant lequel le véhicule est garé et a fini de charger",
                "TotalConsumption": "Consommation de toutes les sessions",
                "availabilityDuration": "Durée de disponibilité",
                "availability_device_count": "Bornes",
                "availability_evse_count": "PdC",
                "averageIncome": "Panier moyen des charges payantes. Les charges gratuites sont exclues du calcul",
                "average_charge_time": "Tant que le véhicule soutire de l'énergie à la borne",
                "average_plugin_time": "Tant que le véhicule est branché à la borne, incluant le temps pendant lequel le véhicule est garé et a fini de charger",
                "device_offline_duration": "Bornes - durée hors ligne",
                "device_offline_duration_rate": "Bornes - taux hors ligne",
                "evse_available_duration_rate": "PdC - taux de disponibilité",
                "evse_failure_count": "Pannes",
                "evse_unavailable_duration": "PdC - durée des indisponibilités",
                "evse_unavailable_duration_rate": "PdC - taux d'indisponibilité",
                "failed_session_count": "Nombre de sessions considérées \"échouées\" (< 2 min ou < 500 Wh)",
                "failed_session_rate": "Ratio sessions \"échouées\" \/ nombre total de sessions",
                "numberOfIssues": "Nombre de problèmes",
                "offlineDuration": "Durée hors ligne",
                "sessionIssueRatio": "Ratio des sessions ayant connu un problème",
                "successful_session_consumption": "Consommation totale des sessions qualifiées",
                "successful_session_count": "Les sessions qualifiées sont les sessions de charge supérieures à 2 minutes",
                "successful_session_rate": "Ratio sessions qualifiées \/ nombre total de sessions",
                "totalIncome": "Chiffre d'affaires total",
                "total_charge_time": "Tant que le véhicule soutire de l'énergie à la borne",
                "total_plugin_time": "Tant que le véhicule est branché à la borne, incluant le temps pendant lequel le véhicule est garé et a fini de charger",
                "total_session_consumption": "Consommation totale",
                "total_session_count": "Les statistiques sont calculées sur le nombre de sessions qualifiées uniquement",
                "unavailabilityDuration": "Durée d'indisponibilité"
            },
            "g": "g",
            "ie": "c'est-à-dire",
            "kg": "kg",
            "km": "km",
            "l": "litres",
            "labels": {
                "LessThan10": "< 10",
                "LessThan120": "< 120",
                "LessThan1440": "< 1440",
                "LessThan15": "< 15",
                "LessThan20": "< 20",
                "LessThan240": "< 240",
                "LessThan30": "< 30",
                "LessThan40": "< 40",
                "LessThan480": "< 480",
                "LessThan5": "< 5",
                "LessThan60": "< 60",
                "LessThan720": "< 720",
                "MoreThan1440": "> 1440",
                "MoreThan60": "> 60",
                "SessionsByConsumption": {
                    "_0_5_kwh": "0 ≤ kWh < 5",
                    "_10_20_kwh": "10 ≤ kWh < 20",
                    "_20_40_kwh": "20 ≤ kWh < 40",
                    "_40_60_kwh": "40 ≤ kWh < 60",
                    "_5_10_kwh": "5 ≤ kWh < 10",
                    "_60_kwh": "≥ 60 kWh"
                },
                "SessionsByDuration": {
                    "_0_15_min": "0 ≤ min < 15",
                    "_120_240_min": "2 ≤ h < 4",
                    "_1440_min": "≥ 24 h",
                    "_15_30_min": "15 ≤ min < 30",
                    "_240_480_min": "4 ≤ h < 8",
                    "_30_60_min": "30 ≤ min < 60",
                    "_480_720_min": "8 ≤ h < 12",
                    "_60_120_min": "1 ≤ h < 2",
                    "_720_1440_min": "12 ≤ h < 24"
                },
                "SessionsByPower": {
                    "_0_4_kw": "0 ≤ kW < 4",
                    "_100_150_kw": "100 ≤ kW < 150",
                    "_12_22_kw": "12 ≤ kW < 22",
                    "_150_kw": "≥ 150 kW",
                    "_22_50_kw": "22 ≤ kW < 50",
                    "_4_8_kw": "4 ≤ kW < 8",
                    "_50_100_kw": "50 ≤ kW < 100",
                    "_8_12_kw": "8 ≤ kW < 12"
                }
            },
            "liters": "litres",
            "mi": "miles",
            "miles": "miles",
            "noProvider": "Pas de fournisseur (P&C, TPE)",
            "noStats": "Pas de statistiques disponibles",
            "noStatsMessage": "Pas de données pour cette période",
            "numberOfIssues": "Nombre de problèmes",
            "numberOfSessions": "{number} sessions",
            "offlineDuration": "Durée hors ligne",
            "pdf": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbures et NOx",
                "IncomeByNetwork": "Chiffre d'affaires par réseau (TTC)",
                "IncomeByProvider": "Chiffre d'affaires par fournisseur (TTC)",
                "IncomePerLocation": "Chiffre d'affaires par station (TTC)",
                "LessThan10kWh": "≤ 10 kWh",
                "LessThan12": "≤ 12 kW",
                "LessThan12kW": "≤ 12 kW",
                "LessThan20kWh": "≤ 20 kWh",
                "LessThan22": "≤ 22 kW",
                "LessThan22kW": "≤ 22 kW",
                "LessThan4": "≤ 4 kW",
                "LessThan40kWh": "≤ 40 kWh",
                "LessThan4kW": "≤ 4 kW",
                "LessThan5kWh": "≤ 5 kWh",
                "LessThan60kWh": "≤ 60 kWh",
                "LessThan8": "≤ 8 kW",
                "LessThan8kW": "≤ 8 kW",
                "MWh": "MWh",
                "MoreThan22": "Toutes les sessions",
                "MoreThan22kW": "Toutes les sessions",
                "MoreThan60kWh": "Toutes les sessions",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Particules",
                "SessionsByConnectorType": "Répartition par connecteur",
                "SessionsByConsumption": "Sessions par consommation",
                "SessionsByConsumptionTitle": "Sessions par consommation",
                "SessionsByDay": "Sessions par jour",
                "SessionsByDayTitle": "Sessions par jour",
                "SessionsByDuration": "Sessions par durée",
                "SessionsByDurationTitle": "Sessions par durée de charge",
                "SessionsByHours": "Sessions par heure",
                "SessionsByMonth": "Sessions par mois",
                "SessionsByMonthTitle": "Sessions par mois",
                "SessionsByNetwork": "Sessions par réseau",
                "SessionsByPower": "Sessions par puissance",
                "SessionsByPowerTitle": "Sessions par puissance",
                "SessionsByProvider": "Sessions par fournisseur",
                "SessionsHistogramme": "Histogramme de fréquentation",
                "SessionsPerLocation": "Sessions par station",
                "aggregatedDuration": "Durée agrégée",
                "amountCurrency": "Montant en {currency}",
                "availability": "Disponibilité",
                "average": "Moyenne",
                "averageBasket": "Panier moyen",
                "averageChargeTime": "Temps de charge moyen",
                "averageCostBySession": "Coût moyen par session",
                "averageDuration": "Durée moyenne en charge",
                "averagePluginTime": "Durée moyenne connecté",
                "chargeTime": "Durée de session",
                "connector": "Connecteur",
                "consumption": "Consommation",
                "data": "Données",
                "distanceAvoidedThermalVehicle": "Distance évitée en véhicules thermiques",
                "duration": "Durée",
                "environmentImpact": "Impact environnemental",
                "environmentInfos": "Informations environnementales",
                "fileName": "cpo-stats-",
                "group": "Fournisseur",
                "hhmmss": "h,m,s",
                "income": "Chiffre d'affaires (TTC)",
                "kWh": "kWh",
                "kg": "kg",
                "km": "km",
                "lessThan15Min": "≤ 15 min",
                "lessThan1Hour": "≤ 1 heure",
                "lessThan2Hour": "≤ 2 heures",
                "lessThan30Min": "≤ 30 min",
                "lessThan4Hour": "≤ 4 heures",
                "liters": "litres",
                "location": "Station",
                "mainIndicators": "Indicateurs principaux",
                "miles": "miles",
                "month": "Mois",
                "moreThan4Hour": ">  4 heures",
                "network": "Réseau",
                "nodata": "Pas assez de données",
                "number": "Nombre",
                "numberOfSessions": "Nombre de sessions",
                "occupationRate": "Taux d'occupation",
                "operator": "Fournisseur",
                "others": "Autres",
                "percent": "Pourcentage",
                "period": "Période",
                "pluginTime": "Durée de charge",
                "savedFuel": "Carburant économisé",
                "sessionSuccessRate": "Taux de sessions qualifiées",
                "successfulSessions": "Sessions qualifiées",
                "title": "Statistiques",
                "total": "Total",
                "weekDay": "Jour de semaine"
            },
            "percentageOfDuration": "{percentage}% de la durée totale",
            "q1": "1er trimestre",
            "q2": "2e trimestre",
            "q3": "3e trimestre",
            "q4": "4e trimestre",
            "rempConvVehicles": "Données environnementales",
            "selectNetworkPlaceholder": "Choisir le réseau",
            "sessionIssueRatio": "Taux de sessions à problème",
            "sessionSimpleStats": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "DistanceAvoidedThermalVehicle": "Distance évitée en véhicules thermiques",
                "EffectiveChargingOverConnectionRate": "Ratio du temps de charge effectif \/ durée totale des sessions",
                "HydrocarbonAndNoxAvoidedEmissions": "Hydrocarbures et NOx",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Particules",
                "SavedFuel": "Carburant économisé",
                "SessionNumber": "Nombre de sessions",
                "average_charge_time": "Durée de charge moyenne",
                "average_conso_by_session": "Consommation moyenne par session",
                "average_plugin_time": "Durée moyenne connecté",
                "chargingDuration": "Durée de charge",
                "consumption": "Consommation",
                "failed_session_count": "Sessions non qualifiées",
                "failed_session_rate": "Taux d'aberration",
                "income": "Chiffre d'affaires (TTC)",
                "sessionDuration": "Durée de session",
                "successful_session_consumption": "Consommation des sessions qualifiées",
                "successful_session_count": "Nombre de sessions qualifiées",
                "successful_session_rate": "Taux de sessions qualifiées",
                "total_charge_time": "Temps de charge total",
                "total_plugin_time": "Temps de connexion total",
                "total_session_consumption": "Consommation totale",
                "total_session_count": "Nombre de sessions total"
            },
            "successOnly": "Sessions qualifiées uniquement",
            "title": "Statistiques",
            "top5Remains": "Autre",
            "total": "Total",
            "totalIncome": "Chiffre d'affaires total : {income}{currency}",
            "totalIncomeLabel": "Chiffre d'affaires total",
            "unavailabilityDuration": "Durée d'indisponibilité"
        },
        "stickers": {
            "bigStickers": "Inclure les stickers 10x10",
            "download": "Télécharger",
            "evses": "Points de charge",
            "exemple": "Exemple de format compatible :",
            "guide": "Pour générer tous les codes QR dans un pdf, veuillez copier et coller votre liste dans la zone de texte et cliquez sur télécharger. Vous pouvez également afficher un aperçu du PDF en cliquant sur le bouton",
            "noEvsesAvailable": "Aucun PdC n'est disponible avec cet opérateur",
            "selectOperator": "Sélectionner un opérateur pour importer des PdC",
            "showPreview": "Afficher l'aperçu PDF",
            "title": "Générateur de code QR pour PdC"
        },
        "supervision": {
            "default_error": "Erreur",
            "downloadRecentBrowser": "Merci de mettre à jour votre navigateur",
            "following_errors": "Erreurs:",
            "not_allowed": "Accès refusé"
        },
        "table": {
            "columns": "Colonnes",
            "count": "Un résultat|Résultats {from} à {to} sur {count} résultats|{count} résultats",
            "defaultOption": "Choisir {column}",
            "filter": "Filtrer :",
            "filterBy": "Filtrer par {column}",
            "filterPlaceholder": "Rechercher",
            "first": "Premier",
            "goToFirstPage": "Début",
            "goToLastPage": "Fin",
            "last": "Dernier",
            "limit": "Résultats :",
            "loadPaging": "Voir le nombre de résultats",
            "loading": "Chargement en cours…",
            "noFilter": "Pas de filtre appliqué",
            "noResults": "Pas de résultat",
            "page": "Page :",
            "undefined": "Indéfini"
        },
        "tags": {
            "assign": "Assigner des tags",
            "asyncSearch": "Rechercher un tag existant",
            "asyncSearchFilter": "Filtrer les tags",
            "delete": "Supprimer ce tag",
            "description": "Description",
            "hideAllTags": "Masquer tous les tags",
            "isCascaded": "Appliquer en cascade",
            "none": "Aucun tag",
            "or": "ou",
            "placeholder": "Placeholder",
            "preview": "Aperçu",
            "removeTagMessage": "Vous vous apprêtez à retirer le tag suivant de ce réseau : \"{tag}\" <br> Souhaitez-vous continuer ?",
            "removeTagTitle": "Retirer un tag",
            "settings": "Paramètres du tag",
            "showAllTags": "Afficher tous les tags",
            "tags": "Tags",
            "title": "Gestion des tags d'objets",
            "toast": {
                "created": "Le tag a été créé avec succès.",
                "deleted": "Le tag a été supprimé avec succès.",
                "updated": "Le tag a été mis à jour avec succès."
            }
        },
        "tariffs": {
            "create": {
                "breadcrumb": "Nouveau tarif",
                "cpoTitle": "Nouveau tarif dans {name}",
                "feedback": "Tarif créé",
                "tariffList": "Tarifs",
                "title": "Nouveau tarif"
            },
            "edit": {
                "breadcrumb": "Modifier",
                "feedback": "Tarif modifié",
                "tariffList": "Tarif",
                "title": "Modifier le tarif"
            },
            "form": {
                "cgv": "Conditions générales (CGU, CGV)",
                "cpo": "Réseau",
                "currency": "Devise",
                "currencyPlaceholder": "Devise",
                "defaultTariffPlaceholder": "Rechercher un tarif",
                "description": "Description",
                "errorNameAlreadyExists": "Un tarif avec le même nom existe déjà",
                "is_private": "Privé",
                "maxPrice": "Prix maximum",
                "name": "Nom",
                "payment_authorization_amount": "Autorisation de paiement TTC",
                "provision": "Provision TTC",
                "refPlaceholder": "Peut se compléter avec le nom",
                "refUnavailableMessage": "Cette référence n'est pas disponible, merci d'en essayer une autre",
                "submit": "Enregistrer",
                "updatedAt": "Mis à jour"
            },
            "list": {
                "addTariff": "Tarif",
                "breadcrumb": "Tarifs",
                "cpoTitle": "Tarifs de {name}",
                "currency": "Devise",
                "elements": "Groupes de prix",
                "globalRestrictions": "Seuil d'application",
                "is_private": "Privé",
                "name": "Nom",
                "network": "Réseau",
                "payment_authorization_amount": "Autorisation de paiement TTC",
                "provision": "Provision TTC",
                "title": "Tarifs",
                "updated_at": "Mis à jour",
                "vat": "TVA"
            },
            "maxPrice": {
                "add": "Créer",
                "confirmDelete": "Confirmer la suppression du prix maximum partagé ?",
                "maxPrice": "Prix maximum",
                "maxPriceDeleted": "Prix maximum supprimé",
                "maxPriceEdited": "Prix maximum modifié",
                "noMaxPrices": "Pas de prix maximum défini",
                "searchAddGroup": "Rechercher ou créer un groupe",
                "title": "Prix maximum partagé",
                "tooltipPriceDelete": "Supprimer le prix partagé"
            },
            "operators": {
                "CONSUMPTION": {
                    "<": "Consommation inférieure à",
                    "<=": "Consommation inférieure ou égale à",
                    ">": "Consommation supérieure à",
                    ">=": "Consommation supérieure ou égale à"
                },
                "DURATION": {
                    "<": "Durée inférieure à",
                    "<=": "Durée inférieure ou égale à",
                    ">": "Durée supérieure à",
                    ">=": "Durée supérieure ou égale à"
                },
                "and": "ET",
                "or": "OU"
            },
            "prices": {
                "DURATION_description": "La facturation dépend de la durée de branchement, indépendamment de la consommation du véhicule",
                "ENERGY_description": "La facturation dépend de la consommation",
                "FLAT_description": "La facturation inclut une part fixe",
                "PARKING_TIME_description": "La facturation dépend du temps de parking, sans consommation électrique",
                "STOP_description": "La session de charge s'arrêtera au-delà d'un certain seuil",
                "TIME_description": "La facturation dépend du temps de charge, avec consommation électrique",
                "addFormTitle": "Nouveau prix",
                "addTooltip": "Créer un nouveau prix",
                "confirmDeletion": "Confirmer la suppression du prix ?",
                "created": "Prix créé",
                "deleteTooltip": "Supprimer le prix",
                "deleted": "Prix supprimé",
                "editFormTitle": "Modifier le prix",
                "editTooltip": "Modifier le prix",
                "edited": "Prix modifié",
                "minutes": "minutes",
                "stepSize": "Pas",
                "type": "Type"
            },
            "restrictions": {
                "allDays": "Tous les jours",
                "consumption": "Energie (kWh)",
                "created": "Groupe de prix créé",
                "date": "Date",
                "days": "Jours",
                "deleteGroupTooltip": "Supprimer groupe",
                "duration": "Durée (minutes)",
                "editTooltip": "Modifier les restrictions",
                "edited": "Restriction modifiée",
                "formTitle": "Restrictions",
                "info": "Les prix ajoutés dans ce groupe sont soumis à des restrictions",
                "invalidConsumption": "Valeur d'énergie invalide",
                "invalidDays": "Choisir au moins un jour",
                "invalidDuration": "Valeur de durée invalide",
                "invalidPower": "Valeur de puissance invalide",
                "max": "Max",
                "min": "Min",
                "power": "Puissance (kW)",
                "price": "Prix",
                "removeDate": "Retirer la date",
                "time_after_consumption_end": "Durée après la fin de consommation (minutes)",
                "updated": "Seuil mis à jour"
            },
            "show": {
                "add": "Ajouter",
                "addCondition": "Ajouter un seuil",
                "addPriceFormTitle": "Créer un prix",
                "breadcrumb": "Tarif",
                "cgvUrl": "URL des conditions générales",
                "conditions": "Seuil d'application",
                "confirmGroupDeletion": "Confirmer la suppression du groupe ?",
                "confirmNotificationDeletion": "Confirmer la suppression des notifications ?",
                "confirmTariffDeletion": "Confirmer la suppression du tarif ?",
                "consumption": "Energie (Wh)",
                "costPerPowerUpdated": "Coût par puissance moyenne mis à jour",
                "cpo": "Réseau",
                "createGroup": "Nouveau groupe",
                "currency": "Devise",
                "date": "Date",
                "days": "Jours",
                "description": "Description",
                "duration": "Durée (minutes)",
                "edit": "Modifier",
                "editPriceFormTitle": "Modifier le prix",
                "elements": "Prix",
                "fixedPrice": "Coût fixe",
                "from": "De",
                "groupDeleted": "Groupe supprimé",
                "hours": "Horaires",
                "invalidConsumption": "Valeur d'énergie invalide",
                "invalidDays": "Choisir au moins un jour",
                "invalidDuration": "Valeur de durée invalide",
                "invalidPower": "Valeur de puissance invalide",
                "is_private": "Privé",
                "lastUpdate": "Mis à jour :",
                "max": "Max",
                "maxPrice": "Prix maximum",
                "min": "Min",
                "minutes": "minutes",
                "mustAddRestriction": "Les prix ajoutés dans ce groupe sont soumis à des restrictions",
                "name": "Nom",
                "noCgv": "Pas de conditions générales",
                "noConditions": "Pas de seuil",
                "noDescription": "Pas de description",
                "noPrices": "Pas de prix",
                "notificationAdded": "Notification évolution de tarif ajoutée",
                "notificationCantBeAdd": "Aucune notification applicable. Le tarif possède une restriction liée à la puissance et\/ou à l'énergie",
                "notificationDeleted": "Notification évolution de tarif supprimée",
                "notificationEmpty": "Pas de notification paramétrée",
                "notificationTitle": "Notification d'évolution de tarif",
                "payment_authorization_amount": "Autorisation de paiement TTC",
                "popup": {
                    "atTimeEvolve": "au moment de",
                    "beforeAndAtTimeEvolve": "avant et au moment de",
                    "beforeEvolve": "avant",
                    "chooseMomentTitle": "Choisissez à quel moment les utilisateurs devront recevoir une notification",
                    "evolveTariffText": "l’évolution tarifaire",
                    "minBefore": "minutes avant",
                    "minutes": "minutes",
                    "sendNotifText": "Envoyer une notification",
                    "sendNotifTitle": "Envoi de la notification",
                    "title": "Paramétrer une notification sur le tarif"
                },
                "power": "Puissance (kW)",
                "powerMax": "Puissance maximum",
                "powerMin": "Puissance minimum",
                "powerTimeTitle": "Puissance moyenne sur durée",
                "price": "Coût à la minute",
                "priceGroup": "Groupe {number}",
                "prices": "Prix",
                "provision": "Provision TTC",
                "restrictionEdited": "Restriction modifiée",
                "restrictionFormTitle": "Restrictions",
                "restrictionIntro": "Définir quand le tarif s'applique",
                "restrictions": "Restrictions",
                "stepSize": "Pas",
                "tariffDeleted": "Tarif supprimé",
                "to": "A",
                "type": "Type",
                "weekDays": "Jours de semaine",
                "weekEnd": "Week-end",
                "wh": "Wh"
            },
            "status": {
                "active": "Actifs",
                "inactive": "Inactifs"
            }
        },
        "validation": {
            "accepted": "{attribute} doit être accepté",
            "active_url": "{attribute} n'est pas une URL valide",
            "after": "{attribute} doit être une date postérieure au {date}",
            "after_or_equal": "{attribute} doit être une date postérieure ou égale au {date}",
            "alpha": "{attribute} ne doit contenir que des lettres",
            "alpha_dash": "{attribute} ne doit contenir que des lettres, chiffres et traits",
            "alpha_num": "{attribute} ne doit contenir que des lettres et des chiffres",
            "array": "{attribute} doit être un tableau",
            "before": "{attribute} doit être une date antérieure au {date}",
            "before_or_equal": "{attribute} doit être une date antérieure ou égale au {date}",
            "between": {
                "array": "{attribute} doit être compris entre {min} et {max}",
                "file": "{attribute} doit être compris entre {min} et {max} ko",
                "numeric": "{attribute} doit être compris entre {min} et {max}",
                "string": "{attribute} doit être compris entre {min} et {max} caractères"
            },
            "boolean": "{attribute} doit être vrai ou faux",
            "confirmed": "{attribute} confirmation ne correspond pas",
            "custom": {
                "attribute-name": {
                    "rule-name": "message personnalisé"
                }
            },
            "date": "{attribute} n'est pas une date valide",
            "date_format": "{attribute} ne correspond pas au format {date}",
            "different": "{attribute} et {other} doivent être différents",
            "digits": "{attribute} doit faire {digits} positions",
            "digits_between": "{attribute} doit faire entre {min} et {max} positions",
            "dimensions": "{attribute} a des dimensions d'image invalides",
            "distinct": "{attribute} a une valeur dupliquée",
            "email": "{attribute} doit être une adresse e-mail valide",
            "exists": "{attribute} est invalide",
            "file": "{attribute} doit être un fichier",
            "filled": "{attribute} doit contenir une valeur",
            "gt": {
                "array": "{attribute} doit contenir plus de {values} items",
                "file": "{attribute} doit être supérieur à {value} ko",
                "numeric": "{attribute} doit être supérieur à {value}",
                "string": "{attribute} doit être supérieur à {value} caractères"
            },
            "gte": {
                "array": "{attribute} doit être supérieur à {value} objets",
                "file": "{attribute} doit être supérieur ou égal à {value} ko",
                "numeric": "{attribute} doit être supérieur ou égal à {value}",
                "string": "{attribute} doit être supérieur ou égal à {value} caractères"
            },
            "image": "{attribute} doit être une image",
            "in": "{attribute} est invalide",
            "in_array": "{attribute} n'existe pas dans {other}",
            "integer": "{attribute} doit être un entier",
            "ip": "{attribute} doit être une adresse IP valide",
            "ipv4": "{attribute} doit être une adresse IPv4 valide",
            "ipv6": "{attribute} doit être une adresse IPv6 valide",
            "json": "{attribute} doit être un string JSON valide",
            "lt": {
                "array": "{attribute} doit contenir moins de {value} objets",
                "file": "{attribute} doit faire moins de {value} ko",
                "numeric": "{attribute} doit être inférieur à {value}",
                "string": "{attribute} doit être inférieur à {value} caractères"
            },
            "lte": {
                "array": "{attribute} doit être inférieur à {value}",
                "file": "{attribute} doit être inférieur ou égal à {value} ko",
                "numeric": "{attribute} doit être inférieur ou égal à {value}",
                "string": "{attribute} doit être inférieur ou égal à {value} caractères"
            },
            "max": {
                "array": "{attribute} doit être inférieur à {value} objets",
                "file": "{attribute} doit être inférieur à {value} ko",
                "numeric": "{attribute} doit être inférieur à {max}",
                "string": "{attribute} doit être inférieur à {max} caractères"
            },
            "mimes": "{attribute} doit être une fichier de type {values}",
            "mimetypes": "{attribute} doit être une fichier de type {values}",
            "min": {
                "array": "{attribute} doit contenir au moins {min} objets",
                "file": "{attribute} doit faire au moins {min} ko",
                "numeric": "{attribute} doit faire au moins {min}",
                "string": "{attribute} doit faire au moins {min} caractères"
            },
            "not_in": "{attribute} est invalide",
            "not_regex": "{attribute} a un format invalide",
            "numeric": "{attribute} doit être un nombre",
            "present": "{attribute} doit avoir un champ présent",
            "regex": "{attribute} doit avoir un format valide",
            "required": "{attribute} est requis",
            "required_if": "{attribute} est requis quand {other} est {value}",
            "required_unless": "{attribute} est requis sauf si {other} est {value}",
            "required_with": "{attribute} est requis quand {values} est présent",
            "required_with_all": "{attribute} est requis quand {values} est présent",
            "required_without": "{attribute} est requis quand {values} est absent",
            "required_without_all": "{attribute} est requis quand aucun des values sont présents",
            "same": "{attribute} et {other} doivent correspondre",
            "size": {
                "array": "{attribute} doit contenir {size} objets",
                "file": "{attribute} doit faire {size} ko",
                "numeric": "{attribute} doit faire {size}",
                "string": "{attribute} doit faire {size} caractères"
            },
            "string": "{attribute} doit être une chaîne",
            "timezone": "{attribute} doit être une chaîne valide",
            "unique": "{attribute} est déjà pris",
            "uploaded": "{attribute} n'a pas pu être téléchargé",
            "url": "{attribute} a un format invalide"
        }
    },
    "nl": {
        "agreements": {
            "create": {
                "agreementsList": "Overeenkomsten",
                "breadcrumb": "Toevoegen",
                "feedback": "Overeenkomst is succesvol toegevoegd",
                "title": "Een nieuwe overeenkomst toevoegen"
            },
            "edit": {
                "agreementsList": "Overeenkomsten",
                "breadcrumb": "Bewerken",
                "feedback": "Overeenkomst is succesvol bijgewerkt",
                "title": "Overeenkomsten bewerken"
            },
            "form": {
                "alreadyExists": "Er bestaat al een overeenkomst tussen {cpo} - {emsp}",
                "cpo": "Netwerk",
                "emsp": "Aanbieder",
                "emspPlaceholder": "Zoek een aanbieder",
                "includeSubCpos": "Inclusief sub-netwerken",
                "includeSubEmsps": "Inclusief sub-aanbieders",
                "isActive": "Actief",
                "ocpiModules": "OCPI-modules",
                "searchPlaceholder": "Typ hier",
                "submit": "Opslaan",
                "tariffPlaceholder": "Geen tarief vastgesteld",
                "viaRoaming": "Via het interoperabiliteitsplatform"
            },
            "list": {
                "addAgreement": "Overeenkomst toevoegen",
                "autoliquidation": "Omgekeerde lading",
                "breadcrumb": "Overeenkomsten (lijst)",
                "cpoId": "Netwerk ID",
                "directFilter": "Inclusief bovenliggende netwerken",
                "emspId": "Provider ID",
                "includeSubCpos": "Inclusief sub-netwerken",
                "includeSubEmsps": "Inclusief sub-aanbieders",
                "no": "Nee",
                "noOcpiModules": "Geen OCPI-modules",
                "ocpiModules": "OCPI-modules",
                "optionsSelected": "Geselecteerde opties",
                "tariff": "Specifiek tarief",
                "title": "Overeenkomsten",
                "updated_at": "Laatste update",
                "yes": "Ja"
            },
            "show": {
                "agreements": "Overeenkomsten",
                "bridges": "Bruggen",
                "cpo": "Netwerk",
                "edit": "Bewerken",
                "emsp": "Aanbieder",
                "false": "Fout",
                "includeSubCpos": "Inclusief sub-netwerken",
                "includeSubEmsps": "Inclusief sub-aanbieders",
                "lastUpdate": "Laatste update",
                "noBridges": "Direct",
                "true": "Waar"
            }
        },
        "alert": {
            "alerts": "Waarschuwingen",
            "colorTitle": "Bericht kleur",
            "confirmPublish": "Ben je zeker dat je deze waarschuwing wilt plaatsen?",
            "confirmUnPublish": "Ben je zeker dat je deze waarschuwing wilt verwijderen?",
            "createTitle": "Een nieuwe waarschuwing aanmaken",
            "currentAlert": "Waarschuwing is actief",
            "delete": "Huidige waarschuwing verwijderen",
            "disableFailed": "Het is niet gelukt de waarschuwing te verwijderen",
            "disabled": "De waarschuwing is verwijderd",
            "editStatuses": "Waarschuwingsstatus bewerken",
            "editTitle": "De huidige signalering wijzigen",
            "messageClosed": "Deze waarschuwingen kan je in het tabblad \"Instellingen” vinden",
            "newAlert": "Nieuwe waarschuwing",
            "publishFailed": "Waarschuwing niet gepubliceerd",
            "publishSuccessful": "De waarschuwing is gepubliceerd",
            "showOnlyActive": "Toon Alleen actieve waarschuwingen",
            "title": "Waarschuwingen beheren",
            "type": {
                "danger": "Voorval",
                "info": "Informatie",
                "success": "Resolutie",
                "warning": "Onderhoud"
            },
            "unPublishFailed": "Het is niet gelukt de waarschuwing te verwijderen",
            "unPublishSuccessful": "De waarschuwing is verwijderd.",
            "updateFailed": "Fout bij het verwijderen van waarschuwingen",
            "updated": "De waarschuwing is gewijzigd"
        },
        "audit": {
            "ACTION": "Ondernomen actie",
            "CREATE": "Toegevoegd",
            "DELETE": "Verwijderd",
            "READ": "Geraadpleegd op",
            "RESTORE": "Hersteld",
            "UPDATE": "Bijgewerkt",
            "UPDATE_TRANSLATION": "Vertalingen zijn bijgewerkt",
            "breadcrumb": "Activiteit",
            "noActivity": "Geen activiteit",
            "title": "Activiteit van {naam}",
            "via": {
                "BACKOFFICE": "Back-office",
                "CLI": "CLI",
                "JOBS": "Automatische taak",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "SUPERVISION": "Toezicht"
            }
        },
        "auth": {
            "confirmPassword": "Wachtwoord bevestigen",
            "emailSent": "E-mail is verstuurd",
            "failed": "Verkeerde inloggegevens",
            "login": "Inloggen",
            "passResetSuccess": "Wachtwoord gewijzigd",
            "permanent_ban": "Vanwege te veel inlogpogingen is uw account gedeactiveerd. Neem contact op met uw beheerder.",
            "register": "Aanmelden",
            "reset": "Wachtwoord wijzigen",
            "sendLink": "Stuur link om wachtwoord te wijzigen",
            "successLogout": "Succesvol uitgelogd",
            "temporary_ban": "U heeft te veel inlogpogingen gedaan: Probeer het later opnieuw.",
            "throttle": "Te veel pogingen. Probeer het opnieuw in {seconds} seconden"
        },
        "businessLogs": {
            "breadcrumb": "Logboek",
            "byEvse": "Filter op EVSE",
            "context": "Context",
            "contextValues": {
                "CLI": "Server",
                "JOBS": "Taak",
                "NOVA": "Back-office",
                "OCPI": "OCPI",
                "OCPP": "OCPP",
                "OCPPJ": "OCPP-J",
                "SUPERVISION": "Supervisie",
                "WEBSOCKET": "Websocket"
            },
            "diagnosticStatus": {
                "UploadFailed": "Mislukt om kenmerken te versturen",
                "Uploaded": "Kenmerken succesvol verstuurd"
            },
            "event": "Event",
            "eventValues": {
                "ALREADY_COMPLETED_SESSION_ENDED": "Einde van een al voltooide sessie",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Prijs gevraagd bij een al voltooide sessie",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Energie bijgewerkt bij een al voltooide sessie",
                "ALREADY_COMPLETED_SESSION_STARTED": "Start van een al voltooide sessie",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "Herinrichting van het apparaat",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "Start socket",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Verander beschikbaarheid",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Legen van cache",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Krijg diagnose",
                "ATTEMPTING_REMOTE_RESET": "Opnieuw instellen",
                "ATTEMPTING_REMOTE_START": "Op afstand starten",
                "ATTEMPTING_REMOTE_STOP": "Op afstand stoppen",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Ontgrendelen",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Firmware updaten",
                "CONNECTOR_STATUS_UPDATED": "Status van connector",
                "DETECTION_SENSOR_BLOCKED": "Detectie sensor is geblokkeerd",
                "DETECTION_SENSOR_IGNORED": "Detectie sensor is genegeerd",
                "DETECTION_SENSOR_UNBLOCKED": "Blokkade op detectie sensor is opgeheven",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Sensor detecteert onbekende connector",
                "DIAGNOSTIC_STATUS": "Status van de diagnose",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "Apparaat succesvol opnieuw geladen",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "Niet gelukt apparaat opnieuw te laden",
                "ERCOGENER_START_OUTLET_FAILURE": "Stopcontact werkt succesvol",
                "ERCOGENER_START_OUTLET_SUCCESS": "Mislukt om stopcontact te laten werken",
                "EVSE_STATUS_UPDATED": "EVSE status",
                "FIRMWARE_STATUS": "Firmware status",
                "LAN_DEVICE_BOOTED": "Lader start op",
                "LAN_DEVICE_DOWN": "Lader is offline",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Firmware ge-update",
                "LAN_DEVICE_MODEL_CHANGE": "Model ge-update",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Protocol ge-update",
                "LAN_DEVICE_UP": "Laadpunt on-line",
                "LAN_DEVICE_VENDOR_CHANGE": "Verkoper ge-update",
                "LAN_PUBLIC_IP_CHANGE": "IP aangepast",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "Betaalterminal offline fouten rapport",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "Betaalterminal offline succes rapport",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Betaalterminal online fouten rapport",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Betaalterminal online succes rapport",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Veranderen beschikbaarheid mislukt",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Veranderen beschikbaarheid gelukt",
                "REMOTE_CLEAR_CACHE_FAILURE": "Niet gelukt cache te legen",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache succesvol leeg gemaakt",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Mislukt om diagnose te krijgen",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Diagnose succesvol verkregen",
                "REMOTE_RESET_FAILURE": "Reset mislukt",
                "REMOTE_RESET_SUCCESS": "Successvol ge-reset",
                "REMOTE_START_FAILURE": "Op afstand starten mislukt",
                "REMOTE_START_SUCCESS": "Op afstand starten gelukt",
                "REMOTE_STOP_FAILURE": "Op afstand stoppen mislukt",
                "REMOTE_STOP_SUCCESS": "Op afstand stoppen gelukt",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Openen mislukt",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Openen gelukt",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Update firmware mislukt",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Update firmware successvol",
                "SESSION_AUTHORIZED": "Sessie geautoriseerd",
                "SESSION_DENIED": "Sessie geweigerd",
                "SESSION_ENDED": "Sessie beëindigd",
                "SESSION_ENERGY_UPDATED": "Energie bijgewerkt",
                "SESSION_FORCED": "Sessie geforceerd",
                "SESSION_STARTED": "Sessie gestart",
                "SESSION_START_BLOCKED": "Sessie start geblokkeerd",
                "SESSION_TIMED_OUT": "Sessie timed out",
                "SESSION_TOTAL_COST_UPDATED": "Prijs",
                "TOKEN_AUTHORIZE_ACCEPTED": "Geautoriseerde badge",
                "TOKEN_AUTHORIZE_DENIED": "Badge geweigerd",
                "TOKEN_AUTHORIZE_UNKNOWN": "Token not recognized",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Onbekende connector status",
                "UNKNOWN_SESSION_ASKING_PRICE": "Prijs gevraagd door onbekende sessie",
                "UNKNOWN_SESSION_ENDED": "Onbekende sessie beëindigd",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Energie ge-update door onbekende sessie",
                "UNKNOWN_SESSION_STARTED": "Onbekende sessie gestart"
            },
            "evseStatusUpdated": {
                "administrative": "Administratieve status van {previous_status} naar {current_status}",
                "charging": "Laadstatus from {previous_status} naar {current_status}",
                "message": "EVSE {name} van {previous_status} naar {current_status}. Reden: {status_reason}",
                "operational": "Operationale status from {previous_status} naar {current_status}"
            },
            "filterPlaceholder": "Zoeken via context",
            "firmwareStatus": {
                "DownloadFailed": "Download firmware mislukt",
                "Downloaded": "Download firmware geslaagd",
                "InstallationFailed": "Installeren firmware mislukt",
                "Installed": "Installeren firmware geslaagd"
            },
            "lanDeviceStatus": {
                "down": "is niet langer verbonden met de server",
                "up": "is nu verbonden met de server"
            },
            "level": {
                "ALERT": "Waarschuwingslogboek",
                "CRITICAL": "Kritisch logboek",
                "DEBUG": "Debug-logboek",
                "EMERGENCY": "Noodgevallen logboek",
                "ERROR": "Foutenlogboek",
                "INFO": "Info logboek",
                "NOTICE": "Kennisgevingslogboek",
                "WARNING": "Waarschuwingslogboek"
            },
            "message": "Bericht",
            "messages": {
                "ALREADY_COMPLETED_SESSION_ENDED": "Sessie {sessionLink}, al beëindigd, is beëindigd door laadpunt {chargeboxid}",
                "ALREADY_COMPLETED_SESSION_ENDED_ASKING_PRICE": "Laadpunt {chargeboxid} vraagt prijs voor sessie {sessionLink} al beëindigd",
                "ALREADY_COMPLETED_SESSION_ENERGY_UPDATED": "Charger {chargeboxid} updated consumption of session {sessionLink} already ended",
                "ALREADY_COMPLETED_SESSION_STARTED": "Sessie {sessionLink} al beëindigd is geautoriseerd door laadpunt {chargeboxid}",
                "ATTEMPTING_ERCOGENER_REARMING_DEVICE": "(Ercogener) Poging om volgende device opnieuw te laden {chargeboxid}",
                "ATTEMPTING_ERCOGENER_START_OUTLET": "(Ercogener) Poging om laadpunt te starten",
                "ATTEMPTING_ERCOGENER_STOP_OUTLET": "(Ercogener) Poging om laadpunt te stoppen",
                "ATTEMPTING_REMOTE_CHANGE_AVAILABILITY": "Poging om laadpunt {chargeboxid} te zetten naar status {availability}",
                "ATTEMPTING_REMOTE_CLEAR_CACHE": "Poging om cache te legen van laadpunt {chargeboxid}",
                "ATTEMPTING_REMOTE_GET_DIAGNOSTICS": "Attempt to get diagonistcs of charger {chargeboxid}",
                "ATTEMPTING_REMOTE_RESET": "Poging om laadpunt {chargeboxid} te resetten",
                "ATTEMPTING_REMOTE_START": "Poging om een sessie te starten op laadpunt {chargeboxid} en op connector {connector_id}",
                "ATTEMPTING_REMOTE_STOP": "Poging om sessie {session} te stoppen op laadpunt {chargeboxid}",
                "ATTEMPTING_REMOTE_UNLOCK_CONNECTOR": "Poging om connector {connector_id} te openen op laadpunt {chargeboxid}",
                "ATTEMPTING_REMOTE_UPDATE_FIRMWARE": "Poging om firmware te updaten van laadpunt {chargeboxid} (filename: {filename})",
                "CONNECTOR_STATUS_UPDATED": "Is gezet van {previous_status} naar {current_status}",
                "DETECTION_SENSOR_BLOCKED": "Een probleem is gevonden op EVSE {evse}",
                "DETECTION_SENSOR_IGNORED": "Sommige informatie van laadpunt {chargeboxid} over een probleem is genegeerd",
                "DETECTION_SENSOR_UNBLOCKED": "Probleem met EVSE {evse} verwijderd",
                "DETECTION_SENSOR_UNKNOWN_CONNECTOR": "Laadpunt {chargeboxid} heeft een onbekend probleem gevonden op connector {connector_id}",
                "DIAGNOSTIC_STATUS": "Laadpunt {chargeboxid} {status}",
                "ERCOGENER_REARMING_DEVICE_FAILURE": "(Ercogener) Niet gelukt device opnieuw te laden {chargeboxid}. Reden: {reason}",
                "ERCOGENER_REARMING_DEVICE_SUCCESS": "(Ercogener) Device {chargeboxid} opnieuw geladen",
                "ERCOGENER_START_OUTLET_FAILURE": "(Ercogener) Niet gelukt outlet te starten. Reden: reason",
                "ERCOGENER_START_OUTLET_SUCCESS": "(Ercogener) Outlet gestart",
                "ERCOGENER_STOP_OUTLET_FAILURE": "(Ercogener) Niet gelukt outlet te stoppen. Reden: reason",
                "ERCOGENER_STOP_OUTLET_SUCCESS": "(Ercogener) Outlet gestopt",
                "FIRMWARE_STATUS": "Laadpunt {chargeboxid} {status}",
                "LAN_DEVICE": "Laadpunt {chargeboxid} {lan_device_status}",
                "LAN_DEVICE_BOOTED": "Laadpunt {chargeboxid} opnieuw gestart",
                "LAN_DEVICE_FIRMWARE_CHANGE": "Laadpunt firmware {chargeboxid} is nu {current_firmware} (voorheen {previous_firmware})",
                "LAN_DEVICE_MODEL_CHANGE": "Laadpunt model {chargeboxid} is now {current_model} (voorheen {previous_model})",
                "LAN_DEVICE_PROTOCOL_CHANGE": "Laadpunt {chargeboxid} gebruikt nu {current_protocol} (voorheen {previous_protocol})",
                "LAN_DEVICE_VENDOR_CHANGE": "Laadpuntverkoper {chargeboxid} is now {current_vendor} (voorheen {previous_vendor})",
                "LAN_PUBLIC_IP_CHANGE": "LAN {lan} heeft nu publiek IP {current_ip} (voorheen {previous_ip})",
                "PAYMENT_TERMINAL_OFFLINE_FAILURE_REPORT": "Gedurende een periode van offline, betaling voor sessie {sessionLink} <strong class=\"has-text-danger\"> niet gelukt <\/strong> op kassa (ticket id {ticketId}). Prijs: {price}",
                "PAYMENT_TERMINAL_OFFLINE_SUCCESS_REPORT": "Gedurende een periode van offline, betaling voor sessie {sessionLink} <strong class=\"has-text-success\"> succesvol <\/strong> op kassa (ticket id {ticketId}). Prijs: {price}",
                "PAYMENT_TERMINAL_ONLINE_FAILURE_REPORT": "Betaling voor sessie {sessionLink} <strong class=\"has-text-danger\"> niet gelukt <\/strong> op kassa (ticket id {ticketId})",
                "PAYMENT_TERMINAL_ONLINE_SUCCESS_REPORT": "Payment for session {sessionLink} <strong class=\"has-text-success\"> succeeded <\/strong> on point of sale (ticket id {ticketId})",
                "REMOTE_CHANGE_AVAILABILITY_FAILURE": "Niet gelukt om status van laadpunt {chargeboxid} aan te passen in {availability}. Reden: {reason}",
                "REMOTE_CHANGE_AVAILABILITY_SUCCESS": "Laadpunt {chargeboxid} successvol aangepast in {availability}",
                "REMOTE_CLEAR_CACHE_FAILURE": "Niet gelukt om cache leeg te maken van laadpunt {chargeboxid}. Reden: {reason}",
                "REMOTE_CLEAR_CACHE_SUCCESS": "Cache van laadpunt {chargeboxid} succesvol geleegd",
                "REMOTE_GET_DIAGNOSTICS_FAILURE": "Laadpunt {chargeboxid} geweigerd om diagnose commando te krijgen. Reden: {reason}",
                "REMOTE_GET_DIAGNOSTICS_SUCCESS": "Laadpunt {chargeboxid} heeft opdracht geaccepteerd, diagnose wordt gemaakt",
                "REMOTE_RESET_FAILURE": "Niet gelukt om laadpunt {chargeboxid} te resetten. Reden: {reason}",
                "REMOTE_RESET_SUCCESS": "Laadpunt {chargeboxid} succesvol gereset",
                "REMOTE_START_FAILURE": "Niet gelukt om een sessie te starten op connector {connector_id} van laadpunt {chargeboxid}. Reden: {reason}",
                "REMOTE_START_SUCCESS": "Sessie successvol gestart op {connector_id} van laadpunt {chargeboxid}",
                "REMOTE_STOP_FAILURE": "Niet gelukt om sessie {session} te stoppen op laadpunt {chargeboxid}. Reden: {reason}",
                "REMOTE_STOP_SUCCESS": "Sessie {session} succesvol gestopt op laadpunt {chargeboxid}",
                "REMOTE_UNLOCK_CONNECTOR_FAILURE": "Niet gelukt om connector {connector_id} te openen op laadpunt {chargeboxid}. Reden: {reason}",
                "REMOTE_UNLOCK_CONNECTOR_SUCCESS": "Connector {connector_id} succesvol geopenend op laadpunt {chargeboxid}",
                "REMOTE_UPDATE_FIRMWARE_FAILURE": "Laadpunt {chargeboxid} weigert opdracht update firmware (filename: {filename}). Reden: {reason}",
                "REMOTE_UPDATE_FIRMWARE_SUCCESS": "Laadpunt {chargeboxid} heeft opdracht voor update firmware (filename: {filename}) geaccepteerd",
                "SESSION_AUTHORIZED": "Sessie {session} geautoriseerd voor contract {auth_id} (UID: {token_uid})",
                "SESSION_DENIED": "Sessie {sessionLink} met UID {token_uid} niet geautoriseerd en wordt niet uitgevoerd. Reden:",
                "SESSION_ENDED": "Sessie {session} is afgelopen. {invalid_msg} Verbruik: {consumption}. Duur:",
                "SESSION_ENERGY_UPDATED": "Sessie {session} verbruik {consumption} (totaal: {current_wh})",
                "SESSION_FORCED": "Sesessie {SessionLink} met UID {token_uid} gestart zonder autorisatie",
                "SESSION_STARTED": "Sessie {session} is gestart. Contract: {auth_id}. UID: {token_uid}",
                "SESSION_START_BLOCKED": "Onbekende sessie geblokkeerd door tag {idTag} op laadpunt {chargeboxid}",
                "SESSION_TIMED_OUT": "Sessie {sessionLink} (geautoriseerd op {authorizeDate}) verloopt en wordt niet afgerond",
                "SESSION_TOTAL_COST_UPDATED": "Sessie {session} kosten {current_total_cost} (voorheen {previous_total_cost})",
                "TOKEN_AUTHORIZE_ACCEPTED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-success\">authorized<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<strong>",
                "TOKEN_AUTHORIZE_DENIED": "Provider <strong>{providerName}<\/strong> <strong class=\"has-text-danger\">did not authorize<\/strong> token <strong>{tokenUid}<\/strong> on station <strong>{locationName}<\/strong> for the following reason: ({allowed}) <strong>{reason}<\/strong>",
                "TOKEN_AUTHORIZE_UNKNOWN": "<strong class=\"has-text-warning\">No provider<\/strong> recognized token <strong>{tokenUid}<\/strong>",
                "UNKNOWN_CONNECTOR_STATUS_UPDATED": "Onbekende connector {connector_id} is gezet op {status}",
                "UNKNOWN_SESSION_ASKING_PRICE": "Prijs gevraagd voor laadpunt {chargeboxid} voor onbekende sessie",
                "UNKNOWN_SESSION_ENDED": "Onbekende sessie gestopt door laadpunt {chargeboxid}",
                "UNKNOWN_SESSION_ENERGY_UPDATED": "Verbruik bijgewerkt door lader {chargeboxid} voor onbekende sessie",
                "UNKNOWN_SESSION_STARTED": "Onbekende sessie geautoriseerd door laadpunt {chargeboxid}",
                "default": "Sorry, geen interpretatie mogelijk",
                "moreDetails": "Meer informatie"
            },
            "noContextualData": "Geen contextuele data beschikbaar",
            "orderSentBy": "Bestelling gestuurd via {email}",
            "paymentTerminal": {
                "errorMessage": "Foutmelding:",
                "noErrorMessage": "Geen foutmelding",
                "noReceipt": "Geen ontvangstbewijs voor deze sessie",
                "number": "Betaal terminal nummer: {number}",
                "receiptData": "Ontvangen data:"
            },
            "remoteFailedReason": {
                "NOT_SUPPORTED": "Opdracht wordt niet ondersteund door dit laadpunt",
                "REJECTED": "Opdracht geweigerd door laadpunt",
                "TIMEOUT": "Geen reactie van laadpunt",
                "default": "Onbekende reden",
                "null": "Onbekende reden"
            },
            "sessionStatus": {
                "invalid": "Sessie mislukt"
            },
            "statusReason": {
                "ADMINISTRATIVE_DECLARATION": "Administratieve status update door een supervisor",
                "ALL_CONNECTORS_FAULTED": "Alle connectors zijn verkeerd",
                "ALL_CONNECTORS_UNAVAILABLE": "Alle connectors zijn onbeschikbaar",
                "BLOCKED_EVSE": "Toegang tot EVSE is geblokkeerd door een obstakel",
                "NO_CONNECTOR": "EVSE heeft geen connector",
                "NO_LAN_DEVICE": "EVSE is niet verbonden aan een laadpunt",
                "OFFLINE_LAN_DEVICE": "Laadpunt is offline",
                "ONGOING_RESERVATION": "Een reservering is aan de gang",
                "ONGOING_SESSION": "Een sessie is aan de gang",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Besteld door de elektriciteitsleverancier",
                "default": "Alles is orde",
                "null": "Onbekende reden"
            },
            "title": "Business logs {name}"
        },
        "cdrs": {
            "csv": {
                "confirmTitle": "CSV van {from} naar {to}",
                "default": "Bevestigt u de uitvoering?",
                "tooHeavy": "Het bestand wordt gegenereerd en wordt verstuurd naar {email}"
            },
            "list": {
                "authId": "Contract",
                "breadcrumb": "CDRs",
                "connector": "Connector",
                "cpo": "Netwerk",
                "created": "Gemaakt op",
                "emsp": "Aanbieder",
                "evse": "Laadpunt",
                "externalRef": "Externe referentie",
                "lastSent": "Laatst verzonden",
                "location": "Locatie",
                "ref": "Referentie",
                "scopeTitle": "CDRs of {name}",
                "startDateTime": "Begin met",
                "stopDateTime": "Einde",
                "title": "CDRs",
                "totalCost": "Total price",
                "totalEnergy": "kWh",
                "vat": "BTW",
                "withAdvenir": "With CDRs for Advenir",
                "withChildrenOperators": "Sessies van sub-netwerken"
            },
            "show": {
                "auth": "Autorisatie ID",
                "badge": "ID label",
                "breadcrumb": "CDR",
                "cdrOfSessionRef": "CDR van #{ref}",
                "cost": "Prijs",
                "energy": "Energie",
                "exportToPDF": "Exporteer naar PDF",
                "isWhiteListed": "is white-listed",
                "preTaxPrice": "Prijs excl. BTW",
                "ref": "Referentie",
                "relations": "Relaties",
                "remark": "Opmerkingen",
                "title": "Regel met laad details #{ref}",
                "totalCost": "Totale prijs",
                "totalParkingTime": "Parkeertijd",
                "totalTime": "Totale tijd"
            }
        },
        "commons": {
            "From": "Van",
            "Now": "Nu",
            "To": "Tot",
            "active": "Actief",
            "add": "Nieuw",
            "agreement": "Overeenkomst",
            "all": "Allemaal",
            "asyncSearch": "Typ om te zoeken",
            "authId": "Contract",
            "autoRefreshDisabled": "Automatische verversing voorlopig niet beschikbaar",
            "autoRefreshOff": "Automatisch verversen",
            "autoRefreshOn": "Auto verversen op",
            "back": "Terug naar",
            "by": "door {user}",
            "cancel": "Annuleer",
            "clear": "Duidelijk",
            "common": "Algemeen",
            "confirm": "Bevestig",
            "cpo": "Netwerk",
            "created_at": "Created date",
            "creationPlaceholder": "Oprichting",
            "curve": "Curve",
            "date": "Datum",
            "dateRange": "Filter op datum",
            "delete": "Delete",
            "durationUnits": {
                "days": "dag(en)",
                "hours": "u",
                "minutes": "m",
                "months": "maand(en)",
                "seconds": "s"
            },
            "durationWithDays": "{nb_days} dag(en) en {standard_duration}",
            "edit": "Bewerken",
            "edition": "{name}",
            "emi3": "Particuliere eMI3-ID",
            "emsp": "Aanbieder",
            "end": "Einde",
            "error403": "Niet toegestaan",
            "error404": "Pagina niet gevonden",
            "evse": "CP",
            "exclVat": "excl. BTW",
            "export": "Exporteer",
            "exportToCSV": "Exporteren naar CSV bestand",
            "exportToPDF": "Exporteren naar PDF",
            "false": "Fout",
            "filterOn": "Filter",
            "from": "van",
            "fullPrice": "excl. BTW: {exclVat} \/ BTW: {vat} \/ {vatRate} %",
            "globalError": "Er is een fout opgetreden",
            "histogram": "Histogram",
            "hotline": "Hotline",
            "import": "Importeer",
            "inactive": "Inactief",
            "inclVat": "incl. BTW",
            "isActive": "Actief",
            "kW": "kW",
            "kWh": "kWh",
            "last_update": "Laatste update",
            "location": "Locatie",
            "locationsList": "Locaties",
            "month": "Maand",
            "no": "Geen",
            "noResult": "Geen resultaat",
            "noResultAsync": "Geen resultaat, typ om te zoeken",
            "noSmartphone": "Niet beschikbaar voor smartphone",
            "notDefined": "Ongedefinieerd",
            "ocpiModules": "OCPI-modules",
            "of": "van {data}",
            "onlyCommaAllowed": "Alleen \",\" is toegestaan als scheidingsteken.",
            "optional": "Optioneel",
            "optionsSelected": "{number} geselecteerde optie(s)",
            "placeholderSelect": "Selecteer een optie",
            "preTaxCost": "{amount} exclusief BTW, {vat} BTW",
            "process": "Verwerk",
            "quarter": "Kwartaal",
            "rangeHint": "Data from {from} to {to}",
            "reference": "Referentie",
            "refunds": "Terugbetalingen",
            "schedule": "Planning",
            "search": "Zoeken",
            "session": "Sessie",
            "start": "Begin",
            "status": "Status",
            "submit": "Bevestig",
            "tagId": "Token",
            "tariff": "Tarief",
            "to": "naar",
            "today": "Today",
            "true": "Klopt",
            "type": "Type",
            "updated_at": "Bijgewerkt",
            "vat": "BTW",
            "vatRate": "BTW-tarief",
            "year": "Jaar",
            "years": "Jaren",
            "yes": "Ja"
        },
        "connectors": {
            "actions": {
                "start": "Start met opladen",
                "stop": "Stoppen met opladen",
                "unlock": "Ontgrendel"
            },
            "form": {
                "addConnectorTitle": "Connector toevoegen",
                "amps": "Amperage (A)",
                "cable": "Aangesloten kabel",
                "create": "Connector toevoegen",
                "customPower": "Energie op maat",
                "delete": "Wissen",
                "edit": "Bewerken",
                "editConnectorTitle": "De connector aanpassen",
                "invalid": "Connectoren zijn ongeldig",
                "maxPower": "Max. vermogen : {maxPower} kW",
                "ocppNumber": "OCPP-connector-ID",
                "ocppNumberIsUnavailable": "Is niet beschikbaar",
                "ocppNumberUnavailable": "Deze connector ID is niet beschikbaar",
                "power": "Vermogen",
                "powerType": "Power type",
                "tariff": "Tarief",
                "type": "Type",
                "voltage": "Voltage (V)"
            },
            "list": {
                "errorCode": "Foutcode",
                "evseName": "Naam LP",
                "evseRef": "Ref LP",
                "lastStatusChangeAvailable": "Opgelost sinds",
                "lastStatusChangeIssue": "Standaard sinds",
                "maintenanceAvailableTitle": "Connectoren: fouten opgelost",
                "maintenanceIssuesSubtitle": "Lijst van connectors die minstens 1 uur lang een foutcode hebben gemeld",
                "maintenanceIssuesTitle": "Foutcodes",
                "standard": "Type \/ Vermogen"
            },
            "powerTypes": {
                "AC_1_PHASE": "1 fase",
                "AC_3_PHASE": "3 fase",
                "DC": "Gelijkstroom"
            },
            "show": {
                "cable": "Kabel is aangesloten",
                "cannotRemove": "Er is ten minste één connector nodig",
                "confirmDeletion": "Gelieve de verwijdering te bevestigen",
                "connectorCreated": "Connector aangemaakt",
                "connectorEdited": "Connector bewerkt",
                "connectorRemoved": "Connector verwijderd",
                "defaultTariff": "Standaardtarief",
                "localisation": "Locatie",
                "locked": "Gesloten",
                "noRestrictions": "Geen beperking",
                "noTariff": "Geen tarief vastgesteld",
                "ocppNumber": "OCPP-connector-ID",
                "power": "Vermogen",
                "socketFalse": "Kabel is aangesloten",
                "socketTrue": "Kabel bevestigd",
                "tariff": "Tarief",
                "tariffApply": "Toegepast tarief",
                "title": "Connector #",
                "type": "Type",
                "unlocked": "Ontgrendeld"
            },
            "standard": {
                "chademo": "Chademo",
                "domestic_a": "Standaard\/Domestic type A",
                "domestic_b": "Standaard\/Domestic type B",
                "domestic_c": "Standaard\/Domestic type C",
                "domestic_d": "Standaard\/Domestic type D",
                "domestic_e": "Standaard\/Domestic type E",
                "domestic_f": "Standaard\/Domestic type F",
                "domestic_g": "Standaard\/Domestic type G",
                "domestic_h": "Standaard\/Domestic type H",
                "domestic_i": "Standaard\/Domestic type I",
                "domestic_j": "Standaard\/Domestic type J",
                "domestic_k": "Standaard\/Domestic type K",
                "domestic_l": "Standaard\/Domestic type L",
                "iec_60309_2_single_16": "IEC 60309-Industrial enkel fase 16",
                "iec_60309_2_three_16": "IEC 60309-Industrial drie fase 16",
                "iec_60309_2_three_32": "IEC 60309-Industrial drie fase 32",
                "iec_60309_2_three_64": "IEC 60309-Industrial drie fase 64",
                "iec_62196_t1": "IEC 62196 Type 1",
                "iec_62196_t1_combo": "Combo Type 1",
                "iec_62196_t2": "IEC 62196 Type 2",
                "iec_62196_t2_combo": "Combo Type 2",
                "iec_62196_t3a": "IEC 62196 Type 3A",
                "iec_62196_t3c": "IEC 62196 Type 3C",
                "other": "Andere connectoren",
                "tesla_r": "Tesla Connector Roadster-type",
                "tesla_s": "Tesla Connector Model-S-type"
            },
            "status": {
                "available": "Beschikbaar",
                "charging": "Opladen",
                "faulted": "Fout",
                "finishing": "Afwerking",
                "issue": "Kwestie",
                "occupied": "Bezet",
                "preparing": "Voorbereiding van",
                "reserved": "Gereserveerd",
                "suspendedev": "Geschorste EV",
                "suspendedevse": "Geschorste EVSE",
                "unavailable": "Niet beschikbaar"
            }
        },
        "contact-groups": {
            "actions": {
                "assignGroup": "Add an existing group",
                "delete": "Delete",
                "remove": "Remove",
                "replaceGroup": "Replace group"
            },
            "create": {
                "feedback": "Group successfully created",
                "title": "Create a contact group"
            },
            "edit": {
                "addExistingGroup": "Add an existing group",
                "edit": "Edit",
                "feedback": "Group successfully edited",
                "searchContactGroups": "Search contact groups"
            },
            "form": {
                "affiliations": "Affiliations",
                "assign": "Assign",
                "contacts": "Contacts",
                "description": "Description",
                "info": "Group information",
                "language": "Speaked language",
                "location": "Location",
                "name": "Name of the group",
                "nameExists": "This group already exists",
                "network": "Network",
                "or": "or",
                "planning": "Planning",
                "searchContacts": "Search contacts",
                "searchContactsEmpty": "Add at least one contact to the group"
            },
            "list": {
                "confirmDelete": "Are you sure you want to delete this contact group?",
                "delete": "Delete",
                "duplicate": "Duplicate",
                "incompleteSchedule": "Warning: Incomplete schedule"
            },
            "planning": {
                "add": "Add",
                "addContact": "Add contact",
                "addContactTitle": "Add contact for {day}",
                "addSchedule": "Add a schedule",
                "begin": "Begin",
                "empty": "No contact defined",
                "end": "End",
                "incomplete": "Planning incomplete",
                "schedule": "Planning",
                "selectContact": "Select a contact"
            },
            "show": {
                "breadcrumb": "Contact group {name}",
                "contacts": "Contacts",
                "description": "Description",
                "groupInfo": "Group information",
                "inheritedFrom": "inherited from",
                "language": "Language",
                "name": "Group name",
                "planningContactInfo": "Each contact in this group will get an emergency alert via email. The contacts registered when the emergency occurs will be informed via the contact method(s) chosen : SMS and\/or phone call.",
                "scopeDeleteGroup": "Are you sure you want to delete group {group} ? This action will remove the group from all networks and stations to which it is affiliated.",
                "scopeRemoveGroup": "Are you sure you want to remove group {group} from the network?",
                "supportGroup": "Maintenance group"
            },
            "toast": {
                "deleted": "Contact group has been deleted"
            }
        },
        "contacts": {
            "affiliations": {
                "actions": "Acties",
                "affiliationsOf": "Aansluitingen van",
                "deletePopupTextLocation": "Wilt u deze verbinding echt verwijderen?",
                "deletePopupTextNetwork": "Wilt u deze verbinding echt verwijderen? Dit verwijdert ook alle verbindingen tussen het contact en de locaties van het netwerk.",
                "deletePopupTitleLocation": "Verbinding verwijderen",
                "deletePopupTitleNetwork": "Verbinding verwijderen",
                "locations": "Locaties",
                "network": "Netwerk",
                "role": "Rol",
                "title": "Contactverbindingen",
                "type": "Type"
            },
            "breadcrumb": {
                "affiliations": "Verbindingen",
                "contact": "Contacten",
                "edit": "Bewerken",
                "groups": "Contactgroepen"
            },
            "contactMethods": {
                "email": "E-mail",
                "phone": "Oproep",
                "sms": "SMS"
            },
            "create": {
                "title": "Een contact aanmaken"
            },
            "edit": {
                "close": "Sluit",
                "edit": "Bewerken",
                "editContactsTitle": "Contacten bewerken"
            },
            "error": {
                "contactEmailAlreadyTakenAdmin": "Deze Email is al in gebruik",
                "contactEmailAlreadyTakenNotAdmin": "De contactpersoon bestaat al. Neem contact op met exploitation@freshmile.com om deze aan uw netwerk toe te wijzen"
            },
            "form": {
                "addContactTitle": "Creëer een contact",
                "alwaysOneOwnerContact": "Het netwerk moet minimaal één eigenaar contact hebben.",
                "buttonAddContact": "Nieuw contact aanmaken",
                "buttonAddType": "Type toevoegen",
                "buttonLink": "Koppelen",
                "call": "Oproep",
                "company": "Bedrijf",
                "contact_methods": "Contactmethode(s)",
                "contactsOfNetwork": "Contacten van het netwerk",
                "countrySelectorLabel": "Landcode",
                "editContactTitle": "Een contactpersoon wijzigen",
                "email": "E-mail",
                "errorDeleteType": "Het contact moet minstens één type hebben",
                "errorUpdate": "Er is een fout opgetreden",
                "linkContactExisting": "Koppel een bestaand contact",
                "linkContactNotExisting": "Koppel een nieuw contact",
                "name": "Volledige naam",
                "nameExists": "Naam reeds in gebruik",
                "notesInput": "Opmerkingen",
                "optional": "Optioneel",
                "phone_number": "Telefoonnummer",
                "placeholderUser": "Zoeken op naam, e-mailadres",
                "searchContact": "Zoek een bestaand contact",
                "sms_number": "SMS-nummer",
                "textSearch": "Zoek een bestaand contact, selecteer een type en klik op de koppelknop.",
                "textSearchGroup": "Zoek een bestaand contact en klik op de koppelknop.",
                "titleContactsOfNetwork": "Contact(en)",
                "type": "Type contact",
                "userLink": "Gekoppelde gebruiker"
            },
            "groups": {
                "actions": "Acties",
                "addGroupContact": "Maak een contactgroep aan",
                "affiliationOfGroup": "Directe aansluiting",
                "confirmDelete": "Wilt u het contact echt uit deze contactgroep verwijderen?",
                "contact_methods": "Contactmethoden",
                "contacts": "Contacten",
                "groupOfContact": "Contactgroep",
                "groupOfContactPlaceholder": "Zoek een contactgroep",
                "modalTitle": "Voeg een contactgroep toe aan het contact",
                "nameOfGroup": "Naam van de groep",
                "or": "Of",
                "title": "Contactgroepen",
                "updated": "Bijgewerkt"
            },
            "list": {
                "actions": "Acties",
                "affiliated": "Direct verbonden",
                "allTypes": "Alle contacttypes",
                "breadcrumb": "Contacten",
                "confirmDelete": "Wilt u dit contact echt verwijderen?",
                "contact_methods": "Contactmethoden",
                "contacts": "Contacten",
                "contactsGroups": "Contactgroepen",
                "email": "E-mail",
                "filterType": "Filter op type",
                "groupName": "Groepsnaam",
                "name": "Volledige naam",
                "phone_number": "Telefoonnummer",
                "sms_number": "SMS",
                "title": "Contacten en groepen",
                "type": "Type",
                "types": "Types"
            },
            "show": {
                "accessContactPage": "Toegang tot de contactpagina",
                "company": "Bedrijf",
                "contactMethods": "Contactmethodes",
                "email": "E-mail",
                "groups": "Groepen",
                "nameOfContact": "Naam van het contact",
                "networkAndLocations": "Netwerken en locaties",
                "notes": "Opmerkingen",
                "otherContacts": "Andere contacten",
                "phone_number": "Telefoonnummer",
                "sms_number": "SMS",
                "title": "Contact",
                "type": "Type contact",
                "userLink": "Gekoppelde gebruiker"
            },
            "toast": {
                "deleted": "Contact verwijderd"
            },
            "types": {
                "ADMINISTRATIVE": "Administratief",
                "EMERGENCY": "Noodgeval",
                "INDIVIDUAL": "Individueel",
                "INSTALLER": "Installateur",
                "MAINTENANCE": "Onderhoud",
                "OWNER": "Eigenaar",
                "undefined": "Niet gedefinieerd"
            }
        },
        "countries": {
            "ABW": "Aruba",
            "AFG": "Afghanistan",
            "AGO": "Angola",
            "AIA": "Anguilla",
            "ALB": "Åland Eilanden",
            "AND": "Andorra",
            "ARE": "Verenigde Arabische Emiraten",
            "ARG": "Argentinië",
            "ARM": "Armenië",
            "ASM": "Amerikaans Samoa",
            "ATA": "Antarctica",
            "ATF": "Franse Zuidelijke Gebieden",
            "ATG": "Australië",
            "AUS": "Oostenrijk",
            "AUT": "Azerbeidzjan",
            "AZE": "Azerbaijan",
            "BDI": "Burundi",
            "BEL": "België",
            "BEN": "Benin",
            "BES": "Bonaire, Sint Eustatius en Saba",
            "BFA": "Burkina Faso",
            "BGD": "Bangladesh",
            "BGR": "Bulgarije",
            "BHR": "Bahrein",
            "BHS": "Bahamas",
            "BIH": "Bosnië en Herzegovina",
            "BLM": "Saint Barthélemy",
            "BLR": "Belarus",
            "BLZ": "Belize",
            "BMU": "Bermuda",
            "BOL": "Bolivia (Plurinationale staat van)",
            "BRA": "Brazilië",
            "BRB": "Barbados",
            "BRN": "Brunei Darussalam",
            "BTN": "Bhutan",
            "BVT": "Bouvet Eiland",
            "BWA": "Botswana",
            "CAF": "Centraal Afrikaanse Republiek",
            "CAN": "Canada",
            "CCK": "Cocos (Keeling) eilanden",
            "CHE": "Zwitserland",
            "CHL": "Chili",
            "CHN": "China",
            "CIV": "Ivoorkust",
            "CMR": "Kameroen",
            "COD": "Congo (Democratische Republiek)",
            "COG": "Congo",
            "COK": "Cook eilanden",
            "COL": "Colombia",
            "COM": "Comoren",
            "CPV": "Kaapverdië",
            "CRI": "Costa Rica",
            "CUB": "Cuba",
            "CUW": "Curaçao",
            "CXR": "Christmas eiland",
            "CYM": "Kaaiman eilanden",
            "CYP": "Cyprus",
            "CZE": "Tjechië",
            "DEU": "Duitsland",
            "DJI": "Djibouti",
            "DMA": "Dominica",
            "DNK": "Denemarken",
            "DOM": "Dominicaanse Republiek",
            "DZA": "Algerije",
            "ECU": "Ecuador",
            "EGY": "Egypte",
            "ERI": "Eritrea",
            "ESH": "Westelijke Sahara",
            "ESP": "Spanje",
            "EST": "Estland",
            "ETH": "Ethiopië",
            "FIN": "Finland",
            "FJI": "Fiji",
            "FLK": "Falkland eilanden (Malvinas)",
            "FRA": "Frankrijk",
            "FRO": "Faroë eilanden",
            "FSM": "Micronesië (Federale Staten van)",
            "GAB": "Gabon",
            "GBR": "Verenigd Koninkrijk van Groot Brittanië en Noord Ierland",
            "GEO": "Georgië",
            "GGY": "Guernsey",
            "GHA": "Ghana",
            "GIB": "Gibraltar",
            "GIN": "Guinea",
            "GLP": "Guadeloupe",
            "GMB": "Gambia",
            "GNB": "Guinea-Bissau",
            "GNQ": "Equatoriaal Guinea",
            "GRC": "Griekenland",
            "GRD": "Grenada",
            "GRL": "Groenland",
            "GTM": "Guatemala",
            "GUF": "Frans Guyana",
            "GUM": "Guam",
            "GUY": "Guyana",
            "HKG": "Hong Kong",
            "HMD": "Heard eiland en McDonald eiland",
            "HND": "Honduras",
            "HRV": "Kroatië",
            "HTI": "Haïti",
            "HUN": "Hongarije",
            "IDN": "Indonesië",
            "IMN": "Isle of Man",
            "IND": "India",
            "IOT": "Britse gebieden in Indische Oceaan",
            "IRL": "Ierland",
            "IRN": "Iran (Islamitische Republiek)",
            "IRQ": "Irak",
            "ISL": "Ijsland",
            "ISR": "Israël",
            "ITA": "Italië",
            "JAM": "Jamaica",
            "JEY": "Jersey",
            "JOR": "Jordanië",
            "JPN": "Japan",
            "KAZ": "Kazachstan",
            "KEN": "Kenia",
            "KGZ": "Kirgizië",
            "KHM": "Cambodja",
            "KIR": "Kiribati",
            "KNA": "Saint Kitts en Nevis",
            "KOR": "Korea (Republiek)",
            "KWT": "Koeweit",
            "LAO": "Lao People's Democratische Republiek",
            "LBN": "Libanon",
            "LBR": "Liberië",
            "LBY": "Libië",
            "LCA": "Saint Lucia",
            "LIE": "Liechtenstein",
            "LKA": "Sri Lanka",
            "LSO": "Lesotho",
            "LTU": "Litouwen",
            "LUX": "Luxemburg",
            "LVA": "Letland",
            "MAC": "Macao",
            "MAF": "Sint Maartin (Frans deel)",
            "MAR": "Marokko",
            "MCO": "Monaco",
            "MDA": "Moldavië (Republiek)",
            "MDG": "Madagascar",
            "MDV": "Malediven",
            "MEX": "Mexico",
            "MHL": "Marshall eilanden",
            "MKD": "Macedonië (de voormalig Joegoslavische Republiek)",
            "MLI": "Mali",
            "MLT": "Malta",
            "MMR": "Myanmar",
            "MNE": "Montenegro",
            "MNG": "Mongolië",
            "MNP": "Noordelijke Mariana eilanden",
            "MOZ": "Mozambique",
            "MRT": "Mauritanië",
            "MSR": "Montserrat",
            "MTQ": "Martinique",
            "MUS": "Mauritius",
            "MWI": "Malawi",
            "MYS": "Maleisië",
            "MYT": "Mayotte",
            "NAM": "Namibië",
            "NCL": "Nieuw Caledonië",
            "NER": "Niger",
            "NFK": "Norfolk eiland",
            "NGA": "Nigeria",
            "NIC": "Nicaragua",
            "NIU": "Niue",
            "NLD": "Nederland",
            "NOR": "Noorwegen",
            "NPL": "Nepal",
            "NRU": "Nauru",
            "NZL": "Nieuw Zeeland",
            "OMN": "Oman",
            "PAK": "Pakistan",
            "PAN": "Panama",
            "PCN": "Pitcairn",
            "PER": "Peru",
            "PHL": "Philipijnen",
            "PLW": "Palau",
            "PNG": "Papua Nieuw Guinea",
            "POL": "Polen",
            "PRI": "Puerto Rico",
            "PRK": "Korea (Democratische Republiek)",
            "PRT": "Portugal",
            "PRY": "Paraguay",
            "PSE": "Palestina, Staat",
            "PYF": "Frans Polenesië",
            "QAT": "Qatar",
            "REU": "Réunion",
            "ROU": "Roemenië",
            "RUS": "Russische Federatie",
            "RWA": "Rwanda",
            "SAU": "Saudi Arabië",
            "SDN": "Soedan",
            "SEN": "Senegal",
            "SGP": "Singapore",
            "SGS": "Zuid Georgia en de Zuidelijke Sandwich eilanden",
            "SHN": "Sint Helena, Ascension and Tristan da Cunha",
            "SJM": "Svalbard en Jan Mayen",
            "SLB": "Salomon eilanden",
            "SLE": "Sierra Leone",
            "SLV": "El Salvador",
            "SMR": "San Marino",
            "SOM": "Somalië",
            "SPM": "Saint Pierre en Miquelon",
            "SRB": "Servië",
            "SSD": "Zuid Sudan",
            "STP": "Sao Tome en Principe",
            "SUR": "Suriname",
            "SVK": "Slowakije",
            "SVN": "Slovenië",
            "SWE": "Zweden",
            "SWZ": "Eswatini",
            "SXM": "Sint Maarten (Nederlands deel)",
            "SYC": "Seychellen",
            "SYR": "Syrië, Arabische Republiek",
            "TCA": "Turkse en Kokos eilanden",
            "TCD": "Tsjaad",
            "TGO": "Togo",
            "THA": "Thailand",
            "TJK": "Tadzjikistan",
            "TKL": "Tokelau",
            "TKM": "Turkmenistan",
            "TLS": "Timor-Leste",
            "TON": "Tonga",
            "TTO": "Trinidad en Tobago",
            "TUN": "Tunesië",
            "TUR": "Turkije",
            "TUV": "Tuvalu",
            "TWN": "Taiwan, Provincie van China",
            "TZA": "Tanzania, Verenigde Republiek of",
            "UGA": "Uganda",
            "UKR": "Oekraïne",
            "UMI": "Verenigde Staten min de afgelegen eilanden",
            "URY": "Uruguay",
            "USA": "Verenigde Staten van Amerika",
            "UZB": "Oezbekistan",
            "VAT": "Vaticaan",
            "VCT": "Sint Vincent en de Grenadines",
            "VEN": "Venezuela (Bolivarische Republiek van)",
            "VGB": "Britse Maagden eilanden",
            "VIR": "Amerikaanse Maagden eilanden",
            "VNM": "Vietnam",
            "VUT": "Vanuatu",
            "WLF": "Wallis en Futuna",
            "WSM": "Samoa",
            "YEM": "Jemen",
            "ZAF": "Zuid Afrika",
            "ZMB": "Zambia",
            "ZWE": "Zimbabwe"
        },
        "dashboard": {
            "activity": {
                "consumptionTitle": "Energie (MWh)",
                "sessionsTitle": "Laadsessies (eenheden)",
                "turnoverTitle": "Omzet ({currency})"
            },
            "availableEvses": "Beschikbare Laadpunten",
            "availableEvsesPercentage": "{1} {current} niet beschikbaar CP uit {total} (Beschikbaarheid: {percentage}%)| {current} niet-beschikbare CPuit {total} (Beschikbaarheid: {percentage}%)",
            "currentSessions": "Lopende sessies",
            "currentSessionsPercentage": "{percentage}% van {totaal} CP momenteel laden",
            "exportTranslations": "Exporteer vertalingen",
            "importExportTranslations": "Import \/ export vertalingen",
            "importTranslations": "Importeren van vertalingen",
            "loadMap": "Netwerkkaart laden",
            "networkStatus": "Real-time netwerkstatus",
            "openTickets": "Open tickets",
            "title": "Dashboard"
        },
        "days": {
            "dayNamesCalendar": [
                "Zo",
                "Ma",
                "Di",
                "Wo",
                "Do",
                "Vr",
                "Za"
            ],
            "friday": "Vrijdag",
            "monday": "Maandag",
            "monthNamesCalendar": [
                "Januari",
                "Februari",
                "Maart",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Augustus",
                "September",
                "Oktober",
                "November",
                "December"
            ],
            "saturday": "Zaterdag",
            "sunday": "Zondag",
            "thursday": "Donderdag",
            "tuesday": "Dinsdag",
            "wednesday": "Woensdag"
        },
        "devices": {
            "actions": {
                "breadcrumb": "Acties op afstand",
                "change": "Verander",
                "changeAvailability": "Beschikbaarheid wijzigen",
                "changeConfig": "Configuratie wijzigen",
                "changeConfigPlaceholder": "Configuratie naam",
                "clearCache": "Schoon cache op",
                "cnr": {
                    "rearm": "Achterste apparaat",
                    "rearmFailed": "Het apparaat is niet herbewapend",
                    "rearmSuccess": "Apparaat succesvol herbewapend",
                    "stop": "Stop",
                    "stopSuccess": "Stopcontact uitgeschakeld",
                    "supply": "Energielevering over standaard stekker (1 uur)",
                    "supplySince": "Lopende energielevering sinds: {date}",
                    "supplySuccess": "Standaard stekker succesvol gestart voor 1 uur",
                    "title": "CNR-automaat"
                },
                "configReadonly": "(Niet bewerkbaar)",
                "configValue": "Configuratiewaarde",
                "configurationPlaceholder": "Selecteer of typ hier",
                "customConfig": "Aangepaste configuratie",
                "get": "Haal",
                "getConfiguration": "Krijg configuratie",
                "getDiagnostics": "Krijg diagnostiek",
                "getDiagnosticsManual": "Stuur diagnose via URL",
                "ping": "Ping",
                "protocol": "Protocol: {protocol}",
                "reset": "Opnieuw instellen",
                "selectFile": "Selecteer bestand",
                "title": "Acties op afstand voor {chargeboxid}",
                "unknownConfig": "Onbekende configuratie",
                "updateFirmware": "Firmware bijwerken",
                "updateFirmwareManual": "Update firmware via URL",
                "upload": "Upload",
                "urlPlaceholder": "Enter URL",
                "value": "Waarde:"
            },
            "create": {
                "breadcrumb": "Nieuwe lader",
                "cpoTitle": "Nieuwe lader in {name}",
                "feedback": "Lader succesvol toegevoegd",
                "lanTitle": "Nieuwe lader aangesloten op {name}",
                "locationTitle": "Nieuwe lader in {name}",
                "title": "Nieuwe lader"
            },
            "edit": {
                "breadcrumb": "Bewerk",
                "feedback": "Lader succesvol bewerkt",
                "title": "Bewerk"
            },
            "form": {
                "addLan": "LAN toevoegen",
                "chargeboxid": "Laadbox ID",
                "chargeboxidAvailable": "Laadbox ID is beschikbaar",
                "chargeboxidUnavailable": "Laadbox ID is niet beschikbaar",
                "definition": "Definitie van een lader",
                "deviceProtocol": "Protocol",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "LAN bewerken",
                "isDhcp": "DHCP actief",
                "lan": "LAN",
                "lanDescription": "LAN beschrijving",
                "lanName": "Naam",
                "localIp": "Lokaal IP-adres",
                "localIpIsInvalid": "Lokale poort",
                "localPort": "Model",
                "model": "Gebruik een bestaand model",
                "modelIntro": "LAN #",
                "newLanName": "Nog niet toegeschreven EVSE",
                "offlineEvses": "Openbaar IP-adres",
                "publicIp": "Openbaar IP-adres is ongeldig",
                "publicPort": "Openbare poort",
                "selectLocation": "Locatie",
                "submit": "Voeg  toe",
                "technicalInfos": "Technische informatie",
                "unknownModel": "Onbekend model",
                "url": "URL"
            },
            "list": {
                "addDevice": "Lader toevoegen",
                "all": "Allemaal",
                "breadcrumb": "CBID",
                "chargeboxid": "Laadbox ID",
                "cpoTitle": "CBID van {name}",
                "firmware": "Firmware",
                "isOnline": "Online",
                "lan": "LAN",
                "lastRequestOffline": "Offline sinds",
                "lastRequestOnline": "Online sinds",
                "localIp": "Lokaal IP-adres",
                "locationTitle": "CBID van {name}",
                "maintenanceOfflineSubtitle": "List of CBIDs having lost communication for at least 12 hours",
                "maintenanceOfflineTitle": "Communication losses",
                "maintenanceOnlineTitle": "CBID: issue solved",
                "model": "Model",
                "nbEvses": "Laadpunt",
                "offline": "Offline",
                "online": "Online",
                "protocol": "Protocol",
                "status": "Status",
                "title": "CBID",
                "updatedAt": "Bijgewerkt",
                "vendor": "Leverancier"
            },
            "ocpp": {
                "breadcrumb": "OCPP-logboeken",
                "changeAvailabilityAvailable": "Lader is nu beschikbaar",
                "changeAvailabilityUnavailable": "Lader is nu niet beschikbaar",
                "changeConfigSuccess": "Nieuwe waarde met succes toegepast op {configuration}",
                "clearSuccess": "Cache succesvol opgeschoond",
                "commonSuccess": "Order sent",
                "diagnosticsOngoing": "Diagnostiek laten versturen - wachten op een lader",
                "diagnosticsReady": "Diagnose klaar",
                "failed": "Commando mislukt",
                "getDiagnosticsFail": "Ontvangen van diagnostiek is mislukt",
                "notSupported": "Commando niet ondersteund",
                "pingError": "Ping is mislukt",
                "pingSuccess": "Ping geslaagd",
                "rejected": "Commando afgewezen",
                "remoteFail": "Bestelling op afstand mislukt",
                "resetSuccess": "Opnieuw instellen van de order verzonden - herstart van de lader",
                "startSuccess": "Sessie met succes gestart",
                "stopSuccess": "Sessie met succes gestopt",
                "timeout": "Commando onderbroken",
                "unlockFailed": "Ontgrendelen mislukt",
                "unlockSuccess": "Ontgrendelen geslaagd",
                "uploadFirmwareFailed": "Upload mislukt",
                "uploadFirmwareSuccess": "Upload geslaagd"
            },
            "protocol": {
                "OCPP15J": "OCPP 1.5 J",
                "OCPP15S": "OCPP 1.5 S",
                "OCPP16J": "OCPP 1.6 J",
                "OCPP16S": "OCPP 1.6 S",
                "OCPP20J": "OCPP 2.0 J",
                "OCPP20S": "OCPP 2.0 S",
                "OTHER": "Andere"
            },
            "show": {
                "DHCP": "DHCP",
                "NAT": "NAT",
                "deviceInfo": "Informatie over het apparaat",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "firmware": "Firmware",
                "isOffline": "Offline",
                "isOnline": "Online",
                "lan": "LAN",
                "lastRequest": "Laatste verzoek",
                "localIp": "Lokaal IP-adres",
                "localPort": "Lokale poort",
                "model": "Model",
                "network": "Netwerk",
                "noOcppMessage": "Geen OCPP bericht verstuurd",
                "noPairsConnected": "Geen paar",
                "ocppInfos": "OCPP",
                "pairs": "Paren",
                "protocol": "Protocol",
                "publicPort": "Openbare poort",
                "url": "URL",
                "vendor": "Leverancier"
            }
        },
        "emails": {
            "ExportReady": {
                "body-attached": "In bijlage vindt je het CSV-bestand dat je aangevraagd hebt",
                "body-linked": "Klik op onderstaande link om het gewenste CSV-bestand te downloaden",
                "download-link-text": "Download het bestand",
                "subject": "Je CSV-bestand is klaar"
            },
            "Issues": {
                "closed": {
                    "introduction": "Het Fix ticket: #{id} {title} - {location} is gesloten door {closer} op {closed_at}.",
                    "subject": "Een Fix-onderhoudsticket sluiten - Fix: #{id} - {locationName}"
                },
                "created": {
                    "introduction": "Het volgende Fix ticket is zojuist aangemaakt:",
                    "link": "Link naar het ticket",
                    "name": "Naam van het ticket",
                    "number": "Ticketnummer",
                    "subject": "Een onderhoudsticket openen - Fix: #{id} - {locationName}",
                    "type": "Onderhoudstype :"
                },
                "link": "Bekijk het ticket:",
                "messageCreated": {
                    "introduction": "{updater} schreef zojuist een bericht in het Fix ticket: #{id} {title} - {location} de {created_at}.",
                    "subject": "Er is een bericht toegevoegd aan een - Fix: #{id} - {locationName}"
                },
                "reminder": "Vergeet niet dat het belangrijk is om commentaar te geven op tickets, zodat je de voortgang ervan kunt bijhouden en ze beter kunt controleren.",
                "reopened": {
                    "introduction": "Het Fix ticket: #{id} {title} - {location} is heropend door {opener} in {updated_at}.",
                    "subject": "Heropening van een Fix onderhoudsticket - Fix: #{id} - {locationName}"
                },
                "updated": {
                    "introduction": "Het Fix ticket: #{id} {title} - {location} is gewijzigd door {updater} op {updated_at}.",
                    "subject": "Wijziging van een Fix-onderhoudsticket - Fix: #{id} - {locationName}"
                },
                "userQuoted": {
                    "introduction": "{de} verzender heeft je zojuist geciteerd in het Fix ticket : #{id} {title} - {location} le {quoted_at}.",
                    "subject": "Citaat in een Fix-onderhoudsticket - Fix: #{id} - {locationName}"
                }
            },
            "ResetPassword": {
                "lines": {
                    "call_to_action": "Klik op de link om het wachtwoord te wijzigen",
                    "expiration": "Deze link vervalt in {expirationTime} minuten",
                    "explanation": "Je ontvangt dit bericht omdat we een verzoek hebben ontvangen om je wachtwoord te wijzigen.",
                    "no_action_required": "Negeer dit bericht als je dit verzoek niet hebt ingediend."
                },
                "link": "Wijzig wachtwoord",
                "subject": "Wijziging van het wachtwoord"
            },
            "SendRetrocessionEmployerPdf": {
                "cordially": "Met vriendelijke groeten,",
                "explanation": "Bijgevoegd vindt je de vergoedingsverklaring voor het professioneel opladen in je thuislaadstation.",
                "greetings": "Hallo,",
                "payment": "De overschrijving zal in de komende dagen plaatsvinden.",
                "subject": "Teruggave van professionele herhalingen bij jou thuis"
            },
            "SendRetrocessionPdf": {
                "cordially": "Met vriendelijke groeten,",
                "explanation": "Bijgevoegd vindt je het retrocessiedocument voor het afgelopen kwartaal.",
                "greetings": "Hallo,",
                "payment": "De overschrijving zal in de komende dagen plaatsvinden.",
                "subject": "Retrocessie laatste kwartaal - Freshmile"
            },
            "common": {
                "apologize": "We verontschuldigen ons voor het ongemak",
                "contact": "Als je vragen hebt, kun je contact met ons opnemen op het volgende adres:",
                "greetings": "Hallo {firstname} {lastname}",
                "login": "Aanmelden",
                "thanks": "Bedankt voor het gebruik van {appname}!"
            },
            "fromName": "Freshmile"
        },
        "evses": {
            "administrative_status": {
                "available": "In dienst",
                "inoperative": "Onderhoud",
                "outoforder": "Buiten dienst",
                "planned": "Gepland",
                "removed": "Verwijderd",
                "unknown": "Niet-communicerend"
            },
            "charging_status": {
                "available": "Beschikbaar",
                "charging": "Bezig met opladen",
                "reserved": "Gereserveerd",
                "unknown": "Onbekende laadsessie"
            },
            "create": {
                "breadcrumb": "nieuwe laadpunten",
                "cpoTitle": "Nieuw laadpunt met {name}",
                "evseList": "Laadpunten (lijst)",
                "feedback": "Laadpunt succesvol toegevoegd",
                "locationTitle": "Nieuw laadpunt naar {name}",
                "title": "Nieuw laadpunt"
            },
            "edit": {
                "BOEditRef": "Om de referentie van een belastingspunt te wijzigen nadat het is aangemaakt, gebruik je de actie \"Wijzig de referentie van een belastingspunt\" in de backoffice.",
                "feedback": "Laadpunt succesvol bijgewerkt",
                "title": "Bewerken"
            },
            "export-data-gouv": "data.gouv",
            "form": {
                "addConnector": "Connector toevoegen",
                "addDevice": "Laadpaal toevoegen",
                "addLan": "LAN toevoegen",
                "administrative": "Administratieve status",
                "applyCoordinates": "GPS-coördinaten toepassen",
                "asyncSearch": "Search by EVSE (reference, name)",
                "connectors": "Connectoren",
                "customRefAvailable": "Referentie is beschikbaar",
                "customRefUnavailable": "Referentie is niet beschikbaar",
                "device": "Laadbox ID",
                "directions": "Routebeschrijving",
                "duplicate": "Create an n+1 duplicate of this charge point",
                "emi3": "eMI3 ID",
                "evseInfos": "Charging point information",
                "floorLevel": "Verdieping",
                "hideMap": "Verberg kaart",
                "lan": "LAN op deze locatie",
                "lanDeviceCreationError": "Fout",
                "lanDeviceCreationSuccess": "Laadpaal succesvol toegevoegd",
                "latitude": "Breedtegraad",
                "location": "Locatie",
                "locationInfos": "Locatie-informatie",
                "locationPlaceholder": "Locatie",
                "locations": "Locaties",
                "longitude": "Lengtegraad",
                "name": "Aangepaste naam",
                "newDeviceTitle": "Nieuwe lader in {name}",
                "optional": "(optioneel)",
                "parkingType": "Type parkeerplaats",
                "physicalRef": "Fysieke verwijzing",
                "ref": "Toon kaart",
                "showMap": "Opslaan",
                "submit": "UID"
            },
            "list": {
                "addEvse": "Laadpunt toevoegen",
                "all": "Allemaal",
                "breadcrumb": "Laadpunten",
                "byCpo": "Filter op netwerk-ID",
                "commissionedAt": "Inbedrijfstelling",
                "connectors": "Connectoren",
                "cpoTitle": "Laadpunten van {name}",
                "decommissionedAt": "Deactivatie",
                "device": "Laadpaal",
                "emi3": "eMI3 ID",
                "free": "Gratis",
                "location": "Locatie",
                "locationTitle": "Laadpunten van {name}",
                "name": "Naam",
                "paying": "Betalende laadpaal",
                "reference": "Referentie",
                "status": "Status",
                "tariffs": "Tarieven",
                "title": "Laadpunten",
                "updatedAt": "Voor het laatst bijgewerkt op"
            },
            "operational_status": {
                "available": "In dienst",
                "blocked": "Geblokkeerd",
                "inoperative": "Niet operationeel",
                "outoforder": "Buiten werking",
                "unknown": "Onbekend"
            },
            "show": {
                "administrative": "Administratief",
                "administrativeDetails": "Administratieve status",
                "chargeStarted": "Laden gestart",
                "chargeStopped": "Laden gestopt",
                "charging": "Opladen",
                "chargingDetails": "Laadstatus",
                "connectors": "Connectoren",
                "device": "Laadbox ID",
                "deviceIsConnected": "Aangesloten",
                "directions": "Routebeschrijving",
                "emi3": "eMI3 ID",
                "floorLevel": "Verdieping",
                "misc": "Diverse",
                "name": "Naam",
                "noDescription": "Geen beschrijving",
                "noDeviceConnected": "Geen lader aangesloten, laadpunt onbereikbaar",
                "onGoingSessionStarted": "Sessie begonnen",
                "operational": "Operationeel",
                "operationalDetails": "Operationele status",
                "pairs": "Paren",
                "parkingRestrictions": "Parkeerrestricties",
                "physicalReference": "Fysieke verwijzing",
                "ref": "Referentie",
                "stopCharge": "Stop laden"
            },
            "status": {
                "alive": "Levend",
                "available": "Beschikbaar op",
                "blocked": "Geblokkeerd",
                "charging": "Opladen",
                "inoperative": "Inoperatief",
                "obstructed": "Geblokkeerd",
                "outoforder": "Buiten werking",
                "planned": "Gepland",
                "removed": "Verwijderd",
                "reserved": "Gereserveerd",
                "test": "Test",
                "unavailable": "Niet beschikbaar",
                "unknown": "Niet-communicerende",
                "unobstructed": "Ongehinderd"
            }
        },
        "fix": {
            "bot": {
                "automatic_action_close": {
                    "LanDevice_reconnected": "Alle CBID's van laadpunten zijn opnieuw aangesloten. Het ticket fix is automatisch gesloten.",
                    "charge_completed": "Er is een geldige lading uitgevoerd op een van de laadpunten. Het ticket is automatisch gesloten.",
                    "connector_available": "Er zijn geen fouten gemeld op de laadpuntconnectoren. Het ticket fix is automatisch gesloten.",
                    "inactive_emergency_issue": "Aangezien er geen nieuws is van de gebruiker of de contactpersoon voor noodgevallen, beschouwt Freshmile het ticket voor noodgevallen als opgelost. Het is automatisch gesloten."
                },
                "automatic_action_close_default": "Het ticket voor fix is automatisch gesloten.",
                "automatic_action_create_default": "Het ticket fix is automatisch geopend.",
                "automatic_action_update_default": "Het ticket fix is automatisch gewijzigd.",
                "chargeCompleted": "Lading voltooid",
                "chargeCompletedTitle": "COMPLETED + > 2min + > 500Wh op ten minste één Pdc gekoppeld aan een ticket is opgetreden.",
                "connectorAvailable": "Connector operationeel",
                "connectorAvailableTitle": "Als alle PdC-connectoren gekoppeld aan een ticket een StatusNotificationRequest: NoError teruggeven",
                "disabled": "Gedeactiveerd",
                "lanDeviceConnected": "CBID aangesloten",
                "lanDeviceConnectedTitle": "Als alle CBID's van de PdC's die zijn gekoppeld aan een ticket (opnieuw) zijn aangesloten."
            },
            "create": {
                "addTask": "toevoegen",
                "allOptions": "Alle opties",
                "assignTo": "Ticket toewijzen aan",
                "autoManagement": "Automatisch sluiten",
                "breadcrumb": "Een ticket maken",
                "clickUpload": "Klik om een bestand te selecteren",
                "dropUpload": "Bestanden slepen of klikken. Maximale grootte: {max} Kb",
                "fewerOptions": "Minder opties",
                "issueTitle": "Titel ticket",
                "linkTo": "Ticket koppelen aan",
                "optionnalEntity": "Leeg laten om toe te wijzen aan netwerk {operator}",
                "specificEvse": "PoC's opgeven (standaard hele station)",
                "subscribeTo": "Abonneer gebruikers",
                "title": "Fix - Nieuw ticket",
                "titleScope": "Maak een ticket voor {naam}"
            },
            "dashboard": {
                "automaticActionsApplied": "Automatisch gesloten",
                "createdAt": "Gemaakt op {datum} door {e}-mail",
                "edit": "Bewerken",
                "entityFilter": "Zoeken",
                "exportAll": "Alle exporteren",
                "filterSelection": "{aantal} geselecteerde entiteiten",
                "new": "Nieuw ticket",
                "show": "Zie",
                "showMoreBtn": "Meer zien",
                "statusFilter": "Filter op status",
                "title": "Fix - Dashboard",
                "updatedAt": "Bijgewerkt op {datum} door {e}-mail",
                "withMsg": "Inclusief berichten en beschrijvingen"
            },
            "duplicate": {
                "duplicate": "Dubbele",
                "issueTitle": "Ticket titel",
                "linkMessages": "Dubbele berichten",
                "linkModels": "Dubbele entiteiten",
                "linkTags": "Dubbele tags",
                "linkTasks": "Dubbele taken",
                "success": "Dupliceer ticket",
                "title": "Een ticket dupliceren"
            },
            "emergency-issue-title": {
                "LOCKED_CABLE": "Noodgeval: Kabel geblokkeerd ({evseRef})",
                "SECURITY": "Noodgeval : Beveiligingsprobleem ({evseRef})"
            },
            "entity": {
                "connectors": "Connectoren",
                "devices": "CBID",
                "evses": "PdC",
                "lans": "Lokaal netwerk",
                "locations": "Station",
                "networks": "Netwerk",
                "status": "Status",
                "supervisors": "Toegewezen",
                "tags": "Tags"
            },
            "history": {
                "title": "Fix - Geschiedenis"
            },
            "list": {
                "action": "Acties",
                "assigned": "Toegewezen",
                "author": "Auteur",
                "automaticActionsApplied": "Automatisch sluiten",
                "begin": "Begindatum",
                "breadcrumb0": "Curatief onderhoud",
                "breadcrumb1": "Preventief onderhoud",
                "breadcrumb3": "Informatie",
                "closeSelection": "Selectie sluiten",
                "closedAt": "Gesloten op",
                "closedBy": "Gesloten op",
                "completedAt": "Voltooid op",
                "confirmClose": "Ticket sluiten",
                "correctiveTitle": "Tickets voor correctief onderhoud",
                "correctiveTitleScope": "Correctieve onderhoudstickets voor {naam}",
                "created": "Datum aangemaakt",
                "description": "Beschrijving",
                "end": "Vervaldatum",
                "entities": "Verwante entiteit(en)",
                "historyTitleScope": "Ticketgeschiedenis voor {naam}",
                "incidentDate": "Incident op",
                "informationTitle": "Informatie",
                "informationTitleScope": "Informatie van {naam}",
                "interventionAt": "Interventie op",
                "name": "Naam",
                "preventiveTitle": "Tickets voor preventief onderhoud",
                "preventiveTitleScope": "Onderhoudstickets voor {name}",
                "startedAt": "Overgenomen op",
                "status": "Status",
                "tags": "Tags",
                "tasks": "Taken",
                "title": "Titel",
                "type": "Type onderhoud",
                "updateSelection": "Selectie bijwerken",
                "updatedAt": "Bijgewerkt door",
                "updatedBy": "Bijgewerkt door"
            },
            "scope": {
                "createIssue": "Een ticket aanmaken",
                "ongoingIssues": "Huidige tickets: {count}"
            },
            "show": {
                "about": "Freshmile Fix, ticket #{id}",
                "activity": "Activiteit",
                "add": "Toevoegen",
                "addedBy": "Toegevoegd door {email}",
                "attachments": "Bijlagen",
                "close": "Ticket sluiten",
                "closedAt": "Gesloten op {datum}",
                "closedAtBy": "Gesloten op {datum} door {email}",
                "comments": "Opmerkingen",
                "completeClose": "Je kunt de ontbrekende data invullen voordat je het ticket sluit.",
                "completedDate": "Datum van resolutie",
                "confirmDelete": "Wil je de waarde verwijderen?",
                "confirmDeleteFile": "Wil je het bestand verwijderen?",
                "confirmDeleteTask": "Wil je de taak verwijderen?",
                "deleteMessageAnswers": "Wil je de antwoorden verwijderen?",
                "deleteMessageTitle": "Wil je het bericht verwijderen?",
                "description": "Beschrijving",
                "download": "Downloaden",
                "interventionDate": "Datum van interventie",
                "lastUpdate": "Bijgewerkt: {datum}",
                "lastUpdateBy": "Bijgewerkt: {datum} door {email}",
                "mailBody": "Hello,%0D%0A%0D%0AWe invite you to consult the ticket \"{name}\" : {url}%0D%0A%0D%0AThanks,",
                "messageComment": "Opmerking",
                "messageEdit": "Bewerk",
                "messageReply": "Antwoord op",
                "messages": "Berichten",
                "noMessage": "Momenteel geen bericht",
                "noTask": "Momenteel geen taak",
                "noUpload": "Geen bijlagen op dit moment",
                "original": "Oorspronkelijk ticket :",
                "reopen": "Heropen ticket",
                "replyTo": "Antwoord naar {e}-mail",
                "resolvedBy": "Opgelost door {email} op {datum}",
                "subscribe": "Inschrijven",
                "subscribed": "Abonnees",
                "taskDescription": "Taak beschrijving",
                "title": "Fix #{id}",
                "todoList": "Takenlijst",
                "unsubscribe": "Uitschrijven",
                "update": "bijwerken",
                "urlPrompt": "Voer een URL in:"
            },
            "stats": {
                "emergency": {
                    "average_between_opening_intervention_emergencies": "Average emergency response time",
                    "emergency-by-evse-table": {
                        "title": "Rangschikking van de stations met het hoogste aantal noodstoringen"
                    },
                    "title": "Onderhoudsstatistieken Noodoplossing",
                    "total_ticket_emergencies_fix": "Totaal aantal aangemaakte emergency Fix tickets"
                },
                "maintenance": {
                    "issue-count-table": {
                        "title": "Rangschikking van de stations met het hoogste aantal storingen"
                    },
                    "issue-type-table": {
                        "title": "Rangschikking van soorten netwerkstoringen"
                    },
                    "title": "Onderhoudsstatistieken Fix",
                    "total_tickets_fix_created_by_network": "Totaal aantal aangemaakte Fix tickets",
                    "total_tickets_fix_resolve_by_network": "Totaal aantal opgeloste Fix tickets"
                },
                "network": {
                    "average_between_opening_intervention": "Gemiddelde reactietijd",
                    "average_failures_occurrence_resolution": "Gemiddelde tijd tot oplossing",
                    "evse-unavailability-table": {
                        "title": "Rangschikking van de oplaadpunten met de hoogste onbeschikbaarheid in uren"
                    },
                    "title": "Algemene statistieken over netwerkonderhoud"
                },
                "noIssue": "No Issue",
                "pdf": {
                    "fileName": "Fix – Statistieken",
                    "issue_type": "Aard van de storing",
                    "rate": "Tarief (aantal)"
                },
                "title": "Fix – Statistieken"
            },
            "status": {
                "ABANDONED": "Abandoned",
                "AWAITING": "Waiting",
                "AWAITING_REPORT": "Awaiting Report",
                "BEING_PROCESSED": "Being processed",
                "COMPLETED": "Completed",
                "NEW": "New",
                "ONGOING": "Ongoing",
                "PLANNED": "Planned",
                "dashboard": {
                    "CORRECTIVE": {
                        "AWAITING": "Waiting",
                        "BEING_PROCESSED": "Being Processed",
                        "NEW": "New"
                    },
                    "PREVENTIVE": {
                        "AWAITING": "Scheduled",
                        "AWAITING_REPORT": "Awaiting report",
                        "ONGOING": "Supported",
                        "PLANNED": "To plan"
                    }
                }
            },
            "supervisor": {
                "closed": "Gesloten",
                "open": "Open",
                "title": "Mijn tickets"
            },
            "tags": {
                "addTitle": "Een tag toevoegen",
                "color": "Kleur",
                "confirmDelete": "Wil je de tag verwijderen?",
                "editTitle": "Een tag wijzigen",
                "title": "Beheer tags"
            },
            "title": "Fix",
            "toast": {
                "issue": {
                    "clipboard": "Gekopieerde ticketlink",
                    "updated": "Ticket bijgewerkt"
                },
                "message": {
                    "delete": "Bericht verwijderd",
                    "updated": "Bericht bijgewerkt"
                },
                "quote": {
                    "failed": "Kan een gebruiker niet citeren"
                },
                "subscription": {
                    "add": {
                        "failed": "Failed to subscribe user to this issue",
                        "success": "Gebruiker succesvol aangemeld"
                    },
                    "remove": {
                        "failed": "Kan gebruiker niet afmelden voor dit ticket",
                        "success": "Gebruiker succesvol uitgeschreven"
                    }
                },
                "tag": {
                    "added": "Tag succesvol aangemaakt",
                    "deleted": "Tag verwijderd",
                    "updated": "Tag bijgewerkt"
                },
                "task": {
                    "added": "Taak toegevoegd",
                    "deleted": "Taak verwijderd",
                    "updated": "Taak bijgewerkt"
                },
                "upload": {
                    "added": "Bestand(en) toegevoegd",
                    "deleted": "Bestand(en) verwijderd",
                    "ignored": "De volgende bestanden zijn genegeerd omdat ze te groot zijn ({max} kB): {files}"
                }
            },
            "type": {
                "corrective": "Curatief onderhoud",
                "information": "Informatie",
                "preventive": "Preventief onderhoud",
                "soonLate": "Vertragingen en komende deadlines"
            }
        },
        "header": {
            "alerts": "Waarschuwingen beheren",
            "cdr": "CDRs",
            "contact": "Contacts",
            "dashboard": "Dashboard",
            "device": "CBID",
            "evse": "Laadpunt",
            "fix": {
                "dashboard": "Fix - Dashboard",
                "history": "Fix - History",
                "stats": "Fix - Statistieken",
                "tags": "Tags",
                "title": "Fix"
            },
            "generateQRcodes": "Genereer QR-codes",
            "generateRetro": "Generator of retrocession .pdf",
            "location": "Locaties",
            "locations_mass_import": "Import van lijst met Locaties",
            "logout": "Uitloggen",
            "network": "Netwerken",
            "others": "Andere",
            "reports": "Activeringen",
            "reservation": "Reserveringen",
            "retrocessionGroups": "Retrocession groups",
            "retrocessionLogs": "Retrocession logs",
            "retrocessions": "Retrocessions",
            "role": "Rol",
            "session": "Sessies",
            "settings": "Instellingen",
            "stats": "Statistieken",
            "statsInSubmenu": "Algemeen",
            "tariff": "Tarieven",
            "ticket": "Tickets",
            "translations": "Vertalingen"
        },
        "languages": {
            "de": "Duits",
            "en": "Engels",
            "es": "Spaans",
            "fr": "Frans",
            "gb": "Engels",
            "gn": "Vertaling uitschakelen",
            "nl": "Nederlands",
            "undefined": "Ongedefinieerd"
        },
        "lans": {
            "create": {
                "breadcrumb": "Nieuwe LAN",
                "feedback": "LAN succesvol toegevoegd",
                "title": "Nieuwe LAN in {name}"
            },
            "edit": {
                "breadcrumb": "Bewerk",
                "feedback": "LAN succesvol bijgewerkt",
                "title": "Bewerk"
            },
            "form": {
                "addLan": "LAN toevoegen",
                "availableEvses": "Beschikbaar laadpunt",
                "chargeboxid": "Laadbox ID",
                "chargeboxidAvailable": "Laadbox ID is beschikbaar",
                "chargeboxidUnavailable": "Laadbox ID is niet beschikbaar",
                "description": "Beschrijving",
                "deviceDefinition": "Optioneel=> definitie van de lader",
                "deviceProtocol": "Protocol",
                "dns1": "DNS 1",
                "dns2": "DNS 2",
                "editLan": "LAN bewerken",
                "gateway": "Gateway",
                "gatewayIsInvalid": "Ongeldige Gateway",
                "isDhcp": "DHCP ingeschakeld",
                "lan": "LAN",
                "lanCreation": "Nieuwe LAN",
                "lanCreationError": "Fout",
                "lanCreationSuccess": "LAN succesvol toegevoegd",
                "lanDescription": "LAN-beschrijving",
                "lanDeviceCreation": "Nieuwe LAN",
                "lanName": "Naam",
                "localIp": "Lokaal IP-adres",
                "localPort": "Lokale poort",
                "model": "Model",
                "modelPlaceholder": "Model",
                "name": "Naam",
                "newLanName": "LAN #",
                "offlineEvses": "Offline laadpunt",
                "optional": "(optioneel)",
                "proxy": "Proxy",
                "publicIp": "Openbaar IP-adres",
                "publicIpIsInvalid": "Ongeldig openbaar IP-adres",
                "publicPort": "Openbare poort",
                "selectLocation": "Locatie",
                "submit": "Nieuwe lader toevoegen",
                "subnetMask": "Subnetmasker",
                "subnetMaskIsInvalid": "Ongeldig subnetmasker",
                "technicalInfos": "Technische informatie",
                "unknownModel": "Onbekend model",
                "url": "URL",
                "wirelessLogic": "Draadloze logica"
            },
            "list": {
                "breadcrumb": "LAN",
                "create": "Toevoegen",
                "gateway": "Poort",
                "lanDevices": "Laders",
                "name": "Naam",
                "proxy": "Proxy",
                "publicIp": "Openbaar IP-adres",
                "subnetMask": "Subnetmasker",
                "title": "LAN van {name}",
                "updatedAt": "Voor het laatst bijgewerkt op"
            },
            "proxies": {
                "MAIN": "Main",
                "NONE": "None",
                "WIRELESSLOGIC": "Wireless Logic"
            },
            "show": {
                "addDevice": "CBID toevoegen",
                "connectedDevices": "Aangesloten CBID",
                "description": "Beschrijving",
                "deviceIsConnected": "Aangesloten",
                "edit": "Bewerk",
                "gateway": "Poort",
                "lastUpdate": "Voor het laatst bijgewerkt op",
                "name": "Naam",
                "nat": "NAT",
                "protocol": "Protocol",
                "proxy": "Proxy",
                "publicIp": "Openbaar IP-adres",
                "subnetMask": "Subnetmasker",
                "url": "URL"
            }
        },
        "locations": {
            "create": {
                "breadcrumb": "Nieuwe locatie",
                "cpoTitle": "Nieuwe locatie in {name}",
                "feedback": "Locatie succesvol toegevoegd",
                "locationsList": "Locaties",
                "title": "Nieuwe locatie"
            },
            "edit": {
                "edit": "Bewerk",
                "feedback": "Locatie succesvol bijgewerkt"
            },
            "form": {
                "addOpeningHourFor": "Voeg een openingstijd toe voor {day}",
                "address": "Adres",
                "asyncSearch": "Zoeken op naam, adres, stad, postcode",
                "chargingWhenClosed": "Opladen in gesloten toestand",
                "city": "Stad",
                "country": "Land",
                "countryPlaceholder": "Land",
                "cpoPlaceholder": "Netwerk",
                "directions": "Toegangsinformatie",
                "displayMap": "Kaart weergeven",
                "emi3": "eMI3 ID",
                "facilities": "Faciliteiten",
                "facilitiesPlaceholder": "Faciliteiten",
                "hideMap": "Kaart verbergen",
                "informations": "Informatie",
                "invalidHourMessage": "Ongeldig",
                "latitude": "Breedtegraad",
                "latitudePlaceholder": "Breedtegraad",
                "localisation": "Locatie",
                "longitude": "Lengtegraad",
                "longitudePlaceholder": "Lengtegraad",
                "name": "Naam",
                "network": "Netwerk",
                "openingHoursInfos": "Openingsuren",
                "optional": "(optioneel)",
                "postalcode": "Postcode",
                "resetPosition": "Positie opnieuw instellen",
                "submit": "Opslaan",
                "time_zone": "Timezone",
                "twentyfourseven": "24\/7 geopend",
                "type": "Type",
                "typePlaceholder": "Type"
            },
            "list": {
                "addLocation": "Locatie toevoegen",
                "breadcrumb": "Locaties",
                "chargePoints": "Laadpunten",
                "city": "Stad",
                "country": "Land",
                "cpoId": "Netwerk ID",
                "cpoTitle": "Locaties van {name}",
                "emi3": "eMI3 ID",
                "name": "Naam",
                "timeZone": "Timezone",
                "title": "Locaties",
                "type": "Type",
                "updatedAt": "Bijgewerkt"
            },
            "show": {
                "address": "Adres",
                "chargingWhenClosed": "Opladen in gesloten toestand",
                "city": "Stad",
                "country": "Land",
                "cpo": "Netwerk",
                "directions": "Toegangsinformatie",
                "edit": "Bewerk",
                "emi3": "eMI3 ID",
                "facilities": "Diensten",
                "lastUpdate": "Bijgewerkt",
                "localisation": "Locatie",
                "location": "Locatie",
                "locationInfos": "Locatie-informatie",
                "locations": "Locaties",
                "no": "Geen",
                "noRegularHours": "No opening hours configured",
                "openingHoursInfos": "Openingstijden",
                "twentyfourseven": "24\/7 geopend",
                "type": "Type",
                "yes": "Ja"
            }
        },
        "login": {
            "email": "E-mail",
            "intro": "Volg uw netwerk",
            "loginSubmit": "Aansluiting",
            "password": "Wachtwoord",
            "passwordReset": "Wachtwoord opnieuw instellen"
        },
        "maintenance-alert": {
            "commons": {
                "list": {
                    "last-valid-session-date": "Laatste geldige sessiedatum"
                }
            },
            "downtime": {
                "list": {
                    "subtitle": "Lijst van CBID's die minstens 12 uur geen communicatie hebben gehad",
                    "title": "Communicatiestoringen"
                }
            },
            "error-code": {
                "list": {
                    "subtitle": "Lijst van connectoren die in de afgelopen 1 uur een foutcode hebben gerapporteerd",
                    "title": "Foutcodes"
                }
            },
            "no-energy-rate": {
                "list": {
                    "no-energy-count": "Aantal zonder verbruik",
                    "no-energy-rate": "% zonder verbruik",
                    "subtitle": "Lijst van CBID's met minstens 20% geldige sessies waarbij 0 kWh energie is gerapporteerd in de afgelopen 30 dagen.",
                    "title": "Energie-afwezigheid",
                    "with-energy-count": "Aantal met verbruik"
                }
            },
            "session-failure-rate": {
                "list": {
                    "failure-rate": "% ongeldig (<30 dagen)",
                    "invalid-count": "Ongeldig aantal",
                    "subtitle": "Lijst van CBID's met minstens 60% ongeldige sessies in de afgelopen 30 dagen.",
                    "title": "Laadverhoudingen",
                    "valid-count": "Geldig aantal"
                }
            },
            "usage-rate": {
                "list": {
                    "session-count-last30d": "Aantal sessies (<30 dagen)",
                    "session-count-prior-month": "Aantal sessies (30;60 dagen)",
                    "subtitle": "Lijst van CBID's met minstens 50% afname in gebruik in vergelijking met de afgelopen 30 dagen.",
                    "title": "Gebruiksafnames",
                    "usage-rate": "% afname"
                }
            }
        },
        "networks": {
            "list": {
                "breadcrumb": "Netwerken"
            }
        },
        "notifications": {
            "DOWNTIME": "Communication Losses",
            "ERROR_CODE": "Error Codes",
            "NO_ENERGY_RATE": "Energy Absences",
            "SESSION_FAILURE_RATE": "Load Ratios",
            "USAGE_RATE": "Utilization Drops",
            "confirmSelection": "Selectie bevestigen",
            "selectAll": "Selecteer alles",
            "tabs": {
                "handled": "Verwerkt",
                "unhandled": "In afwachting van"
            },
            "text": "Maintenance"
        },
        "ocpi": {
            "locationFacilities": {
                "AIRPORT": "Luchthaven",
                "BUS_STOP": "Bushalte",
                "CAFE": "Café",
                "CARPOOL_PARKING": "Carpoolplaats",
                "FUEL_STATION": "Benzinestation",
                "HOTEL": "Hotel",
                "MALL": "Winkelcentrum",
                "MUSEUM": "Museum",
                "NATURE": "Natuur",
                "RECREATION_AREA": "Recreatiegebied",
                "RESTAURANT": "Restaurant",
                "SPORT": "Sport",
                "SUPERMARKET": "Supermarkt",
                "TAXI_STAND": "Taxistandplaats",
                "TRAIN_STATION": "Treinstation",
                "WIFI": "Wifi"
            },
            "locationTypes": {
                "ON_STREET": "Parkeren op straat",
                "OTHER": "Andere",
                "PARKING_GARAGE": "Parkeergarage met meerdere verdiepingen",
                "PARKING_LOT": "Parkeerplaats",
                "UNDERGROUND_GARAGE": "Ondergrondse parkeergarage",
                "UNKNOWN": "Onbekend"
            },
            "logs": {
                "CLIENT": "Client",
                "REQUEST": "Aanvraag",
                "RESPONSE": "Reactie",
                "SERVER": "Server",
                "breadcrumb": "OCPI",
                "context": "Context",
                "date": "Datum",
                "distant_operator": "Operator",
                "filterPlaceholder": "Zoeken op bericht",
                "message": "Bericht",
                "method": "Methode",
                "model": "Object",
                "module": "Module",
                "ocpi": "OCPI logboeken",
                "request_type": "Type",
                "title": "OCPI logboeken of {name}",
                "way": "Richting"
            },
            "parkingRestrictions": {
                "CUSTOMERS": "Voorbehouden voor gasten",
                "DISABLED": "Voorbehouden voor gehandicapten",
                "EV_ONLY": "Voorbehouden voor EV",
                "MOTORCYCLES": "Voorbehouden voor motorfietsen of scooters",
                "PLUGGED": "Voorbehouden voor EV tijdens het laden"
            },
            "priceComponentTypes": {
                "DURATION": "Duration",
                "ENERGY": "Energie",
                "FLAT": "Forfaitair",
                "PARKING_TIME": "Parkeertijd",
                "STOP": "Stop",
                "TIME": "Tijd"
            },
            "reservationStatus": {
                "ACTIVE": "Actief",
                "CANCELLED": "Geannuleerd",
                "ERROR": "Fout",
                "FINISHED": "Klaar",
                "NOSHOW": "Niet langs geweest"
            }
        },
        "ocpp": {
            "logs": {
                "REQUEST": "Verzoek",
                "RESPONSE": "Reactie",
                "breadcrumb": "OCPP logboeken",
                "filterPlaceholder": "Zoeken op bericht",
                "message": "Bericht",
                "protocol": "Protocol",
                "requestType": "Type",
                "sentBy": "Uitgever",
                "title": "OCPP logboeken van {name}"
            }
        },
        "operators": {
            "capabilities": {
                "CHARGING_PROFILE_CAPABLE": "Laadprofiel",
                "CREDIT_CARD_PAYABLE": "Verkooppunt",
                "REMOTE_START_STOP_CAPABLE": "Laden met smartphone",
                "RESERVABLE": "Reservering",
                "RESET_CAPABLE": "Reset",
                "RFID_READER": "RFID",
                "UNLOCK_CAPABLE": "Ontgrendeling op afstand",
                "info": {
                    "CHARGING_PROFILE_CAPABLE": "Binnenkort",
                    "CREDIT_CARD_PAYABLE": "Gebruikers kunnen ter plaatse betalen met een creditcard \/ debetkaart",
                    "REMOTE_START_STOP_CAPABLE": "Gebruikers kunnen op afstand starten en stoppen vanaf hun smartphone",
                    "RESERVABLE": "Gebruikers kunnen een laadpunt boeken om op te laden",
                    "RESET_CAPABLE": "Hotline kan de lader van een afstand resetten",
                    "RFID_READER": "Gebruikers kunnen beginnen en stoppen met een RFID-kaart",
                    "UNLOCK_CAPABLE": "Hotline kan kabels op afstand ontgrendelen"
                }
            },
            "configuration": {
                "breadcrumb": "Configuraties",
                "capability_set_by_parent": "Kan niet worden gewijzigd omdat deze is ingesteld door de bron {emi3}",
                "manyEvsesWarning": "Alle laadpunten worden bijgewerkt... wacht even",
                "needSubmit": "Gelieve op te slaan om de wijzigingen te bevestigen",
                "technics": "Technische configuratie",
                "title": "Configuratie van {name}"
            },
            "create": {
                "feedback": "Netwerk succesvol toegevoegd",
                "subCPOTitle": "Nieuw sub-netwerk naar {naame}",
                "title": "Nieuw netwerk"
            },
            "edit": {
                "breadcrumb": "Bewerk",
                "feedback": "Netwerk succesvol bijgewerkt",
                "title": "Bewerk"
            },
            "form": {
                "allowIssueClose": "Allow automatic closing of a ticket",
                "allowIssueCloseDesc": "De optie wordt aangeboden wanneer het ticket is aangemaakt. Indien gewijzigd, heeft dit geen invloed op bestaande tickets.",
                "businessInfos": "Zakelijke informatie",
                "businessName": "Naam",
                "businessURL": "Website",
                "comInterval": "Communicatie-interval",
                "commission": "Provisie en bankkosten",
                "consoInterval": "Interval van de meterwaarden",
                "crc": "CRC",
                "defaultTariff": "Standaardtarief",
                "defaultTariffPlaceholder": "Standaardtarief",
                "defaultVAT": "Standaard BTW",
                "detectionLoop": "Detectielus",
                "emi3IsUndefined": "Ongedefinieerde eMI3-ID",
                "emi3SuccessMessage": "Is beschikbaar",
                "emi3UnavailableMessage": "Deze eMI3 ID is niet beschikbaar. Probeer een andere",
                "generateEmi3": "Genereer",
                "heartbeat_interval": "Hartslaginterval",
                "inheritance": "Uitbreiden tot subnetwerken",
                "initialState": "Oorspronkelijke staat",
                "interopConfig": "Configuratie voor toegang",
                "invalidEmi3Message": "Is geen geldig eMI3-ID. Probeer het opnieuw",
                "maintenance": "Onderhoud",
                "metervalues_sample_interval": "Interval van de meterwaarden",
                "name": "Naam",
                "optional": "(optioneel)",
                "parent": "Bron",
                "parentPlaceholder": "Selecteer een bron",
                "presence": "Detectieapparaat",
                "privateEmi3": "Particuliere eMI3-ID",
                "publicEmi3": "Openbare eMI3-ID",
                "publicEmi3Placeholder": "Selecteer een eMI3-ID",
                "refPlaceholder": "Kan worden gevuld met de naam",
                "refUnavailableMessage": "Deze referentie is niet beschikbaar. Probeer een andere",
                "retrocession": "Retrocessie",
                "retrocessionGroup": "Retrocessie groep",
                "sameAsParent": "Hetzelfde als de bron",
                "sameAsPrivate": "Deze exploitant",
                "segment": "Segment",
                "submit": "Opslaan",
                "support": "Support",
                "timezone": "Tijdzone",
                "timezonePlaceholder": "Tijdzone"
            },
            "hotline": {
                "phoneNumber": "Telefoonnummer",
                "schedule": "Plan",
                "schedules": {
                    "Mon-Fri 7\/22": "Maandag tot vrijdag van 7 tot 22 uur",
                    "Mon-Fri 9\/17": "Maandag tot vrijdag van 9 tot 17 uur",
                    "Mon-Fri H24": "Maandag tot en met vrijdag, 24 uur",
                    "Mon-Sun 7\/22": "Elke dag, van 7.00 tot 22.00 uur",
                    "Mon-Sun 9\/17": "Elke dag, van 9 tot 17 uur",
                    "Mon-Sun H24": "Dagelijks, 24 uur"
                }
            },
            "list": {
                "VAT": "BTW",
                "breadcrumb": "Netwerken",
                "children": "Sub-netwerken",
                "create": "Een netwerk toevoegen",
                "default_tariff": "Tarief",
                "emi3": "eMI3 ID",
                "evses": "Laadpunten",
                "exportWithchildrens": "Inclusief kinderen",
                "locations": "Locaties",
                "name": "Naam",
                "network": "Netwerk",
                "ongoingIssues": "Open tickets",
                "operator": "Netwerk",
                "operatorsList": "Netwerken",
                "parent": "Bron",
                "parent_id": "Bron ID",
                "parent_ref": "Bron ID",
                "public_emi3": "Openbare eMI3-ID",
                "segment": "Segment",
                "subCPOTitle": "Sub-netwerken van {name}",
                "title": "Netwerken",
                "updated_at": "Bijgewerkt"
            },
            "map": {
                "breadcrumb": "Kaart",
                "noLocation": "Er is geen locatie in dit netwerk",
                "options": {
                    "all": "Alle",
                    "charging": "Bezig met laden",
                    "issue": "Buiten werking"
                },
                "title": "Kaart van {name}"
            },
            "segments": {
                "COMPANIES": "Companies",
                "RESIDENTIAL": "Residential",
                "ROADS": "Roads",
                "SHOPS": "Shops",
                "UNINFORMED": "Not specified"
            },
            "show": {
                "VAT": "BTW",
                "business": "Zakelijk",
                "businessName": "Naam",
                "businessURL": "Website",
                "crc": "Hotline",
                "maintenance": "Onderhoud",
                "parent": "Bron",
                "privateEmi3": "Particuliere eMI3-ID",
                "publicEmi3": "Openbare eMI3-ID",
                "segment": "Segment",
                "support": "Ondersteuning",
                "tariff": "Tarief",
                "timezone": "Tijdzone",
                "undefined": "Niet gedefinieerd"
            }
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "password": "Minstens 6 letters of cijfers. Beide wachtwoorden moeten overeenkomen",
            "reset": "Wachtwoord opnieuw instellen",
            "sent": "E-mail is verstuurd",
            "token": "Fout",
            "user": "Verkeerde inloggegevens"
        },
        "payment": {
            "iban": "automatische incasso",
            "methods": {
                "expires:": "Verloopt op:",
                "invalid": "Ongeldig",
                "valid": "Geldig"
            },
            "refunds": {
                "addFormTitle": "Een restitutie uitvoeren",
                "alreadyProcessed": "Restitutie al betaald",
                "amountExceedRefund": "Bedrag hoger dan restitutieprijs",
                "amountIncVat": "Bedrag (incl. btw)",
                "confirmDelete": "Wil je deze terugbetaling echt annuleren?",
                "confirmDeleteTitle": "Restitutie verwijderen",
                "created": "Restitutie aangemaakt",
                "deleted": "Restitutie verwijderd",
                "editFormTitle": "Restitutie wijzigen",
                "edited": "Restitutie gewijzigd",
                "export": "Restituties exporteren",
                "highAmountWarn": "Restitutie groter dan 100 {valuta}",
                "inRetrocession": "Opgenomen in retrocessie",
                "includeRetro": "Op te nemen in retrocessie",
                "method": "Methode",
                "moveConnection": "Je moet verbonden zijn met Move om restituties te kunnen verwerken.",
                "processedAt": "Uitgevoerd op",
                "reason": "Rechtvaardiging",
                "refund": "Terugbetaling",
                "refundIssue": "Er kan een fout zitten in de terugbetaling voor de stripe of prepaid account.",
                "refunded": "Terugbetaald",
                "session": "Refund for session from {date} on {evse_ref}",
                "stripeAutoRefund": "Automatic refund via Stripe is not possible, please refund the user manually.",
                "subscription_with_order": "Refund for subscription to plan \"{plan_name}\" (order from {order_date}).",
                "subscription_without_order": "Refund for subscription to plan \"{plan_name}\".",
                "token_with_order": "Refund for token n°{token_number} (order from {order_date}).",
                "token_without_order": "Refund for token n°{token_number}.",
                "way": {
                    "manual": "Manual",
                    "prepaid": "Prepaid",
                    "prepaid_account": "Prepaid",
                    "stripe": "Stripe"
                }
            },
            "status": {
                "AUTHORIZED": "Geautoriseerde",
                "CANCELLED": "Geannuleerd",
                "CAPTURED": "Opgehaald",
                "DONE": "Gevalideerd",
                "FAILED": "Mislukt",
                "PENDING_USER": "Wachtend op",
                "REFUNDED": "Terugbetaald"
            },
            "transaction_labels": {
                "credit": "Saldo op een vooruitbetaalde rekening: {amount} {CURRENCY}",
                "credit_invoice_refund": "Terugbetaling voor factuur {invoice_ref}",
                "debit": "Afschrijving van vooruitbetaalde rekening: {amount} {CURRENCY}",
                "invoice": "Factuur {ref}",
                "order": "Bestelling {ref}",
                "payment": "Factuur {invoice_ref} (Betaling {payment_ref})",
                "session": "Laadsessie {ref}",
                "subscription": "Abonnement {ref} (tot {end_at})"
            }
        },
        "reservations": {
            "add": "Voeg  toe",
            "authId": "Contract",
            "authIdPlaceholder": "Moet overeenkomen met een eMAID in Move",
            "breadcrumb": "Reserveringen",
            "calendar": "Kalender",
            "cancel": "Annuleer",
            "cancelationIsSuccessful": "Reservering geannuleerd",
            "create": {
                "authId": "Contract",
                "end": "Einde",
                "locationId": "Locatie",
                "start": "Begin met",
                "submit": "Bespaar",
                "title": "Nieuwe reservering"
            },
            "creationIsSuccessful": "Reservering toegevoegd",
            "day": "Dag",
            "list": {
                "breadcrumb": "Reservering"
            },
            "location": "Locatie",
            "locationTitle": "Reserveringen voor {name}",
            "month": "Maand",
            "noReservationsThatDay": "Geen reservering voor deze dag",
            "noReservationsThatMonth": "Geen reservering voor deze maand",
            "noReservationsThatWeek": "Geen reservering voor deze week",
            "reservation": "Reservering",
            "status": {
                "ACTIVE": "Actief",
                "CANCELLED": "Geannuleerd",
                "ERROR": "Fout",
                "FINISHED": "Klaar",
                "NOSHOW": "Niet langs geweest"
            },
            "time": "Tijd",
            "title": "Reserveringen",
            "week": "Week"
        },
        "retrocession-group-logs": {
            "list": {
                "breadcrumb": "Retrocession group logs",
                "created_at": "Date",
                "message": "Message",
                "retrocessionGroupName": "Retrocession group name",
                "scopeTitle": "Retrocession group logs from {name}",
                "title": "Retrocession group logs",
                "type": "Event",
                "user": "Supervisor"
            },
            "types": {
                "ChangeCommission": "Change in commission",
                "ChangeName": "Change of name",
                "ChangeNetwork": "Change the network",
                "ChangeRib": "Change of bank details"
            }
        },
        "retrocession-groups": {
            "create": {
                "breadcrumb": "Créer un groupe de retrocessions",
                "title": "Créer un groupe de retrocessions"
            },
            "edit": {
                "title": "Modifier un groupe de retrocessions"
            },
            "form": {
                "addExisting": "Add an existing retrocession group",
                "applyPastSessions": "Apply changes to past sessions",
                "applyPastSessionsMessage": "Be careful, this is a heavy operation that can take a few seconds or even a few minutes.",
                "assign": "Assign an existing retrocession group",
                "asyncSearch": "Select an existing group",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact (optional)",
                "create": "Create a new retrocession group",
                "general": "General",
                "group": "Retrocession group",
                "name": "Name"
            },
            "iban": {
                "bankAccountName": "Account Name",
                "bic": "BIC",
                "errorBic": "The BIC must be in a valid format of the SEPA zone",
                "errorIban": "The IBAN must be in a valid format of the SEPA zone",
                "iban": "IBAN",
                "modalDeleteConfirm": "The bank account details have been successfully deleted",
                "modalDeleteContent": "If you delete bank account details of the retrocession group, we will not be able to pay you your retrocession.",
                "modalDeleteError": "An error occurred while deleting the bank account details",
                "modalDeleteTitle": "Delete a bank account detail",
                "modalDeleteWarning": "Be careful, you are about to delete bank account details.",
                "modalUpdateTitle": "Edit the RIB of the retrocession group"
            },
            "list": {
                "breadcrumb": "Retrocession groups",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "created_at": "Creation date",
                "has_bank_info": "RIB completed",
                "name": "Retrocession group name",
                "nb_networks": "Number of networks",
                "title": "Retrocession groups"
            },
            "network": {
                "add": "Add",
                "continue": "Continue",
                "create": "Create a new group",
                "removeMessage": "You are about to remove the retrocession group from {name}. Removing the handover group from {name} also removes it from all of its subnets.",
                "removeTitle": "Remove the retrocession group",
                "replace": "Replace retrocession group"
            },
            "show": {
                "bankInfo": "Banking information",
                "commission": "Commissions and bank charges",
                "contact": "Administrative management contact",
                "contactDetail": "Contact",
                "created_at": "Creation date",
                "general": "General informations",
                "name": "Retrocession group name",
                "rib": "RIB"
            },
            "toast": {
                "added": "The retrocession group has been successfully assigned",
                "removed": "The retrocession group has been successfully removed",
                "session-updated": "The sessions has been successfully updated"
            }
        },
        "retrocessions": {
            "exportRefundsOfRetrocession": "Export van terugbetalingen van retrocessie",
            "modal": {
                "amountExceedInitialAmount": "Het retrocessiebedrag mag de oorspronkelijke prijs niet overschrijden",
                "amountWithVat": "Bedrag incl. BTW",
                "createSuccess": "De retrocessie is succesvol aangemaakt",
                "deleteSuccess": "De retrocessie is succesvol verwijderd",
                "editSuccess": "De retrocessie is succesvol gewijzigd",
                "reason": "Rechtvaardiging",
                "retrocede": "Retrocederen",
                "sessionRetrocession": "Sessie Retrocessie"
            },
            "refundOfRetrocession": "Terugbetaling van retrocessie"
        },
        "sessions": {
            "authorizationReasons": {
                "AUTHORIZED_BY_CPO": "CPO",
                "AUTHORIZED_BY_EMSP": "EMSP",
                "NOT_AUTHORIZED_BY_ANY_EMSP": "Geen EMSP",
                "NO_AUTHORIZATION_CHECK": "Geen controle",
                "ORDERED_BY_ELECTRICITY_SUPPLIER": "Elektriciteitsleverancier",
                "PAYMENT_TERMINAL": "POS",
                "UNKNOWN": "Onbekend",
                "description": {
                    "AUTHORIZED_BY_CPO": "Geautoriseerd door CPO",
                    "AUTHORIZED_BY_EMSP": "Geautoriseerd door EMSP",
                    "NOT_AUTHORIZED_BY_ANY_EMSP": "Niet geautoriseerd door een EMSP",
                    "NO_AUTHORIZATION_CHECK": "Autorisatie niet aangevraagd door lader",
                    "ORDERED_BY_ELECTRICITY_SUPPLIER": "Bestelling ontvangen van de elektriciteitsleverancier",
                    "PAYMENT_TERMINAL": "Punt van verkoop (betaalterminal ter plaatse)"
                }
            },
            "create": {
                "agreementsList": "Overeenkomsten",
                "breadcrumb": "Toevoegen",
                "title": "Overeenkomst toevoegen"
            },
            "csv": {
                "confirmTitle": "CSV van {from} naar {to}",
                "default": "Bevestigt u de uitvoering?",
                "retrocessionExporterTitle": "Export for retrocessions",
                "tooHeavy": "Het bestand wordt gegenereerd en wordt verstuurd naar {email}"
            },
            "curve": {
                "breadcrumb": "Curven",
                "title": "Curven voor #{ref}"
            },
            "edit": {
                "agreementsList": "Overeenkomsten",
                "breadcrumb": "Bewerk",
                "title": "Bewerkingsovereenkomst"
            },
            "failureReasons": {
                "AUTHORIZATION_TIMEOUT": "Time-out",
                "EMI3_MINIMUM_DURATION": "< 2 min",
                "INACTIVE": "Inactief",
                "LOCATION_FULLY_BOOKED": "Gereserveerde ruimte",
                "NO_AUTHORIZATION": "Niet toegestaan",
                "UNKNOWN": "Onbekend",
                "description": {
                    "AUTHORIZATION_TIMEOUT": "Autorisatie gestopt",
                    "EMI3_MINIMUM_DURATION": "Toepassing van de aanbevolen start voor eMI3",
                    "NO_AUTHORIZATION": "Niet geautoriseerd door een EMSP"
                }
            },
            "form": {
                "bridges": "Via",
                "cpo": "Netwerk",
                "emsp": "Aanbieder",
                "emspPlaceholder": "Zoek een aanbieder",
                "includeSubCpos": "Inclusief sub-netwerken",
                "includeSubEmsps": "Inclusief sub-aanbieders",
                "isActive": "Actief",
                "ocpiModules": "OCPI-modules",
                "searchPlaceholder": "Typ hier",
                "submit": "Opslaan"
            },
            "list": {
                "active": "Actief",
                "addAgreement": "Overeenkomst toevoegen",
                "all": "Alle",
                "authorizationReason": "Reden voor machtiging",
                "breadcrumb": "Sessies",
                "completed": "Voltooid",
                "connector": "Connector",
                "contrat": "Contract",
                "cpoTitle": "Sessies van {name}",
                "end": "Einde",
                "evse": "Laadpunt",
                "evseTitle": "Sessies van {name}",
                "failureReason": "Reden van mislukking",
                "free": "Gratis",
                "invalid": "Ongeldig",
                "kwh": "kWh",
                "locationTitle": "Sessies van {name}",
                "no": "Geen",
                "ocpiModules": "OCPI-modules",
                "ongoing": "Lopend",
                "optionsSelected": "Geselecteerde opties",
                "pending": "In afwachting van",
                "price": "Prijs",
                "source": "Oorsprong",
                "start": "Begin met",
                "station": "Locatie",
                "status": "Status",
                "stopReceivedAt": "Billing",
                "tag": "Kaart",
                "tariff": "Tarief",
                "tariffPos": "Tariff POS",
                "title": "Sessies",
                "withChildrenOperators": "Sessies van sub-netwerken",
                "yes": "Ja"
            },
            "show": {
                "agreements": "Overeenkomsten",
                "auth": "Machtiging",
                "badge": "Kaart",
                "bridges": "Bruggen",
                "cpo": "Netwerk",
                "curveBreadcrumb": "Curve",
                "curveTitle": "Curve voor",
                "edit": "Bewerk",
                "emsp": "Aanbieder",
                "energy": "Energie",
                "energyLabel": "Energie (kWh)",
                "false": "Fout",
                "finalConsumption": "Eindenergie",
                "fullPrice": "{amount} {currency}",
                "includeSubCpos": "Inclusief sub-netwerken",
                "includeSubEmsps": "Inclusief sub-anbieters",
                "isActive": "Actief",
                "isWhiteListed": "Witte lijst",
                "lastPeriod": "Laatste periode",
                "lastUpdate": "Laatste update=>",
                "noBridges": "Direct",
                "notEnoughChargingData": "Niet genoeg laadgegevens om een grafiek te maken",
                "ongoing": "Lopend",
                "periodDate": "Datum",
                "power": "Vermogen",
                "powerLabel": "Vermogen (kW)",
                "preTaxPrice": "{amount}exclusief belastingen, {vat}% BTW",
                "price": "Prijs",
                "relations": "Relaties",
                "statusIsInvalid": "Sessie mislukt. Geen kosten",
                "statusIsPending": "Sessie is nog niet begonnen",
                "stopReceivedAt": "Billing date",
                "stopSession": "Stop sessie",
                "stopSuccess": "Sessie met succes gestopt",
                "title": "Laadsessie #{ref}",
                "true": "Waar"
            },
            "source": {
                "CPO_REMOTE_ORDER": "CPO",
                "EMSP_REMOTE_ORDER": "EMSP",
                "STATION": "Lader"
            },
            "status": {
                "active": "Lopend",
                "completed": "Klaar",
                "invalid": "Ongeldig",
                "pending": "In afwachting van",
                "valid": "Geldig"
            }
        },
        "settings": {
            "accessibleOperators": "Toegankelijke operatoren (netwerken waartoe je toegang hebt)",
            "changeLang": "Taal wijzigen",
            "currentPermission": "Je huidige rol :",
            "darkTheme": "Donker thema",
            "display": "Mijn weergave aanpassen",
            "fix": {
                "disabled": {
                    "detail": "Je ontvangt geen meldingen",
                    "title": "Gedeactiveerd"
                },
                "fromDisabled": {
                    "toMonitoring": "Je bent geabonneerd op alle tickets in je netwerk",
                    "toParticipating": "Je bent geabonneerd op alle tickets waaraan je bent toegewezen en uitgeschreven voor alle andere tickets",
                    "toQuoting": "Je wordt uitgeschreven uit alle tickets, je ontvangt meldingen wanneer je wordt genoemd in een ticket"
                },
                "fromMonitoring": {
                    "toDisabled": "Je wordt uitgeschreven voor alle tickets",
                    "toParticipating": "Je zult geabonneerd zijn op alle tickets waaraan je bent toegewezen, en uitgeschreven van alle anderen",
                    "toQuoting": "Je wordt uitgeschreven voor alle tickets, je ontvangt meldingen als je in een ticket wordt genoemd"
                },
                "fromParticipating": {
                    "toDisabled": "Je wordt uitgeschreven voor alle tickets",
                    "toMonitoring": "Je zult geabonneerd zijn op alle tickets in je netwerk",
                    "toQuoting": "Je zal uitgeschreven worden uit alle tickets, je zal meldingen ontvangen wanneer je vermeld wordt in een ticket"
                },
                "fromQuoting": {
                    "toDisabled": "Je wordt uitgeschreven voor alle tickets",
                    "toMonitoring": "Je zal geabonneerd zijn op alle tickets in je netwerk",
                    "toParticipating": "Je zult geabonneerd zijn op alle tickets waaraan je bent toegewezen, en uitgeschreven van alle anderen"
                },
                "isSubscribable": "Ik wil niet geabonneerd zijn op tickets van andere supervisors",
                "monitoring": {
                    "detail": "Standaard ben je geabonneerd op alle tickets in je netwerk. Je ontvangt dus meldingen van elke activiteit.",
                    "title": "Bewaking"
                },
                "participating": {
                    "detail": "Je wordt geabonneerd op alle tickets waaraan je bent toegewezen, die worden genoemd of waarop je bent geabonneerd.",
                    "title": "Deelname"
                },
                "quoting": {
                    "detail": "Je ontvangt alleen meldingen als je wordt genoemd in een ticket of als je commentaar geeft. Je bent niet geabonneerd op deze tickets.",
                    "title": "Als je wordt genoemd"
                },
                "title": "Instellingen e-mailmeldingen voor Fix"
            },
            "fixedHeight": "Ruiten bevriezen (kolomnamen op scherm houden als je scrolt)",
            "hasAtLeastOneEmspInfo": "* In Park kun je geen leverancier selecteren",
            "others": "Overige",
            "preview": "Voorbeeld",
            "reduceMargin": "Marges verkleinen",
            "success": "Opgeslagen voorkeuren",
            "switchPermission": "Rol wijzigen",
            "tables": "Lijsten",
            "title": "Instellingen",
            "truncateHeader": "Kolomnamen verkleinen"
        },
        "sidebar": {
            "items": {
                "actions": "Acties",
                "affiliations": "Groepen",
                "agreements": "Overeenkomsten",
                "audit": "Activiteit",
                "business": "Zakelijke logboeken",
                "cdrs": "CDRs",
                "configurations": "Configuraties",
                "corrective": "Corrective maintenance",
                "costDetails": "Details over de kosten",
                "curve": "Curven",
                "details": "Details",
                "devices": "Laadbox ID",
                "duplicate": "Duplicate",
                "edit": "Bewerken",
                "evses": "Laadpunten",
                "fix": "Fix",
                "groups": "Groepen",
                "history": "History",
                "information": "Information",
                "lans": "LAN",
                "locations": "Locaties",
                "logs": "Logboeken",
                "map": "Kaart",
                "ocpi": "OCPI-logboeken",
                "ocpp": "OCPP-logboeken",
                "preventive": "Preventive maintenance",
                "reservations": "Reserveringen",
                "sessions": "Sessies",
                "subcpos": "Sub-netwerken",
                "tariffs": "Tarieven"
            }
        },
        "station-check": {
            "breadcrumb": {
                "logs": "Logboeken",
                "report": "Inbedrijfstelling"
            },
            "csv": {
                "confirmTitle": "Confirm your export"
            },
            "list": {
                "device_ok": "PdC Info correct",
                "location_ok": "Station Info correct",
                "nb_cbids": "Number of CBID",
                "nb_fail": "Fail",
                "nb_success": "Tested & successful",
                "nb_tested": "Tested",
                "title": "Commissioning of Terminals"
            },
            "logs": {
                "emptyEmailReplace": "Installer",
                "keys": {
                    "lan_device_commissioned": "CBID commissioned",
                    "lan_device_feedback_comment": "CBID Info Comment added",
                    "lan_device_feedback_comment_withdrawn": "CBID Info Comment withdrawn",
                    "lan_device_feedback_ok": "CBID Info correct",
                    "lan_device_feedback_resolved": "CBID feedback resolved",
                    "lan_device_feedbacks_all_done": "All CBID info processed",
                    "lan_device_test_is_online_ko": "Connection Test KO",
                    "lan_device_test_is_online_ok": "Connection Test OK",
                    "lan_device_test_remote_orders_ko": "Remote Commands Test KO",
                    "lan_device_test_remote_orders_ok": "Remote Commands Test OK",
                    "lan_device_validated": "CBID validated",
                    "report_archived_automatically": "Report archived automatically",
                    "report_archived_manually": "Report archived manually",
                    "report_location_feedback_ok": "Station Info correct",
                    "report_location_feedback_updated": "Station Info updated",
                    "report_status_incomplete": "Report Status incomplete",
                    "report_status_to_ko": "Report Status KO",
                    "report_status_to_ok": "Report Status OK"
                },
                "tableContact": "Related Contact",
                "tableMessage": "Message",
                "tableType": "Type",
                "title": "Activation logs of",
                "type": {
                    "LanDeviceFeedback": "Feedback",
                    "LanDeviceTest": "Tests",
                    "Location": "Station",
                    "Supervision": "Supervision"
                }
            },
            "show": {
                "address": "Address",
                "allPaid": "All paid",
                "archiveReport": "Archive this report",
                "archived": "Archived",
                "buttonValidateWithCommissioning": "Validate selection with commissioning",
                "buttonValidateWithoutCommissioning": "Validate selection without commissioning",
                "cbidUntested": "Untested",
                "comment": "Report",
                "commentHistory": "Global comments history",
                "commissioned": "In service",
                "company": "Company",
                "dateGeneration": "Status generation date",
                "dateUpdate": "Update date",
                "email": "Email address",
                "firstname": "First name",
                "fitter": "Fitter",
                "fitterSay": "Installer says",
                "informationsStation": "Station Information",
                "installerHistory": "Installer history",
                "is_online": "Connection test",
                "listCbidTested": "List of CBID OK",
                "listUntestedAndFailedCbid": "List of CBID NOK",
                "modalArchivedConfirmation": "The report has been successfully archived.",
                "modalArchivedConfirmationFailed": "The report could not be archived.",
                "modalArchivedContent": "You are about to archive this report.",
                "modalArchivedQuestion": "Do you want to continue?",
                "modalArchivedTitle": "Archive a Report",
                "modalCommentConfirmation": "The comment has been successfully marked as resolved.",
                "modalCommentConfirmationFailed": "Comment resolution failed.",
                "modalCommentContent": "You are about to mark this comment as resolved. It will disappear as a result.",
                "modalCommentQuestion": "Do you want to continue?",
                "modalCommentTitle": "Mark as resolved",
                "modalFailedCommissioningContent": "It seems the connection to the following terminals has been lost since the last test.",
                "modalFailedCommissioningTitle": "Terminal Commissioning Failed",
                "name": "Name",
                "noCbidToDisplay": "No CBID to display here",
                "noComments": "No comments",
                "numberOfCbid": "Number of CBID",
                "ocpp-numbers-missmatch": "Warning, the connector IDs do not seem to match, please check the logs.",
                "oneIsFree": "At least one is free",
                "phone": "Phone",
                "pointOfCharge": "Charge Points",
                "pricing": "Pricing",
                "remote_orders_ok": "Remote Orders Test",
                "report": "Report",
                "selectAllCbid": "Select all CBID",
                "showOcppLogs": "Show logs",
                "status": "Status",
                "statusOf": "Status of",
                "terminalComments": "Comments",
                "testerMostRecent": "Information of the most recent installer",
                "testsStatus": "Test status",
                "timestamp": "Timestamp",
                "toValidated": "To be validated",
                "validated": "validated",
                "waitingCommissioning": "Waiting for commissioning"
            },
            "status": {
                "archived": "Archived",
                "incomplete": "Incomplete",
                "nok": "NOK",
                "ok": "Ready"
            }
        },
        "stats": {
            "CO2AvoidedEmissions": "CO2",
            "CarbonMonoxideAvoidedEmissions": "CO",
            "DistanceAvoidedThermalVehicle": "Vermeden afstand in conventionele voertuigen",
            "HydrocarbonAndNoxAvoidedEmissions": "Koolwaterstoffen en NOx",
            "IncomeByNetwork": "Verkoop per netwerk (incl. BTW)",
            "IncomeByProvider": "Verkoop per aanbieder (incl. BTW)",
            "IncomePerLocation": "Verkoop per locatie (incl. BTW)",
            "MTBF": "MTBF",
            "MTTR": "MTTR",
            "NoxAvoidedEmissions": "NOx",
            "ParticleAvoidedEmissions": "Deeltjes",
            "SavedFuel": "Vermeden brandstofverbruik",
            "SessionsByConnectorType": "Sessies per connector",
            "SessionsByConsumption": "Sessies naar verbruik",
            "SessionsByDay": "Sessies per dag",
            "SessionsByDuration": "Sessies op duur",
            "SessionsByHours": "Sessies per uur",
            "SessionsByMonth": "Sessies per maand",
            "SessionsByNetwork": "Sessies per netwerk",
            "SessionsByPower": "Sessies door de macht",
            "SessionsByProvider": "Sessies per aanbieder",
            "SessionsPerLocation": "Sessies per locatie",
            "availability": "Beschikbaarheid",
            "availabilityDuration": "Beschikbaarheidsduur",
            "average": "Gemiddelde",
            "avoidedEmissions": "Vermeden emissies",
            "descriptions": {
                "AberrationRate": "Aberratiegraad",
                "AverageChargingDuration": "Terwijl het voertuig energie onttrekt aan het station",
                "AverageConnectionDuration": "Terwijl het voertuig geparkeerd staat en verbonden is met het station, ook wanneer het is gestopt met laden",
                "AverageConsumptionBySession": "Gemiddeld verbruik per sessie",
                "CO2AvoidedEmissions": "CO2-uitstoot vermeden dankzij het gebruik van oplaadbare EV's op de stations",
                "CarbonMonoxideAvoidedEmissions": "CO -uitstoot vermeden dankzij het gebruik van oplaadbare EV's op de stations",
                "CleanSessionNumber": "Aantal gekwalificeerde sessies",
                "DistanceAvoidedThermalVehicle": "Schatting van de afstand die wordt afgelegd met het opladen van EV's op de stations",
                "EffectiveChargingOverConnectionRate": "Verhouding werkelijke laadtijd \/ totale verbindingstijd",
                "ExcludedSession": "Uitgesloten sessies",
                "HydrocarbonAndNoxAvoidedEmissions": "Koolwaterstoffen en NOx vermeden emissies",
                "MTBF": "Gemiddelde tijd tussen storingen",
                "MTTR": "Gemiddelde tijd om te repareren",
                "NoxAvoidedEmissions": "NOx-emissies vermeden dankzij het gebruik van oplaadbare EV's op de stations",
                "ParticleAvoidedEmissions": "Uitstoot van deeltjes wordt vermeden dankzij het gebruik van EV's die op de stations worden opgeladen.",
                "SavedFuel": "De cijfers zijn gebaseerd op de huidige mix van conventionele voertuigen in de Europese Unie",
                "SessionNumber": "Aantal sessies",
                "SuccessConsumption": "Verbruik van gekwalificeerde sessies",
                "TotalChargingDuration": "Terwijl het voertuig energie onttrekt aan het station",
                "TotalConnectionDuration": "Terwijl het voertuig geparkeerd staat en verbonden is met het station, ook wanneer het is gestopt met laden",
                "TotalConsumption": "Verbruik van alle sessies",
                "availabilityDuration": "Beschikbaarheidsduur",
                "availability_device_count": "Lader",
                "availability_evse_count": "Laadpunten",
                "averageIncome": "Gemiddelde kosten van pay-for-sessies. Gratis sessies zijn uitgesloten",
                "average_charge_time": "Terwijl het voertuig energie onttrekt aan het station",
                "average_plugin_time": "Terwijl het voertuig geparkeerd staat en verbonden is met het station, ook wanneer het is gestopt met laden",
                "device_offline_duration": "Lader - offline tijd",
                "device_offline_duration_rate": "Lader - offline graad",
                "evse_available_duration_rate": "Laadpunt - beschikbaarheidsgraad",
                "evse_failure_count": "Problemen",
                "evse_unavailable_duration": "Laadpunten - duur van de onbeschikbaarheid",
                "evse_unavailable_duration_rate": "Laadpunten - onbeschikbaarheidsgraad",
                "failed_session_count": "Aantal sessies die als \"mislukt\" worden beschouwd (< 2 min of < 500 Wh)",
                "failed_session_rate": "Verhouding \"mislukte\" sessies \/ totaal aantal sessies",
                "numberOfIssues": "Aantal problemen",
                "offlineDuration": "Offline duur",
                "sessionIssueRatio": "Verhouding sessies met probleem",
                "successful_session_consumption": "Totaal verbruik van gekwalificeerde sessies",
                "successful_session_count": "Gekwalificeerde sessies zijn oplaadsessies van meer dan 2 minuten",
                "successful_session_rate": "Verhouding gekwalificeerde sessies \/ totaal aantal sessies",
                "totalIncome": "Totale verkoop",
                "total_charge_time": "Terwijl het voertuig energie onttrekt aan het station",
                "total_plugin_time": "Terwijl het voertuig geparkeerd staat en verbonden is met het station, ook wanneer het is gestopt met laden",
                "total_session_consumption": "Totaal verbruik",
                "total_session_count": "De statistieken zijn alleen gebaseerd op het aantal gekwalificeerde sessies",
                "unavailabilityDuration": "Onbeschikbaarheidsduur"
            },
            "g": "g",
            "ie": "d.w.z.",
            "kg": "kg",
            "km": "km",
            "l": "liter",
            "labels": {
                "LessThan10": "< 10",
                "LessThan120": "< 120",
                "LessThan1440": "< 1440",
                "LessThan15": "< 15",
                "LessThan20": "< 20",
                "LessThan240": "< 240",
                "LessThan30": "< 30",
                "LessThan40": "< 40",
                "LessThan480": "< 480",
                "LessThan5": "< 5",
                "LessThan60": "< 60",
                "LessThan720": "< 720",
                "MoreThan1440": "> 1440",
                "MoreThan60": "> 60",
                "SessionsByConsumption": {
                    "_0_5_kwh": "0 ≤ kWh < 5",
                    "_10_20_kwh": "10 ≤ kWh < 20",
                    "_20_40_kwh": "20 ≤ kWh < 40",
                    "_40_60_kwh": "40 ≤ kWh < 60",
                    "_5_10_kwh": "5 ≤ kWh < 10",
                    "_60_kwh": "≥ 60 kWh"
                },
                "SessionsByDuration": {
                    "_0_15_min": "0 ≤ min < 15",
                    "_120_240_min": "2 ≤ h < 4",
                    "_1440_min": "≥ 24 h",
                    "_15_30_min": "15 ≤ min < 30",
                    "_240_480_min": "4 ≤ h < 8",
                    "_30_60_min": "30 ≤ min < 60",
                    "_480_720_min": "8 ≤ h < 12",
                    "_60_120_min": "1 ≤ h < 2",
                    "_720_1440_min": "12 ≤ h < 24"
                },
                "SessionsByPower": {
                    "_0_4_kw": "0 ≤ kW < 4",
                    "_100_150_kw": "100 ≤ kW < 150",
                    "_12_22_kw": "12 ≤ kW < 22",
                    "_150_kw": "≥ 150 kW",
                    "_22_50_kw": "22 ≤ kW < 50",
                    "_4_8_kw": "4 ≤ kW < 8",
                    "_50_100_kw": "50 ≤ kW < 100",
                    "_8_12_kw": "8 ≤ kW < 12"
                }
            },
            "liters": "liter",
            "mi": "mijlen",
            "miles": "mijlen",
            "noProvider": "Geen aanbieder (P&C, betaalterminal)",
            "noStats": "Geen statistieken beschikbaar",
            "noStatsMessage": "Geen gegevens voor deze periode",
            "numberOfIssues": "Aantal problemen",
            "numberOfSessions": "{number} sessies",
            "offlineDuration": "Offline duur",
            "pdf": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "HydrocarbonAndNoxAvoidedEmissions": "Koolwaterstoffen en NOx",
                "IncomeByNetwork": "Verkoop per netwerk (incl. BTW)",
                "IncomeByProvider": "Verkoop per aanbieder (incl. BTW)",
                "IncomePerLocation": "Verkoop per locatie (incl. BTW)",
                "LessThan10kWh": "≤ 10 kWh",
                "LessThan12": "≤ 12 kW",
                "LessThan12kW": "≤ 12 kW",
                "LessThan20kWh": "≤ 20 kWh",
                "LessThan22": "≤ 22 kW",
                "LessThan22kW": "≤ 22 kW",
                "LessThan4": "≤ 4 kW",
                "LessThan40kWh": "≤ 40 kWh",
                "LessThan4kW": "≤ 4 kW",
                "LessThan5kWh": "≤ 5 kWh",
                "LessThan60kWh": "≤ 60 kWh",
                "LessThan8": "≤ 8 kW",
                "LessThan8kW": "≤ 8 kW",
                "MWh": "MWh",
                "MoreThan22": "Alle sessies",
                "MoreThan22kW": "Alle sessies",
                "MoreThan60kWh": "Alle sessies",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Deeltjes",
                "SessionsByConnectorType": "Uitsplitsing per connector",
                "SessionsByConsumption": "Sessies naar verbruik",
                "SessionsByConsumptionTitle": "Sessies naar verbruik",
                "SessionsByDay": "Sessies per dag",
                "SessionsByDayTitle": "Sessies per dag",
                "SessionsByDuration": "Sessies op duur",
                "SessionsByDurationTitle": "Sessies op duur",
                "SessionsByHours": "Sessies per uur",
                "SessionsByMonth": "Sessies per maand",
                "SessionsByMonthTitle": "Sessies per maand",
                "SessionsByNetwork": "Sessies per netwerk",
                "SessionsByPower": "Sessies door de macht",
                "SessionsByPowerTitle": "Sessies door de macht",
                "SessionsByProvider": "Sessies per aanbieder",
                "SessionsHistogramme": "Bezettingsgrafiek",
                "SessionsPerLocation": "Sessies per locatie",
                "aggregatedDuration": "Geaggregeerde duur",
                "amountCurrency": "Bedrag in {currency}",
                "availability": "Beschikbaarheid",
                "average": "Gemiddelde",
                "averageBasket": "Gemiddelde betaalde prijs",
                "averageChargeTime": "Gemiddelde oplaadtijd",
                "averageCostBySession": "Gemiddelde kosten per sessie",
                "averageDuration": "Gemiddelde laadduur",
                "averagePluginTime": "Gemiddelde duur van de verbinding",
                "chargeTime": "Sessieduur",
                "connector": "Connector",
                "consumption": "Verbruik",
                "data": "Gegevens",
                "distanceAvoidedThermalVehicle": "Vermeden afstand in conventionele voertuigen",
                "duration": "Duur",
                "environmentImpact": "Milieu-impact",
                "environmentInfos": "Milieugegevens",
                "fileName": "cpo-stats-",
                "group": "Aanbieder",
                "hhmmss": "h,m,s",
                "income": "Verkoop (incl. BTW)",
                "kWh": "kWh",
                "kg": "kg",
                "km": "km",
                "lessThan15Min": "≤ 15 min",
                "lessThan1Hour": "≤ 1 uur",
                "lessThan2Hour": "≤ 2 uur",
                "lessThan30Min": "≤ 30 min",
                "lessThan4Hour": "≤ 4 uur",
                "liters": "liter",
                "location": "Locatie",
                "mainIndicators": "Belangrijkste indicatoren",
                "miles": "mijlen",
                "month": "Maand",
                "moreThan4Hour": ">  4 uur",
                "network": "Netwerk",
                "nodata": "Niet genoeg gegevens",
                "number": "Aantal",
                "numberOfSessions": "Aantal sessies",
                "occupationRate": "Bezettingsgraad",
                "operator": "Aanbieder",
                "others": "Andere",
                "percent": "Percentage",
                "period": "Periode",
                "pluginTime": "Oplaadduur",
                "savedFuel": "Bespaarde brandstof",
                "sessionSuccessRate": "Gekwalificeerd sessietarief",
                "successfulSessions": "Gekwalificeerde sessies",
                "title": "Statistieken",
                "total": "Totaal",
                "weekDay": "Weekdag"
            },
            "percentageOfDuration": "{percentage}% van de totale duur",
            "q1": "Eerste kwartaal",
            "q2": "Tweede kwartaal",
            "q3": "Derde kwartaal",
            "q4": "Vierde kwartaal",
            "rempConvVehicles": "Milieugegevens",
            "selectNetworkPlaceholder": "Selecteer netwerk",
            "sessionIssueRatio": "Sessie-uitgifteverhouding",
            "sessionSimpleStats": {
                "CO2AvoidedEmissions": "CO2",
                "CarbonMonoxideAvoidedEmissions": "CO",
                "DistanceAvoidedThermalVehicle": "Vermeden afstand in conventionele voertuigen",
                "EffectiveChargingOverConnectionRate": "Verhouding werkelijke laadtijd \/ totale verbindingstijd",
                "HydrocarbonAndNoxAvoidedEmissions": "Koolwaterstoffen en NOx",
                "NoxAvoidedEmissions": "NOx",
                "ParticleAvoidedEmissions": "Deeltjes",
                "SavedFuel": "Bespaarde brandstof",
                "SessionNumber": "Aantal sessies",
                "average_charge_time": "Gemiddelde laadduur",
                "average_conso_by_session": "Gemiddeld verbruik per sessie",
                "average_plugin_time": "Gemiddelde duur van de verbinding",
                "chargingDuration": "Oplaadduur",
                "consumption": "Verbruik",
                "failed_session_count": "Niet-gekwalificeerde sessies",
                "failed_session_rate": "Aberratiegraad",
                "income": "Verkoop (incl. BTW)",
                "sessionDuration": "Sessieduur",
                "successful_session_consumption": "Verbruik van gekwalificeerde sessies",
                "successful_session_count": "Aantal gekwalificeerde sessies",
                "successful_session_rate": "Gekwalificeerde sessies tarief",
                "total_charge_time": "Totale laadduur",
                "total_plugin_time": "Totale duur bij aansluiting",
                "total_session_consumption": "Totaal verbruik",
                "total_session_count": "Totaal aantal sessies"
            },
            "successOnly": "Alleen gekwalificeerde sessies",
            "title": "Statistieken",
            "top5Remains": "Andere",
            "total": "Totaal",
            "totalIncome": "Totaal verkoop: {income}{currency}",
            "totalIncomeLabel": "Totaal verkoop",
            "unavailabilityDuration": "Onbeschikbaarheidsduur"
        },
        "stickers": {
            "bigStickers": "Inclusief 10x10 stickers",
            "download": "Download",
            "evses": "Laadpunten",
            "exemple": "Voorbeeld van een compatibel formaat:",
            "guide": "Om alle QR codes in een pdf te genereren, kopieer en plak dan je lijst in het tekstvak en klik op download. Je kunt ook een voorbeeld van de PDF bekijken door te klikken op de knop",
            "noEvsesAvailable": "Geen LP beschikbaar met deze Operator",
            "selectOperator": "Selecteer een operator om LP te importeren",
            "showPreview": "Toon PDF-voorvertoning",
            "title": "QR-code generator voor LP's"
        },
        "supervision": {
            "default_error": "Fout",
            "downloadRecentBrowser": "Gelieve je browser te updaten",
            "following_errors": "Fouten:",
            "not_allowed": "Toegang geweigerd"
        },
        "table": {
            "columns": "Kolommen",
            "count": "Een record|Het tonen van {from} tot {to} van {count} records|{count} records",
            "defaultOption": "Kies {column}",
            "filter": "Filter:",
            "filterBy": "Filter op {column}",
            "filterPlaceholder": "Op zoeken",
            "first": "Eerste",
            "goToFirstPage": "Begin",
            "goToLastPage": "Einde",
            "last": "Laatste",
            "limit": "Resultaten:",
            "loadPaging": "Bekijk het aantal resultaten",
            "loading": "Bezig met laden...",
            "noFilter": "Geen filter toegepast",
            "noResults": "Geen resultaten",
            "page": "Pagina:",
            "undefined": "Ongedefinieerde"
        },
        "tariffs": {
            "create": {
                "breadcrumb": "Tarief toevoegen",
                "cpoTitle": "Nieuw tarief met {name}",
                "feedback": "Tarief succesvol toegevoegd",
                "tariffList": "Tarieven",
                "title": "Een nieuw tarief toevoegen"
            },
            "edit": {
                "breadcrumb": "Bewerk",
                "feedback": "Tarief succesvol bijgewerkt",
                "tariffList": "Tarieven",
                "title": "Tarief bewerken"
            },
            "form": {
                "cgv": "Voorwaarden en condities",
                "cpo": "Netwerk",
                "currency": "Valuta",
                "currencyPlaceholder": "Valuta",
                "description": "Beschrijving",
                "errorNameAlreadyExists": "A tariff with the same name already exists",
                "is_private": "Private",
                "maxPrice": "Maximale prijs",
                "name": "Naam",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provisie incl. BTW",
                "refPlaceholder": "Kan worden gevuld met de naam",
                "refUnavailableMessage": "Deze referentie is niet beschikbaar. Probeer een andere",
                "submit": "Bespaar",
                "updatedAt": "Laatste update"
            },
            "list": {
                "addTariff": "Tarief",
                "breadcrumb": "Tarieven",
                "cpoTitle": "Tarieven van {name}",
                "currency": "Valuta",
                "elements": "Prijsgroepen",
                "globalRestrictions": "Applicatiedrempel",
                "is_private": "Private",
                "name": "Naam",
                "network": "Netwerk",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "provision": "Provisie incl. BTW",
                "title": "Tarieven",
                "updated_at": "Laatste update",
                "vat": "BTW of omzetbelasting"
            },
            "maxPrice": {
                "add": "Voeg  toe",
                "confirmDelete": "Bevestig gedeelde max. prijsverwijdering?",
                "maxPrice": "Maximale prijs",
                "maxPriceDeleted": "Gedeelde maximumprijs geschrapt",
                "maxPriceEdited": "Gedeelde maximumprijs bijgewerkt",
                "noMaxPrices": "Er is geen maximumprijs vastgesteld",
                "searchAddGroup": "Zoek of voeg groep toe",
                "title": "Gedeelde maximumprijs",
                "tooltipPriceDelete": "Gedeelde prijs verwijderen"
            },
            "operators": {
                "CONSUMPTION": {
                    "<": "Verbruik minder dan",
                    "<=": "Verbruik minder of gelijk aan",
                    ">": "Verbruik groter dan",
                    ">=": "Verbruik groter of gelijk aan"
                },
                "DURATION": {
                    "<": "Duur minder dan",
                    "<=": "Duur minder of gelijk aan",
                    ">": "Duur groter dan",
                    ">=": "Duur groter of gelijk aan"
                },
                "and": "EN",
                "or": "OF"
            },
            "prices": {
                "DURATION_description": "De facturering is gebaseerd op de aansluittijd, ongeacht het verbruik van het voertuig",
                "ENERGY_description": "Facturatie is afhankelijk van het verbruik",
                "FLAT_description": "De facturering omvat een vast bedrag",
                "PARKING_TIME_description": "De facturering is afhankelijk van de tijd zonder dat er energie wordt verbruikt",
                "STOP_description": "Het laden stopt boven een bepaalde drempel",
                "TIME_description": "De facturering hangt af van de tijd die nodig is om energie op te nemen",
                "addFormTitle": "Nieuwe prijs",
                "addTooltip": "Een nieuwe prijs toevoegen",
                "confirmDeletion": "Bevestig de prijsschrapping?",
                "created": "Prijs toegevoegd",
                "deleteTooltip": "Prijs verwijderen",
                "deleted": "Prijs verwijderd",
                "editFormTitle": "Prijs bewerken",
                "editTooltip": "Prijs bewerken",
                "edited": "Prijs gewijzigd",
                "minutes": "minuten",
                "stepSize": "Stap",
                "type": "Type"
            },
            "restrictions": {
                "allDays": "Elke dag",
                "consumption": "Energie (kWh)",
                "created": "Prijsgroep succesvol toegevoegd",
                "date": "Datum",
                "days": "Dagen",
                "deleteGroupTooltip": "Verwijder groep",
                "duration": "Duur (minuten)",
                "editTooltip": "Bewerk beperkingen",
                "edited": "Beperking succesvol bewerkt",
                "formTitle": "Beperkingen",
                "info": "De prijzen die in deze groep worden toegevoegd zullen aan beperkingen voldoen",
                "invalidConsumption": "Ongeldige energiewaarde",
                "invalidDays": "Selecteer ten minste één dag",
                "invalidDuration": "Ongeldige duurwaarde",
                "invalidPower": "Ongeldige vermogenswaarde",
                "max": "Max",
                "min": "Min",
                "power": "Vermogen (kW)",
                "price": "Prijs",
                "removeDate": "Verwijder datum",
                "time_after_consumption_end": "Time after the consumption has ended (minutes)",
                "updated": "Drempelwaarde bijgewerkt"
            },
            "show": {
                "add": "Add",
                "addCondition": "Voeg een drempel toe",
                "addPriceFormTitle": "Prijs toevoegen",
                "breadcrumb": "Tarief",
                "cgvUrl": "URL-voorwaarden",
                "conditions": "Applicatiedrempel",
                "confirmGroupDeletion": "Bevestig de groepsschrapping?",
                "confirmNotificationDeletion": "Kennisgevingen verwijderen bevestigen?",
                "confirmTariffDeletion": "Tariefwijziging bevestigen ?",
                "consumption": "Energie (Wh)",
                "costPerPowerUpdated": "Cost per average power updated",
                "cpo": "Netwerk",
                "createGroup": "Een nieuwe groep toevoegen",
                "currency": "Valuta",
                "date": "Datum",
                "days": "Dagen",
                "description": "Beschrijving",
                "duration": "Duur (minuten)",
                "edit": "Bewerk",
                "editPriceFormTitle": "Prijs bewerken",
                "elements": "Prijzen",
                "fixedPrice": "Fixed cost",
                "from": "Van",
                "groupDeleted": "Groep geschrapt",
                "hours": "Plan",
                "invalidConsumption": "Ongeldige energiewaarde",
                "invalidDays": "Selecteer ten minste één dag",
                "invalidDuration": "Ongeldige duurwaarde",
                "invalidPower": "Ongeldige vermogenswaarde",
                "is_private": "Private",
                "lastUpdate": "Laatste update:",
                "max": "Max",
                "maxPrice": "Maximale prijs",
                "min": "Min",
                "minutes": "minuten",
                "mustAddRestriction": "De prijzen die in deze groep worden toegevoegd, zijn onderworpen aan beperkingen.",
                "name": "Naam",
                "noCgv": "Geen voorwaarden",
                "noConditions": "Geen drempelwaarde",
                "noDescription": "Geen beschrijving",
                "noPrices": "Geen prijzen",
                "notificationAdded": "Kennisgeving van tariefontwikkeling toegevoegd",
                "notificationCantBeAdd": "Geen kennisgeving van toepassing. Er is een beperking met betrekking tot stroom en\/of energie voor het tarief",
                "notificationDeleted": "Kennisgeving van tariefontwikkeling geschrapt",
                "notificationEmpty": "Geen geconfigureerde melding",
                "notificationTitle": "Kennisgeving van tariefontwikkeling",
                "payment_authorization_amount": "Payment authorization incl. VAT",
                "popup": {
                    "atTimeEvolve": "op het moment",
                    "beforeAndAtTimeEvolve": "voor en op tijd",
                    "beforeEvolve": "voor",
                    "chooseMomentTitle": "Kies wanneer gebruikers een melding ontvangen",
                    "evolveTariffText": "de tariefontwikkeling",
                    "minBefore": "minuten voor",
                    "minutes": "minuten",
                    "sendNotifText": "Een melding verzenden",
                    "sendNotifTitle": "Verzenden van de melding",
                    "title": "Een melding op het tarief configureren"
                },
                "power": "Vermogen (kW)",
                "powerMax": "Maximum power",
                "powerMin": "Minimum power",
                "powerTimeTitle": "Average power over time",
                "price": "Prijs",
                "priceGroup": "Groep {number}",
                "prices": "Prijzen",
                "provision": "Provisie incl. BTW",
                "restrictionEdited": "Beperking succesvol bewerkt",
                "restrictionFormTitle": "Beperkingen",
                "restrictionIntro": "Bepaal wanneer het tarief van toepassing is",
                "restrictions": "Beperkingen",
                "stepSize": "Stap",
                "tariffDeleted": "Tarief geschrapt",
                "to": "Naar",
                "type": "Type",
                "weekDays": "Weekdagen",
                "weekEnd": "Week-end",
                "wh": "Wh"
            },
            "status": {
                "active": "Activa",
                "inactive": "Inactief"
            }
        },
        "validation": {
            "accepted": "{attribute} moet geaccepteerd worden",
            "active_url": "{attribute} is geen geldige URL",
            "after": "{attribute} moet een datum zijn na {date}",
            "after_or_equal": "{attribute} moet een datum zijn na of gelijk aan {date}",
            "alpha": "{attribute} mag alleen letters bevatten",
            "alpha_dash": "{attribute} mag alleen letters, cijfers en streepjes bevatten",
            "alpha_num": "{attribute} mag alleen letters en cijfers bevatten",
            "array": "{attribute} moet een matrix zijn",
            "before": "{attribute} moet een datum zijn vóór {date}",
            "before_or_equal": "{attribute} moet een datum zijn voor of gelijk aan {date}",
            "between": {
                "array": "{attribute} moet tussen {min} en {max} liggen",
                "file": "{attribute} moet tussen {min} en {max} KB liggen",
                "numeric": "{attribute} moet tussen {min} en {max} liggen",
                "string": "{attribute} moet tussen {min} en {max} karakters liggen"
            },
            "boolean": "{attribute} moet waar of onwaar zijn",
            "confirmed": "{attribute} bevestiging komt niet overeen",
            "custom": {
                "attribute-name": {
                    "rule-name": "aangepast bericht"
                }
            },
            "date": "{attribute} is geen geldige datum",
            "date_format": "{attribute} komt niet overeen met het {datumformaat}",
            "different": "{attribute} en {andere} moeten verschillend zijn",
            "digits": "{attribute} moet op {cijferposities} staan",
            "digits_between": "{attribute} moet tussen {min} en {max} posities liggen",
            "dimensions": "{attribute} heeft ongeldige afbeeldingsafmetingen",
            "distinct": "{attribute} heeft een dubbele waarde",
            "email": "{attribute} moet een geldig e-mailadres zijn",
            "exists": "{attribute} is ongeldig",
            "file": "{attribute} moet een bestand zijn",
            "filled": "{attribute} moet een waarde bevatten",
            "gt": {
                "array": "{attribute} moet meer dan {value} items bevatten",
                "file": "{attribute} moet groter zijn dan {value} ko",
                "numeric": "{attribute} moet groter zijn dan {waarde}",
                "string": "{attribute} moet groter zijn dan {waarde} karakters"
            },
            "gte": {
                "array": "{attribute} moet groter zijn dan {waarde} objecten",
                "file": "{attribute} moet groter zijn dan of gelijk zijn aan {waarde} ko",
                "numeric": "{attribute} moet groter zijn dan of gelijk zijn aan {waarde}",
                "string": "{attribute} moet groter zijn dan of gelijk zijn aan {waarde} karakters"
            },
            "image": "{attribute} moet een afbeelding zijn",
            "in": "{attribute} is ongeldig",
            "in_array": "{attribute} bestaat niet in {other}",
            "integer": "{attribute} moet een geheel getal zijn",
            "ip": "{attribute} moet een geldig IP-adres zijn",
            "ipv4": "{attribute} moet een geldig IPv4-adres zijn",
            "ipv6": "{attribute} moet een geldig IPv6-adres zijn",
            "json": "{attribute} moet een geldige JSON-string zijn",
            "lt": {
                "array": "{attribute} moet minder dan {value} objecten bevatten",
                "file": "{attribute} moet kleiner zijn dan {value} KB",
                "numeric": "{attribute} moet kleiner zijn dan {value}",
                "string": "{attribute} moet kleiner zijn dan {value} tekens"
            },
            "lte": {
                "array": "{attribute} moet kleiner zijn dan {value}",
                "file": "{attribute} moet kleiner zijn dan of gelijk zijn aan {waarde} kB",
                "numeric": "kleiner dan of gelijk aan {value}",
                "string": "{attribute} moet kleiner zijn dan of gelijk aan {waarde} karakters"
            },
            "max": {
                "array": "{attribute}{attribuut} moet kleiner zijn dan {waarde} objecten",
                "file": "{attribute} moet kleiner zijn dan {waarde} kB",
                "numeric": "{attribute} moet kleiner zijn dan {max}",
                "string": "{attribute} moet kleiner zijn dan {max} tekens"
            },
            "mimes": "{attribute} moet een bestand zijn van het type {values}",
            "mimetypes": "{attribute} moet een bestand zijn van het type {values}",
            "min": {
                "array": "{attribute} moet minimaal {min} objecten bevatten",
                "file": "{attribute} moet minimaal {min} KB zijn",
                "numeric": "{attribute} moet minimaal {min}",
                "string": "{attribute} moet minimaal {min} tekens lang zijn"
            },
            "not_in": "{attribute} is ongeldig",
            "not_regex": "{attribute} heeft een ongeldig formaat",
            "numeric": "{attribute} moet een getal zijn",
            "present": "{attribute} moet een aanwezig veld hebben",
            "regex": "{attribute} moet een geldig formaat hebben",
            "required": "{attribute} is verplicht",
            "required_if": "{attribute} is vereist wanneer {other} {value} is",
            "required_unless": "{attribute} is verplicht tenzij {other} {value} is",
            "required_with": "{attribute} is vereist wanneer {values} aanwezig is",
            "required_with_all": "{attribute} is vereist wanneer {values} aanwezig is",
            "required_without": "{attribute} is vereist wanneer {values} niet aanwezig is",
            "required_without_all": "{attribute} is vereist als geen van de waarden aanwezig is",
            "same": "{attribute} en {other} moeten overeenkomen",
            "size": {
                "array": "{attribute} moet {size} objecten bevatten",
                "file": "{attribute} moet {size} ko zijn",
                "numeric": "{attribute} moet {grootte} zijn",
                "string": "{attribute} moet {size} karakters zijn"
            },
            "string": "{attribute} moet een tekenreeks zijn",
            "timezone": "{attribute} moet een geldige string zijn",
            "unique": "{attribute} is al bezet",
            "uploaded": "{attribute} kon niet worden gedownload",
            "url": "{attribute} heeft een ongeldig formaat"
        }
    }
}