<template>
    <div
        id="side-details"
        :class="{ 'column is-3 sidebar is-paddingless': toggleStore?.showMobileSideDetails }"
    >
        <transition :name="!toggleStore.showMobileSideDetails ? 'slide-prev' : 'slide-next'">
            <b-button
                class="side-toggle has-background-white-ter is-borderless"
                :class="{'is-closed': !toggleStore.showMobileSideDetails}"
                :icon-left="toggleStore.showMobileSideDetails ? 'caret-right' : 'caret-left'"
                @click="toggleMobileSideDetails()"
            />
        </transition>
        <b-sidebar
            v-model="toggleStore.showMobileSideDetails"
            class="side-details"
            :fullheight="true"
            :fullwidth="false"
            :overlay="false"
            :right="true"
            :can-cancel="false"
            @update:modelValue="toggleMobileSideDetails"
        >
            <slot />
        </b-sidebar>
    </div>
</template>

<script>
import { mapStores, } from 'pinia'

import helpers from '@/config/helpers.js'

import useToggleStore from '@/state/toggleStore.js'

export default {
    computed: {
        ...mapStores(useToggleStore),
    },
    created () {
        if (helpers.isMobile())
            this.toggleStore.showMobileSideDetails = false
        else
            this.toggleStore.toggleShowMobileSideDetails()
    },
    methods: {
        toggleMobileSideDetails () {
            this.toggleStore.toggleShowMobileSideDetails()
        },
    },
}
</script>

<style lang="scss">
    .side-toggle {
        position: fixed;
        left: auto;
        right: min(25%, 400px);
        top: 100px;
        border-radius: 6px 0 0 6px;
        z-index: 999;
        box-shadow: 0 0 1em rgb(10 10 10 / 10%) !important;
        clip-path: inset(-1em 0 -1em -1em);
        &.is-closed {
            right: 0;
        }
    }

    .side-details .sidebar-content {
        width: 25%;
        max-width: 400px;
    }

    //Get from buefy sidemenu transition to get same animation on side-toggle
    // Slide
    .slide-next-enter-active,
    .slide-next-leave-active,
    .slide-prev-enter-active,
    .slide-prev-leave-active {
        transition: transform 250ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
    }
    .slide-prev-leave-to, .slide-next-enter {
        transform: translate3d(-100%, 0, 0);
        position: absolute;
        width: 100%;
    }
    .slide-prev-enter, .slide-next-leave-to {
        transform: translate3d(100%, 0, 0);
        position: absolute;
        width: 100%;
    }
</style>
