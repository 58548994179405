import helpers from '@/config/helpers.js'
import translations from '@/config/translations.js'

const activeLocale = helpers.getActiveLocale()

// See https://buefy.github.io/#/documentation/constructor-options for all options
const buefyConfig = {
    defaultIconPack: 'fas', // fas = solid, far = regular (pro), fal = light (pro), fab = brands
    defaultTooltipAnimated: true, // animated by default
    defaultTooltipType: 'is-light',
    defaultDayNames: translations.dayNamesTranslated(),
    defaultMonthNames: translations.monthNamesTranslated(),
    defaultFirstDayOfWeek: activeLocale !== 'en'
        ? 1
        : 0, // 0 = Sunday, 1 = Monday
    defaultToastDuration: 6500, // Toast msg duration
    defaultNoticeQueue: true, // allow multiple Toast msg display
    defaultDialogConfirmText: translations.I18N.global.t('commons.confirm'),
    defaultDialogCancelText: translations.I18N.global.t('commons.cancel'),
}

export default buefyConfig
