import { defineStore, } from 'pinia'

const useToggleStore = defineStore('toggle', {
    state: () => ({
        sideDetailsIsExpanded: true,
        // Under "headerResponsive" (see breakpoints), sidebar are hidden by default
        showMobileSideMenu: true,
        showMobileSideDetails: false,
    }),
    actions: {
        toggleShowMobileSideMenu (force = null) {
            this.showMobileSideMenu = force || !this.showMobileSideMenu
        },
        toggleShowMobileSideDetails () {
            this.showMobileSideDetails = !this.showMobileSideDetails
        },
        hideAllMobil () {
            this.showMobileSideDetails = false
            this.showMobileSideMenu = false
        },
    },
})

export default useToggleStore
