import { defineStore, } from 'pinia'

const issueStore = defineStore('issue', {
    state: () => ({
        issues_count_by: {},
    }),
    actions: {
        setIssuesCountBy (payload) {
            this.SET_ISSUES_COUNT_BY = payload
        },
        deleteIssuesCountBy (key) {
            this.issues_count_by[key] = undefined
        },
    },
})

export default issueStore
