// Zest constants

export const OCPP_CONFIG = [
    'AllowOfflineTxForUnknownId', 'AuthorizationCacheEnabled', 'AuthorizeRemoteTxRequests',
    'BlinkRepeat', 'ChargeProfileMaxStackLevel', 'ChargingScheduleAllowedChargingRateUnit',
    'ChargingScheduleMaxPeriods', 'ClockAlignedDataInterval', 'ConnectionTimeOut', 'ConnectorPhaseRotation',
    'ConnectorPhaseRotationMaxLength', 'ConnectorSwitch3to1PhaseSupported', 'GetConfigurationMaxKeys',
    'HeartbeatInterval', 'LightIntensity', 'LocalAuthListEnabled', 'LocalAuthListMaxLength',
    'LocalAuthorizeOffline', 'LocalPreAuthorize', 'MaxChargingProfilesInstalled', 'MaxEnergyOnInvalidId',
    'MeterValuesAlignedData', 'MeterValuesAlignedDataMaxLength', 'MeterValuesSampledData',
    'MeterValuesSampledDataMaxLength', 'MeterValueSampleInterval', 'MinimumStatusDuration',
    'NumberOfConnectors', 'ReserveConnectorZeroSupported', 'ResetRetries', 'SendLocalListMaxLength',
    'StopTransactionOnEVSideDisconnect', 'StopTransactionOnInvalidId', 'StopTxnAlignedData',
    'StopTxnAlignedDataMaxLength', 'StopTxnSampledData', 'StopTxnSampledDataMaxLength',
    'SupportedFeatureProfiles', 'SupportedFeatureProfilesMaxLength', 'TransactionMessageAttempts',
    'TransactionMessageRetryInterval', 'UnlockConnectorOnEVSideDisconnect', 'WebSocketPingInterval'
]

export const UNDEFINED_EMSP_ID = 0 // (stat) to target provider with sessions without emsp

export const UNDEFINED_EMSP_NAME = 'No provider (P&C, TPE)' // (stat) to name provider with sessions without emsp (no need to translate it)

export const FIX = {
    BOARD_COLUMN_LIMIT: 10,
    TYPE_PREVENTIVE: 'PREVENTIVE',
    TYPE_CORRECTIVE: 'CORRECTIVE',
    TYPE_INFO: 'INFORMATION',
    TYPE_SOON_LATE: 'SOON_LATE',
}
export const INTEROP_EMI3_IDS = {
    gireveCpo: 'XCGIR',
    gireveEmsp: 'XEGIR',
    hubjectCpo: 'XCHUB',
    hubjectEmps: 'XEHUB', // and XEHB2, actually our current FRFR1 instance with hubject. Note jean: replace it later if XEHUB is deprecated (and adapt defineInteropPlatform() logic)
    roamingCpo: 'XCROA',
    roamingEmsp: 'XEROA',
}

export const ROAMING_PLATFORMS = [
    // refs of operator suitable to be set in the 'agreement.via_operator_ref' field
    'XEGIR',
    'XEHUB'
]

export const OCPI_MODULES_TOGGLABLE = ['locations', 'sessions', 'cdrs', 'tokens'] // in interop resources

export const OCPI_DATA = {
    capabilities: {
        CHARGING_PROFILE_CAPABLE: 'CHARGING_PROFILE_CAPABLE',
        CREDIT_CARD_PAYABLE: 'CREDIT_CARD_PAYABLE',
        REMOTE_START_STOP_CAPABLE: 'REMOTE_START_STOP_CAPABLE',
        RESERVABLE: 'RESERVABLE',
        RFID_READER: 'RFID_READER',
        UNLOCK_CAPABLE: 'UNLOCK_CAPABLE',
        RESET_CAPABLE: 'RESET_CAPABLE',
    },
    connectorPowerTypes: {
        AC_1_PHASE: 'AC_1_PHASE',
        AC_3_PHASE: 'AC_3_PHASE',
        DC: 'DC',
    },
    // 2.1.1 compliant
    facilities: [
        'AIRPORT',
        'BUS_STOP',
        'CAFE',
        'CARPOOL_PARKING',
        'FUEL_STATION',
        'HOTEL',
        'MALL',
        'MUSEUM',
        'NATURE',
        'RECREATION_AREA',
        'RESTAURANT',
        'SPORT',
        'SUPERMARKET',
        'TAXI_STAND',
        'TRAIN_STATION',
        'WIFI'
    ],
    standards: {
        CHADEMO: 'CHADEMO',
        DOMESTIC_A: 'DOMESTIC_A',
        DOMESTIC_B: 'DOMESTIC_B',
        DOMESTIC_C: 'DOMESTIC_C',
        DOMESTIC_D: 'DOMESTIC_D',
        DOMESTIC_E: 'DOMESTIC_E',
        DOMESTIC_F: 'DOMESTIC_F',
        DOMESTIC_G: 'DOMESTIC_G',
        DOMESTIC_H: 'DOMESTIC_H',
        DOMESTIC_I: 'DOMESTIC_I',
        DOMESTIC_J: 'DOMESTIC_J',
        DOMESTIC_K: 'DOMESTIC_K',
        DOMESTIC_L: 'DOMESTIC_L',
        IEC_60309_2_single_16: 'IEC_60309_2_single_16',
        IEC_60309_2_three_16: 'IEC_60309_2_three_16',
        IEC_60309_2_three_32: 'IEC_60309_2_three_32',
        IEC_60309_2_three_64: 'IEC_60309_2_three_64',
        IEC_62196_T1: 'IEC_62196_T1',
        IEC_62196_T1_COMBO: 'IEC_62196_T1_COMBO',
        IEC_62196_T2: 'IEC_62196_T2',
        IEC_62196_T2_COMBO: 'IEC_62196_T2_COMBO',
        IEC_62196_T3A: 'IEC_62196_T3A',
        IEC_62196_T3C: 'IEC_62196_T3C',
        TESLA_R: 'TESLA_R',
        TESLA_S: 'TESLA_S',
    },
}

// (Pulp #683 user ev name) beNomad connector to standards
export const BENOMAD_EV_CONNECTOR = {
    30: 'UNKNOWN',
    31: 'IEC_62196_T1',
    32: 'IEC_62196_T2',
    34: 'IEC_62196_T3A',
    35: 'IEC_62196_T3C',
    36: 'CHADEMO',
    37: 'IEC_62196_T1_COMBO',
    38: 'IEC_62196_T2_COMBO',
    39: 'TESLA_S',
    41: 'IEC_60309_2_single_16',
    42: ['IEC_60309_2_three_16', 'IEC_60309_2_three_32', 'IEC_60309_2_three_64'],
    43: 'DOMESTIC_A',
    44: 'DOMESTIC_B',
    45: 'DOMESTIC_C',
    46: 'DOMESTIC_D',
    47: 'DOMESTIC_E',
    48: 'DOMESTIC_F',
    49: 'DOMESTIC_G',
    50: 'DOMESTIC_H',
    51: 'DOMESTIC_I',
    52: 'DOMESTIC_J',
    53: 'DOMESTIC_K',
    54: 'DOMESTIC_L',
    70: 'TESLA_R',
}

export const paymentStatus = {
    prepaid: 'PREPAID_ACCOUNT',
    monthly: 'MONTHLY_INVOICE',
}

export const paymentType = {
    card: 'STRIPE_CARD',
    paypal: 'PAYPAL_ACCOUNT',
    iban: 'STRIPE_IBAN',
    cardFleet: 'card',
    sepaDebitFleet: 'sepa_debit',
}

export const TARIFF_GLOBAL_RESTRICTIONS_MAX_ELEMENTS = 2

export const TARIFF_ENDUSER_LIMITS_MAX_ELEMENTS = 4

// used in AddPassModal in Pulp to extract the visual_number_pattern string
export const NUMBER_PATTERN_PARAM = '{number}'

export const DEFAULT_CURRENCY = 'EUR'

export const MIN_DATA_DATE = new Date('2014', 0, 1) // = 01/01/14 Our soft data min date (for example, min date in datepicker export)

// Use in our algo (see `/components/UI/UserAvatar`)
export const BOT_COLOR = 'rgb(0,163,224)'
export const AVATAR_COLORS = [
    'rgb(0,189,112)', // $GREEN_COLOR
    'rgb(235,0,41)', // $RED_COLOR
    'rgb(255,70,18)', // $FIX_ORANGE_COLOR
    'rgb(130,64,195)', // $PURPLE_COLOR
    'rgb(255,0,102)', // $FUSHIA_COLOR
    'rgb(255,191,59)', // $YELLOW_COLOR
    'rgb(0,124,194)', // $REGULAR_BLUE_COLOR
    'rgb(251,119,84)', // $MAINTENANCE_ORANGE_COLOR
    'rgb(242,227,7)', // $PIKA_YELLOW_COLOR
    'rgb(63,94,181)', // $MAUVE_COLOR
    'rgb(246,133,189)', // $TIMELINE_PINK
    'rgb(137,216,249)', // $TIMELINE_BLUE
    'rgb(154,56,32)' // $BRICK_RED_COLOR
]

// Key names in local storage
export const LS_KEYS = {
    enduserGroup: 'enduser_group_id', // used in Pulp
    geography: 'order_geography_filter',
    merchSubs: 'order_merch_subs_filter',
    promocodeQuery: 'order_promocode_filter',
}

export const MODEL_TYPES = {
    session: 'session',
    subscription: 'subscription',
    subscriptionRenewal: 'subscription_renewal',
    token: 'token',
    other: null,
}
