export default {
    add: 'plus',
    agreements: 'handshake-alt',
    billingCenters: 'cash-register',
    booking: 'calendar',
    calls: 'headset',
    cancel: 'undo',
    cdrs: 'stream',
    chart: 'poll',
    check: 'check',
    comments: 'comments',
    configurations: 'cog',
    contracts: 'file-contract',
    corrective: 'tools',
    delete: 'trash',
    details: 'home', // home-alt
    devices: 'gamepad', // tools
    edit: 'pen',
    evses: 'charging-station',
    feedbacks: 'comment-alt',
    fix: 'shield-check',
    history: 'history',
    infos: 'info-circle',
    interop: 'globe',
    invoices: 'file-invoice',
    lans: 'wifi',
    list: 'list',
    locations: 'map-marker-alt',
    map: 'map',
    networks: 'sitemap',
    orders: 'shopping-basket',
    preventive: 'debug',
    ratings: 'star',
    reservation: 'calendar-day',
    save: 'save',
    sessions: 'bolt',
    subscriptions: 'award',
    tariffs: 'usd-circle',
    tokens: 'key',
    transactions: 'wallet',
    users: 'user',
    retrocessions: 'icon-retro-group',
    car: 'car',
    // Logs
    logs: {
        ocpp: 'file-alt',
        ocpi: 'bezier-curve',
        business: 'chart-network',
        audit: 'history',
        status: {
            CREATE: 'plus',
            READ: 'eye',
            UPDATE: 'edit',
            UPDATE_TRANSLATION: 'language',
            DELETE: 'trash',
            ACTION: 'hand-pointer',
            RESTORE: 'angel',
        },
    },
    contact: {
        groups: 'users',
        link: 'link',
    },
    // OCPI capabilities (see pulp evses.capabilities ENUM)
    capabilities: {
        CHARGING_PROFILE_CAPABLE: 'user',
        CREDIT_CARD_PAYABLE: 'credit-card',
        REMOTE_START_STOP_CAPABLE: 'mobile-alt',
        RESERVABLE: 'calendar',
        RFID_READER: 'barcode-read', // pro only
        UNLOCK_CAPABLE: 'unlock',
        RESET_CAPABLE: 'undo',
    },
    // facilities icons
    HOTEL: 'bed',
    RESTAURANT: 'utensils',
    CAFE: 'coffee',
    MALL: 'shopping-bag',
    SUPERMARKET: 'shopping-cart',
    SPORT: 'running',
    RECREATION_AREA: 'umbrella-beach',
    NATURE: 'leaf',
    MUSEUM: 'university',
    BUS_STOP: 'bus-alt',
    TAXI_STAND: 'taxi',
    TRAIN_STATION: 'train',
    AIRPORT: 'plane-departure',
    CARPOOL_PARKING: 'parking',
    FUEL_STATION: 'gas-pump',
    WIFI: 'wifi',
}
