import invert from 'lodash/invert'
import keys from 'lodash/keys'
import { values as _values, } from 'lodash/values'

const countryCodes = {

    OfficialOperatorEmi3s: {
        cpo: 'FRFR1',
        emsp: 'FRFRM',
        zest: 'FRFR0',
        pulp: 'FRFRA',
    },

    virtualCountryCodes: ['XE', 'XC'],

    iso3166CountryCodes: {
        AF: 'AFG',
        AX: 'ALA',
        AL: 'ALB',
        DZ: 'DZA',
        AS: 'ASM',
        AD: 'AND',
        AO: 'AGO',
        AI: 'AIA',
        AQ: 'ATA',
        AG: 'ATG',
        AR: 'ARG',
        AM: 'ARM',
        AW: 'ABW',
        AU: 'AUS',
        AT: 'AUT',
        AZ: 'AZE',
        BS: 'BHS',
        BH: 'BHR',
        BD: 'BGD',
        BB: 'BRB',
        BY: 'BLR',
        BE: 'BEL',
        BZ: 'BLZ',
        BJ: 'BEN',
        BM: 'BMU',
        BT: 'BTN',
        BO: 'BOL',
        BQ: 'BES',
        BA: 'BIH',
        BW: 'BWA',
        BV: 'BVT',
        BR: 'BRA',
        IO: 'IOT',
        BN: 'BRN',
        BG: 'BGR',
        BF: 'BFA',
        BI: 'BDI',
        CV: 'CPV',
        KH: 'KHM',
        CM: 'CMR',
        CA: 'CAN',
        KY: 'CYM',
        CF: 'CAF',
        TD: 'TCD',
        CL: 'CHL',
        CN: 'CHN',
        CX: 'CXR',
        CC: 'CCK',
        CO: 'COL',
        KM: 'COM',
        CG: 'COG',
        CD: 'COD',
        CK: 'COK',
        CR: 'CRI',
        CI: 'CIV',
        HR: 'HRV',
        CU: 'CUB',
        CW: 'CUW',
        CY: 'CYP',
        CZ: 'CZE',
        DK: 'DNK',
        DJ: 'DJI',
        DM: 'DMA',
        DO: 'DOM',
        EC: 'ECU',
        EG: 'EGY',
        SV: 'SLV',
        GQ: 'GNQ',
        ER: 'ERI',
        EE: 'EST',
        SZ: 'SWZ',
        ET: 'ETH',
        FK: 'FLK',
        FO: 'FRO',
        FJ: 'FJI',
        FI: 'FIN',
        FR: 'FRA',
        GF: 'GUF',
        PF: 'PYF',
        TF: 'ATF',
        GA: 'GAB',
        GM: 'GMB',
        GE: 'GEO',
        DE: 'DEU',
        GH: 'GHA',
        GI: 'GIB',
        GR: 'GRC',
        GL: 'GRL',
        GD: 'GRD',
        GP: 'GLP',
        GU: 'GUM',
        GT: 'GTM',
        GG: 'GGY',
        GN: 'GIN',
        GW: 'GNB',
        GY: 'GUY',
        HT: 'HTI',
        HM: 'HMD',
        VA: 'VAT',
        HN: 'HND',
        HK: 'HKG',
        HU: 'HUN',
        IS: 'ISL',
        IN: 'IND',
        ID: 'IDN',
        IR: 'IRN',
        IQ: 'IRQ',
        IE: 'IRL',
        IM: 'IMN',
        IL: 'ISR',
        IT: 'ITA',
        JM: 'JAM',
        JP: 'JPN',
        JE: 'JEY',
        JO: 'JOR',
        KZ: 'KAZ',
        KE: 'KEN',
        KI: 'KIR',
        KP: 'PRK',
        KR: 'KOR',
        KW: 'KWT',
        KG: 'KGZ',
        LA: 'LAO',
        LV: 'LVA',
        LB: 'LBN',
        LS: 'LSO',
        LR: 'LBR',
        LY: 'LBY',
        LI: 'LIE',
        LT: 'LTU',
        LU: 'LUX',
        MO: 'MAC',
        MK: 'MKD',
        MG: 'MDG',
        MW: 'MWI',
        MY: 'MYS',
        MV: 'MDV',
        ML: 'MLI',
        MT: 'MLT',
        MH: 'MHL',
        MQ: 'MTQ',
        MR: 'MRT',
        MU: 'MUS',
        YT: 'MYT',
        MX: 'MEX',
        FM: 'FSM',
        MD: 'MDA',
        MC: 'MCO',
        MN: 'MNG',
        ME: 'MNE',
        MS: 'MSR',
        MA: 'MAR',
        MZ: 'MOZ',
        MM: 'MMR',
        NA: 'NAM',
        NR: 'NRU',
        NP: 'NPL',
        NL: 'NLD',
        NC: 'NCL',
        NZ: 'NZL',
        NI: 'NIC',
        NE: 'NER',
        NG: 'NGA',
        NU: 'NIU',
        NF: 'NFK',
        MP: 'MNP',
        NO: 'NOR',
        OM: 'OMN',
        PK: 'PAK',
        PW: 'PLW',
        PS: 'PSE',
        PA: 'PAN',
        PG: 'PNG',
        PY: 'PRY',
        PE: 'PER',
        PH: 'PHL',
        PN: 'PCN',
        PL: 'POL',
        PT: 'PRT',
        PR: 'PRI',
        QA: 'QAT',
        RE: 'REU',
        RO: 'ROU',
        RU: 'RUS',
        RW: 'RWA',
        BL: 'BLM',
        SH: 'SHN',
        KN: 'KNA',
        LC: 'LCA',
        MF: 'MAF',
        PM: 'SPM',
        VC: 'VCT',
        WS: 'WSM',
        SM: 'SMR',
        ST: 'STP',
        SA: 'SAU',
        SN: 'SEN',
        RS: 'SRB',
        SC: 'SYC',
        SL: 'SLE',
        SG: 'SGP',
        SX: 'SXM',
        SK: 'SVK',
        SI: 'SVN',
        SB: 'SLB',
        SO: 'SOM',
        ZA: 'ZAF',
        GS: 'SGS',
        SS: 'SSD',
        ES: 'ESP',
        LK: 'LKA',
        SD: 'SDN',
        SR: 'SUR',
        SJ: 'SJM',
        SE: 'SWE',
        CH: 'CHE',
        SY: 'SYR',
        TW: 'TWN',
        TJ: 'TJK',
        TZ: 'TZA',
        TH: 'THA',
        TL: 'TLS',
        TG: 'TGO',
        TK: 'TKL',
        TO: 'TON',
        TT: 'TTO',
        TN: 'TUN',
        TR: 'TUR',
        TM: 'TKM',
        TC: 'TCA',
        TV: 'TUV',
        UG: 'UGA',
        UA: 'UKR',
        AE: 'ARE',
        GB: 'GBR',
        US: 'USA',
        UM: 'UMI',
        UY: 'URY',
        UZ: 'UZB',
        VU: 'VUT',
        VE: 'VEN',
        VN: 'VNM',
        VG: 'VGB',
        VI: 'VIR',
        WF: 'WLF',
        EH: 'ESH',
        YE: 'YEM',
        ZM: 'ZMB',
        ZW: 'ZWE',
    },

    iso4217CurrencyCodes: [
        'AED',
        'AFN',
        'ALL',
        'AMD',
        'ANG',
        'AOA',
        'ARS',
        'AUD',
        'AWG',
        'AZN',
        'BAM',
        'BBD',
        'BDT',
        'BGN',
        'BHD',
        'BIF',
        'BMD',
        'BND',
        'BOB',
        'BOV',
        'BRL',
        'BSD',
        'BTN',
        'BWP',
        'BYN',
        'BZD',
        'CAD',
        'CDF',
        'CHE',
        'CHF',
        'CHW',
        'CLF',
        'CLP',
        'CNY',
        'COP',
        'COU',
        'CRC',
        'CUC',
        'CUP',
        'CVE',
        'CZK',
        'DJF',
        'DKK',
        'DOP',
        'DZD',
        'EGP',
        'ERN',
        'ETB',
        'EUR',
        'FJD',
        'FKP',
        'GBP',
        'GEL',
        'GHS',
        'GIP',
        'GMD',
        'GNF',
        'GTQ',
        'GYD',
        'HKD',
        'HNL',
        'HRK',
        'HTG',
        'HUF',
        'IDR',
        'ILS',
        'INR',
        'IQD',
        'IRR',
        'ISK',
        'JMD',
        'JOD',
        'JPY',
        'KES',
        'KGS',
        'KHR',
        'KMF',
        'KPW',
        'KRW',
        'KWD',
        'KYD',
        'KZT',
        'LAK',
        'LBP',
        'LKR',
        'LRD',
        'LSL',
        'LYD',
        'MAD',
        'MDL',
        'MGA',
        'MKD',
        'MMK',
        'MNT',
        'MOP',
        'MRU',
        'MUR',
        'MVR',
        'MWK',
        'MXN',
        'MXV',
        'MYR',
        'MZN',
        'NAD',
        'NGN',
        'NIO',
        'NOK',
        'NPR',
        'NZD',
        'OMR',
        'PAB',
        'PEN',
        'PGK',
        'PHP',
        'PKR',
        'PLN',
        'PYG',
        'QAR',
        'RON',
        'RSD',
        'RUB',
        'RWF',
        'SAR',
        'SBD',
        'SCR',
        'SDG',
        'SEK',
        'SGD',
        'SHP',
        'SLL',
        'SOS',
        'SRD',
        'SSP',
        'STN',
        'SVC',
        'SYP',
        'SZL',
        'THB',
        'TJS',
        'TMT',
        'TND',
        'TOP',
        'TRY',
        'TTD',
        'TWD',
        'TZS',
        'UAH',
        'UGX',
        'USD',
        'USN',
        'UYI',
        'UYU',
        'UYW',
        'UZS',
        'VES',
        'VND',
        'VUV',
        'WST',
        'XAF',
        'XAG',
        'XAU',
        'XBA',
        'XBB',
        'XBC',
        'XBD',
        'XCD',
        'XDR',
        'XOF',
        'XPD',
        'XPF',
        'XPT',
        'XSU',
        'XTS',
        'XUA',
        'XXX',
        'YER',
        'ZAR',
        'ZMW',
        'ZWL',

        // unofficials
        'CNH', 'CNT', 'GGP', 'IMP', 'JEP', 'KID', 'NIS', 'NTD', 'PRB', 'SLS', 'RMB', 'TVD',

        // crypto currencies
        'ETH', 'VTC', 'XBC', 'XBT', 'XLM', 'XMR', 'XRP', 'ZEC'
    ],
    /* Converts a valid country code from 2 characters to 3 characters or vice-versa
     *
     * @param targetLength The length of the country code this method should return, either 2 or 3.
     *
     * @return string The uppercase country code converted to the specified length.
     */
    convertCountryCode: (countryCode, targetLength) => {
        if (targetLength !== 2 && targetLength !== 3) return

        if (countryCode.length === targetLength) return countryCode.toUpperCase()

        let list = countryCodes.iso3166CountryCodes // key = alpha2, value = alpha3
        if (targetLength === 2) {
            // key = alpha3, value = alpha2
            list = invert(list)
        }

        const newCountryCode = list[countryCode.toUpperCase()]
        if (!newCountryCode) return

        return newCountryCode.toUpperCase()
    },
    /**
     * @param {string} countryCode iso3166 country code
     * @returns {boolean} bool Tell wether the provided code is a valid ISO 3166-1 alpha 2 or alpha 3 country code.
     */
    isValidCountryCode (countryCode) {
        const { length, } = countryCode
        const code = countryCode.toUpperCase()

        const list = countryCodes.iso3166CountryCodes // key = alpha2, value = alpha3

        if (length === 2) {
            const values = keys(list)
            return values.indexOf(code) > -1
        }

        if (length === 3) {
            const values = _values(list)
            return values.indexOf(code) > -1
        }

        return false
    },

    /**
     * @param {string} code iso3166 country code
     * @returns {boolean} Tell whether the provided code is a valid ISO 4217 currency code.
     */
    isValidCurrencyCode (code) {
        if (code.length !== 3) return false

        const list = countryCodes.iso4217CurrencyCodes
        return list.indexOf(code.toUpperCase()) > -1
    },
}

export default countryCodes
