import { defineStore, } from 'pinia'

const useUserStore = defineStore('user', {
    state: () => ({
        user: null, // the value of the 'authUser' piece of data shared to each view
        permissions: null,
        isAdmin: false,
    }),

    actions: {
    // Simple state assignments, no need for complex mutations
        setUser (user) {
            this.user = user
        },
        setAdmin (isAdmin) {
            this.isAdmin = isAdmin
        },
        setPermissions (permissions) {
            this.permissions = permissions
        },
    },
})

export default useUserStore
