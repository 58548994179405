<template>
    <div
        v-if="editor"
        class="editor"
        :class="{'has-background-white' : editableMutable}"
    >
        <div
            v-if="editableMutable"
            class="menubar"
        >
            <b-button
                v-if="visibility.heading && visibility.h1"
                :class="{ 'is-primary': editor.isActive('heading', { level: 1 }) }"
                type="is-light"
                label="H1"
                class="mb-1"
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            />

            <b-button
                v-if="visibility.heading && visibility.h2"
                :class="{ 'is-primary': editor.isActive('heading', { level: 2 }) }"
                type="is-light"
                label="H2"
                class="mb-1"
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            />

            <b-button
                v-if="visibility.heading && visibility.h3"
                :class="{ 'is-primary': editor.isActive('heading', { level: 3 }) }"
                type="is-light"
                class="mb-1 mr-1"
                label="H3"
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            />

            <b-button
                v-if="visibility.textFormatting && visibility.bold"
                :class="{ 'is-primary': editor.isActive('bold') }"
                icon-left="bold"
                type="is-light"
                class="mb-1"
                @click="editor.chain().focus().toggleBold().run()"
            />

            <b-button
                v-if="visibility.textFormatting && visibility.italic"
                :class="{ 'is-primary': editor.isActive('italic') }"
                type="is-light"
                icon-left="italic"
                class="mb-1"
                @click="editor.chain().focus().toggleItalic().run()"
            />

            <b-button
                v-if="visibility.textFormatting && visibility.strike"
                :class="{ 'is-primary': editor.isActive('strike') }"
                type="is-light"
                icon-left="strikethrough"
                class="mb-1"
                @click="editor.chain().focus().toggleStrike().run()"
            />

            <b-button
                v-if="visibility.textFormatting && visibility.underline"
                :class="{ 'is-primary': editor.isActive('underline') }"
                type="is-light"
                icon-left="underline"
                class="mb-1 mr-1"
                @click="editor.chain().focus().toggleUnderline().run()"
            />

            <b-button
                v-if="visibility.list && visibility.itemList"
                :class="{ 'is-primary': editor.isActive('bulletList') }"
                type="is-light"
                icon-left="list-ul"
                class="mb-1"
                @click="editor.chain().focus().toggleBulletList().run()"
            />

            <b-button
                v-if="visibility.list && visibility.orderedList"
                :class="{ 'is-primary': editor.isActive('orderedList') }"
                type="is-light"
                icon-left="list-ol"
                class="mb-1 mr-1"
                @click="editor.chain().focus().toggleOrderedList().run()"
            />

            <b-button
                v-if="visibility.insert && visibility.quote"
                :class="{ 'is-primary': editor.isActive('blockquote') }"
                type="is-light"
                icon-left="quote-right"
                class="mb-1"
                @click="editor.chain().focus().toggleBlockquote().run()"
            />

            <b-button
                v-if="visibility.insert && visibility.code"
                :class="{ 'is-primary': editor.isActive('code') }"
                type="is-light"
                icon-left="code"
                class="mb-1"
                @click="editor.chain().focus().toggleCode().run()"
            />

            <b-button
                v-if="visibility.insert && visibility.codeBlock"
                :class="{ 'is-primary':editor.isActive('codeBlock') }"
                type="is-light"
                icon-left="file-code"
                class="mb-1"
                @click="editor.chain().focus().toggleCodeBlock().run()"
            />

            <b-button
                v-if="visibility.insert && visibility.link"
                :class="{ 'is-primary': editor.isActive('link') }"
                type="is-light"
                icon-left="link"
                class="mb-1"
                @click="setLink"
            />

            <b-button
                v-if="visibility.insert && visibility.break"
                icon-left="minus"
                type="is-light"
                class="mb-1 mr-1"
                @click="editor.chain().focus().setHorizontalRule().run()"
            />

            <b-button
                v-if="visibility.oopsi && visibility.undo"
                icon-left="undo"
                type="is-light"
                class="mb-1"
                @click="editor.chain().focus().undo().run()"
            />

            <b-button
                v-if="visibility.oopsi && visibility.redo"
                icon-left="redo"
                type="is-light"
                class="mb-1"
                @click="editor.chain().focus().redo().run()"
            />
        </div>
        <editor-content
            ref="editor"
            class="editor-content"
            :class="{'content-editable': editableMutable}"
            :editor="editor"
        />
    </div>
</template>

<script>
import Blockquote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import CodeBlock from '@tiptap/extension-code-block'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import {
    Editor, EditorContent,
} from '@tiptap/vue-3'
import merge from 'lodash/merge'

const defaultOptions = {
    // group visibility
    heading: true,
    textFormatting: true,
    list: true,
    insert: true,
    oopsi: true,
    // button visibility
    h1: true,
    h2: true,
    h3: true,
    bold: true,
    italic: true,
    strike: true,
    underline: true,
    itemList: true,
    orderedList: true,
    quote: true,
    code: true,
    codeBlock: true,
    link: true,
    break: true,
    undo: true,
    redo: true,
}

export default {
    components: {
        EditorContent,
    },
    props: {
        content: {
            default: '',
            required: true,
            type: String,
        },
        editable: {
            default: true,
            required: false,
            type: Boolean,
        },
        options: {
            type: Object,
            default: () => defaultOptions,
        },
    },
    data () {
        const visibilityOptions = merge(defaultOptions, this.options)
        return {
            editableMutable: this.editable,
            constentMutable: this.content,
            editor: null,
            editorOptions: {
                extensions: [
                    Document,
                    Blockquote,
                    BulletList,
                    CodeBlock,
                    Paragraph,
                    HardBreak,
                    Text,
                    Heading.configure({ levels: [1, 2, 3], }),
                    HorizontalRule,
                    ListItem,
                    OrderedList,
                    Link.configure({ linkOnPaste: true, }),
                    Bold,
                    Code,
                    Italic,
                    Strike,
                    Underline,
                    History
                ],
                autofocus: true,
                editable: this.editable,
                content: this.getContent(),
                onUpdate: () => {
                    this.$emit('update', this.editor.getText()
                        ? JSON.stringify(this.editor.getJSON())
                        : '')
                },
            },
            visibility: visibilityOptions,
        }
    },
    watch: {
        content () {
            this.editorOptions.content = this.getContent()
        },
        editable (bool) {
            this.editableMutable = bool
            this.editorOptions.editable = bool
            this.editor.destroy()
            this.init()
        },
    },
    mounted () {
        this.init()
    },
    beforeUnmount () {
        this.editor.destroy()
    },
    methods: {
        init () {
            this.editor = new Editor(this.editorOptions)
        },
        getContent () {
            try {
                return JSON.parse(this.content)
            } catch (error) {
                return this.content
            }
        },
        clear () {
            this.editor.commands.clearContent()
        },
        isEmpty () {
            return this.editor.isEmpty
        },
        setLink () {
            this.$buefy.dialog.prompt({
                message: this.$i18n.t('fix.show.urlPrompt'),
                trapFocus: true,
                onConfirm: (value) => this.editor.chain().focus().setLink({ href: value, }).run(),
            })
        },
    },
}
</script>

<style lang="scss">
.editor {

    border-radius: 4px;

    .menubar {
        padding: 5px;
        border: 0;
    }

    .editor-content {
        height: auto;
        padding: 10px;
    }

    .content-editable {
        height: 250px;
        overflow-y: scroll;
        resize: vertical;
    }
}

.ProseMirror {
    height: 100%;
    word-wrap: break-word;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    em {
        font-style: italic;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }

    .suggestion {
        color: #027A88;
        border-radius: 0.3rem;
        padding: 0.1rem 0.3rem;
        background: linear-gradient(45deg, rgba(#03774e, 0.1), rgba(#007cc2, 0.1));

        background-size: 400% 400%;
        -webkit-animation: bgGradient 5s ease infinite;
        animation: bgGradient 5s ease infinite;
    }

    @-webkit-keyframes bgGradient {
        0%{background-position:45% 0}
        50%{background-position:56% 100%}
        100%{background-position:45% 0}
    }

    @keyframes bgGradient {
        0%{background-position:45% 0}
        50%{background-position:56% 100%}
        100%{background-position:45% 0}
    }
}

</style>
