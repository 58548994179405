<template>
    <div class="sidebar-page">
        <section
            class="sidebar-layout"
            :class="{'sidemenu-open': toggleStore.showMobileSideMenu}"
        >
            <b-sidebar
                v-model="toggleStore.showMobileSideMenu"
                :expand-on-hover="true"
                :delay="500"
                :can-cancel="false"
                type="is-dark"
                mobile="fullwidth"
                fullheight
                reduce
            >
                <b-menu class="is-custom-mobile p-1">
                    <b-menu-list v-if="menu">
                        <b-menu-item
                            :href="mainData[1]"
                            :icon="mainData[2]"
                            :icon-pack="mainData[3]"
                            :class="titleClass"
                            :data-testid="`menu-${menu}`"
                        >
                            <template #label>
                                <span>{{ mainData[0] }}</span>
                                <span
                                    v-if="menu === resources.evse && evse && evse.status"
                                    :class="'tag is-' + evse.status.toLowerCase()"
                                >
                                    {{ $t('evses.status.' + evse.status.toLowerCase()) }}
                                </span>
                                <span
                                    v-if="menu === resources.session && session && session.status"
                                    :class="`tag is-session-${session.status.toLowerCase()}`"
                                >
                                    {{ $t(`sessions.status.${session.status.toLowerCase()}`) }}
                                </span>
                                <span
                                    v-if="menu === resources.device && device"
                                    :class="{'tag is-success': device.is_online, 'tag is-danger': !device.is_online}"
                                >
                                    {{ $t(`devices.show.${device.is_online ? 'isOnline' : 'isOffline'}`) }}
                                </span>
                            </template>
                        </b-menu-item>
                    </b-menu-list>

                    <hr class="my-1">

                    <b-menu-list>
                        <b-menu-item
                            v-for="item in getAllowedItems(items)"
                            :key="item.ref"
                            :active="activeItem === item.ref"
                            :model-value="activeItem === item.ref"
                            :expanded="hasChildActive(item)"
                            :class="notifications[item.countIndex] ? notifications[item.countIndex].cssClass : null"
                            :icon="item.icon"
                            :href="item.link"
                            :data-testid="`menu-${item.ref}`"
                        >
                            <template #label>
                                <span>{{ $t(`sidebar.items.${item.ref}`) }}
                                    <b-tag
                                        v-if="item.countIndex >= 0"
                                        :type="getTagType(notifications[item.countIndex])"
                                        class="is-pulled-right is-rounded"
                                    >
                                        {{ notifications[item.countIndex] ? notifications[item.countIndex].count : 0 }}
                                    </b-tag>
                                </span>
                            </template>
                            <b-menu-item
                                v-for="childItem in getAllowedItems(item.items)"
                                :key="childItem.ref"
                                :active="activeItem === childItem.ref"
                                :model-value="activeItem === childItem.ref"
                                :icon="childItem.icon"
                                :class="notifications[childItem.countIndex] ? notifications[childItem.countIndex].cssClass : 'is-default'"
                                :href="childItem.link"
                                :data-testid="`menu-${childItem.ref}`"
                            >
                                <template #label>
                                    <span>{{ $t(`sidebar.items.${childItem.ref}`) }}
                                        <b-tag
                                            v-if="childItem.countIndex >= 0"
                                            class="is-rounded is-pulled-right"
                                            :type="getTagType(notifications[childItem.countIndex])"
                                        >
                                            {{ notifications[childItem.countIndex] ? notifications[childItem.countIndex].count : 0 }}
                                        </b-tag>
                                    </span>
                                </template>
                            </b-menu-item>
                        </b-menu-item>
                    </b-menu-list>

                    <b-menu-list v-if="moveShortcutLink && isAdmin">
                        <b-button
                            icon-left="external-link"
                            tag="a"
                            class="is-pulp admin-shortcut mt-2"
                            :href="moveShortcutLink"
                            target="_blank"
                            data-testid="menu-move"
                        >
                            Voir dans Move
                        </b-button>
                    </b-menu-list>
                </b-menu>
            </b-sidebar>
        </section>
    </div>
</template>

<script>
import filter from 'lodash/filter'
import { mapStores, } from 'pinia'

import apiUrls from '@/config/apiUrls.js'
import { FIX, } from '@/config/constants.js'
import Icon from '@/config/icon.js'
import { actionNamespaces, resources, Url, } from '@/config/url.js'

import useIssueStore from '@/state/issueStore.js'
import useMainStore from '@/state/mainStore.js'
import useToggleStore from '@/state/toggleStore.js'
import useUserStore from '@/state/userStore.js'

export default {
    props: {
        menu: {
            type: String,
            required: true,
        },
        activeItem: {
            type: String,
            required: true,
        },
        moveShortcutLink: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            Icon,
            resources,
            items: [],
            notifications: [],
            marginLeft: window.matchMedia('screen and (max-width: 1023px').matches
                ? '4rem'
                : '2rem',
        }
    },
    computed: {
        ...mapStores(useMainStore, useIssueStore, useToggleStore, useUserStore),
        permissions () {
            return this.userStore.permissions
        },
        isAdmin () {
            return this.userStore.isAdmin
        },
        operator () {
            return this.mainStore.operator
        },
        location () {
            return this.mainStore.location
        },
        evse () {
            return this.mainStore.evse
        },
        device () {
            return this.mainStore.device
        },
        session () {
            return this.mainStore.session
        },
        cdr () {
            return this.mainStore.cdr
        },
        retrocessionGroup () {
            return this.mainStore.retrocessionGroup
        },
        stationCheck () {
            return this.mainStore.stationCheck
        },
        contact () {
            return this.mainStore.contact
        },
        /**
         * @returns {String[]} of 3 index: [0] = title to print, [1] = url, [2] = icon
         */
        mainData () {
            if (this.menu === resources.network && this.operator)
                return [this.operator.name || '', new Url('network', this.operator.ref).value, Icon.networks]
            if (this.menu === resources.location && this.location)
                return [this.location.name || '', new Url('location', this.location.custom_ref).value, Icon.locations]
            if (this.menu === resources.report && this.stationCheck) {
                const stationCheckName = this.stationCheck.location_name
                    ? `Activation ${this.stationCheck.location_name}`
                    : ''
                const url = new Url('report', `reports/${this.stationCheck.id}`).value
                return [stationCheckName, url, 'wrench']
            }
            if (this.menu === resources.evse && this.evse)
                return [this.evse.custom_ref || '', new Url('evse', this.evse.custom_ref).value, Icon.evses]
            if (this.menu === resources.contact && this.contact)
                return [this.contact.name || '', new Url('contact', this.contact.id).value, Icon.users]
            if (this.menu === resources.device && this.device)
                return [this.device.chargeboxid || '', new Url('device', this.device.chargeboxid).value, Icon.devices]
            if (this.menu === resources.session && this.session)
                return [this.session.custom_ref || '', new Url('session', this.session.custom_ref).value, Icon.sessions]
            if (this.menu === resources.retrocession && this.retrocessionGroup)
                return [this.retrocessionGroup.name || '', new Url('retrocession', this.retrocessionGroup.id).value, Icon.retrocessions, 'icomoon']
            return ['', '#', '']
        },
        titleClass () {
            if (this.mainData[0].length > 25) return 'menu-title is-size-7'
            if (this.mainData[0].length > 17) return 'menu-title is-size-6'
            return 'menu-title'
        },
    },
    created () {
        window.addEventListener('resize', this.onResize)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.onResize)
    },
    mounted () {
        this.onResize()
        switch (this.menu) {
        case resources.network: this.buildNetworkItems(); break
        case resources.location: this.buildLocationItems(); break
        case resources.report: this.buildStationCheckItems(); break
        case resources.contact: this.buildContactItems(); break
        case resources.evse: this.buildEvseItems(); break
        case resources.device: this.buildDeviceItems(); break
        case resources.session: this.buildSessionItems(); break
        case resources.retrocession: this.buildRetrocessionItems(); break
        default: break
        }
    },
    methods: {
        updateMargin () {
            // Update the main content left margin, so it is not hidden under the sidebar if it is open.
            // Need to do this in JS because firefox does not support the :has pseudo-selector
            if (this.toggleStore.showMobileSideMenu)
                document.querySelector('#main-content').style.marginLeft = this.marginLeft
            else
                document.querySelector('#main-content').style.marginLeft = '0'
        },
        onResize () {
            // manually check if the viewport is 'mobile' or 'tablet' breakpoint to set the correct margin
            this.marginLeft = window.matchMedia('screen and (max-width: 1023px').matches
                ? '4rem'
                : '2rem'
            this.updateMargin()
        },
        /**
         * @param {{class: string}|null} notification - notification object
         * @returns {string|null}
         */
        getTagType (notification) {
            if (notification && 'cssClass' in notification) {
                if (notification.cssClass === 'icon-notification-blue') return 'is-blue'
                if (notification.cssClass === 'icon-notification-yellow') return 'is-warning'
                if (notification.cssClass === 'icon-notification') return 'is-danger'
            }

            return null
        },
        getAllowedItems (items) {
            return filter(items, { isAllowed: true, })
        },
        hasChildActive (item) {
            const items = this.getAllowedItems(item.items)
            for (let i = 0; i < items.length; i++) {
                if (this.activeItem === items[i].ref)
                    return true
            }
            return false
        },
        async buildNetworkItems () {
            if (!this.operator) return []

            const { ref, } = this.operator

            if (this.permissions.canReadFixIssue) await this.getNotifications('network', ref)
            const fixItems = this.buildFixItems('network', ref)

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('network', ref).value,
                icon: Icon.details,
                isAllowed: true,
            },
            fixItems, {
                ref: resources.session,
                link: new Url('session').byNetwork(ref).value,
                icon: Icon.sessions,
                isAllowed: this.permissions.canReadSession,
            }, {
                ref: 'subcpos',
                link: new Url('network').byParent(ref).value,
                icon: Icon.networks,
                isAllowed: true,
            }, {
                ref: resources.location,
                link: new Url('location').byNetwork(ref).value,
                icon: Icon.locations,
                isAllowed: this.permissions.canReadLocation,
            }, {
                ref: resources.evse,
                link: new Url('evse').byNetwork(ref).value,
                icon: Icon.evses,
                isAllowed: this.permissions.canReadEvse,
            }, {
                ref: resources.device,
                link: new Url('device').byNetwork(ref).value,
                icon: Icon.devices,
                isAllowed: this.permissions.canReadDevice,
            }, {
                ref: actionNamespaces.map,
                link: new Url('network', ref, 'map').value,
                icon: Icon.map,
                isAllowed: this.permissions.canReadLocation,
            }, {
                ref: resources.tariff,
                link: new Url('tariff').byNetwork(ref).value,
                icon: Icon.tariffs,
                isAllowed: this.permissions.canReadTariff,
            }, {
                ref: resources.cdr,
                link: new Url('cdr').byNetwork(ref).value,
                icon: Icon.cdrs,
                isAllowed: this.permissions.canReadCdr,
            }, {
                ref: resources.agreement,
                link: new Url().setZestAgreementUrl(ref),
                icon: Icon.agreements,
                isAllowed: this.permissions.canReadAgreement,
                // Note 12/06/20 this mechanic is not used for now, but maybe later
                /* items: [
                        {
                            ref: 'list',
                            link: urls.agreements.index(ref),
                            icon: Icon.list
                        },
                        {
                            ref: 'create',
                            link: urls.agreements.create(ref),
                            icon: Icon.add
                    }] */
            }, {
                ref: actionNamespaces.configuration,
                link: new Url('network', ref, 'configurations').value,
                icon: Icon.configurations,
                isAllowed: this.isAdmin,
            }, {
                ref: actionNamespaces.audit,
                link: new Url('network', ref, actionNamespaces.audit).value,
                icon: Icon.logs.audit,
                isAllowed: this.permissions.canReadAuditLog,
            }]
        },
        async buildLocationItems () {
            if (!this.location) return []

            const customRef = this.location.custom_ref

            if (this.permissions.canReadFixIssue) await this.getNotifications('location', customRef)

            const fixItems = this.buildFixItems('location', customRef)

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('location', customRef).value,
                icon: Icon.details,
                isAllowed: true,
            }, fixItems, {
                ref: resources.session,
                link: new Url('session').byLocation(customRef).value,
                icon: Icon.sessions,
                isAllowed: this.permissions.canReadSession,
            }, {
                ref: resources.evse,
                link: new Url('evse').byLocation(customRef).value,
                icon: Icon.evses,
                isAllowed: this.permissions.canReadEvse,
            }, {
                ref: resources.device,
                link: new Url('device').byLocation(customRef).value,
                icon: Icon.devices,
                isAllowed: this.permissions.canReadDevice,
            }, {
                ref: resources.lan,
                link: new Url().setZestLanUrl(customRef),
                icon: Icon.lans,
                isAllowed: this.permissions.canReadLan,
            }, {
                ref: resources.cdr,
                link: new Url('cdr').byLocation(customRef).value,
                icon: Icon.cdrs,
                isAllowed: this.permissions.canReadCdr,
            }, {
                ref: actionNamespaces.business,
                link: new Url('location', customRef, 'business').value,
                icon: Icon.logs.business,
                isAllowed: this.permissions.canReadBusinessLog,
            }, {
                ref: actionNamespaces.ocpi,
                link: new Url('location', customRef, 'ocpi').value,
                icon: Icon.logs.ocpi,
                isAllowed: this.permissions.canReadOcpiLog,
            }, {
                ref: actionNamespaces.audit,
                link: new Url('location', customRef, actionNamespaces.audit).value,
                icon: Icon.logs.audit,
                isAllowed: this.permissions.canReadAuditLog,
            }]
        },
        buildStationCheckItems () {
            if (!this.stationCheck) return []
            const idReport = this.stationCheck.id

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('report', `reports/${idReport}`).value,
                icon: Icon.details,
                isAllowed: this.permissions.canReadReports,
            }, {
                ref: actionNamespaces.logs,
                link: new Url('report', `report-logs/${idReport}`).value,
                icon: Icon.logs.business,
                isAllowed: this.permissions.canReadReportLog,
            }]
        },
        async buildContactItems () {
            if (!this.contact) return []

            const contactId = this.contact.id

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('contact', `${contactId}`).value,
                icon: Icon.details,
                isAllowed: this.permissions.canReadContact,
            }, {
                ref: 'affiliations',
                link: new Url('contact', `${contactId}/affiliations`).value,
                icon: Icon.contact.link,
                isAllowed: this.permissions.canReadContact,
            }, {
                ref: 'groups',
                link: new Url('contact', `${contactId}/groups`).value,
                icon: Icon.contact.groups,
                isAllowed: this.permissions.canReadContact && this.permissions.canReadContactGroup,
            }]
        },
        async buildEvseItems () {
            if (!this.evse) return []

            const customRef = this.evse.custom_ref

            if (this.permissions.canReadFixIssue) await this.getNotifications('evse', customRef)
            const fixItems = this.buildFixItems('evse', customRef)

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('evse', customRef).value,
                icon: Icon.details,
                isAllowed: true,
            }, fixItems, {
                ref: resources.session,
                link: new Url('session').byEvse(customRef).value,
                icon: Icon.sessions,
                isAllowed: this.permissions.canReadSession,
            }, {
                ref: resources.cdr,
                link: new Url('cdr').byEvse(customRef).value,
                icon: Icon.cdrs,
                isAllowed: this.permissions.canReadCdr,
            }, {
                ref: actionNamespaces.business,
                link: new Url('evse', customRef, 'business').value,
                icon: Icon.logs.business,
                isAllowed: this.permissions.canReadBusinessLog,
            }, {
                ref: actionNamespaces.ocpi,
                link: new Url('evse', customRef, 'ocpi').value,
                icon: Icon.logs.ocpi,
                isAllowed: this.permissions.canReadOcpiLog,
            }, {
                ref: actionNamespaces.audit,
                link: new Url('evse', customRef, actionNamespaces.audit).value,
                icon: Icon.logs.audit,
                isAllowed: this.permissions.canReadAuditLog,
            }]
        },
        async buildDeviceItems () {
            if (!this.device) return []

            const cbi = this.device.chargeboxid
            if (this.permissions.canReadFixIssue) await this.getNotifications('device', cbi)
            const fixItems = this.buildFixItems('device', cbi)

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('device', cbi).value,
                icon: Icon.details,
                isAllowed: true,
            }, fixItems, {
                ref: 'actions',
                link: new Url('device', cbi, 'actions').value,
                icon: Icon.sessions,
                isAllowed: this.permissions.canBasicOcppActions || this.permissions.canAdvancedOcppActions,
            }, {
                ref: actionNamespaces.ocpp,
                link: new Url('device', cbi, 'ocpp').value,
                icon: Icon.logs.ocpp,
                isAllowed: this.permissions.canReadOcppLog,
            }, {
                ref: actionNamespaces.audit,
                link: new Url('device', cbi, actionNamespaces.audit).value,
                icon: Icon.logs.audit,
                isAllowed: this.permissions.canReadAuditLog,
            }]
        },
        buildSessionItems () {
            if (!this.session) return []

            const customRef = this.session.custom_ref

            // Adapt this when CDR has his own scope
            const hasCdr = this.session.cdr || this.cdr

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('session', customRef).value,
                icon: Icon.details,
                isAllowed: true,
            },
            {
                ref: actionNamespaces.curve,
                link: new Url('session', customRef, actionNamespaces.curve).value,
                icon: Icon.chart,
                isAllowed: true,
            }, {
                ref: 'costDetails',
                link: new Url('session', customRef, 'costDetails').value,
                icon: Icon.cdrs,
                isAllowed: this.permissions.canReadSessionCostDetails,
            }, {
                ref: resources.cdr,
                link: new Url('cdr', hasCdr.custom_ref).setQueryString('session', customRef).value,
                icon: Icon.cdrs,
                isAllowed: hasCdr && hasCdr.custom_ref !== null && this.permissions.canReadCdr,
            }, {
                ref: actionNamespaces.ocpi,
                link: new Url('session', customRef, 'ocpi').value,
                icon: Icon.logs.ocpi,
                isAllowed: this.permissions.canReadOcpiLog,
            }]
        },
        buildFixItems (scope, ref) {
            let correctiveUrl = new Url('fix', FIX.TYPE_CORRECTIVE.toLowerCase())
            let preventiveUrl = new Url('fix', FIX.TYPE_PREVENTIVE.toLowerCase())
            let informationUrl = new Url('fix', FIX.TYPE_INFO.toLowerCase())
            let historyUrl = new Url('fix', 'history')

            switch (scope) {
            case 'network':
                correctiveUrl = correctiveUrl.byNetwork(ref).value
                preventiveUrl = preventiveUrl.byNetwork(ref).value
                informationUrl = informationUrl.byNetwork(ref).value
                historyUrl = historyUrl.byNetwork(ref).value
                break
            case 'location':
                correctiveUrl = correctiveUrl.byLocation(ref).value
                preventiveUrl = preventiveUrl.byLocation(ref).value
                informationUrl = informationUrl.byLocation(ref).value
                historyUrl = historyUrl.byLocation(ref).value
                break
            case 'evse':
                correctiveUrl = correctiveUrl.byEvse(ref).value
                preventiveUrl = preventiveUrl.byEvse(ref).value
                informationUrl = informationUrl.byEvse(ref).value
                historyUrl = historyUrl.byEvse(ref).value
                break
            case 'device':
                correctiveUrl = correctiveUrl.byDevice(ref).value
                preventiveUrl = preventiveUrl.byDevice(ref).value
                informationUrl = informationUrl.byDevice(ref).value
                historyUrl = historyUrl.byDevice(ref).value
                break
            default: break
            }

            return {
                ref: resources.fix,
                icon: Icon.fix,
                isAllowed: this.permissions.canReadFixIssue,
                countIndex: 3,
                items: [{
                    ref: actionNamespaces.fix.corrective,
                    link: correctiveUrl,
                    icon: Icon.corrective,
                    isAllowed: this.permissions.canReadFixIssue,
                    countIndex: 1,
                },
                {
                    ref: actionNamespaces.fix.preventive,
                    link: preventiveUrl,
                    icon: Icon.preventive,
                    isAllowed: this.permissions.canReadFixIssue,
                    countIndex: 0,
                },
                {
                    ref: actionNamespaces.fix.information,
                    link: informationUrl,
                    icon: Icon.infos,
                    isAllowed: this.permissions.canReadFixIssue && this.isAdmin,
                    countIndex: 2,
                },
                {
                    ref: actionNamespaces.fix.history,
                    link: historyUrl,
                    icon: Icon.history,
                    isAllowed: this.permissions.canReadFixIssue,
                }],
            }
        },
        buildRetrocessionItems () {
            if (!this.retrocessionGroup) return []

            const { id, } = this.retrocessionGroup

            this.items = [{
                ref: actionNamespaces.detail,
                link: new Url('retrocession', id).value,
                icon: Icon.details,
                isAllowed: true,
            }, {
                ref: 'linked_networks',
                link: new Url('network').byRetrocessionGroup(id).value,
                icon: Icon.networks,
                isAllowed: true,
            }, {
                ref: actionNamespaces.business,
                link: new Url('retrocessionGroupLog').byRetrocessionGroup(id).value,
                icon: Icon.logs.business,
                isAllowed: this.isAdmin,
            }]
        },
        async getNotifications (prefix, ref) {
            const url = apiUrls.fix.issuesCountBy(prefix, ref)
            const response = await axios.get(url.value)

            if (!response?.data?.data)
                return

            this.issueStore.setIssuesCountBy({ key: ref, data: response?.data?.data, })

            const preventiveCount = response.data.data[FIX.TYPE_PREVENTIVE] || 0
            const correctiveCount = response.data.data[FIX.TYPE_CORRECTIVE] || 0
            const infoCount = response.data.data[FIX.TYPE_INFO] || 0
            const allCount = response.data.data.total || 0
            const hasCountInfo = infoCount > 0 && this.isAdmin

            this.notifications.push(
                {
                    count: preventiveCount,
                    cssClass: preventiveCount > 0
                        ? 'icon-notification-blue'
                        : '',
                },
                {
                    count: correctiveCount,
                    cssClass: correctiveCount > 0
                        ? 'icon-notification'
                        : '',
                },
                {
                    count: this.isAdmin
                        ? infoCount
                        : 0,
                    cssClass: hasCountInfo
                        ? 'icon-notification-yellow'
                        : '',
                }
            )

            let notifClass = 'icon-notification'
            if (correctiveCount === 0) {
                if (hasCountInfo)
                    notifClass = 'icon-notification-yellow'
                else if (preventiveCount > 0)
                    notifClass = 'icon-notification-blue'
            }

            const totalCountVisible = this.isAdmin
                ? allCount
                : allCount - infoCount

            if (totalCountVisible <= 0)
                notifClass = ''

            this.notifications.push({
                count: totalCountVisible,
                cssClass: notifClass,
            })
        },
    },
}
</script>
