import merge from 'lodash/merge'
import { defineStore, } from 'pinia'

const useConfigStore = defineStore('config', {
    state: () => ({
        config: {},
    }),
    actions: {
        setConfig (config) { merge(this.config, config) },
        setFixConfig (fixConfig) { this.config.fixConfig = fixConfig },
        setActivations (activations) { this.config.activations = activations },
        setNotifications (notifications) { this.config.notifications = notifications },
        setActiveLocale (locale) { this.config.current_locale = locale },
    },
})

export default useConfigStore
