import axios from 'axios'
import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import helpers from '@/config/helpers.js'
import permissions from '@/config/permissions.js'
import translations from '@/config/translations.js'

import useConfigStore from '@/state/configStore.js'
import useToggleStore from '@/state/toggleStore.js'
import useUserStore from '@/state/userStore.js'

import { ToastProgrammatic, } from 'buefy'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/nl'

export default () => {
    const activeLocale = helpers.getActiveLocale()
    const { t, } = translations.I18N.global

    const configStore = useConfigStore()
    const toggleStore = useToggleStore()
    const userStore = useUserStore()

    /**
     * We'll load the axios HTTP library which allows us to easily issue requests
     * to our Laravel back-end. This library automatically handles sending the
     * CSRF token as a header based on the value of the "XSRF" token cookie.
     */
    window.axios = axios
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    /**
     * Next we will register the CSRF Token as a common header with Axios so that
     * all outgoing HTTP requests automatically have it attached. This is just
     * a simple convenience so we don't have to attach every token manually.
     */
    const token = document.head.querySelector('meta[name="csrf-token"]') || 'abc'
    if (token) window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
    else console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')

    // Add global error handling
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // check for errorHandle config
            if (
                Object.prototype.hasOwnProperty.call(error.config, 'errorHandle')
                && error.config.errorHandle === false
            ) return Promise.reject(error)

            // if has response show the error
            if (error.response) {
                new ToastProgrammatic().open({
                    type: 'is-danger',
                    message: helpers.handleError(error.response.data, t('commons.globalError')),
                    position: 'is-bottom',
                })
            }
        }
    )

    // Set global context injected from the php blade into window object to different stores
    configStore.setConfig(window.config)
    configStore.setActiveLocale(activeLocale)
    configStore.setNotifications(window.notifications)
    configStore.setActivations(window.activations)
    userStore.setUser(window.authUser)
    let isAdmin = false
    if (window.authUser) {
        isAdmin = window.authUser.role.is_admin === true
        userStore.setAdmin(isAdmin)
        userStore.setPermissions(permissions.getRights(window.authUser.role.rights, isAdmin))
    }

    // Extend dayjs with plugins, set configurations and global instance
    dayjs.locale(activeLocale)
    dayjs.extend(LocalizedFormat)
    dayjs.extend(Duration)
    dayjs.locale(activeLocale)
    window.dayjs = dayjs

    // Hide side menu if window is resized
    window.onresize = function onresize () {
        if (helpers.isMobile() && toggleStore.showMobileSideMenu)
            toggleStore.toggleShowMobileSideMenu(false)
        else if (!helpers.isMobile() && !toggleStore.showMobileSideMenu) toggleStore.toggleShowMobileSideMenu(true)
    }

    // Disable middle click scroll on Windows: https://thewebdev.info/2022/01/23/how-to-prevent-middle-mouse-click-scrolling-with-javascript/
    document.body.onmousedown = (e) => {
        if (e.button === 1) {
            e.preventDefault()
            return false
        }
    }

    // Display flash messages if any error or success
    if (window.flashError) {
        new ToastProgrammatic().open({
            type: 'is-danger',
            message: window.flashError,
            position: 'is-bottom',
        })
    }
    if (window.flashSuccess) {
        new ToastProgrammatic().open({
            type: 'is-success',
            message: window.flashSuccess,
            position: 'is-bottom',
        })
    }
}
