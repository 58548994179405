function canPerformActionOnModel (rights = [], action, model) {
    return rights.has(model) && rights.get(model).indexOf(action) >= 0
}

/**
 * @param {Object} permissions User permissions
 * @param {Boolean} isAdmin User is admin?
 * @returns {Object} { 'canReadOperator': {bool}, ... }
 */
function getRights (permissions, isAdmin = false) {
    const rights = new Map()
    permissions.forEach((permission) => {
        rights.set(permission.label, permission.type)
    })
    return {
        canReadOperator: isAdmin || canPerformActionOnModel(rights, 'READ', 'OPERATOR'),
        canReadOperatorCommission: isAdmin,
        canReadOperatorRetrocession: isAdmin,
        canCreateOperator: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'OPERATOR'),
        canUpdateOperator: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'OPERATOR'),
        canSearchOperator: isAdmin || canPerformActionOnModel(rights, 'SEARCH', 'OPERATOR'),
        canExportOperator: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'OPERATOR'),

        canReadLocation: isAdmin || canPerformActionOnModel(rights, 'READ', 'LOCATION'),
        canCreateLocation: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'LOCATION'),
        canUpdateLocation: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'LOCATION'),
        canSearchLocation: isAdmin || canPerformActionOnModel(rights, 'SEARCH', 'LOCATION'),
        canImportLocation: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'MASS_IMPORT'),
        canExportLocation: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'LOCATION'),

        canReadEvse: isAdmin || canPerformActionOnModel(rights, 'READ', 'EVSE'),
        canCreateEvse: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'EVSE'),
        canUpdateEvse: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'EVSE'),
        canSearchEvse: isAdmin || canPerformActionOnModel(rights, 'SEARCH', 'EVSE'),
        canExportEvse: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'EVSE'),
        canGenerateQRcodes: isAdmin,

        canReadTariff: isAdmin || canPerformActionOnModel(rights, 'READ', 'TARIFF'),
        canCreateTariff: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'TARIFF'),
        canUpdateTariff: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'TARIFF'),
        canDeleteTariff: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'TARIFF'),
        canSearchTariff: isAdmin || canPerformActionOnModel(rights, 'SEARCH', 'TARIFF'),
        canExportTariff: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'TARIFF'),
        canActionTariff: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'TARIFF'),

        canReadDevice: isAdmin || canPerformActionOnModel(rights, 'READ', 'LANDEVICE'),
        canCreateDevice: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'LANDEVICE'),
        canUpdateDevice: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'LANDEVICE'),
        canSearchDevice: isAdmin || canPerformActionOnModel(rights, 'SEARCH', 'LANDEVICE'),
        canExportDevice: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'LANDEVICE'),

        canBasicOcppActions: isAdmin || canPerformActionOnModel(rights, 'READ', 'OCPPACTIONS'), // ping & reset
        canAdvancedOcppActions: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'OCPPACTIONS'),

        canReadSession: isAdmin || canPerformActionOnModel(rights, 'READ', 'SESSION'),
        canActionSession: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'SESSION'),
        canExportSession: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'SESSION'),
        canExportRetrocessionSession: isAdmin,

        canActionStopCharge: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'STOPCHARGE'),
        canReadSessionCostDetails: isAdmin || canPerformActionOnModel(rights, 'READ', 'SESSION_COST_DETAILS'),

        canReadReservation: isAdmin || canPerformActionOnModel(rights, 'READ', 'RESERVATION'),
        canCreateReservation: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'RESERVATION'),
        canActionReservation: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'RESERVATION'),

        canReadCdr: isAdmin || canPerformActionOnModel(rights, 'READ', 'CDR'),
        canExportCdr: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'CDR'),
        canReadLan: isAdmin || canPerformActionOnModel(rights, 'READ', 'LAN'),
        canCreateLan: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'LAN'),
        canUpdateLan: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'LAN'),

        canReadAgreement: isAdmin || canPerformActionOnModel(rights, 'READ', 'AGREEMENT'),
        canCreateAgreement: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'AGREEMENT'),
        canUpdateAgreement: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'AGREEMENT'),

        canCreateConnector: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'CONNECTOR'),
        canUpdateConnector: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'CONNECTOR'),
        canDeleteConnector: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'CONNECTOR'),
        canActionConnector: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'CONNECTOR'),

        canReadStat: isAdmin || canPerformActionOnModel(rights, 'READ', 'STAT'),
        canReadSetting: isAdmin || canPerformActionOnModel(rights, 'READ', 'SETTING'),
        canReadFixSetting: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'SETTING'),

        canReadTranslation: isAdmin || canPerformActionOnModel(rights, 'READ', 'TRANSLATION'),
        canUpdateTranslation: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'TRANSLATION'),
        canActionTranslation: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'TRANSLATION'),

        canReadAuditLog: isAdmin || canPerformActionOnModel(rights, 'READ', 'AUDITLOG'),
        canReadOcppLog: isAdmin || canPerformActionOnModel(rights, 'READ', 'OCPPLOG'),
        canReadOcpiLog: isAdmin || canPerformActionOnModel(rights, 'READ', 'OCPILOG'),
        canReadBusinessLog: isAdmin || canPerformActionOnModel(rights, 'READ', 'BUSINESSLOG'),

        canExportAuditLog: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'AUDITLOG'),
        canExportOcppLog: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'OCPPLOG'),
        canExportOcpiLog: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'OCPILOG'),
        canExportBusinessLog: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'BUSINESSLOG'),

        canReadMaintenance: isAdmin || canPerformActionOnModel(rights, 'READ', 'MAINTENANCE'),
        canActionMaintenance: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'MAINTENANCE'),
        canExportMaintenance: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'MAINTENANCE'),

        // Fix related permissions
        canReadFixIssue: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_ISSUE'),
        canCreateFixIssue: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'FIX_ISSUE'),
        canUpdateFixIssue: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_ISSUE'),
        canDeleteFixIssue: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'FIX_ISSUE'),
        canActionFixIssue: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'FIX_ISSUE'),
        canExportFixIssue: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'FIX_ISSUE'),

        canReadFixMessage: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_MESSAGE'),
        canCreateFixMessage: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'FIX_MESSAGE'),
        canUpdateFixMessage: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_MESSAGE'),
        canDeleteFixMessage: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'FIX_MESSAGE'),
        canActionFixMessage: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'FIX_MESSAGE'),

        canReadFixTag: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_TAG'),
        canCreateFixTag: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'FIX_TAG'),
        canUpdateFixTag: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_TAG'),
        canDeleteFixTag: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'FIX_TAG'),
        canActionFixTag: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'FIX_TAG'),

        canReadFixUpload: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_UPLOAD'),
        canCreateFixUpload: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'FIX_UPLOAD'),
        canDeleteFixUpload: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'FIX_UPLOAD'),
        canActionFixUpload: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'FIX_UPLOAD'),

        canReadFixTask: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_TASK'),
        canCreateFixTask: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'FIX_TASK'),
        canUpdateFixTask: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_TASK'),
        canDeleteFixTask: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'FIX_TASK'),
        canActionFixTask: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'FIX_TASK'),

        canUpdateFixBody: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_BODY'),
        canUpdateFixAssigned: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_ASSIGNED'),
        canUpdateFixBeginDate: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_BEGIN_DATE'),
        canUpdateFixEndDate: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_END_DATE'),
        canUpdateFixStartDate: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_START_DATE'),
        canUpdateFixInterventionDate: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_INTERVENTION_DATE'),
        canUpdateFixCompletedDate: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_COMPLETED_DATE'),

        canReadFixStat: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_STAT'),
        canExportFixStat: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'FIX_STAT'),

        canReadFixSubscribe: isAdmin || canPerformActionOnModel(rights, 'READ', 'FIX_SUBSCRIBE'), // Read the list of subscribed supervisor
        canUpdateFixSubscribe: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'FIX_SUBSCRIBE'), // Self Subscribe
        canActionFixSubscribe: isAdmin || canPerformActionOnModel(rights, 'ACTION', 'FIX_SUBSCRIBE'), // Subscribe other supervisor (used in combination with the canReadSupervisor and canSearchSupervisor)
        canDeleteFixSubscribe: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'FIX_SUBSCRIBE'), // Remove any subscribed supervisor

        canReadSupervisor: isAdmin || canPerformActionOnModel(rights, 'READ', 'SUPERVISOR'),
        canSearchSupervisor: isAdmin || canPerformActionOnModel(rights, 'SEARCH', 'SUPERVISOR'),

        canReadRefund: isAdmin,
        canCreateRefund: isAdmin,
        canUpdateRefund: isAdmin,
        canDeleteRefund: isAdmin,
        canExportRefund: isAdmin,

        canReadContact: isAdmin || canPerformActionOnModel(rights, 'READ', 'CONTACT'),
        canCreateContact: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'CONTACT'),
        canUpdateContact: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'CONTACT'),
        canDeleteContact: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'CONTACT'),
        canExportContact: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'CONTACT'),

        canReadContactTypeOwner: isAdmin || canPerformActionOnModel(rights, 'READ', 'CONTACT_TYPE_OWNER'),

        canReadContactGroup: isAdmin || canPerformActionOnModel(rights, 'READ', 'CONTACT_GROUP'),
        canCreateContactGroup: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'CONTACT_GROUP'),
        canUpdateContactGroup: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'CONTACT_GROUP'),
        canDeleteContactGroup: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'CONTACT_GROUP'),
        canExportContactGroup: isAdmin || canPerformActionOnModel(rights, 'EXPORT', 'CONTACT_GROUP'),

        canReadActiveAlerts: isAdmin,
        canReadAlertHistory: isAdmin,
        canCreateAlert: isAdmin,
        canUpdateAlert: isAdmin,
        canDeleteAlert: isAdmin,

        canCreateIbanRetrocessionGroup: canPerformActionOnModel(rights, 'CREATE', 'IBAN_RETROCESSION_GROUP'),
        canReadRetrocessionGroup: isAdmin || canPerformActionOnModel(rights, 'READ', 'RETROCESSION_GROUP'),
        canCreateRetrocessionGroup: isAdmin,
        canUpdateRetrocessionGroup: isAdmin,
        canUpdateRetrocessionGroupContact: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'RETROCESSION_GROUP_CONTACT'),
        canReadRetrocessionGroupLogs: isAdmin || canPerformActionOnModel(rights, 'READ', 'RETROCESSION_GROUP_LOG'),
        canGeneratePdfRetrocessionGroup: isAdmin,

        canReadReports: isAdmin,
        canExportReports: isAdmin,
        canArchiveReport: isAdmin,
        canValidateReport: isAdmin,
        canReadReportLog: isAdmin,
        canExportReportLog: isAdmin,

        canReadTag: isAdmin || canPerformActionOnModel(rights, 'READ', 'TAG'),
        canCreateTag: isAdmin || canPerformActionOnModel(rights, 'CREATE', 'TAG'),
        canRemoveTag: isAdmin || canPerformActionOnModel(rights, 'REMOVE', 'TAG'),
        canDeleteTag: isAdmin || canPerformActionOnModel(rights, 'DELETE', 'TAG'),
        canUpdateTag: isAdmin || canPerformActionOnModel(rights, 'UPDATE', 'TAG'),
    }
}
export default { getRights, }
