<template>
    <div
        id="notifications"
        :class="{'has-notifications': $parent.nbNotifications > 0}"
        class="navbar-item has-dropdown is-hoverable"
    >
        <a
            :class="{'has-text-light': $parent.nbNotifications <= 0}"
            class="navbar-link"
            href="#"
        >
            <span class="tag is-rounded is-danger">{{ $parent.nbNotifications }}</span>
            <span class="is-inline">{{ $t("notifications.text") }}</span>
        </a>
        <div
            v-if="permissions.canReadMaintenance && maintenanceNotifications"
            class="navbar-dropdown"
        >
            <a
                v-for="[alertName, alertCount] in Object.entries(maintenanceNotifications)"
                :key="alertName"
                class="navbar-item"
                :href="getUrlByAlertName(alertName)"
            >
                <b-tag
                    :type="alertCount > 0 ? 'is-danger' : null"
                    rounded
                >
                    {{ alertCount }}
                </b-tag>
                {{ $t(`notifications.${alertName}`) }}
            </a>
        </div>
    </div>
</template>

<script>
import kebabCase from 'lodash/kebabCase'
import pickBy from 'lodash/pickBy'
import { mapStores, } from 'pinia'

import { Url, } from '@/config/url.js'

import useConfigStore from '@/state/configStore.js'
import useUserStore from '@/state/userStore.js'

export default {
    data () {
        return {
            notifications: null,
        }
    },
    computed: {
        ...mapStores(useUserStore, useConfigStore),
        permissions () {
            return this.userStore.permissions
        },
        maintenanceNotifications () {
            return pickBy(this.notifications, (_, notificationName) => notificationName !== 'ongoing_issues')
        },
    },
    created () {
        this.notifications = this.configStore.config.notifications
    },
    methods: {
        getUrlByAlertName (alertName) {
            return new Url('maintenanceAlert', kebabCase(alertName)).byTab(this.notifications[alertName] > 0
                ? 'unhandled'
                : 'handled').value
        },
    },
}
</script>
