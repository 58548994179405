import { createI18n, } from 'vue-i18n'

import Locale from '@/vue-i18n-locales.generated.js'

// Initialize i18n
const I18N = createI18n({
    globalInjection: true, // Inject $t globally
    locale: window.config.default_locale, // config.current_locale is null from "PHP"
    fallbackLocale: window.config.default_locale, // This is the user's main language
    messages: Locale, // Localisation data
    silentTranslationWarn: true, // Suppress translation warnings
    legacy: true, // Use Vue 2's i18n API
})

const translations = {
    getBrowserLanguage: () => {
        const language = navigator.languages
            ? navigator.languages[0]
            : navigator.language || navigator.userLanguage
        return language.indexOf('-') >= 0
            ? language.split('-')[0].toLowerCase()
            : language.toLowerCase()
    },

    getLanguageCode: (code) => (code === 'en'
        ? 'gb'
        : code),

    dayNamesTranslated: () => {
        const dayNames = []
        for (let i = 0; i < 7; i++)
            dayNames.push(I18N.global.t(`days.dayNamesCalendar.${i.toString()}`)) // Use I18N.global.t in Vue 3

        return dayNames
    },

    monthNamesTranslated: () => {
        const monthNames = []
        for (let i = 0; i < 12; i++)
            monthNames.push(I18N.global.t(`days.monthNamesCalendar.${i.toString()}`)) // Use I18N.global.t
        return monthNames
    },
    I18N,
}

export default translations
